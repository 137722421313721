import React, { useRef, useState } from "react";
import { Button, Form, Input, Divider, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useStore } from "../../../store";
import { putRequest, deleteRequest } from "../../../api/http";
import { useTab } from "../../../custom-hooks/useTab";
import { toast } from "react-toastify";
import { setTeamsAndRoles } from "../../../store/actions";
import userServiceApi from "../../../api/userServiceApi";

export default function TeamsConfigurations({ micrositeId }) {
  const [form] = Form.useForm();
  const { teams_and_roles, dispatch } = useStore();
  const { getOptions } = useTab();
  const [newItems, setNewItems] = useState([]);
  const [editID, setEditID] = useState();
  const inputRef = useRef(null);

  // const [itemFieldVal, setItemFieldVal] = useState('');

  const handleAdd = ({ itemField }) => {
    if (itemField.trim().length) {
      setNewItems([...newItems, itemField]);
      inputRef.current.value = "";
      inputRef.current.focus();
      form.resetFields();
    }
  };

  const handleRemove = (tagToRemove) => {
    setNewItems(newItems.filter((tag) => tag !== tagToRemove));
  };

  const handleSave = async () => {
    const moddedArray = newItems.map((item) => {
      return {
        title: item,
      };
    });
    try {
      const res = await putRequest(
        userServiceApi.saveTeamsCoursesProducts(micrositeId, "TEAMS_AND_ROLES"),
        moddedArray
      );
      if (res) {
        toast.success("New teams added");
        setNewItems([]);
        dispatch(setTeamsAndRoles(res));
      }
    } catch (error) { }
  };

  const handleDelete = async (team) => {
    try {
      const res = await deleteRequest(
        userServiceApi.deleteTeamsCoursesProduct(team.id)
      );
      if (res) {
        toast.success("Team deleted successfully");
        getOptions(micrositeId, "TEAMS_AND_ROLES");
      }
    } catch (error) { }
  };

  const handleEdit = async (value) => {
    // console.log("edited", value.productname, editID)
    let editArray = [
      {
        title: value.teamname,
        id: editID,
      },
    ];

    try {
      const res = await putRequest(
        userServiceApi.saveTeamsCoursesProducts(micrositeId, "TEAMS_AND_ROLES"),
        editArray
      );
      // console.log("res", res);
      if (res) {
        setEditID();
        toast.success("Product edited successfully");
        dispatch(setTeamsAndRoles(res));
      }
    } catch (error) {
      // toast.error("Something went wrong, try again later.")
    }
  };

  // console.log("newItems array", newItems)
  return (
    <div className="tabContent">
      <div className="add-field">
        <Form form={form} className="add-field-form" onFinish={handleAdd}>
          <Form.Item name="itemField">
            <Input ref={inputRef} className="input-field" placeholder="Enter new team name" />
          </Form.Item>
          <Form.Item>
            <Button className="greenBtn add-btn" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
      {newItems.length > 0 && (
        <>
          <div className="new-items">
            {newItems.map((newItem, index) => (
              <div className="tag" key={newItem}>
                <Tag
                  className="tags-component"
                  closable={true}
                  onClose={() => handleRemove(newItem)}
                >
                  {newItem}
                </Tag>
              </div>
            ))}
          </div>
          <div className="btn-container">
            <Button className="greenBtn SaveBtn" onClick={handleSave}>
              Save
            </Button>
          </div>
        </>
      )}
      <Divider />
      <div className="display">
        {teams_and_roles.map((team) => (
          <div className="product-item">
            {team.id === editID ? (
              <>
                <Form
                  onFinish={handleEdit}
                  className="item-edit-form"
                  initialValues={{
                    teamname: team.title,
                  }}
                >
                  <Form.Item name="teamname">
                    <Input className="edit-input" />
                  </Form.Item>
                  <>
                    <Form.Item>
                      <Button
                        style={{ marginRight: "5px" }}
                        className="greenBtn SaveBtn"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button className="SaveBtn" onClick={() => setEditID()}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </>
                </Form>
              </>
            ) : (
              <>
                <h3>{team.title}</h3>
                <div className="op-btns">
                  <div className="remove-btns">
                    <EditOutlined onClick={() => setEditID(team.id)} />
                  </div>
                  <div className="remove-btns">
                    <DeleteOutlined onClick={() => handleDelete(team)} />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
