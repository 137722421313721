
import { SET_TAB_DATA, SET_TAB_LOADER, SET_UPDATED_VALUES, SET_SKILLS, SET_TEAMS_AND_ROLES, SET_PRODUCT_GROUPS, SET_COURSES_QUALIFICATIONS, SET_SETTINGS, SET_MICROSITES, SET_MICROSITE_ACTIONS } from './constants'


export const setTabData = (tabData) => ({
    type: SET_TAB_DATA,
    tabData,
});

export const setTabLoader = (tabLoader) => ({
    type: SET_TAB_LOADER,
    tabLoader,
});

export const setUpdatedValues = (updatedValues) => ({
    type: SET_UPDATED_VALUES,
    updatedValues,
});


export const setSkills = (skills) => ({
    type: SET_SKILLS,
    skills,
});

export const setProductGroups = (product_groups) => ({
    type: SET_PRODUCT_GROUPS,
    product_groups,
});

export const setCoursesQualifications = (courses_qualifications) => ({
    type: SET_COURSES_QUALIFICATIONS,
    courses_qualifications,
});

export const setTeamsAndRoles = (teams_and_roles) => ({
    type: SET_TEAMS_AND_ROLES,
    teams_and_roles,
});

export const setSettings = (settings) => ({
    type: SET_SETTINGS,
    settings,
});


export const setMicroSites = (microSites) => ({
    type: SET_MICROSITES,
    microSites,
});


export const setMicroSiteActions = (microSiteActions) => ({
    type: SET_MICROSITE_ACTIONS,
    microSiteActions,
});