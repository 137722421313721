import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import "./Login.scss";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import userServiceApi from "../../api/userServiceApi";
import { getRequest } from "../../api/http";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";
import shareBtn from "../../assets/images/share.svg";

export default function Login() {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const location = useLocation();
  const urlPartner = location.pathname.split("/")[2];
  localStorage.setItem("loginPage", "true");

  const partnerID = urlPartner.replace(/_/g, " ");

  localStorage.setItem("partnerID", partnerID);
  // sessionStorage.setItem("partnerID", partnerID);
  // console.log(partner)
  const [partnerData, setPartnerData] = useState({});

  const getPartnerDetailsData = async (partnerID) => {
    // console.log("APICall entry")
    try {
      // setLoading(true);
      const response = await getRequest(
        userServiceApi.getPartnerDetailsLoginScreen(partnerID)
      );
      if (response && response.data) {
        setPartnerData(response.data);
        localStorage.setItem("partner", response.data.name);
      }
    } catch (error) {
      // setLoading(false);
      toast.error(error.error);
    }
  };

  useEffect(() => {
    // !isMobile ? setIsMobileView(true) : "";
    if (isMobile) {
      setIsMobileView(true);
    }
    setTimeout(() => {
      getPartnerDetailsData(partnerID);
      // setLoader(false);
    }, 1000);
  }, []);

  if (partnerData) {
    return (
      <div
        className="login-bg"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(103, 121, 148, 0.685), rgba(2, 102, 202, 0.601)), url(${partnerData?.wallpaper})`,
        }}
      >
        {/* {isMobile ? (
          // toast.error("Better viwed on larger screens")
          toast.info('Better viewed on larger screens!', {
            toastId: '',
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        ) : ""} */}
        {!isMobileView ? (
          <div className="share-btn">
            <RWebShare
              data={{
                // text: "Web Share - GfG",
                url: location.pathname,
                // title: "GfG",
              }}
            >
              {/* <ShareAltOutlined /> */}
              <img src={shareBtn} />
            </RWebShare>
          </div>
        ) : (
          ""
        )}

        {/* <div className="login-main-container"> */}
        {!isMobileView ? (
          // <div>
          <div className="login-main-container">
            <div className="login-left-panel">
              <div className="header">
                <span>{partnerData.siteName}</span>
              </div>
              <div className="content">
                <span>{partnerData.description}</span>
              </div>
            </div>
            <div className="login-right-panel">
              <div className="card-login">
                <div className="logo">
                  <img src={partnerData.mediaLink} />
                  {/* <img src={partnerData.mediaLink} /> */}
                  {/* <img src={JLRLogo} /> */}
                </div>
                {isLoginMode ? (
                  <LoginForm />
                ) : (
                  <ForgotPasswordForm setIsLoginMode={setIsLoginMode} />
                )}
                <div className="links">
                  <NavLink to="/signup" className="sign-up">
                    Sign up
                  </NavLink>
                  <NavLink to="/forgot-password" className="forgot-password">
                    Forgot Password
                  </NavLink>
                  {/* 
                <p
                  className="forgot-password"
                  onClick={() => setIsLoginMode(!isLoginMode)}
                >
                  {isLoginMode ? "Forgot Password?" : "Cancel"}
                </p> */}
                </div>
                <div className="footer d-flex">
                  <div
                    className="footer-content"
                    onClick={() =>
                      window.open("https://www.connectsu.co.uk/", "_blank")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {/* <span>Powered by</span> */}
                    <div className="footer-logo">
                      <img
                        src={require("../../assets/images/PoweredNew.png")}
                        alt="company logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="login-main-container"></div>
        )}
        {/* </div> */}
        <Modal
          // width={250}
          // style={{ top: "30vh", right: "-73vh" }}
          open={isMobileView}
          onCancel={() => setIsMobileView(false)}
          footer={null}
          closable={false}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>
              Please use laptop/desktop for creating and editing your Microsite
            </h3>
          </div>
        </Modal>
      </div>
    );
  }
}
