import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import jwt_decode from "jwt-decode";
import Button from "../buttons/Button";
import FormLabel from "../formLabel/FormLabel";
import userServiceApi from "../../api/userServiceApi";
import { getRequest, postRequest } from "../../api/http";
import { SyncOutlined } from "@ant-design/icons";

export default function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [loginAlert, setLoginAlert] = useState(false);
  const [wrongCred, setWrongCred] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const response = await postRequest(userServiceApi.login(), values);
      if (response) {
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("refresh", response.refreshToken);
        const decoded = jwt_decode(response.accessToken);
        localStorage.setItem("loginPage", 'false');

        // console.log("decoded", decoded);
        // console.log("decoded time", new Date(decoded.exp * 1000))
        localStorage.setItem("OrgEmail", decoded.email);
        if (decoded.role === "ORGANIZATION_ADMIN") {
          try {
            const headers = {
              Authorization: `Bearer ${response.accessToken}`,
            };
            const OrgResponse = await getRequest(
              userServiceApi.adminOrganization(),
              { headers }
            );
            if (OrgResponse) {
              localStorage.setItem("OrgId", OrgResponse.data.id);
              localStorage.setItem("OrgImage", OrgResponse.data.mediaLink);
              localStorage.setItem("OrgName", OrgResponse.data.name);
              // localStorage.setItem("isOrganizationProfilePending", OrgResponse.data.isOrganizationProfilePending);

              // toast.success("Login Successfully!");
              if (OrgResponse.data.isOrganizationProfilePending === true) {
                navigate("/edit-organization", {
                  state: { tab: "organizationDetailsTab" },
                });
              } else {
                navigate("/");
              }
            }
          } catch (error) {
            // toast.error(error.message);
            setLoading(false);
          }
        } else {
          setLoading(false);
          setWrongCred(true);
          // toast.error("Login using organization admin credentials")
        }
      } else {
        setLoginAlert(true);
        setLoading(false);
      }
    } catch (error) {
      // toast.error(error.error, {
      //   autoClose: 10000
      // });
      setLoading(false);
    }
  };

  // if (loginAlert) {
  //   console.log("Alerted")
  // }

  return (
    <div className="login-form-container">
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        className="form-container"
        onFinish={(values) => handleLogin(values)}
      >
        <Form.Item
          label={<FormLabel label="Email" className="form-label" />}
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email",
            },
            {
              type: "email",
              message: "Invalid Email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label={<FormLabel label="Password" className="form-label" />}
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password placeholder="Enter password" />
        </Form.Item>
        {loginAlert ? (
          <label style={{ color: "red" }}>
            You have entered an invalid username of password
          </label>
        ) : wrongCred ? (
          <label style={{ color: "red" }}>
            Login using organisation admin credentials
          </label>
        ) : (
          ""
        )}
        <Button
          label="Login"
          width="100%"
          type="submit"
          className="login-button"
          loading={loading && <SyncOutlined spin />}
        />
      </Form>
    </div>
  );
}
