import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Switch,
  Radio,
  Row,
  Col,
  Button,
  Modal,
  Spin,
} from "antd";
import userServiceApi from "../../api/userServiceApi";
import { getRequest } from "../../api/http";
import TextArea from "antd/lib/input/TextArea";

export default function SignupForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState({});
  const [selectedOption, setselectOption] = useState({});
  const [countries, setCountries] = useState([]);
  const [partnerData, setPartnerData] = useState({});
  const partner = localStorage.getItem("partner");
  const partnerID = localStorage.getItem("partnerID");
  const [member, setMember] = useState(
    sessionStorage.getItem("isactive")
      ? sessionStorage.getItem("isactive")
      : false
  );
  const [allOrgTypes, setAllOrgTypes] = useState([]);

  const getOnOrganisationType = async () => {
    try {
      const response = await getRequest(
        userServiceApi.getOnOrganisationType(partnerID)
      );
      if (response) {
        const responseList = response.data;
        setAllOrgTypes(responseList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOnOrganisationType();
  }, []);

  const getPartnerDetailsData = async (partnerID) => {
    try {
      // setLoading(true);
      const response = await getRequest(
        userServiceApi.getPartnerDetailsLoginScreen(partnerID)
      );
      if (response && response.data) {
        setPartnerData(response.data);
      }
    } catch (error) {
      // setLoading(false);
      // toast.error(error.error);
    }
  };

  const fetchSectorList = async () => {
    try {
      // setLoading(true);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(
        userServiceApi.getAllSectors(partnerID),
        {
          headers,
        }
      );
      setSectors(response.data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const sectorChange = async (sectorId, i) => {
    setselectOption((prev) => {
      prev[i] = sectorId;
      return { ...prev };
    });
    try {
      // setLoading(true);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(
        userServiceApi.getSubSectors(sectorId),
        { headers }
      );

      setSubSectors((prev) => {
        prev[sectorId] = response.data;
        return { ...prev };
      });
      if (response) {
        form.setFieldValue(
          ["sectorsOutputs", i, "subSectorOutput", "name"],
          ""
        );
      }
      // setLoading(false);
    } catch (error) {
      // toast.error(error.error);
      // setLoading(false);
    }
  };

  const fetchCountryList = async () => {
    try {
      // setLoading(true);
      const response = await getRequest(userServiceApi.getAllCountries());
      setCountries(response.data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const onFinish = async (values) => {
    // let dateOfBirth = moment(new Date(values.dateOfBirth)).format("DD-MM-YYYY");
    // let submit = { ...values };

    // console.log("vals", values);
    let formData = {
      addressStreet: values?.addressStreet,
      email: values?.email,
      isActiveMember: member,
      name: values?.name,
      phone: values?.phone,
      postcode: values?.postcode,
      size: values?.size,
      typeId: values?.type,
      userName: values?.userName,
      websiteLink: values?.websiteLink,
    };

    sessionStorage.setItem("adr", values?.addressStreet);
    sessionStorage.setItem("email", values?.email);
    sessionStorage.setItem("isactive", member);
    sessionStorage.setItem("name", values?.name);
    sessionStorage.setItem("phone", values?.phone);
    sessionStorage.setItem("postcode", values?.postcode);
    sessionStorage.setItem("size", values?.size);
    sessionStorage.setItem("userName", values?.userName);
    sessionStorage.setItem("webLink", values?.websiteLink);
    const typeName = allOrgTypes.find((type) => type.id === formData.typeId)?.type || "";
    sessionStorage.setItem("typeId", values.type);
    sessionStorage.setItem("type", typeName);
    navigate("/terms", {
      state: {
        formData,
        nameType: typeName,
      },
    });
  };
  const onFinishFailed = (errorInfo) => { };

  useEffect(() => {
    const storedSwitchValue = sessionStorage.getItem("isactive");
    // console.log("stored", storedSwitchValue)
    if (storedSwitchValue !== null) {
      // Convert the stored value to boolean if necessary
      setMember(storedSwitchValue === "true");
    }
  }, []);

  const validatePostalCode = (value) => {
    const ukPostalCodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/;
    if (ukPostalCodeRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  // const GoToLoginHandler = () => {
  //   navigate("/login");
  // };

  const redirectHandler = () => {
    sessionStorage.clear();
    if (isMobile) {
      setAlertModal(true);
    } else {
      navigate(`/login/${partnerID}`);
    }
  };

  useEffect(() => {
    // !isMobile ? setIsMobileView(true) : "";
    // if (isMobile) {
    //   setIsMobileView(true)
    // }
    setTimeout(() => {
      getPartnerDetailsData(partnerID);
      // setLoader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    fetchCountryList();
    fetchSectorList();
  }, []);

  return (
    <React.Fragment>
      <div className="headerBannerRow">
        <div className="headerBannerCol">
          <div className="container">
            <img
              className="signup-logo"
              style={{ cursor: "pointer" }}
              src={partnerData.mediaLink}
              alt=""
              onClick={redirectHandler}
            />
            {/* <label>{partnerData.siteName}</label> */}
            <h3 className="signUpHeading">
              Add your organisation to {partnerData.siteName}
            </h3>
          </div>
        </div>
      </div>
      {/* {console.log("test", partnerData?.organizationTypes?.length, partnerData?.organizationTypes)} */}
      {/* {console.log("first ", partnerData?.organizationTypes[0])} */}
      <div className="changePwdContainer">
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            // type: partnerData?.organizationTypes?.length > 0
            //   ? partnerData?.organizationTypes[0]
            //   : null,
            type: sessionStorage.getItem("typeId")
              ? sessionStorage.getItem("typeId")
              : allOrgTypes.length > 0
                ? allOrgTypes[0]?.id
                : "",
            isActiveMember: sessionStorage.getItem("isactive")
              ? sessionStorage.getItem("isactive")
              : false,
            name: sessionStorage.getItem("name")
              ? sessionStorage.getItem("name")
              : "",
            addressStreet: sessionStorage.getItem("adr")
              ? sessionStorage.getItem("adr")
              : "",
            postcode: sessionStorage.getItem("postcode")
              ? sessionStorage.getItem("postcode")
              : "",
            size: sessionStorage.getItem("size")
              ? sessionStorage.getItem("size")
              : "MICRO",
            userName: sessionStorage.getItem("userName")
              ? sessionStorage.getItem("userName")
              : "",
            email: sessionStorage.getItem("email")
              ? sessionStorage.getItem("email")
              : "",
            phone: sessionStorage.getItem("phone")
              ? sessionStorage.getItem("phone")
              : "",
            websiteLink: sessionStorage.getItem("webLink")
              ? sessionStorage.getItem("webLink")
              : "",
          }}
        >
          {/* <h3 className="signUpHeading">Add your organisation to {partnerData.siteName}</h3> */}
          <Row
            className="rowBody"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          ></Row>
          <div className="changePwdBody">
            {loading ? (
              <div className="data-loader">
                <Spin tip="Loading" size="large" />
              </div>
            ) : (
              <>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item org-type-radio"
                    label="Organisation type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Organisation name is required",
                      },
                    ]}
                  >
                    <Radio.Group buttonStyle="solid">
                      {allOrgTypes?.length > 0
                        ? allOrgTypes.map((type) => (
                          <Radio.Button
                            key={type.id}
                            value={type.id}
                            style={{ margin: "3px", borderRadius: "5px", borderLeft: "1px solid #d9d9d9" }}
                          >
                            {type.type}
                          </Radio.Button>
                        ))
                        : null}
                    </Radio.Group>
                    {/* <Select placeholder="Select Option">
                      {OrganiationTypes.map((OrganiationType, i) => (
                        <Select.Option key={i} value={OrganiationType.id}>
                          {OrganiationType.name}
                        </Select.Option>
                      ))}
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item member-form switchItem"
                    label="Are you an existing member"
                    name="isActiveMember"
                    rules={[
                      { required: true, message: "Active member is required" },
                    ]}
                  >
                    <div className="flexSwitch">
                      <Switch
                        className="member-form-switch"
                        // defaultChecked={member}
                        checked={member}
                        // value={member}
                        // checked={member}
                        onChange={(e) => {
                          if (e) {
                            setMember(true);
                          } else {
                            setMember(false);
                          }
                        }}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                      />
                      <div className="memberCondition">{`${partnerData?.name ? partnerData?.name : ""
                        }`}</div>
                    </div>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Organisation name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Organisation name is required",
                      },
                    ]}
                  >
                    <Input placeholder="Organisation name" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Address"
                    name="addressStreet"
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Full address including postal code (You need to be in our catchment area)"
                    />
                  </Form.Item>
                  {/* <span className="adrInfo" style={{color:"red",marginLeft:"215px"}}></span> */}
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Postal code"
                    name="postcode"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => validatePostalCode(value),
                        message: "Please enter a valid UK postal code",
                      },
                    ]}
                  >
                    <TextArea rows={1} placeholder="postal code" />
                  </Form.Item>
                  {/* <span className="adrInfo" style={{color:"red",marginLeft:"215px"}}></span> */}
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Organisation size"
                    name="size"
                    rules={[
                      {
                        required: true,
                        message: "Organisation size is required",
                      },
                    ]}
                  >
                    <Radio.Group defaultValue="a" buttonStyle="solid">
                      <Radio.Button value="MICRO" style={{ margin: "3px", borderRadius: "5px", borderLeft: "1px solid #d9d9d9" }}>Micro 0-9</Radio.Button>
                      <Radio.Button value="SMALL" style={{ margin: "3px", borderRadius: "5px", borderLeft: "1px solid #d9d9d9" }}>Small 10-49</Radio.Button>
                      <Radio.Button value="MEDIUM" style={{ margin: "3px", borderRadius: "5px", borderLeft: "1px solid #d9d9d9" }}>Medium 50-249</Radio.Button>
                      <Radio.Button value="LARGE" style={{ margin: "3px", borderRadius: "5px", borderLeft: "1px solid #d9d9d9" }}>Large 249+</Radio.Button>
                    </Radio.Group>
                    {/* <Select placeholder="Select Option">
                      {OrganiationSizes.map((OrganiationSize, i) => (
                        <Select.Option key={i} value={OrganiationSize.id}>
                          {OrganiationSize.name}
                        </Select.Option>
                      ))}
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Full name"
                    name="userName"
                    rules={[
                      { required: true, message: "Full name is required" },
                      {
                        pattern: new RegExp(/^[a-zA-Z_ ]*$/),
                        message: "Field does not accept numbers",
                      },
                    ]}
                  >
                    <Input placeholder="Full name" />
                  </Form.Item>
                </Col>
                {/* <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="First name"
                    name="firstName"
                    rules={[{ required: true, message: "FirstName is required" }, {
                      pattern: new RegExp(/^[a-zA-Z_ ]*$/),
                      message: "Field does not accept numbers"
                    }]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Last name"
                    name="lastName"
                    rules={[{ required: true, message: "Last name is required" }, {
                      pattern: new RegExp(/^[a-zA-Z_ ]*$/),
                      message: "Field does not accept numbers"
                    }]}
                  >
                    <Input placeholder="Last name" />
                  </Form.Item>
                </Col> */}
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Enter valid email" },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Phone number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        // pattern: new RegExp(/^[0-9\b]+$/),
                        message: "Phone number is required",
                      },
                      {
                        pattern: /^[0-9\s]+$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Phone number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item
                    className="form-item"
                    label="Organisation website"
                    name="websiteLink"
                  >
                    <Input placeholder="Website" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={24}>
                </Col>
                <Col className="gutter-row submitBtn" xs={24} xl={24}>
                  <div className="signUpBtns">
                    <Button className="greenBtn signup-form-next-btn" htmlType="submit">
                      Next
                    </Button>
                  </div>
                </Col>
              </>
            )}
          </div>
        </Form>
        <Modal
          width={450}
          style={{ top: "30vh" }}
          open={alertModal}
          onCancel={() => setAlertModal(false)}
          footer={null}
          closable={false}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>Please use a laptop or a desktop to login</h3>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}
