import React, { useEffect, useState } from "react";
import { Rate, Progress, Button, List, Form, Input, Modal } from "antd";
import { toast } from "react-toastify";
import { CommentOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../../../../../api/http";
import userServiceApi from "../../../../../../api/userServiceApi";
import "./reviews.scss";

const Reviews = (val) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [micrositeId, setMicrositeId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [deletingReply, setDeletingReply] = useState({ id: null, index: null });
  const [deleteReviewId, setDeleteReviewId] = useState();
  const [replyTexts, setReplyTexts] = useState({});

  const openDeleteReplyModal = (replyId, index) => {
    setDeletingReply({ id: replyId, index });
  };

  const closeDeleteReplyModal = () => {
    setDeletingReply({ id: null, index: null });
  };

  const openModal = (reviewId) => {
    setDeleteReviewId(reviewId);
    // console.log("reviewId", reviewId)
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setRejectReason("");
  };

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    setMicrositeId(lastPart);

    if (micrositeId) {
      fetchData();
    }
  }, [micrositeId]);

  const fetchData = () => {
    const token = localStorage.getItem("token");

    getRequest(userServiceApi.getReviews(micrositeId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("res", response.data)
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };



  const calculateAverageRating = () => {
    if (data.length === 0) return 0;

    const totalRating = data.reduce((sum, item) => sum + item.rating, 0);
    const averageRating = totalRating / data.length;

    return Number(averageRating);
  };
  const avgrate = calculateAverageRating();

  const calculateRatingDistribution = () => {
    if (data.length === 0) {
      return {};
    }

    const ratingCounters = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    data.forEach((item) => {
      const roundedRating = Math.floor(item.rating);
      ratingCounters[roundedRating] += 1;
    });

    const totalCount = data.length;

    const ratingPercentages = {
      1: (ratingCounters[1] / totalCount) * 100,
      2: (ratingCounters[2] / totalCount) * 100,
      3: (ratingCounters[3] / totalCount) * 100,
      4: (ratingCounters[4] / totalCount) * 100,
      5: (ratingCounters[5] / totalCount) * 100,
    };

    return ratingPercentages;
  };

  const ratingPercentages = calculateRatingDistribution();

  const handleReplySubmit = (review) => {
    const token = localStorage.getItem("token");

    const replyData = {
      parentId: review.id,
      mobileNumber: review.mobileNumber,
      isPartnerApproved: true,
      description: replyTexts[review.id] || "",
      rating: review.rating,
      micrositeName: review.micrositeName,
      micrositeId: review.micrositeId,
      replies: null,
    };

    postRequest(userServiceApi.replyReview(), replyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success("Reply added successfully", response.data);
        setReplyTexts((prev) => ({
          ...prev,
          [review.id]: "",
        }));
        fetchData();
      })
      .catch((error) => {
        toast.error("Error adding reply:", error);
      });
  };

  const setReplyText = (reviewId, text) => {
    setReplyTexts((prev) => ({
      ...prev,
      [reviewId]: text,
    }));
  };

  const [replyFormOpen, setReplyFormOpen] = useState({});

  const toggleReplyForm = (reviewId) => {
    setReplyFormOpen((prevState) => ({
      ...prevState,
      [reviewId]: !prevState[reviewId],
    }));
  };

  const handleDeleteReview = (rejectReason) => {
    const isPending = data.some(
      (item) => item.id === deleteReviewId && item.partnerApprovalStatus === "PENDING"
    );

    if (isPending) {
      toast.warning("Delete Request already sent to partner");
      setModalVisible(false);
      setRejectReason("");
    } else {
      const token = localStorage.getItem("token");
      try {
        const res = putRequest(userServiceApi.deleteReview(deleteReviewId), rejectReason, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        if (res) {
          setModalVisible(false);
          toast.success("Delete Request sent to partner", res.data);
          fetchData();
          setRejectReason("");
          setDeleteReviewId();
        }
      } catch (error) {

      }
    }
  };

  const handleDeleteReply = (reviewId) => {
    const token = localStorage.getItem("token");

    deleteRequest(userServiceApi.deleteReviewReply(reviewId))
      .then((response) => {
        toast.success("Reply deleted successfully", response.data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting reply:", error);
      });
  };


  return (
    <div className="tabContent">
      <div className="rating-container">
        <div className="avg-rating-container">
          <div className="avg-rating">
            {avgrate.toFixed(1)}
          </div>
          <div className="star-rating">
            <Rate className="star-ratings" allowHalf value={avgrate} disabled />
          </div>
          <div className="total-reviews">
            <p>({data.length} Reviews)</p>
          </div>
        </div>
        <div className="single-ratings">
          <div className="single-rating-line">
            <span className="number">5</span>
            <Progress
              style={{ width: "80%", marginBottom: "10px" }}
              percent={ratingPercentages[5]}
              format={(percent) => `${percent.toFixed(2)}%`}
              showInfo={false}
            />
          </div>
          <div className="single-rating-line">
            <span className="number">4</span>
            <Progress
              style={{ width: "80%", marginBottom: "10px" }}
              percent={ratingPercentages[4]}
              format={(percent) => `${percent.toFixed(2)}%`}
              showInfo={false}
            />
          </div>
          <div className="single-rating-line">
            <span className="number">3</span>
            <Progress
              style={{ width: "80%", marginBottom: "10px" }}
              percent={ratingPercentages[3]}
              format={(percent) => `${percent.toFixed(2)}%`}
              showInfo={false}
            />
          </div>
          <div className="single-rating-line">
            <span className="number">2</span>
            <Progress
              style={{ width: "80%", marginBottom: "10px" }}
              percent={ratingPercentages[2]}
              format={(percent) => `${percent.toFixed(2)}%`}
              showInfo={false}
            />
          </div>
          <div className="single-rating-line">
            <span className="number">1</span>
            <Progress
              style={{ width: "80%", marginBottom: "10px" }}
              percent={ratingPercentages[1]}
              format={(percent) => `${percent.toFixed(2)}%`}
              showInfo={false}
            />
          </div>
        </div>
      </div>
      <div className="review-container">
        <List
          dataSource={data}
          renderItem={(item) => (
            <div className="review">
              <Rate
                allowHalf
                value={item.rating}
                disabled
                className="review-rating"
              />
              <Button
                type="link"
                icon={<CommentOutlined />}
                onClick={() => toggleReplyForm(item.id)}
                style={{ marginLeft: 10 }}
              />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                style={{ marginLeft: 10 }}
                onClick={() => openModal(item.id)}
              />
              <div>{item.description}</div>
              {replyFormOpen[item.id] && (
                <div>
                  <Form>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder="Your reply..."
                        value={replyTexts[item.id] || ""}
                        onChange={(e) => setReplyText(item.id, e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => handleReplySubmit(item)}
                      >
                        Reply
                      </Button>
                    </Form.Item>
                  </Form>
                  {item.replies && item.replies.length > 0 && (
                    <List
                      style={{ marginLeft: 20 }}
                      dataSource={item.replies}
                      renderItem={(reply, index) => (
                        <div className="replies-container">
                          <div
                            className="reply"
                            style={{
                              display: "flex",
                              // justifyContent: "space-between",
                            }}
                          >
                            <p>{reply.description}</p>
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                              onClick={() =>
                                openDeleteReplyModal(reply.id, index)
                              }
                            />
                          </div>
                          {index < item.replies.length - 1 && (
                            <hr
                              style={{ borderColor: "lightgray", opacity: 0.3 }}
                            />
                          )}
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
              <hr style={{ borderColor: "lightgray", opacity: 0.5 }} />
            </div>
          )}
        />
      </div>
      <Modal
        title="Reason for Deletion"
        visible={modalVisible}
        onOk={() => handleDeleteReview(rejectReason)}
        onCancel={closeModal}
      >
        <Form>
          <Form.Item label="Reason">
            <Input
              type="text"
              placeholder="Enter reason for deletion"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={deletingReply.id !== null}
        onOk={() => {
          handleDeleteReply(deletingReply.id);
          closeDeleteReplyModal();
        }}
        onCancel={closeDeleteReplyModal}
      >
        <p>Are you sure you want to delete this reply?</p>
      </Modal>
    </div>
  );
};

export default Reviews;
