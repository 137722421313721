import { useStore } from "../store";
import { getMediaProperty } from "../utils";
import { setUpdatedValues } from "../store/actions";
import { toast } from "react-toastify";


export function useConnectsuForm() {

    const { dispatch, updatedValues, } = useStore();
    const updateImage = (event, i, form, tabKey) => {
        if (event.target.files && event.target.files[0].size >= 2e+6) {
            toast.error('File should not exceed 2MB')
        } else {

            const fields = form.getFieldValue(tabKey);
            if (fields && fields.length > 0) {

                const media = getMediaProperty(event);
                const modifiedData = fields.map((d, indx) => {
                    if (indx === i) {
                        d.mediaLink = { ...d.mediaLink, ...media.mediaProperty }
                        d.blob = media.blob
                    }
                    return d
                })

                form.resetFields();
                form.setFieldsValue({
                    [tabKey]: modifiedData
                });

                var prev = updatedValues;

                const prevItems = prev[tabKey];
                if (prevItems) {
                    const names = [...prevItems, i];
                    const removeDuplicates = names.filter(function (
                        item,
                        index,
                        inputArray
                    ) {
                        return inputArray.indexOf(item) == index;
                    });
                    prev[tabKey] = removeDuplicates;
                } else {
                    prev[tabKey] = [i];
                }
                dispatch(setUpdatedValues(prev))
            }
        }
    }

    const updateAccreditationImage = (event, i, form, tabKey) => {
        if (event.target.files && event.target.files[0].size >= 4e+6) {
            toast.error('File should not exceed 4MB')
        }
        else {

            const fields = form.getFieldValue(tabKey);
            if (fields && fields.length > 0) {
                const { mediaProperty: outerMediaProperty, blob } = getMediaProperty(event);
                const { mediaProperty } = outerMediaProperty

                const modifiedData = fields.map((d, indx) => {
                    if (indx === i) {
                        d.awardsLogos = {
                            "mediaProperty": {
                                ...mediaProperty,
                                name: `${mediaProperty.id}_${mediaProperty.name}`,
                            }, mediaType: "IMAGE"
                        }
                        d.blob = blob
                    }
                    return d
                })
                form.resetFields();
                form.setFieldsValue({
                    [tabKey]: modifiedData
                });

                var prev = updatedValues;

                const prevItems = prev[tabKey];
                if (prevItems) {
                    const names = [...prevItems, i];
                    const removeDuplicates = names.filter(function (
                        item,
                        index,
                        inputArray
                    ) {
                        return inputArray.indexOf(item) == index;
                    });
                    prev[tabKey] = removeDuplicates;
                } else {
                    prev[tabKey] = [i];
                }
                dispatch(setUpdatedValues(prev))
            }
        }
    }

    const updateFile = (event, i, form, tabKey) => {

        // Check file size and show error if it exceeds 2MB
        if (event.target.files && event.target.files[0].size >= 5e+6) {
            toast.error('File should not exceed 5MB');
        } else {
            // Retrieve current form fields for the given tabKey
            const fields = form.getFieldValue(tabKey);


            if (fields && fields.length > 0) {
                // Update the specific field with the new file
                const modifiedData = fields.map((d, indx) => {
                    if (indx === i) {
                        d.BrochureFile = event.target.files[0]; // Ensure this is the correct field
                    }
                    return d;
                });


                // Reset and set form fields with the updated data
                form.resetFields();
                form.setFieldsValue({
                    [tabKey]: modifiedData
                });

                // Correctly update the state of updatedValues
                var prev = { ...updatedValues }; // Ensure prev is a copy of updatedValues
                const prevItems = prev[tabKey] || []; // Initialize to an empty array if undefined
                if (!prevItems.includes(i)) {
                    prevItems.push(i); // Add the index if not already present
                }
                prev[tabKey] = prevItems;
                // Dispatch the updated state
                dispatch(setUpdatedValues(prev));
            }
        }
    };



    const rerenderForm = (form) => {
        const fields = form.getFieldsValue(true);
        form.resetFields();
        form.setFieldsValue(fields);
    }
    return {
        updateImage,
        rerenderForm,
        updateAccreditationImage,
        updateFile
    }
}