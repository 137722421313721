import React from "react";

export default function FormLabel(props) {
  return (
    <div>
      <p style={{ margin: 0, lineHeight: 0.5 }} className={`text-semi-bold ${props.className}`}>
        {props.label}
      </p>{" "}
      {props.subLabel && <span style={{ fontSize: 12 }}>{props.subLabel}</span>}
    </div>
  );
}

FormLabel.defaultProps = {
  label: "Input Label",
  subLabel: null,
};
