import React, { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import userServiceApi from "../api/userServiceApi";
import { useStore } from "../store";
import { setTabData, setSkills, setTeamsAndRoles, setCoursesQualifications, setProductGroups } from "../store/actions";
import { getRequest, deleteRequest, postRequest, putRequest } from "../api/http";
import { vacancyKeys, contract, contactUSkeys, aboutUsEnumsArr } from "../config";
import { contactEnumsArr } from "../config";
import { useParams } from "react-router-dom";

export function useTab() {
  const { dispatch } = useStore();
  const { micrositeId } = useParams();

  const [loader, setLoader] = useState(true);
  const [postLoader, setPostLoader] = useState(false);

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const getSingleTabData = async (tabKey, form) => {
    try {
      setLoader(true);

      const getApiEndpoint = () => {
        if (aboutUsEnumsArr.includes(tabKey)) {
          return userServiceApi.getAboutUsDetails(tabKey, micrositeId);
        }
        else if (contactEnumsArr.includes(tabKey)) {
          return userServiceApi.getContactUsDetails(tabKey, micrositeId);
        }
        else if (vacancyKeys.includes(tabKey)) {
          return userServiceApi.getMicrositeDetailsVacancyTab(tabKey, micrositeId);
        } else if (contract === tabKey) {
          return userServiceApi.getMicrositeDetailsContractTab(micrositeId);
        } else {
          return userServiceApi.getMicrositeDetailsTabs(tabKey, micrositeId);
        }
      };

      const response = await getRequest(getApiEndpoint(), { headers });

      if (response?.data) {
        form?.resetFields();

        const processedData = response.data.map(d => {
          if (d) {
            if (d.closingDate) d.closingDate = moment(d.closingDate);
            if (d.closeDate) d.closeDate = moment(d.closeDate);
            if (d.startDate) d.startDate = moment(d.startDate);
            if (d.endDate) d.endDate = moment(d.endDate);
            if (d.closeTime) d.closeTime = moment(d.closeTime);
          }
          return d;
        });

        dispatch(setTabData({ mainKey: tabKey, data: processedData }));
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoader(false);
    }
  };


  const deleteSingleItem = async (tabKey, id, form) => {
    let tempTabKey = tabKey
    if (aboutUsEnumsArr.includes(tabKey)) {
      tempTabKey = "about-us"
    }
    try {
      // setPostLoader(true);
      console.log("in delete");
      const response = await deleteRequest(tempTabKey === 'ADDRESS' ?
        userServiceApi.updateContactUsSales(id)
        :
        vacancyKeys.includes(tempTabKey)
          ? userServiceApi.deleteApprenticeshipVacancies(micrositeId, tempTabKey, id)
          : contract === tempTabKey
            ? userServiceApi.deleteContractTab(id)
            : userServiceApi.deleteMicrositeDetailsTabs(micrositeId, tempTabKey, id)
      );
      if (response && response.id) {
        toast.success("Deleted successfully");
        getSingleTabData(tabKey, form);
      } else {
        toast.error("Failed to delete!!");
      }
    } catch (error) {
      toast.error(error.error);
    }
    finally {
      setPostLoader(false);
    }
  };

  const createNewItem = async (tabKey, data, form) => {
    var headerList = {};
    console.log("==in create new item")
    if (contactEnumsArr.includes(tabKey)) {
      headerList = { headers };
    } else {
      headerList = {
        headers: { "Content-Type": "multipart/form-data" },
        headers,
      };
    }

    try {
      setPostLoader(true);
      let apiCall;
      if (contactEnumsArr.includes(tabKey)) {
        if (tabKey === contactUSkeys.address) {
          apiCall = userServiceApi.createContactUsAddress(micrositeId);
        }
        else {
          apiCall = userServiceApi.createContactUsSales(micrositeId);
          data = data[0];
        }
      }
      else if (aboutUsEnumsArr.includes(tabKey)) {
        apiCall = userServiceApi.putAboutUsDetails(micrositeId);
      }
      else if (vacancyKeys.includes(tabKey)) {
        apiCall = userServiceApi.createApprenticeshipVacancies(micrositeId, tabKey);
      } else if (contract === tabKey) {
        apiCall = userServiceApi.createContractDetails(micrositeId);
      } else {
        apiCall = userServiceApi.createMicrositeTabs(tabKey, micrositeId);
      }

      let response
      if (tabKey === contactUSkeys.address || aboutUsEnumsArr.includes(tabKey)) {
        response = await putRequest(apiCall, data, headerList);
      }
      else {
        response = await postRequest(apiCall, data, headerList);
      }

      if (response) {
        console.log("api calleddd response", response)
        setPostLoader(false);
        getSingleTabData(tabKey, form);
        toast.success("Data created successfully");
      } else {
        console.log('error posting data')
      }
    } catch (error) {
      console.log('error posting data', error)

    }
    finally {
      setPostLoader(false);
    }
  };

  const updateItems = async (tabKey, data, isAdd, form) => {
    setPostLoader(true)
    var headerList = {};
    console.log("==in update item")

    if (contactEnumsArr.includes(tabKey)) {
      headerList = { headers };
    } else {
      headerList = {
        headers: { "Content-Type": "multipart/form-data" },
        headers,
      };
    }

    try {
      setPostLoader(true);

      let apiCall;

      if (contactEnumsArr.includes(tabKey)) {

        if (contactUSkeys.address === tabKey) {
          console.log('passing id to update', micrositeId)
          apiCall = userServiceApi.createContactUsAddress(micrositeId);
        }
        else {
          apiCall = userServiceApi.updateContactUsSales(data[0].sectionBlockId, micrositeId);
          data = data[0];
        }
      }
      else if (aboutUsEnumsArr.includes(tabKey)) {
        apiCall = userServiceApi.putAboutUsDetails(micrositeId);
      }
      else if (vacancyKeys.includes(tabKey)) {
        apiCall = userServiceApi.createApprenticeshipVacancies(micrositeId, tabKey);
      } else if (contract === tabKey) {
        apiCall = userServiceApi.updateContractDetails(micrositeId);
      } else {
        apiCall = userServiceApi.updateMicrositeDetailsTabs(tabKey, micrositeId);
      }


      let response;
      response = await putRequest(apiCall, data, headerList)

      if (response) {
        console.log("api called response", response)
        getSingleTabData(tabKey, form);
        if (isAdd) {
          toast.success("Data created successfully");
        } else {
          toast.success("Data updated successfully");
        }
      } else {
        console.log('failed to update')
      }
    } catch (error) {
      console.log('failed to update', error)
    }
    finally {
      setPostLoader(false);
    }
  };

  const callMicroServices = (tabKey, formData, form) => {
    if (formData["newData"]) {
      if (tabKey == "why-work") {
        updateItems(tabKey, formData["newData"], true, form);
      } else {
        console.log("----in microservice new", formData["newData"])
        createNewItem(tabKey, formData["newData"], form);
      }
    }

    if (formData["extraData"]) {
      if (vacancyKeys.includes(tabKey)) {
        // createNewItem(tabKey, formData["extraData"], form);
        updateItems(tabKey, formData["extraData"], true, form);
      } else if (contract === tabKey) {
        console.log("----in microservice extra", formData["extraData"])
        createNewItem(tabKey, formData["extraData"], form);
      } else {
        updateItems(tabKey, formData["extraData"], true, form);
      }
    }
    if (formData["editedData"]) {
      if (vacancyKeys.includes(tabKey) || contract === tabKey) {
        // createNewItem(tabKey, formData["editedData"], form);
        console.log("----in microservice edited", formData["edited"])
        updateItems(tabKey, formData["editedData"], false, form);
      } else {
        updateItems(tabKey, formData["editedData"], false, form);
      }
    }
  };

  const getSkills = async () => {
    try {
      const response = await getRequest(userServiceApi.getAllSkills(), {
        headers,
      });
      if (response && response.data) {
        dispatch(setSkills(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOptions = async (micrositeId, activeKey) => {
    try {
      const response = await getRequest(userServiceApi.getTeamsCoursesProducts(micrositeId, activeKey));
      if (response && response.data) {
        // console.log("res i useTab", response.data);
        if (activeKey === "PRODUCT") {
          dispatch(setProductGroups(response.data));
        } else if (activeKey === "COURSES") {
          dispatch(setCoursesQualifications(response.data));
        } else if (activeKey === "TEAMS_AND_ROLES") {
          dispatch(setTeamsAndRoles(response.data));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getSingleTabData,
    deleteSingleItem,
    callMicroServices,
    getSkills,
    getOptions,
    dataLoader: loader,
    postLoader,
  };
}
