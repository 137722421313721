import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Input,
    Form,
    Select,
    Row,
    Col,
    Spin,
    Tooltip,
} from "antd";
import {
    PlusOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { aboutUSkeys, TAB_KEYS } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../../../utils";
import ReactPlayer from "react-player/youtube";

const { TextArea } = Input;
const MAX_CHARACTERS = 500;

export default function AboutUsComponent({
    dataLoader,
    form,
    handleTabs,
    activeKey,
}) {
    // const params = useParams();
    const { state } = useLocation();
    // const navigate = useNavigate();
    const tabKey = state;
    const { tabData } = useStore();
    const whichTab = aboutUSkeys.About;
    const { deleteSingleItem, postLoader } = useTab();
    const { updateImage } = useConnectsuForm();
    const [loader, setLoader] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const { updatedValues } = useStore();

    if (activeKey === "ABOUT_US") {
        localStorage.setItem("tab", activeKey);
    }

    // useEffect(() => {
    //   localStorage.setItem("tab", "ABOUT_US");
    // }, []);

    useEffect(() => {
        setFormChanged(false);
    }, []);

    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;

        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    const renderMedia = (dataItem) => {
        const commonStyles = {
            width: "350px",
            height: "250px",
            // margin: "10px",
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "black"
        };

        if (
            dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <ReactPlayer
                        controls={true}
                        url={dataItem.mediaLink.url}
                        width="100%"
                        height="100%"
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <iframe
                        src={dataItem.mediaLink.url}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{ backgroundColor: "black" }}
                    // width="350px"
                    // height="auto"
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "IMAGE" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <img
                        src={dataItem?.mediaLink?.url}
                        alt=""
                        className="c1 carousel-image"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                </div>
            );
        }

        return null;
    };

    const findValue = (i, key) => {
        return getFormFieldValue(form, whichTab, i, key);
    };

    const MakeFieldDisableHandler = (i) => {
        let value = form.getFieldValue([whichTab, i, "title"]);
        if (value == "WhyDo" || value == "howDo" || value == "whatDo") {
            return true;
        }
    };

    const ShowCustomTitleHandler = (i) => {
        let value = form.getFieldValue([whichTab, i, "title"]);
        if (value == "WhyDo") {
            return "Why do we do what we do ?";
        }
        if (value == "howDo") {
            return "How do we do what we do ?";
        }
        if (value == "whatDo") {
            return "What do we do ?";
        }
        return "";
    };

    const SelectChangeHandler = (e, i) => {
        form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
    };

    useEffect(() => {
        form.resetFields();

        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [tabData[whichTab]]);

    if (dataLoader || postLoader || loader) {
        return (
            <div className="data-loader">
                <Spin tip="Loading" size="large" />
            </div>
        );
    } else {
        return (
            <div>
                <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {(tabKey.split('/')[1] === whichTab || tabKey === 'about-us') ? (
                        <>
                            <Col className="gutter-row tabContent" span={24}>
                                <>
                                    <Form.List
                                        name={whichTab}
                                        initialValue={
                                            tabData &&
                                                tabData[whichTab] &&
                                                tabData[whichTab].length > 0
                                                ? tabData[whichTab]
                                                : [{ title: 'WhyDo' }, { title: 'whatDo' }, { title: 'howDo' }]
                                        }
                                    >
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }, i) => (
                                                    <div key={key} className="card-item">
                                                        <Col
                                                            className="gutter-row tabContent"
                                                            span={16}
                                                            style={{ paddingRight: "25px" }}
                                                        >
                                                            <div className="d-flex">
                                                                {MakeFieldDisableHandler(i) && (
                                                                    <Form.Item
                                                                        className="custom-work"
                                                                        {...restField}
                                                                        label="About Us"
                                                                        labelCol={{ span: 5 }}
                                                                        name={[name, "title"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <label className="work-title">
                                                                            {ShowCustomTitleHandler(i)}
                                                                        </label>
                                                                    </Form.Item>
                                                                )}
                                                                {!MakeFieldDisableHandler(i) && (
                                                                    <Form.Item
                                                                        className="custom-work"
                                                                        {...restField}
                                                                        label="About Us"
                                                                        labelCol={{ span: 5 }}
                                                                        name={[name, "title"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Title is required",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Enter title" />
                                                                    </Form.Item>
                                                                )}

                                                                <Form.Item>
                                                                    {fields.length > 1 &&
                                                                        i !== 0 &&
                                                                        findValue(i, "id") &&
                                                                        !MakeFieldDisableHandler(i) ? (
                                                                        <div className="remove-btn">

                                                                            <DeleteOutlined
                                                                                onClick={() =>
                                                                                    deleteSingleItem(
                                                                                        whichTab,
                                                                                        form.getFieldValue([
                                                                                            whichTab,
                                                                                            i,
                                                                                            "id",
                                                                                        ]),
                                                                                        form
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : null}
                                                                </Form.Item>
                                                            </div>
                                                            {/* <div className="d-flex"> */}
                                                            <Form.Item
                                                                label="Description"
                                                                {...restField}
                                                                labelCol={{ span: 5 }}
                                                                name={[name, "description"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Description is required",
                                                                    },
                                                                    {
                                                                        max: MAX_CHARACTERS - 1,
                                                                        message: `Description should be less than ${MAX_CHARACTERS} character`,
                                                                    }
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    placeholder="Enter Description"
                                                                    rows={5}
                                                                    maxLength={MAX_CHARACTERS}
                                                                    showCount
                                                                />
                                                            </Form.Item>

                                                            {/* </div> */}
                                                            <div className="d-flex">
                                                                <Form.Item
                                                                    {...restField}
                                                                    className="custom-work"
                                                                    name={[name, "mediaLink", "mediaType"]}
                                                                    label="Video or Image"
                                                                    labelCol={{ span: 5 }}
                                                                // rules={[
                                                                //   {
                                                                //     required: true,
                                                                //     message: "Select any option",
                                                                //   },
                                                                // ]}
                                                                // validateTrigger="onChange"
                                                                // shouldUpdate={(prev, cur) => {
                                                                // }}
                                                                >
                                                                    <Select
                                                                        name={[name, "mediaLink", "mediaType"]}
                                                                        onChange={(e) => SelectChangeHandler(e, i)}
                                                                        placeholder="Select Option"
                                                                    >
                                                                        <Select.Option value="VIMEO_MEDIA">
                                                                            Add Vimeo Link
                                                                        </Select.Option>
                                                                        <Select.Option value="SOCIAL_MEDIA">
                                                                            Add Youtube Link
                                                                        </Select.Option>
                                                                        <Select.Option value="IMAGE">
                                                                            Upload Image
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <div className="remove-btn">
                                                                        <Tooltip
                                                                            title={
                                                                                "Upload Video link or image with size lesser than 2.5MB"
                                                                            }
                                                                        >
                                                                            <QuestionCircleOutlined />
                                                                        </Tooltip>
                                                                    </div>
                                                                </Form.Item>
                                                            </div>
                                                            {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                                                <Form.Item
                                                                    label="Youtube Link"
                                                                    labelCol={{ span: 5 }}
                                                                    {...restField}
                                                                    name={[name, "mediaLink", "url"]}
                                                                // rules={[
                                                                //   {
                                                                //     required: true,
                                                                //     message: "Youtube link is required",
                                                                //   },
                                                                // ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                                                <Form.Item
                                                                    label="Vimeo Link"
                                                                    labelCol={{ span: 5 }}
                                                                    {...restField}
                                                                    name={[name, "mediaLink", "url"]}
                                                                // rules={[
                                                                //   {
                                                                //     required: true,
                                                                //     message: "Vimeo link is required",
                                                                //   },
                                                                // ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            ) : findValue(i, "type") === "IMAGE" ? (
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Image"
                                                                    colon={false}
                                                                    // label={<p style={{ fontSize: '16px', margin: '0px' }}><span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>Image</p>}
                                                                    name="media"
                                                                    className="experienceImg"
                                                                    labelCol={{ span: 5 }}
                                                                // rules={[
                                                                //   {
                                                                //     validator: (_, value) => {
                                                                //       return findValue(i, "blob")
                                                                //         ? Promise.resolve()
                                                                //         : findValue(i, "url")
                                                                //         ? Promise.resolve()
                                                                //         : findValue(i, "type") === "IMAGE"
                                                                //         ? Promise.reject(
                                                                //             new Error("Image is required")
                                                                //           )
                                                                //         : Promise.resolve();
                                                                //     },
                                                                //   },
                                                                // ]}
                                                                >
                                                                    {findValue(i, "blob") ? (
                                                                        <Avatar
                                                                            src={URL.createObjectURL(
                                                                                findValue(i, "blob")
                                                                            )}
                                                                            alt=""
                                                                        />
                                                                    ) : findValue(i, "url") ? (
                                                                        <Avatar src={findValue(i, "url")} alt="" />
                                                                    ) : (
                                                                        <Avatar src={UserIcon} />
                                                                    )}
                                                                    <label
                                                                        name="file"
                                                                        className="fileUploadBtn greenBtn"
                                                                    >
                                                                        {" "}
                                                                        Choose File
                                                                        <input
                                                                            accept="image/png, image/gif, image/jpeg"
                                                                            type="file"
                                                                            // required={true}
                                                                            onChange={(e) =>
                                                                                updateImage(e, i, form, whichTab)
                                                                            }
                                                                        />
                                                                    </label>
                                                                </Form.Item>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <Form.Item
                                                                label="External Link"
                                                                {...restField}
                                                                className="w-100"
                                                                name={[name, "urlLink"]}
                                                                // rules={[
                                                                //   {
                                                                //     required: false,
                                                                //     message: "Job Link is required",
                                                                //   },
                                                                // ]}
                                                                labelCol={{ span: 5 }}
                                                            >
                                                                <Input
                                                                    placeholder="External Link"
                                                                    name={[name, "urlLink"]}
                                                                />
                                                            </Form.Item>

                                                        </Col>
                                                        <Col className="gutter-row tabContent" span={8}>
                                                            <div>{renderMedia(tabData[whichTab][i])}</div>
                                                        </Col>
                                                    </div>
                                                    // </Space>
                                                ))}

                                            </>
                                        )}
                                    </Form.List>
                                </>
                            </Col>
                        </>
                    ) : (
                        <></>
                    )}
                </Row>
            </div>
        );
    }
}
