import React, { useEffect, useState } from "react";
import { Modal, Form, Tooltip, Avatar, Input, Button, Table } from "antd";
import { toast } from "react-toastify";
import { deleteRequest, getRequest, postRequest } from "../../api/http";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import { useStore } from "../../store";
import "../microsite-accordian/style.scss";
import "./manage-microsites.scss";
import UserIcon from "../../assets/images/icons/userLogo.svg";
import uuid from "react-uuid";
import MicrositeEdit from "../microsite-accordian/microsite-edit";
import Preview from "../microSites/edit/preview";
import previewIcon from "../../assets/images/preview-icon.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import duplicateIcon from "../../assets/images/duplicate-icon.svg";
import { setMicroSiteActions } from "../../store/actions";
import { useNavigate, useLocation } from "react-router-dom";
import { TAB_KEYS } from "../../config";
import backArrow from "../../assets/images/back-arrow.svg";

function ManageMicrosite() {
  const [loading, setLoading] = useState(true);
  const { settings, dispatch, microSiteActions } = useStore();
  // const { getMicroSites } = useMicrosite();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [logo, setLogo] = useState();
  const [logoFile, setLogoFile] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [totalData, setData] = useState([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [previewId, setPreviewId] = useState();
  const [previewRow, setPreviewRow] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  const reloadFlagFunction = () => {
    setReloadFlag(!reloadFlag);
  };

  useEffect(() => {
    getAllMicrosites();
  }, [reloadFlag]);

  const handleDelete = (id) => {
    setIsModalDeleteOpen(true);
    setDeleteRowId(id);
  };

  const handleCancelDelete = () => {
    setIsModalDeleteOpen(false);
  };

  const getAllMicrosites = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const response = await getRequest(userServiceApi.getAllMicrosites(), {
        headers,
      });

      if (response) {
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteMicrosite = async () => {
    try {
      const response = await deleteRequest(
        userServiceApi.deleteMicrosite(deleteRowId)
      );
      if (response) {
        setIsModalDeleteOpen(false);
        toast.success("Microsite deleted successfully");
        getAllMicrosites();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAllMicrosites();
  }, []);

  const handleMenus = (activeKey, menuKey, change) => {
    // console.log("handle", activeKey)
    dispatch(setMicroSiteActions({ activeKey, menuKey, change }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0].size >= 2e6) {
      toast.error("File size should not exceed 2MB");
      return;
    }
    const file = event.target.files[0];
    if (file) {
      let unique_ID = uuid();

      const fileNameArray = file.name.split(".");
      const fileExtension = fileNameArray[fileNameArray.length - 1];
      const fileName = fileNameArray.slice(0, -1).join(".");
      setLogo({
        mediaType: "IMAGE",
        mediaProperty: {
          id: unique_ID,
          name: fileName,
          extension: fileExtension,
        },
      });

      const blob = file.slice(0, file.size);
      const newLogo = new File(
        [blob],
        `${unique_ID}_${fileName}.${fileExtension}`,
        {
          type: file.type,
        }
      );

      setLogoFile(newLogo);
      setSelectedImage(URL.createObjectURL(newLogo));
    }
  };

  const onFinish = async (values) => {
    const formData = new FormData();

    if (logoFile) {
      formData.append("media", logoFile);
    }

    const micrositeProfileInput = {
      name: values.name,
      media: logo,
    };
    const json = JSON.stringify(micrositeProfileInput);
    const blob = new Blob([json], {
      type: "application/json",
    });
    formData.append("micrositeProfileInput", blob);

    try {
      const response = await postRequest(
        userServiceApi.createMulMicrosite(),
        formData
      );


      if (response) {
        toast.success("Microsite created successfully!");
        navigate(`/${response.id}`, { state: state ? state : TAB_KEYS.aboutUs })
        // getMicroSites();
        closeModal();
      } else {
        console.error("Failed to create microsite. Please try again.");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedImage(null);
    setLogo(null);
    setLogoFile(null);
  };

  const addMicrosite = () => {
    setModalVisible(true);
  };

  const callDuplicate = async (micrositeID) => {
    try {
      const res = await postRequest(
        userServiceApi.duplicateMicrosite(micrositeID))
      if (res) {
        toast.success("Successfully duplicated microsite")

        setTimeout(() => {
          getAllMicrosites();
        }, 1000);

      }
    } catch (error) {
      toast.error("Duplicating microsite not successful")
    }
  }

  const columns = [
    {
      title: "Microsite Name",
      dataIndex: "name",
      key: "name",
      render: (t, r) => (
        <div className="microsite-info">
          <div className="table-microsite-logo"><img src={r.mediaLink} alt="micrositeLogo" /></div>
          <div className="table-microsite-name">{r.name}</div>
        </div>
      )
    },
    {
      title: " ",
      dataIndex: " ",
      key: "action",
      render: (value, row, index) => (
        <div className="microsite-header-last">
          <div className="microsite-header-last-sub">
            <div
              className="microsite-header-last-sub-sec"
              onClick={() => handleDuplicate(row.id)}
            >
              <img className="manage-table-icons" src={duplicateIcon} />
              <div className="microsite-header-last-sub-sec-label">
                Duplicate
              </div>
            </div>
            <div
              className="microsite-header-last-sub-sec"
              onClick={() => {
                // console.log("clicked", row)
                setPreviewId(row.id)
                setPreviewRow(row)
                handleMenus(row.id, "preview", true)
              }}
            >
              <img className="manage-table-icons" src={previewIcon} />
              <div className="microsite-header-last-sub-sec-label">
                Preview
              </div>
            </div>
            <div
              className="microsite-header-last-sub-sec"
              onClick={() => handleDelete(row.id)}
            >
              <img className="manage-table-icons" src={deleteIcon} />
              <div className="microsite-header-last-sub-sec-label">
                Delete
              </div>
            </div>
          </div>
        </div>
      )

    }
  ]



  const handleDuplicate = (micrositeID) => {

    callDuplicate(micrositeID);
  }

  if (loading) return <LoadingView message="Loading..." />;

  return (
    <div className="tabContainer">
      <div className="add-microsite-btn">
        <div className="header page-header">
          <div className="backbtn-container">
            <img src={backArrow} alt="" onClick={() => navigate('/')} />
            <p>Manage microsites</p>
          </div>
        </div>
        <Button
          className="greenBtn"
          // htmlType="submit"
          onClick={addMicrosite}
        >
          Add new Microsite
        </Button>
      </div>
      <Modal className="confirmation-modal" open={modalVisible} footer={null} onCancel={closeModal}>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 10 }}
          form={form}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Microsite name is required",
              },
            ]}
            label="Microsite name"
            name="name"
          >
            <Input style={{ width: "300px" }} />
          </Form.Item>
          <Form.Item
            label={
              <div className="ant-form-item-label ">
                <label htmlFor="media" title="Organisation logo">
                  Microsite logo
                </label>
              </div>
            }
            colon={false}
            name="media"
            className="experienceImg centered-label"
            rules={[
              {
                required: true,
                message: "Logo is required",
              },
            ]}
          >
            <div className="choose-btn">
              {selectedImage ? (
                <img className="org-admin-logo" size={64} src={selectedImage} />
              ) : (
                <Avatar size={64} src={UserIcon} />
              )}
              <div className="d-flex">
                <Tooltip
                  title={
                    "Upload image with size lesser than 2.5MB"
                  }
                >
                  <label className="fileUploadBtn greenBtn">
                    {" "}
                    Choose File
                    <input type="file" onChange={handleFileChange} />
                  </label>
                </Tooltip>
              </div>
            </div>
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="sendBtn"
              htmlType="submit"
            // style={{
            //   backgroundColor: "#5AA2FB",
            //   borderRadius: "5px",
            //   padding: "20px",
            //   color: "white",
            //   display: "flex",
            //   alignItems: "center",
            // }}
            >
              <span>Create</span>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* {totalData && totalData.length > 0
        ? totalData.map((data) => (
          <div className="ms-accordian">
            {microSiteActions &&
              microSiteActions[data.id] &&
              microSiteActions[data.id]["edit"] &&
              microSiteActions[data.id]["edit"] ? (
              <div className="microsite-edit-header">
                <MicrositeEdit
                  page={"manageMicrosite"}
                  reloadFlagFunction={reloadFlagFunction}
                  data={data}
                  closeSettings={() => handleMenus(data.id, "edit", false)}
                />
              </div>
            ) : (
              <div className="microsite-header">
                <div
                  className="microsite-header-first"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/${data.id}`, {
                        state: state ? state : TAB_KEYS.aboutUs,
                      });
                    }}
                  >
                    <img
                      className="org-admin-logo"
                      style={{ backgroundColor: "#fff" }}
                      src={data.mediaLink}
                      size={10}
                    />
                  </div>
                  <div className="microsite-header-first-title">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${data.id}`, {
                          state: state ? state : TAB_KEYS.aboutUs,
                        });
                      }}
                    >{data.name}</span>
                    <span className="title-edit">
                      <a
                        style={{
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}
                        onClick={() => handleMenus(data.id, "edit", true)}
                      >
                        Edit name
                      </a>
                    </span>
                  </div>
                </div>
                <div className="microsite-header-last">
                  <div className="microsite-header-last-sub">
                    <div
                      className="microsite-header-last-sub-sec"
                      onClick={() => handleDuplicate(data.id)}
                    >
                      <img src={duplicateIcon} />
                      <div className="microsite-header-last-sub-sec-label">
                        Duplicate
                      </div>
                    </div>
                    <div
                      className="microsite-header-last-sub-sec"
                      onClick={() => handleMenus(data.id, "preview", true)}
                    >
                      <img src={previewIcon} />
                      <div className="microsite-header-last-sub-sec-label">
                        Preview
                      </div>
                    </div>
                    <div
                      className="microsite-header-last-sub-sec"
                      onClick={() => handleDelete(data.id)}
                    >
                      <img src={deleteIcon} />
                      <div className="microsite-header-last-sub-sec-label">
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {microSiteActions &&
              microSiteActions[data.id] &&
              microSiteActions[data.id]["preview"] &&
              microSiteActions[data.id]["preview"] ? (
              <Preview
                micrositeId={data.id}
                siteSetting={settings}
                closeSettings={() => handleMenus(data.id, "preview", false)}
                data={data}
              />
            ) : (
              <></>
            )}
          </div>
        ))
        : ""} */}

      {microSiteActions &&
        microSiteActions[previewId] &&
        microSiteActions[previewId]["preview"] &&
        microSiteActions[previewId]["preview"] ? (
        <Preview
          micrositeId={previewId}
          siteSetting={settings}
          closeSettings={() => {
            handleMenus(previewId, "preview", false);
            setPreviewId();
          }}
          data={previewRow}
        />
      ) : (
        <></>
      )}
      <div className="table-container">
        <Table
          rowClassName={() => "rowClassName1"}
          columns={columns}
          dataSource={totalData}
          // showSearchBarHandler={showSearchBarHandler}
          pagination={{
            position: ["none", "none"],
          }}
        />
      </div>
      <Modal
        className="confirmation-modal"
        open={isModalDeleteOpen}
        footer={null}
        onOk={deleteMicrosite}
        onCancel={handleCancelDelete}
        destroyOnClose={true}
        closable={false}
      >
        <div className="message">
          Are you sure you want to delete this Microsite?
        </div>
        <div className="modalBtns">
          <Button label="No" className="cancel-btn" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button
            label="Yes"
            className="confirm-btn"
            onClick={deleteMicrosite}
          >
            Yes
          </Button>
        </div>
      </Modal>

      <div className="footer">
        <div
          className="footer-content"
          onClick={() => window.open("https://www.connectsu.co.uk/", "_blank")}
          style={{ cursor: "pointer" }}
        >
          {/* <span>Powered by</span> */}
          <div className="footer-logo">
            <img
              src={require("../../assets/images/PoweredNew.png")}
              alt="company logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageMicrosite;
