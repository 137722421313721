import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Spin, Form, Select } from "antd";
import { Line } from "react-chartjs-2";
import "./org-analytics.scss";
import userServiceApi from "../../api/userServiceApi";
import { getRequest } from "../../api/http";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import "./microsite-analytics.scss";

export default function MicrositeAnalytics() {
  const { micrositeId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const currentYear = new Date().getFullYear();

  const [loader, setLoader] = useState(true);

  const [filterType, setFilterType] = useState();
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [yearDropdownOptions, setYearDropdownOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();

  const [showQuarterly, setShowQuarterly] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState();
  const [showMonthly, setShowMonthly] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();

  const [micrositeLineData, setMicrositeLineData] = useState(null);
  const [micrositeContactsAnalytics, setMicrositeContactsAnalytics] =
    useState(null);



  const getMicrositeAnalytics = async (id, month, year, quarter) => {
    // setSelectedMicrosite(name);
    try {
      const res = await getRequest(userServiceApi.getMicrositeAnalytics(id, month, year, quarter));
      let labels = [];
      let ViewsData = [];
      let SalesData = [];
      let HRData = [];
      let EowData = [];
      if (res && res.data) {
        res.data.map((month) => {
          labels.push(month.monthYear);
          ViewsData.push(month.count);
          SalesData.push(month.salesCount);
          HRData.push(month.hrCount);
          EowData.push(month.experienceOfWorkCount);
        });

        setMicrositeLineData({
          labels,
          datasets: [
            {
              label: "Views",
              data: ViewsData,
              // data: [24, 59, 76, 45, 14, 98, 87, 34, 56, 18, 54, 78],
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        });

        setMicrositeContactsAnalytics({
          labels,
          datasets: [
            {
              label: "Sales",
              data: SalesData,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "HR",
              data: HRData,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: "Experiences of Work",
              data: EowData,
              borderColor: "rgb(53, 192, 215)",
              backgroundColor: "rgba(53, 192, 215, 0.5)",
            },
          ],
        });
        setLoader(false);
        // if (micrositeLineData && micrositeContactsAnalytics) {
      }
    } catch (error) { }
  };

  useEffect(() => {
    getMicrositeAnalytics(micrositeId)
  }, [])

  useEffect(() => {
    getMicrositeAnalytics(
      micrositeId,
      // selectedAgeID,
      // selectedProfID,
      // ((filterType === "yearly" && selectedYear) ||
      //   (filterType === "monthly" && selectedMonth) ||
      //   (filterType === "quarterly" && selectedQuarter)) &&
      // filterType,
      filterType === "monthly" && selectedMonth,
      (filterType === "yearly" ||
        filterType === "monthly" ||
        filterType === "quarterly") &&
      selectedYear,
      filterType === "quarterly" && selectedQuarter
    );
  }, [
    // selectedAgeID,
    // selectedProfID,
    selectedYear,
    selectedQuarter,
    selectedMonth,
  ]);

  const getYearsDropdown = () => {
    const currentYear = new Date().getFullYear();
    let yearsarray = [];
    for (let year = 2023; year <= currentYear + 20; year++) {
      // console.log(year)
      // yearsarray.push({ label: year, value: year });
      yearsarray.push(year);
    }
    setYearDropdownOptions(yearsarray);
  };

  // console.log("years", yearDropdownOptions)

  useEffect(() => {
    getYearsDropdown();
  }, []);


  //microsite popup graph data
  const ModalLineoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start",
      },
    },
  };

  if (loader) {
    return (
      <div className="tabContainer analytics-tab">
        {/* <Tabs defaultActiveKey="1" className="analytics-tab">
          <Tabs.TabPane tab="Microsite Analytics" key={"1"}> */}
        <div className="data-loader">
          <Spin tip="Loading" size="large" />
        </div>
        {/* </Tabs.TabPane>
        </Tabs> */}
      </div>
    );
  } else {
    return (
      <div className="tabContainer">
        <div className="header page-header">
          <div className="backbtn-container">
            <img src={backArrow} alt="" onClick={() => state.page === "home" ? navigate('/') : navigate('/org-analytics')} />
            <p>{state.micrositeName}</p>
          </div>
        </div>
        <div className="analytics-tab">
          {/* <Tabs defaultActiveKey="1" className="analytics-tab">
          <Tabs.TabPane tab="Microsite Analytics" key={"1"}> */}
          <div className="microsite-analytics">
            <div className="filter-box">
              <p className="filters-heading">Filters</p>
              <div className="add-filter-btns">
                <Form.Item className="term-select" label="Term" colon={false}>
                  <Select
                    allowClear
                    className="customSelect"
                    // placeholder={firstValue}
                    placeholder="Select Term"
                    value={filterType}
                    onChange={(e) => {
                      setFilterType(e);
                      if (e == "quarterly") {
                        setShowMonthly(false);
                        setSelectedMonth();
                        setSelectedYear();
                        setSelectedQuarter();
                        setShowYearDropdown(true);
                        setShowQuarterly(true);
                      } else if (e == "monthly") {
                        setSelectedMonth();
                        setSelectedYear();
                        setSelectedQuarter();
                        setShowYearDropdown(true);
                        setShowQuarterly(false);
                        setShowMonthly(true);
                      } else if (e == "yearly") {
                        setSelectedMonth();
                        setSelectedYear();
                        setSelectedQuarter();
                        setShowMonthly(false);
                        setShowQuarterly(false);
                        setShowYearDropdown(true);
                      }
                    }}
                    onClear={() => {
                      setFilterType();
                      setSelectedYear();
                      setSelectedMonth();
                      setSelectedQuarter();
                      setShowYearDropdown(false);
                      setShowMonthly(false);
                      setShowQuarterly(false);
                    }}
                    options={[
                      {
                        value: "yearly",
                        label: "Yearly",
                      },
                      {
                        value: "monthly",
                        label: "Monthly",
                      },
                      {
                        value: "quarterly",
                        label: "Quarterly",
                      },
                    ]}
                  ></Select>
                </Form.Item>
                {showYearDropdown && (
                  <Form.Item className="term-select" label="Year" colon={false}>
                    <Select
                      allowClear
                      className="customSelect"
                      // placeholder={firstValue}
                      placeholder="Select Year"
                      value={selectedYear}
                      onChange={(e) => {
                        // console.log("e", e)
                        setSelectedYear(e);
                      }}
                      onClear={() => {
                        setSelectedYear();
                        setSelectedMonth();
                        setSelectedQuarter();
                        setShowQuarterly(false);
                        setShowMonthly(false);
                        setShowYearDropdown(false);
                        setFilterType();
                      }}
                    >
                      {yearDropdownOptions.length > 0 &&
                        yearDropdownOptions.map((year, i) => (
                          // console.log('year', year)
                          <Select.Option
                            disabled={year > currentYear ? true : false}
                            key={year}
                            value={year}
                          >
                            {year}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {showMonthly && (
                  <Form.Item className="term-select" label="Month" colon={false}>
                    <Select
                      allowClear
                      className="customSelect"
                      // placeholder={firstValue}
                      disabled={selectedYear ? false : true}
                      placeholder="Select Month"
                      value={selectedMonth}
                      onChange={(e) => {
                        // console.log("e", e)
                        setSelectedMonth(e);
                      }}
                      onClear={() => {
                        setSelectedMonth();
                        setSelectedYear();
                        // setFilterType();
                        // setShowMonthly(false);
                        // setShowYearDropdown(false);
                      }}
                      options={[
                        { value: "January", label: "January" },
                        { value: "February", label: "February" },
                        { value: "March", label: "March" },
                        { value: "April", label: "April" },
                        { value: "May", label: "May" },
                        { value: "June", label: "June" },
                        { value: "July", label: "July" },
                        { value: "August", label: "August" },
                        { value: "September", label: "September" },
                        { value: "October", label: "October" },
                        { value: "November", label: "November" },
                        { value: "December", label: "December" },
                      ]}
                    ></Select>
                  </Form.Item>
                )}
                {showQuarterly && (
                  <Form.Item className="term-select" label="Quarter" colon={false}>
                    <Select
                      allowClear
                      className="customSelect"
                      // placeholder={firstValue}
                      placeholder="Select Quarter"
                      value={selectedQuarter}
                      disabled={selectedYear ? false : true}
                      onChange={(e) => {
                        // console.log("e", e)
                        setSelectedQuarter(e);
                      }}
                      onClear={() => {
                        setSelectedQuarter();
                        // setFilterType();
                        setSelectedYear();
                        // setShowQuarterly(false);
                        // setShowYearDropdown(false);
                      }}
                      options={[
                        {
                          value: "1",
                          label: "Q1",
                        },
                        {
                          value: "2",
                          label: "Q2",
                        },
                        {
                          value: "3",
                          label: "Q3",
                        },
                        {
                          value: "4",
                          label: "Q4",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                )}
              </div>
            </div>
            <div className="overall-views-graph">
              {micrositeLineData && (
                <div className="microsite-data">
                  <h2 className="modal-sub-heading">
                    <b>Views</b>
                  </h2>
                  <div className="graph-wrapper">
                    <Line
                      options={ModalLineoptions}
                      height={"250px"}
                      data={micrositeLineData}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="overall-views-graph" style={{ marginTop: "15px" }}>
              {micrositeContactsAnalytics && (
                <div className="microsite-data">
                  <h2 className="modal-sub-heading">
                    <b>Sales vs HR vs Experiences of work</b>
                  </h2>
                  <div className="graph-wrapper">
                    <Line
                      options={ModalLineoptions}
                      height={"250px"}
                      data={micrositeContactsAnalytics}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </Tabs.TabPane>
        </Tabs> */}
      </div>
    );
  }
}
