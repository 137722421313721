import React, { useRef, useState } from "react";
import { Button, Form, Input, Divider, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useStore } from "../../../store";
import { putRequest, deleteRequest } from "../../../api/http";
import { useTab } from "../../../custom-hooks/useTab";
import { setCoursesQualifications } from "../../../store/actions";
import { toast } from "react-toastify";
import userServiceApi from "../../../api/userServiceApi";

export default function CoursesConfigurations({ micrositeId }) {
    const [form] = Form.useForm();
    const { courses_qualifications, dispatch } = useStore();
    const { getOptions } = useTab();
    const inputRef = useRef(null);

    const [newItems, setNewItems] = useState([]);
    const [editID, setEditID] = useState();
    // const [itemFieldVal, setItemFieldVal] = useState('');

    const handleAdd = ({ itemField }) => {
        if (itemField.trim().length) {
            setNewItems([...newItems, itemField]);
            inputRef.current.value = "";
            inputRef.current.focus();
            form.resetFields();
        }
    };

    const handleRemove = (tagToRemove) => {
        setNewItems(newItems.filter((tag) => tag !== tagToRemove));
    };

    const handleSave = async () => {
        const moddedArray = newItems.map((item) => {
            return {
                title: item,
            };
        });
        try {
            const res = await putRequest(
                userServiceApi.saveTeamsCoursesProducts(micrositeId, "COURSES"),
                moddedArray
            );
            // console.log("res", res)
            if (res) {
                toast.success("New courses added");
                setNewItems([]);
                dispatch(setCoursesQualifications(res));
            }
        } catch (error) { }
    };

    const handleDelete = async (course) => {
        try {
            const res = await deleteRequest(
                userServiceApi.deleteTeamsCoursesProduct(course.id)
            );
            if (res) {
                toast.success("Course deleted successfully");
                getOptions(micrositeId, "COURSES");
            }
        } catch (error) { }
    };

    const handleEdit = async (value) => {
        // console.log("edited", value.productname, editID)
        let editArray = [
            {
                title: value.coursename,
                id: editID,
            },
        ];

        try {
            const res = await putRequest(
                userServiceApi.saveTeamsCoursesProducts(micrositeId, "COURSES"),
                editArray
            );
            // console.log("res", res);
            if (res) {
                setEditID();
                toast.success("Product edited successfully");
                dispatch(setCoursesQualifications(res));
            }
        } catch (error) {
            // toast.error("Something went wrong, try again later.")
        }
    };

    return (
        <div className="tabContent">
            <div className="add-field">
                <Form form={form} className="add-field-form" onFinish={handleAdd}>
                    <Form.Item name="itemField">
                        <Input
                            ref={inputRef}
                            className="input-field"
                            placeholder="Enter new course name"
                            required={true}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button className="greenBtn add-btn" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {newItems.length > 0 && (
                <>
                    <div className="new-items">
                        {newItems.map((newItem, index) => (
                            <div className="tag" key={newItem}>
                                <Tag
                                    className="tags-component"
                                    closable={true}
                                    onClose={() => handleRemove(newItem)}
                                >
                                    {newItem}
                                </Tag>
                            </div>
                        ))}
                    </div>
                    <div className="btn-container">
                        <Button className="greenBtn SaveBtn" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </>
            )}
            <Divider />
            <div className="display">
                {courses_qualifications.map((course) => (
                    <div className="product-item">
                        {course.id === editID ? (
                            <>
                                <Form
                                    onFinish={handleEdit}
                                    className="item-edit-form"
                                    initialValues={{
                                        coursename: course.title,
                                    }}
                                >
                                    <Form.Item name="coursename">
                                        <Input className="edit-input" />
                                    </Form.Item>
                                    <>
                                        <Form.Item>
                                            <Button
                                                style={{ marginRight: "5px" }}
                                                className="greenBtn SaveBtn"
                                                htmlType="submit"
                                            >
                                                Save
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button className="SaveBtn" onClick={() => setEditID()}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </>
                                </Form>
                            </>
                        ) : (
                            <>
                                <h3>{course.title}</h3>
                                <div className="op-btns">
                                    <div className="remove-btns">
                                        <EditOutlined onClick={() => setEditID(course.id)} />
                                    </div>
                                    <div className="remove-btns">
                                        <DeleteOutlined onClick={() => handleDelete(course)} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
