import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Space,
  Spin,
  Table,
  Switch,
  Tooltip,
  Upload,
  Typography
} from "antd";
import userServiceApi from "../../../../api/userServiceApi";
import { FileOutlined, PlusCircleFilled, InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { deleteRequest, getRequest } from "../../../../api/http";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import "./work-experience.scss"
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
const { TextArea } = Input;
const MAX_CHARACTERS = 700;

export default function WorkExperienceCopy({ dataLoader }) {
  // const params = useParams();
  const { state } = useLocation();
  const { micrositeId } = useParams();

  localStorage.setItem("tab", "WORK_EXPERIENCE")

  // const tabKey = state;
  const [loader, setLoader] = useState(true);
  const [toggleState, setToggleState] = useState(false);
  const [temptoggleState, setTempToggleState] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [mediaType2, setMediaType2] = useState(null);
  const [isTableShown, setIsTableShown] = useState(false)
  const [selectedEvent, setselectedEvent] = useState("")
  const [eof, setEof] = useState([]);
  const [eofLoader, setEofLoader] = useState(true);
  const [eofTitle, setEofTitle] = useState([]);
  const [eofOpportunityTitle, setEofOpportunityTitle] = useState([]);
  const [dataSrcState, setDataSrc] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [opportunityType, setOpportunityType] = useState([]);
  // const [showForm, setShowForm] = useState({});
  let [showForm, setShowForm] = useState([]);
  let [showForm2, setShowForm2] = useState([]);
  let [showEdit, setShowEdit] = useState([]);
  const [image, setImage] = useState();
  const [media, setmedia] = useState({});
  const [getFile, setFile] = useState();
  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(true)
  const [description, setDescription] = useState();
  const [vimeoLink, setVimeoLink] = useState();
  const [youtubeLink, setYoutubeLink] = useState();
  const [form] = Form.useForm();

  // const findValue = (i, key) => {
  //   return getFormFieldValue(form, whichTab, i, key);
  // };

  // const SelectChangeHandler = (e, i) => {
  //   form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  // };

  const changeHandler = (event) => {
    setImageFlag(true)
    if (event.target.files && event.target.files[0].size >= 2e6) {
      toast.error("File should not exceed 2MB");
    } else {
      setImage(event.target.files[0]);
      let unique_ID = uuid();
      var file = event.target.files[0].name;
      var FT = event.target.files[0].type;
      var fSize = event.target.files[0].size;
      //Get file name
      var fileArray = file.split(".");
      var fileExt = fileArray[fileArray.length - 1];
      //Get file type
      var fileName = file.split(".").slice(0, -1).join(".");
      var fileType = FT.substr(0, FT.lastIndexOf("/"));
      const items = {
        mediaType: fileType === "image" ? "IMAGE" : null,
        mediaProperty: {
          id: unique_ID,
          name: fileName,
          extension: fileExt,
        },
      };
      setmedia({ ...items });
      var image = event.target.files[0];
      var blob = image.slice(0, image.size);
      const newFile = new File([blob], `${unique_ID}_${fileName}.${fileExt}`, {
        type: `${image.type}`,
      });
      setFile(newFile);

      // const fields = form.getFieldsValue(true);
      // form.resetFields();
      // form.setFieldsValue({ ...fields, media: newFile });
    }
  };

  const getToggleData = (headerData) => {
    eof?.data?.forEach(element => {
      const rowindex = headerData?.findIndex(row => row?.experienceOfWorkID === element?.experienceOfWorkTitleId)
      if (element?.experienceOfWorkOpportunityOutput?.opportunityType === "VIRTUAL" && headerData[rowindex]?.virtual?.experienceOfWorkOppID === element?.experienceOfWorkOpportunityTitleId) {
        headerData[rowindex].virtual = {
          "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[0].id : null,
          "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[0].title : null,
          "toggle": element?.experienceOfWorkOpportunityOutput?.toggle,
          "description": element?.experienceOfWorkOpportunityOutput?.description ? element?.experienceOfWorkOpportunityOutput?.description : null,
          "id": element?.id ? element?.id : null,
          "media": {
            "mediaid": element?.experienceOfWorkOpportunityOutput?.media?.id ? element?.experienceOfWorkOpportunityOutput?.media?.id : null,
            "mediaType": element?.experienceOfWorkOpportunityOutput?.media?.mediaType ? element?.experienceOfWorkOpportunityOutput?.media?.mediaType : null,
            "url": element?.experienceOfWorkOpportunityOutput?.media?.url ? element?.experienceOfWorkOpportunityOutput?.media?.url : null
          }
        }
      } else {
        headerData[rowindex].faceToFace = {
          "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.id : null,
          "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.title : null,
          "toggle": element?.experienceOfWorkOpportunityOutput?.toggle,
          "description": element?.experienceOfWorkOpportunityOutput?.description ? element?.experienceOfWorkOpportunityOutput?.description : null,
          "id": element?.id ? element?.id : null,
          "media": {
            "mediaid": element?.experienceOfWorkOpportunityOutput?.media?.id ? element?.experienceOfWorkOpportunityOutput?.media?.id : null,
            "mediaType": element?.experienceOfWorkOpportunityOutput?.media?.mediaType ? element?.experienceOfWorkOpportunityOutput?.media?.mediaType : null,
            "url": element?.experienceOfWorkOpportunityOutput?.media?.url ? element?.experienceOfWorkOpportunityOutput?.media?.url : null
          }
        }
      }
    });
    // setFinalData(headerData)
    return headerData;
  }

  const dataSrc = (eofTitle) => {
    let dataSrcArray = [];

    eofTitle?.data?.forEach(element => {
      let rowObj = {
        "experienceOfWorkID": element?.id,
        "experienceOfWorkTitle": element?.title,
        "virtual": {
          "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[0]?.id : null,
          "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[0]?.title : null,
        },
        "faceToFace": {
          "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.id : null,
          "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.title : null,
        }
      }
      dataSrcArray.push(rowObj);
    });

    // console.log("datasrcArray", dataSrcArray)
    setDataSrc(dataSrcArray)
    setFinalData(getToggleData(dataSrcArray));
    setLoader(false);
  }

  const setNewToggledData = (headerData, response) => {
    // response?.data(element => {
    const rowindex = headerData.findIndex(row => row?.experienceOfWorkID === response?.data?.experienceOfWorkTitleId)
    if (response?.data?.experienceOfWorkOpportunityOutput?.opportunityType === "VIRTUAL" && headerData[rowindex]?.virtual?.experienceOfWorkOppID === response?.data?.experienceOfWorkOpportunityTitleId) {
      headerData[rowindex].virtual = {
        "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[0]?.id : null,
        "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[0]?.title : null,
        "toggle": response?.data?.experienceOfWorkOpportunityOutput?.toggle,
        "description": response?.data?.experienceOfWorkOpportunityOutput?.description ? response?.data?.experienceOfWorkOpportunityOutput?.description : null,
        "id": response?.data?.id ? response?.data?.id : null,
        "media": {
          "mediaid": response?.data?.experienceOfWorkOpportunityOutput?.media?.id ? response?.data?.experienceOfWorkOpportunityOutput?.media?.id : null,
          "mediaType": response?.data?.experienceOfWorkOpportunityOutput?.media?.mediaType ? response?.data?.experienceOfWorkOpportunityOutput?.media?.mediaType : null,
          "url": response?.data?.experienceOfWorkOpportunityOutput?.media?.url ? response?.data?.experienceOfWorkOpportunityOutput?.media?.url : null
        }
      }
    } else {
      headerData[rowindex].faceToFace = {
        "experienceOfWorkOppID": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.id : null,
        "experienceOfWorkOppTitle": eofOpportunityTitle ? eofOpportunityTitle?.data[1]?.title : null,
        "toggle": response?.data?.experienceOfWorkOpportunityOutput?.toggle,
        "description": response?.data?.experienceOfWorkOpportunityOutput?.description ? response?.data?.experienceOfWorkOpportunityOutput?.description : null,
        "id": response?.data?.id ? response?.data?.id : null,
        "media": {
          "mediaid": response?.data?.experienceOfWorkOpportunityOutput?.media?.id ? response?.data?.experienceOfWorkOpportunityOutput?.media?.id : null,
          "mediaType": response?.data?.experienceOfWorkOpportunityOutput?.media?.mediaType ? response?.data?.experienceOfWorkOpportunityOutput?.media?.mediaType : null,
          "url": response?.data?.experienceOfWorkOpportunityOutput?.media?.url ? response?.data?.experienceOfWorkOpportunityOutput?.media?.url : null
        }
      }
    }
    setFinalData(headerData)
    // setLoader(false)
    // });
  }

  const getExperienceofworkTitles = async () => {
    // console.log("1st API, titles")
    try {
      setLoader(true);
      const response = await getRequest(
        userServiceApi.geteoftitle()
      );
      if (response) {
        // console.log("1st API eofTitles(left side)", response)
        setEofTitle(response);
        showForm = []
        response?.data?.map((val) => {
          showForm.push(false)
        })
        setShowForm(showForm)
      }
      // setLoader(false);
    } catch (error) {
      // console.log("EOFTitle :", error);
    }
  }

  const getEofOpportunityTitles = async () => {
    // console.log("2nd API, opportunity titles")
    // Setting the data 
    try {
      setLoader(true)
      const response = await getRequest(
        userServiceApi.geteofopportunitytitle()
      );
      if (response) {
        // console.log("2nd API (top)", response)
        setEofOpportunityTitle(response);
      }
      // setLoader(false);
    } catch (error) {
      toast.error(error.error);
    }
  }
  const getExperienceofwork = async (micrositeId) => {
    // console.log("3rd API, eof data titles")
    // setting data of the toggles with form data
    try {
      setLoader(true);
      const response = await getRequest(
        userServiceApi.geteof(micrositeId)
      );
      if (response) {
        // console.log("3rd API (toggle data)", response)
        setEofLoader(false)
        setEof(response);
      }
      // if (response.length() > 0) {
      //   setselectedEvent("Yes")
      // }
      // setLoader(false);
    } catch (error) {
      toast.error(error.error);
      // setLoader(false);
    }
    if (eof?.data?.length) {
      // setFirstOption("1")
    }
  }

  useEffect(() => {
    // form.resetFields();
    getExperienceofworkTitles();
    getEofOpportunityTitles();
    getExperienceofwork(micrositeId);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 5000);
  }, []);

  useEffect(() => {
    // console.log("effect", !eofLoader, eofTitle?.data?.length > 0, eofOpportunityTitle?.data?.length > 0)
    // only if the eof toggle data, top titles and the side titles are ready, call the dataSrc function
    if (!eofLoader && eofTitle?.data?.length > 0 && eofOpportunityTitle?.data?.length > 0) {
      dataSrc(eofTitle);
    }
  }, [eofLoader, eofTitle, eofOpportunityTitle])

  // const selectedOption = (e) => {
  //   setselectedEvent(e);
  //   if (e == "Yes") {
  //     setIsTableShown(true);
  //   }
  //   else {
  //     setIsTableShown(false);
  //   }
  // }

  const formSubmit = async (id, data) => {
    try {
      setLoader(true);
      const response = await axios.put(userServiceApi.updateeof(micrositeId, id), data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewToggledData(dataSrcState, response);
      setOpportunityType(null);
      setLoader(false);
      setDescription(null);
      setMediaType(null);
      setMediaType2(null);
      setImageFlag(true);
      setFile();

      toast.success("Data updated successfully");
    } catch (error) {
      toast.error(error.error);
      setLoader(false);
    }
  }

  const onFinish = (values, index, record, opType) => {
    const submitFormData = new FormData();
    // onChange(false, index)
    let payload = {};
    if (opType === "VIRTUAL") {
      payload['experienceOfWorkOpportunityTitleId'] = record.virtual.experienceOfWorkOppID;
    } else if (opType === "FACE_TO_FACE") {
      payload['experienceOfWorkOpportunityTitleId'] = record.faceToFace.experienceOfWorkOppID;

    }
    payload['experienceOfWorkTitleId'] = record.experienceOfWorkID;
    payload['micrositeId'] = micrositeId; //useLocation to get microsite ID
    payload['experienceOfWorkOpportunityInput'] = {};
    payload['experienceOfWorkOpportunityInput']['toggle'] = true;
    payload['experienceOfWorkOpportunityInput']['description'] = description;
    payload['experienceOfWorkOpportunityInput']['opportunityType'] = (opType === "VIRTUAL") ? "VIRTUAL" : "FACE_TO_FACE";
    if (imageFlag) {
      if (mediaType || mediaType2) {
        payload['experienceOfWorkOpportunityInput']['media'] = {}
        if (mediaType === "SOCIAL_MEDIA" || mediaType2 === "SOCIAL_MEDIA") {
          payload['experienceOfWorkOpportunityInput']['media']['mediaType'] = "SOCIAL_MEDIA"
          payload['experienceOfWorkOpportunityInput']['media']['url'] = youtubeLink;

        } else if (mediaType === "VIMEO_MEDIA" || mediaType2 === "VIMEO_MEDIA") {
          payload['experienceOfWorkOpportunityInput']['media']['mediaType'] = "VIMEO_MEDIA"
          payload['experienceOfWorkOpportunityInput']['media']['url'] = vimeoLink;

        } else if (mediaType === "IMAGE" || mediaType2 === "IMAGE") {
          payload['experienceOfWorkOpportunityInput']['media']['mediaType'] = "IMAGE"
        }
      }
    }

    const finalFormData = JSON.stringify(payload);
    const blob = new Blob([finalFormData], {
      type: "application/json"
    })
    submitFormData.append(`experienceOfWorkInput`, blob)

    if (imageFlag) {
      if (mediaType === "IMAGE" || mediaType2 === "IMAGE") {
        submitFormData.append(`file`, getFile)
      }
    }

    if (opType === "VIRTUAL") {
      const id = record.virtual.id;
      formSubmit(id, submitFormData);
      // setMediaType(null);
      form.resetFields()
    }
    else {
      const id = record.faceToFace.id;
      formSubmit(id, submitFormData);
      // setMediaType(null);
      form.resetFields()
    }
  }

  const primaryCallPOSTAPI = async (data) => {
    try {
      setLoader(true);
      const response = await axios.post(userServiceApi.geteof(micrositeId), data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewToggledData(dataSrcState, response);
      getExperienceofwork(micrositeId);
      // toast.success("Data created successfully");
      setLoader(false);
    } catch (error) {
      toast.error(error.error);
      // setLoading(false);
    }
  }

  const primaryCallPUTAPI = async (data, id) => {
    try {
      setLoader(true);
      const response = await axios.put(userServiceApi.updateeof(micrositeId, id), data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewToggledData(dataSrcState, response);
      getExperienceofwork(micrositeId);
      // toast.success("Data created successfully");
      setLoader(false);
    } catch (error) {
      toast.error(error.error);
      // setLoading(false);
    }
  }


  const primaryCall = (value, record, opType) => {
    let data = new FormData();
    let payload = {};
    payload['experienceOfWorkOpportunityInput'] = {};
    payload['experienceOfWorkOpportunityInput']['toggle'] = true;
    payload['experienceOfWorkOpportunityInput']['opportunityType'] = (opType === "VIRTUAL") ? "VIRTUAL" : "FACE_TO_FACE";
    payload['experienceOfWorkTitleId'] = record.experienceOfWorkID;
    payload['micrositeId'] = micrositeId;
    if (opType === "VIRTUAL") {
      payload['experienceOfWorkOpportunityTitleId'] = record.virtual.experienceOfWorkOppID;
      payload['id'] = record.virtual.id;
    } else if (opType === "FACETOFACE") {
      payload['experienceOfWorkOpportunityTitleId'] = record.faceToFace.experienceOfWorkOppID;
      payload['id'] = record.faceToFace.id;
    }

    const finalData = JSON.stringify(payload);
    const blob = new Blob([finalData], {
      type: "application/json"
    })

    data.append(`experienceOfWorkInput`, blob)
    if (payload.id === undefined) {
      primaryCallPOSTAPI(data);
    } else {
      primaryCallPUTAPI(data, payload.id);
    }
    // try {
    //   //API call with payload
    // } catch (error) {

    // }
  }

  const deleteAPICall = async (id, data) => {
    try {
      setLoader(true);
      const response = await axios.put(userServiceApi.updateeof(micrositeId, id), data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      setNewToggledData(dataSrcState, response);
      getExperienceofwork(micrositeId);
      setLoader(false);

    } catch (error) {
      toast.error(error.error);
    }
  }

  const deleteCall = (value, record, opType) => {
    let deleteData = new FormData();

    let payload = {};
    payload['experienceOfWorkOpportunityInput'] = {};
    payload['experienceOfWorkOpportunityInput']['toggle'] = false;
    payload['experienceOfWorkOpportunityInput']['opportunityType'] = (opType === "VIRTUAL") ? "VIRTUAL" : "FACE_TO_FACE";
    // payload['experienceOfWorkTitleId'] = record.experienceOfWorkID;
    // payload['micrositeId'] = micrositeId;
    // if (opportunityType === "VIRTUAL") {
    //   payload['experienceOfWorkOpportunityTitleId'] = record.virtual.experienceOfWorkOppID;
    // } else if (opportunityType === "FACETOFACE") {
    //   payload['experienceOfWorkOpportunityTitleId'] = record.faceToFace.experienceOfWorkOppID;
    // }

    const finalDeleteData = JSON.stringify(payload);
    const blob = new Blob([finalDeleteData], {
      type: "application/json"
    })

    deleteData.append(`experienceOfWorkInput`, blob)

    if (opType === "VIRTUAL") {
      const id = record.virtual.id;
      deleteAPICall(id, deleteData);
    }
    else {
      const id = record.faceToFace.id;
      deleteAPICall(id, deleteData);
    }
  }

  const columns = [
    {
      title: "",
      dataIndex: "experienceOfWorkTitle",
      key: "experienceOfWorkID",
      render: (value) => (
        <div>
          <p><b>{value}</b></p>
        </div>
      )
    },
    {
      title: "Virtual Opportunities",
      className: "table-headers",
      dataIndex: "virtual",
      key: "virtual",
      render: (obj, record, index) => (
        <>
          {/* {setRecordData([...recordData, record])} */}
          {/* {(record.virtual.toggle) ? setToggleState("true") : setToggleState("false")} */}
          <>
            <div className="upload-form">
              <Form onFinish={(values) => {
                onFinish(values, index, rowData, opportunityType);
              }}
                form={form} encType='multipart/form-data'>
                <div className="d-flex">
                  <Form.Item
                    // name={`toggle-${index}`}
                    style={{ marginLeft: '38%' }}>
                    <Switch
                      defaultChecked={record.virtual.toggle}
                      // defaultChecked={true}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(value) => {
                        if (value) {
                          primaryCall(value, record, "VIRTUAL");
                          // setToggleState(value)
                        } else {
                          deleteCall(value, record, "VIRTUAL");
                          showForm[index] = false;
                          setFlag(true)
                          // setToggleState(value)
                        }
                      }}
                    />
                  </Form.Item>
                  {record.virtual.toggle && flag &&
                    < Form.Item >
                      <Tooltip title={"Click to add/edit more information"}>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setShowForm({ ...showForm, index: true })
                            // showForm[index] = true;

                            showForm[index] = !showForm[index];

                            if (showForm[index] === true) {
                              setFlag(false)
                            }
                            // if (flag === "true" && showForm[index]) {
                            setShowForm(showForm);
                            setRowData(record);
                            setOpportunityType(record.virtual.experienceOfWorkOppTitle)
                            setTempToggleState(!temptoggleState)
                            // setImageFlag(false)
                            setImageFlag(true)
                            setMediaType(finalData[index].virtual?.media.mediaType)
                            if (finalData[index].virtual?.media.mediaType === "SOCIAL_MEDIA") {
                              setYoutubeLink(finalData[index].virtual?.media.url)
                            } else if (finalData[index].virtual?.media.mediaType === "VIMEO_MEDIA") {
                              setVimeoLink(finalData[index].virtual?.media.url)
                            }
                            // }
                            // showEdit[index] = true;
                            // setShowEdit(showEdit);
                          }}
                        >Add/Edit</p>
                      </Tooltip>
                    </Form.Item>
                  }
                </div>
                {showForm[index] &&
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues[showForm] !== currentValues[showForm]}
                  >
                    <Form.Item
                      value={description}
                      name={`virtualdescription-${index}`}
                      initialValue={finalData[index].virtual?.description}
                      rules={[
                        {
                          max: MAX_CHARACTERS - 1,
                          message: `Description should be less than ${MAX_CHARACTERS} character`,
                        }
                      ]}
                    >
                      <TextArea
                        className=""
                        rows={2}
                        maxLength={MAX_CHARACTERS}
                        showCount
                        allowClear
                        onClear={() => setDescription()}
                        placeholder="Enter Description"
                        onChange={(e) => setDescription(e.target.value)}
                      ></TextArea>
                    </Form.Item>
                    <Form.Item
                      style={{ textAlign: "initial" }}
                      name={`mediaType-${index}`}
                      initialValue={finalData[index].virtual?.media.mediaType}
                    >
                      <Select placeholder="Select Video or Image"
                        allowClear
                        onClear={() => {
                          // console.log("here")
                          setMediaType(null);
                          setImageFlag(false);
                          form.setFieldValue("url", "")
                        }}
                        onChange={(value) => {
                          setMediaType(value);
                          form.setFieldValue("url", "")
                          // setVimeoLink(null);
                          // setYoutubeLink(null);
                        }}
                      >
                        <Select.Option value="VIMEO_MEDIA">Add Vimeo Link</Select.Option>
                        <Select.Option value="SOCIAL_MEDIA">Add Video Link</Select.Option>
                        <Select.Option value="IMAGE">Upload Image</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues[`mediaType-${index}`] !== currentValues[`mediaType-${index}`]}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue(`mediaType-${index}`) === 'VIMEO_MEDIA' ? (
                          <Form.Item value={vimeoLink} label="Vimeo link" name={"url"} initialValue={finalData[index].virtual?.media.mediaType === 'VIMEO_MEDIA' ? finalData[index].virtual?.media.url : null}>
                            <Input onChange={(e) => {
                              setImageFlag(true)
                              setVimeoLink(e.target.value)
                            }} />
                          </Form.Item>
                        ) : getFieldValue(`mediaType-${index}`) === 'SOCIAL_MEDIA' ? (
                          <Form.Item value={youtubeLink} label="Youtube Link" name={"url"} initialValue={finalData[index].virtual?.media.mediaType === 'SOCIAL_MEDIA' ? finalData[index].virtual?.media.url : null}>
                            <Input onChange={(e) => {
                              setImageFlag(true)
                              setYoutubeLink(e.target.value)
                            }} />
                          </Form.Item>
                        ) : getFieldValue(`mediaType-${index}`) === 'IMAGE' ? (
                          <Form.Item name={"url"}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {finalData[index].virtual?.media.url ? (
                                getFile ? (
                                  <Avatar
                                    size={64}
                                    // src={finalData[index].virtual?.media.mediaType === "IMAGE" ? URL.createObjectURL(finalData[index].virtual?.media.url) : null}
                                    // src={getFile ? finalData[index].virtual?.media.url : null}
                                    src={
                                      getFile ? URL.createObjectURL(getFile) : null
                                    }
                                    alt={getFile ? getFile.name : null} />
                                ) : (
                                  <Avatar size={64} src={finalData[index].virtual?.media.mediaType === "IMAGE" ? finalData[index].virtual?.media.url : UserIcon} />
                                )
                              ) : image === undefined ? (
                                <Avatar size={64} src={UserIcon} />
                              ) : (
                                <Avatar
                                  size={64}
                                  src={
                                    getFile ? URL.createObjectURL(getFile) : null
                                  }
                                  alt={getFile ? getFile.name : null}
                                />
                              )}

                              <label
                                name="file"
                                className="fileUploadBtn greenBtn"
                              >
                                {" "}
                                Choose File
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  // onChange={(e) =>
                                  //   updateImage(e, 1, form, whichTab)}
                                  onChange={changeHandler}
                                />
                              </label>
                            </div>
                          </Form.Item>
                        ) : ""
                      }
                    </Form.Item>
                    <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <Button
                          className="greenBtn"
                          style={{ color: "white", borderRadius: "5px" }}
                          onClick={() => {
                            showForm[index] = false
                            setShowForm(showForm)
                            setTempToggleState(!temptoggleState)
                            form.submit()
                            setFlag(true)
                          }}>Save</Button>
                      </div>
                    </Form.Item>
                    {/* </>
                      )} */}
                  </Form.Item>
                }

              </Form>
            </div>
          </>
        </>
      )
    },
    {
      title: "Face to Face opportunities",
      className: "table-headers",
      dataIndex: "faceToFace",
      key: "faceToFace",
      render: (obj, record, index) => (
        <>
          <>
            <div className="upload-form">
              <Form onFinish={(values) => {
                onFinish(values, index, rowData, opportunityType);
              }}
                form={form} enctype='multipart/form-data'>
                <div className="d-flex">
                  <Form.Item
                    // name={`toggle-${index}`}
                    style={{ marginLeft: "38%" }}>
                    <Switch
                      defaultChecked={record.faceToFace.toggle}
                      // defaultChecked={true}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(value) => {
                        if (value) {
                          primaryCall(value, record, "FACETOFACE");

                        } else {
                          deleteCall(value, record, "FACETOFACE");
                          showForm2[index] = false;
                          setFlag(true)
                        }
                        setToggleState(value)
                      }}
                    />
                  </Form.Item>
                  {record.faceToFace.toggle && flag &&
                    <Form.Item>
                      <Tooltip title={"Click to add/edit more information"}>
                        {/* <PlusCircleFilled
                          style={{ color: "#59A638", marginLeft: "-5px", transform: "scale(1.3)", marginTop: "5px" }}
                          className={showEdit[index] ? "edit-enabled" : "edit-disabled"}
                          onClick={() => {
                            // setShowForm({ ...showForm, index: true })

                            showForm2[index] = !showForm2[index];
                            if (showForm2[index] === true) {
                              setFlag(false)
                            }
                            setShowForm2(showForm2);

                            setRowData(record);
                            setOpportunityType(record.faceToFace.experienceOfWorkOppTitle)
                            setTempToggleState(!temptoggleState)
                            setImageFlag(false)
                            setDescription(finalData[index].faceToFace?.description)
                            setMediaType(finalData[index].faceToFace?.media.mediaType)
                            if (finalData[index].faceToFace?.media.mediaType === "SOCIAL_MEDIA") {
                              setYoutubeLink(finalData[index].faceToFace?.media.url)
                            } else if (finalData[index].faceToFace?.media.mediaType === "VIMEO_MEDIA") {
                              setVimeoLink(finalData[index].faceToFace?.media.url)
                            }
                            // else (finalData[index].faceToFace?.media.mediaType === "IMAGE") {

                            // }
                            // showEdit[index] = true;
                            // setShowEdit(showEdit);
                          }}
                        /> */}
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setShowForm({ ...showForm, index: true })

                            showForm2[index] = !showForm2[index];
                            if (showForm2[index] === true) {
                              setFlag(false)
                            }
                            setShowForm2(showForm2);

                            setRowData(record);
                            setOpportunityType(record.faceToFace.experienceOfWorkOppTitle)
                            setTempToggleState(!temptoggleState)
                            // setImageFlag(false)
                            setImageFlag(true);
                            setDescription(finalData[index].faceToFace?.description)
                            setMediaType(finalData[index].faceToFace?.media.mediaType)
                            if (finalData[index].faceToFace?.media.mediaType === "SOCIAL_MEDIA") {
                              setYoutubeLink(finalData[index].faceToFace?.media.url)
                            } else if (finalData[index].faceToFace?.media.mediaType === "VIMEO_MEDIA") {
                              setVimeoLink(finalData[index].faceToFace?.media.url)
                            }
                            // else (finalData[index].faceToFace?.media.mediaType === "IMAGE") {

                            // }
                            // showEdit[index] = true;
                            // setShowEdit(showEdit);
                          }}
                        >Add/Edit</p>
                      </Tooltip>
                    </Form.Item>
                  }
                </div>
                {showForm2[index] &&
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues[showForm2] !== currentValues[showForm2]}
                  >
                    {/* {({ getFieldValue }) => (
                      <> */}
                    <Form.Item
                      value={description}
                      name={"faceTofacedescription"}
                      initialValue={finalData[index].faceToFace?.description}
                      rules={[
                        {
                          max: MAX_CHARACTERS - 1,
                          message: `Description should be less than ${MAX_CHARACTERS} character`,
                        }
                      ]}
                    >
                      <TextArea
                        className=""
                        maxLength={MAX_CHARACTERS}
                        showCount
                        placeholder="Enter Description" rows={2}
                        allowClear onClear={() => setDescription()}
                        onChange={(e) => setDescription(e.target.value)}>

                      </TextArea>
                    </Form.Item>
                    <Form.Item
                      style={{ textAlign: "initial" }}
                      name={`faceTofacemediaType2-${index}`}
                      initialValue={finalData[index].faceToFace?.media.mediaType}
                    >
                      <Select placeholder="Select Video or Image"
                        // name={[name, "mediaLink", "mediaType"]}
                        allowClear
                        onClear={() => {
                          setMediaType2(null);
                          setImageFlag(false);
                          form.setFieldValue("url", "")
                        }}
                        onChange={(value) => {
                          setMediaType2(value);
                          form.setFieldValue("url", "")
                        }}
                      >
                        <Select.Option value="VIMEO_MEDIA">Add Vimeo Link</Select.Option>
                        <Select.Option value="SOCIAL_MEDIA">Add Video Link</Select.Option>
                        <Select.Option value="IMAGE">Upload Image</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues[`faceTofacemediaType2-${index}`] !== currentValues[`faceTofacemediaType2-${index}`]}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue(`faceTofacemediaType2-${index}`) === 'VIMEO_MEDIA' ? (
                          <Form.Item label="Vimeo link" name={"url"} initialValue={finalData[index].faceToFace?.media.mediaType === 'VIMEO_MEDIA' ? finalData[index].faceToFace?.media.url : null}>
                            <Input onChange={(e) => {
                              setImageFlag(true)
                              setVimeoLink(e.target.value)
                            }} />
                          </Form.Item>
                        ) : getFieldValue(`faceTofacemediaType2-${index}`) === 'SOCIAL_MEDIA' ? (
                          <Form.Item label="Youtube Link" name={"url"} initialValue={finalData[index].faceToFace?.media.mediaType === 'SOCIAL_MEDIA' ? finalData[index].faceToFace?.media.url : null}>
                            <Input onChange={(e) => {
                              setImageFlag(true)
                              setYoutubeLink(e.target.value)
                            }} />
                          </Form.Item>
                        ) : getFieldValue(`faceTofacemediaType2-${index}`) === 'IMAGE' ? (
                          <Form.Item name={"url"}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {finalData[index].faceToFace?.media.url ? (
                                getFile ? (
                                  <Avatar
                                    size={64}
                                    // src={finalData[index].virtual?.media.mediaType === "IMAGE" ? URL.createObjectURL(finalData[index].virtual?.media.url) : null}
                                    // src={getFile ? finalData[index].faceToFace?.media.url : null}
                                    src={
                                      getFile ? URL.createObjectURL(getFile) : null
                                    }
                                    alt={getFile ? getFile.name : null} />
                                ) : (
                                  <Avatar size={64} src={finalData[index].faceToFace?.media.mediaType === "IMAGE" ? finalData[index].faceToFace?.media.url : UserIcon} />
                                )
                              ) : image === undefined ? (
                                <Avatar size={64} src={UserIcon} />
                              ) : (
                                <Avatar
                                  size={64}
                                  // src={finalData[index].virtual?.media.mediaType === "IMAGE" ? URL.createObjectURL(finalData[index].virtual?.media.url) : null}
                                  // src={getFile ? finalData[index].faceToFace?.media.url : null}
                                  // alt={UserIcon} 
                                  src={
                                    getFile ? URL.createObjectURL(getFile) : null
                                  }
                                  alt={getFile ? getFile.name : null}
                                />
                              )}

                              <label
                                name="faceTofacefile"
                                className="fileUploadBtn greenBtn"
                              >
                                {" "}
                                Choose File
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  onChange={changeHandler}
                                />
                              </label>
                            </div>
                          </Form.Item>
                        ) : ""
                      }
                    </Form.Item>
                    <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="greenBtn"
                        style={{ color: "white", borderRadius: "5px" }}
                        onClick={() => {
                          showForm2[index] = false
                          setShowForm2(showForm2)
                          setTempToggleState(!temptoggleState)
                          form.submit()
                          setFlag(true)
                        }}>Save</Button>
                    </Form.Item>
                    {/* </>
                      )} */}
                  </Form.Item>
                }

              </Form>
            </div>
          </>
        </>
      )
    }
  ];

  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [fileLoader, setFileLoader] = useState(true);

  const getFiles = async () => {
    setFileLoader(true);
    try {
      const response = await getRequest(userServiceApi.getInsuranceFile(micrositeId));
      if (response) {
        setExistingFiles(response.data);
        setFileLoader(false);
      }
    } catch (error) {
      // console.log("rror")
    }
  }
  useEffect(() => {
    getFiles();
  }, [])

  useEffect(() => {
    getFiles();
  }, [fileList])

  const checkFile = (file) => {
    const isPDFOrDOC = file.type === 'application/pdf' || file.type === 'application/msword';
    if (!isPDFOrDOC) {
      toast.error('You can only upload PDF and DOC files!');
    }
    return isPDFOrDOC;
  };

  const fileAPI = async (formData) => {
    try {
      setLoader(true);
      const response = await axios.put(userServiceApi.uploadInsuranceDoc(micrositeId), formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response) {
        toast.success("File uploaded successfully");
        // window.location.reload();
        getFiles();
        // setLoader(false);
      }
      // if (response.status === 200) {
      //   // File uploaded successfully, update its status
      //   const updatedFile = { ...file, status: 'done' };
      //   const updatedFileList = [...fileList];
      //   const fileIndex = updatedFileList.findIndex(item => item.uid === updatedFile.uid);

      //   if (fileIndex !== -1) {
      //     updatedFileList[fileIndex] = updatedFile;
      //     setFileList(updatedFileList);
      //   }

      //   toast.success('File uploaded successfully');
      // } else {
      //   toast.error('File upload failed');
      // }
      // setLoader(false);

    } catch (error) {
      toast.error(error.error);
      // setLoading(false);
    }
  }

  const handleUploadAPI = ({ file }) => {
    var formData = new FormData();
    const blob = new Blob([file], {
      type: "multipart/form-data"
    });
    formData.append("file", blob, file.name);
    if (existingFiles) {
      formData.append("documentId", existingFiles.documentId)
    }
    fileAPI(formData);
    setLoader(false);

    if (Object.values(fileList).length > 0) {
      // toast.error("You can upload only one file")
    } else {
      setFileList((pre) => {
        return { ...pre, [file.uid]: file }
      })
    }
  }



  const handleDeleteFile = async (documentId) => {
    try {
      // setLoader(true);
      const response = await deleteRequest(userServiceApi.deleteInsuranceDoc(documentId));
      if (response) {
        toast.success("Document deleted successfully");
        // window.location.reload();
        getFiles();
        // setLoader(false)
      }
    } catch (error) {
      // toast.error("Error in deleting the document")
    }
  }

  const uploadProps = {
    accept: '.pdf',
    beforeUpload: checkFile,
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    customRequest: handleUploadAPI,
  }

  if (loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <div className="files">
          <div className="file-upload">
            <label>Add Employer's insurance liability document</label>
            <div className="upload-component">
              {/* <Space direction="horizontal"> */}
              <Dragger {...uploadProps} className="dropbox">
                <div className="box-content">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </div>
              </Dragger>
              {/* {Object.values(fileList).map((file, i) => {
                  return (
                    <Space className="fileList-Item">
                      <FileOutlined />
                      <Typography>{file.name}</Typography>
                    </Space>
                  )
                })} */}
              {/* </Space> */}
            </div>
          </div>
          <div className="existing">
            <label>Existing document</label>
            {existingFiles ? (
              <Space className="fileList-Item">
                <FileOutlined />
                <Typography>{existingFiles?.fileName}</Typography>
                <DeleteOutlined onClick={() => handleDeleteFile(existingFiles?.documentId)} />
              </Space>
            ) : existingFiles && !fileLoader ? (
              <Space className="fileList-Item">
                <Typography className="nodoc-text">Uploading document</Typography>
              </Space>
            ) : (
              <Space className="fileList-Item">
                <Typography>No document uploaded</Typography>
              </Space>
            )}
          </div>
        </div >
        <Table pagination={false} columns={columns} dataSource={finalData} />
      </div >
    );
  }
}
