import React, { useState } from "react";
// import Button from "../buttons/Button";
import { Form, Input, Avatar, Button } from "antd";
import { toast } from "react-toastify";
import { getMediaProperty } from "../../utils";
import { useMicrosite } from "../../custom-hooks/useMicrosite";
import UserIcon from "../../assets/images/icons/userLogo.svg";
import "./style.scss";

export default function MicrositeEdit({
  page,
  reloadFlagFunction,
  data,
  closeSettings,
}) {
  const [form] = Form.useForm();
  const [media, setMedia] = useState({});

  const { updateMicroSite, microSiteUpdateLoading } = useMicrosite();

  const submit = (values) => {
    if (
      (values && values.name && values.name !== data.name) ||
      (media && media.mediaProperty)
    ) {
      var formData = new FormData();
      if (media && media.blob) {
        formData.append("media", media.blob);
      }
      var valueData = values;

      if (media && media.mediaProperty) {
        valueData.media = media.mediaProperty;
      }
      const json = JSON.stringify(valueData);
      const blob = new Blob([json], {
        type: "application/json",
      });
      formData.append("micrositeProfileInput", blob);

      updateMicroSite(data.id, formData, reloadFlagFunction, page);
    } else {
      toast.error("Nothing to update");
    }
  };
  const changeImage = (event) => {
    if (event.target.files && event.target.files[0].size >= 2e6) {
      toast.error("File should not exceed 2MB");
    } else {
      const media = getMediaProperty(event);
      setMedia(media);
    }
  };
  return (
    <Form onFinish={submit} form={form} initialValues={data} style={{ width: "100%" }}>
      <div className="ms-edit-main">
        <div className="ms-edit-left">
          <div className="ms-edit-media">
            {media && media.blob ? (
              <img className="edit-img" src={URL.createObjectURL(media.blob)} />
            ) : data && data.mediaLink ? (
              <img className="edit-img" src={data.mediaLink} />
            ) : (
              <img className="edit-img" src={UserIcon} />
            )}
            <div>
              <label name="file" className="fileUploadBtn greenBtn">
                {" "}
                Choose Image
                <input
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  // required={true}
                  onChange={(e) => changeImage(e)}
                />
              </label>
            </div>
          </div>

          <Form.Item
            name={"name"}
            rules={[{ required: true, message: "Microsite name  required" }]}
          >
            <Input placeholder="Microsite name" className="micrositename" />
          </Form.Item>
        </div>
        <div className="ms-edit-right">
          <Button
            // label="Cancel"
            onClick={closeSettings}
            type="button"
            className="cancelBtn"
          >Cancel</Button>
          <Button
            label="Save"
            className="greenBtn"
            type="submit"
            onClick={() => form.submit()}
          // loading={microSiteUpdateLoading}
          >Save</Button>
        </div>
      </div>
    </Form>
  );
}
