import React, { useState } from "react";
import { toast } from "react-toastify";
import userServiceApi from "../api/userServiceApi";
import { getRequest } from "../api/http";
import { useStore } from "../store";
import { putRequest } from "../api/http";
import { setSettings, setMicroSiteActions } from "../store/actions";

export function useSettings() {

  const { dispatch } = useStore();
  const [loading, setLoader] = useState(false)

  const getSettings = async (micrositeId) => {
    setLoader(true)
    const partnerId = localStorage.getItem('partnerID')

    try {
      const response = await getRequest(userServiceApi.getSettings(partnerId,micrositeId));
      if (response && response.data) {
        // setSiteSitting(response.data);
        dispatch(setSettings(response.data.settings))
      }
    } catch (error) {
      console.log(error)      
       toast.error("Failed to fetch Pro settings");
    }
    finally
    {
      setLoader(false)
    }
  };
  
  const updateSettings = async (micrositeId, data) => {
    const partnerId = localStorage.getItem('partnerID')

    setLoader(true)
    try {
      const response = await putRequest(userServiceApi.putSettings(partnerId,micrositeId), data);
      if (response) {
        dispatch(setSettings(response.settings));
        dispatch(setMicroSiteActions({ activeKey: micrositeId, menuKey: 'settings', change: false }))

        toast.success("Settings updated successfully!!");
      } else {
        toast.error('Something went wrong..!');
      }
    } catch (error) {
      toast.error(error.error);
    }
    finally
    {
      setLoader(false)
    }
  }


  return {
    getSettings,
    updateSettings,
    settingsUpdateLoader: loading
  }
}