import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Input,
    Form,
    Select,
    Row,
    Col,
    Spin,
    Tooltip,
    Tabs
} from "antd";
import {
    PlusOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { aboutUSkeys, TAB_KEYS } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../../../utils";
import ImageLabel from "../../../../../image-label";
import ReactPlayer from "react-player/youtube";
import linkedin from "../../../../../../assets/images/linkedIn.svg";
import mail from "../../../../../../assets/images/mail.svg";

const { TextArea } = Input;
const MAX_CHARACTERS = 500;

export default function OurTeamComponent({
    dataLoader,
    form,
    handleTabs,
    activeKey,
    whichTab
}) {
    // const params = useParams();
    const { state } = useLocation();
    // const navigate = useNavigate();
    const tabKey = state;

    const { tabData } = useStore();
    // const whichTab = aboutUSkeys["Our Team"];
    const { deleteSingleItem, postLoader } = useTab();
    const { updateAccreditationImage } = useConnectsuForm();
    const [loader, setLoader] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const { updatedValues } = useStore();

    if (activeKey === "ABOUT_US") {
        localStorage.setItem("tab", activeKey);
    }

    useEffect(() => {
        setFormChanged(false);
    }, []);

    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;
        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    const renderMedia = (dataItem) => {
        const commonStyles = {
            width: "350px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
        };
        if (
            dataItem?.aboutUsTeamDataOutPut?.image && dataItem?.aboutUsTeamDataOutPut?.image?.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <img
                        src={dataItem?.aboutUsTeamDataOutPut?.image?.url}
                        alt=""
                        className="c1 carousel-image"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                </div>
            );
        }

        return null;
    };

    const findValue = (i, key) => {
        return getFormFieldValue(form, whichTab, i, key);
    };

    const handleDelete = (form, id,) => {

        deleteSingleItem(
            whichTab,
            id,
            form
        )
    }
    useEffect(() => {
        form.resetFields();
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [tabData[whichTab]]);

    if (dataLoader || postLoader || loader) {
        return (
            <div className="data-loader">
                <Spin tip="Loading" size="large" />
            </div>
        );
    } else {
        return (
            <div>
                <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {tabKey.split('/')[1] === whichTab ? (
                        <>
                            <Col className="gutter-row tabContent" span={24}>
                                <Form.List
                                    name={whichTab}
                                    initialValue={
                                        tabData &&
                                            tabData[whichTab] &&
                                            tabData[whichTab].length > 0
                                            ? tabData[whichTab].map((ele) => {
                                                // console.log('ele', ele)
                                                return {
                                                    id: ele.id,
                                                    bio: ele.aboutUsTeamDataOutPut.bio,
                                                    linkedin: ele.aboutUsTeamDataOutPut.linkedin,
                                                    email: ele.aboutUsTeamDataOutPut.email,
                                                    mediaLink: ele.aboutUsTeamDataOutPut.image
                                                }
                                            })
                                            : [{}]
                                    }
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, i) => {
                                                return <div key={i} className="card-item">
                                                    <Col
                                                        className="gutter-row tabContent"
                                                        span={16}
                                                        style={{ paddingRight: "25px" }}
                                                    >
                                                        <div style={{ display: 'flex' }} >
                                                            <Form.Item
                                                                className="w-100"
                                                                label="Bio"
                                                                {...restField}
                                                                labelCol={{ span: 5 }}
                                                                name={[name, "bio"]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Bio is required",
                                                                    },
                                                                    {
                                                                        max: MAX_CHARACTERS - 1,
                                                                        message: `Description should be less than ${MAX_CHARACTERS} character`,
                                                                    }
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    placeholder="Enter Bio Description"
                                                                    rows={5}
                                                                    maxLength={MAX_CHARACTERS}
                                                                    showCount
                                                                />
                                                            </Form.Item>
                                                            <div className="remove-btn" style={{ position: 'relative' }} >
                                                                {<DeleteOutlined
                                                                    style={{ cursor: 'pointer' }}
                                                                    title="Delete Team"
                                                                    onClick={() => {

                                                                        handleDelete(form, form.getFieldValue([
                                                                            whichTab,
                                                                            i,
                                                                            "id",
                                                                        ]), tabKey)

                                                                    }
                                                                    }
                                                                />}
                                                            </div>
                                                        </div>
                                                        <Form.Item
                                                            label={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={linkedin} alt="Image" style={{ marginRight: '3px' }} />
                                                                    Linkedin
                                                                </div>
                                                            }
                                                            labelCol={{ span: 5 }}
                                                            {...restField}
                                                            name={[name, "linkedin"]}
                                                        >
                                                            <Input placeholder="Linkedin" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={mail} alt="Image" style={{ marginRight: '3px' }} />
                                                                    Email
                                                                </div>
                                                            }
                                                            labelCol={{ span: 5 }}
                                                            {...restField}
                                                            name={[name, "email"]}
                                                            rules={[
                                                                { type: "email", message: "Enter valid email" },
                                                            ]}
                                                        >
                                                            <Input placeholder="Email" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Image"
                                                            colon={false}
                                                            // label={<p style={{ fontSize: '16px', margin: '0px' }}><span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>Image</p>}
                                                            name="media"
                                                            className="experienceImg"
                                                            labelCol={{ span: 5 }}

                                                        >
                                                            {findValue(i, "blob") ? (
                                                                <Avatar
                                                                    src={URL.createObjectURL(
                                                                        findValue(i, "blob")
                                                                    )}
                                                                    alt=""
                                                                />
                                                            ) : findValue(i, "url") ? (
                                                                <Avatar src={findValue(i, "url")} alt="" />
                                                            ) : (
                                                                <Avatar src={UserIcon} />
                                                            )}
                                                            <label
                                                                name="file"
                                                                className="fileUploadBtn greenBtn"
                                                            >
                                                                {" "}
                                                                Choose File
                                                                <input
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    type="file"
                                                                    // required={true}
                                                                    onChange={(e) =>
                                                                        updateAccreditationImage(e, i, form, whichTab)
                                                                    }
                                                                />
                                                            </label>
                                                        </Form.Item>

                                                        <Form.Item
                                                            className="w-100"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "end",
                                                            }}
                                                        >
                                                            {formChanged ? (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    onClick={() => {
                                                                        form
                                                                            .submit()
                                                                        // .then(() => {
                                                                        //     setFormChanged(false);
                                                                        // })
                                                                        // .catch((errorInfo) => {
                                                                        //     console.error(
                                                                        //         "Form submission failed:",
                                                                        //         errorInfo
                                                                        //     );
                                                                        // });
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    style={{
                                                                        opacity: "0.5",
                                                                        pointerEvents: "none",
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row tabContent" span={8}>
                                                        <div>{tabData[whichTab]?.[i] ? renderMedia(tabData[whichTab][i]) : null}</div>

                                                    </Col>
                                                </div>
                                            })}

                                            <div className="inputBody">
                                                <div style={{ textAlign: "right" }}>
                                                    {fields && fields.length <= 1 ? (
                                                        <></>
                                                    ) : (
                                                        <Button
                                                            className="cancelBtn"
                                                            type="button"
                                                            onClick={() => {
                                                                const itemsToRemove = [];
                                                                fields.forEach((f, i) => {
                                                                    if (
                                                                        !findValue(i, "id") &&
                                                                        i !== 0
                                                                    ) {
                                                                        itemsToRemove.push(f.name);
                                                                    }
                                                                });
                                                                if (itemsToRemove.length > 0) {
                                                                    remove(itemsToRemove);
                                                                }
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                    <Button className="greenBtn" onClick={() => add({})}>
                                                        Add More
                                                        <PlusOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </>
                    ) : (
                        <></>
                    )}
                </Row>
            </div>
        );
    }
}
