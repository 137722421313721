import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Tooltip,
} from "antd";
import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ImageLabel from "../../../image-label";
import ytPlayBtn from "../../../../assets/images/ytPlayBtn.svg";
import ModalPlayer from "../../ModalMediaPlayer";

const { TextArea } = Input;

export default function BusinessCardComponent({ dataLoader, form, handleTabs, activeKey }) {
  const params = useParams();
  const { state } = useLocation();

  // console.log("active key in business card", activeKey);

  if (activeKey === "businessCard") {
    localStorage.setItem("tab", activeKey)
  }

  const tabKey = state;
  const { tabData } = useStore();
  const whichTab = TAB_KEYS.businessCard;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage } = useConnectsuForm();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();

  // useEffect(() => {
  //   localStorage.setItem("tab", "BUSINESS_CARD");
  // }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);
  const onChange = () => {
    setFormChanged(true);
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const renderMediaDescription = (dataItem) => {
    return <div className="infoClass" style={{ alignItems: 'center' }} >
      <div className="logoClass">
        <img
          src={dataItem.micrositeLogo}
          alt="Logo"
          style={{ maxWidth: "90%", height: "50px" }}
        />
      </div>
      <div
        style={{
          fontSize: "13px",
          paddingBottom: "3px",
          lineHeight: "1.4714285714285714",
          // listStyle: "none",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        }}
      >
        {dataItem.description}
      </div>
    </div>
  }

  const renderMedia = (dataItem) => {

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      // Convert the URL to its standard format
      const standardURL = convertToStandardURL(dataItem?.mediaLink?.url);
      const videoID = standardURL?.split("v=")[1].split("&")[0];

      return (
        <div className="card" style={{ paddingLeft: '7px', paddingRight: '7px', height: 'auto', }}>
          <div className="cardContent" style={{ height: 'auto' }}>
            <div
              className="media youtube"
              style={{ objectFit: "contain" }}
              onClick={() =>
                PlayVideoHandler(standardURL, dataItem.mediaLink?.mediaType)
              }
            >
              <img
                src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                alt=""
                className="video-preview-image"
                style={{ height: "100%", borderRadius: "8px" }}
              />
              <img className="ytBtnOverlay" src={ytPlayBtn} />
            </div>
            {renderMediaDescription(dataItem)}
          </div>
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="card" style={{ paddingLeft: '7px', paddingRight: '7px', height: 'auto', }}>
          <div className="cardContent" style={{ height: 'auto', flexGrow: '1' }}>
            <div
              className="media youtube"
              onClick={() => {
                PlayVideoHandler(
                  dataItem.mediaLink?.url,
                  dataItem.mediaLink?.mediaType
                );
              }}
            >
              <img
                src={`https://vumbnail.com/${dataItem.mediaLink?.url?.split("/")[4]
                  }.jpg`}
                className="video-preview-image"
                style={{ height: "100%", borderRadius: "8px" }}
              />
              <img className="ytBtnOverlay" src={ytPlayBtn} />
            </div>
            {renderMediaDescription(dataItem)}

          </div>
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="card" style={{ paddingLeft: '7px', paddingRight: '7px', height: 'auto', }}>
          <div className="cardContent" style={{ height: 'auto', flexGrow: '1' }}>
            <div className="media youtube">
              <img
                src={dataItem?.mediaLink?.url}
                alt=""
                className="c1 carousel-image"
                style={{ maxWidth: "100%", maxHeight: "120px" }}
              />
            </div>
            {renderMediaDescription(dataItem)}
          </div>
        </div>
      );
    }

    return null;
  };

  const MAX_CHARACTERS = 110;

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };
  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: "100%" }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <div className="card-item">
                            <Col
                              className="gutter-row tabContent"
                              span={14}
                              style={{ paddingLeft: "0px" }}
                            >
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  className="w-100"
                                  label="Business Card"
                                  // style={{ fontWeight: "bold" }}
                                  labelCol={{ span: 5 }}
                                  name={[name, "description"]}
                                  rules={[
                                    {
                                      max: MAX_CHARACTERS - 1,
                                      message: `Description should be less than ${MAX_CHARACTERS} character`,
                                    }
                                  ]}
                                >
                                  <TextArea
                                    value={value}
                                    onChange={handleInputChange}
                                    placeholder="Enter Business card description"
                                    maxLength={MAX_CHARACTERS}
                                    showCount
                                    rows={3}
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Description and video/image provided here would be visible in the home screen of the end user app"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>

                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  className="w-100"
                                  name={[name, "mediaLink", "mediaType"]}
                                  label="Video or Image"
                                  labelCol={{ span: 5 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Select any option",
                                    },
                                  ]}
                                >
                                  <Select
                                    name={[name, "mediaLink", "mediaType"]}
                                    onChange={(e) => SelectChangeHandler(e, i)}
                                    placeholder="Select Option"
                                  >
                                    <Select.Option value="VIMEO_MEDIA">
                                      Add Vimeo Link
                                    </Select.Option>
                                    <Select.Option value="SOCIAL_MEDIA">
                                      Add Youtube Link
                                    </Select.Option>
                                    <Select.Option value="IMAGE">
                                      Upload Image
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Upload Video link or image with size lesser than 2.5MB"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                <Form.Item
                                  label="Youtube Link"
                                  {...restField}
                                  labelCol={{ span: 5 }}
                                  name={[name, "mediaLink", "url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Youtube link is required",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                <Form.Item
                                  label="Vimeo Link"
                                  labelCol={{ span: 5 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vimeo link is required",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "IMAGE" ? (
                                <Form.Item
                                  {...restField}
                                  label={<ImageLabel label={"Image"} />}
                                  colon={false}
                                  name="media"
                                  className="experienceImg"
                                  labelCol={{ span: 5 }}
                                  rules={[
                                    {
                                      validator: (_, value) => {
                                        if (findValue(i, "type") === "IMAGE") {
                                          return findValue(i, "blob")
                                            ? Promise.resolve()
                                            : findValue(i, "url")
                                              ? Promise.resolve()
                                              : Promise.reject(
                                                new Error("Image is required")
                                              );
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  {findValue(i, "blob") ? (
                                    <Avatar
                                      src={URL.createObjectURL(
                                        findValue(i, "blob")
                                      )}
                                      alt=""
                                    />
                                  ) : findValue(i, "url") ? (
                                    <Avatar src={findValue(i, "url")} alt="" />
                                  ) : (
                                    <Avatar src={UserIcon} />
                                  )}

                                  <label
                                    name="file"
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {" "}
                                    Choose File
                                    <input
                                      accept="image/png, image/gif, image/jpeg"
                                      type="file"
                                      // required={true}
                                      onChange={(e) =>
                                        updateImage(e, i, form, whichTab)
                                      }
                                    />
                                  </label>
                                </Form.Item>
                              ) : (
                                <></>
                              )}
                            </Col>
                            <Col className="gutter-row tabContent" span={10}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {renderMedia(tabData[whichTab][i])}
                              </div>
                            </Col>
                          </div>
                        ))}

                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (!findValue(i, "id") && i !== 0) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Form.List>
                </>
              </Col>
            </>
          ) : (
            <></>
          )}
          <Form.Item
            className="w-100"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {formChanged ? (
              <Button
                className="saveBtn greenBtn"
                onClick={() => {
                  form.submit().then(() => {
                    setFormChanged(false);
                  }).catch((errorInfo) => {
                    console.error('Form submission failed:', errorInfo);
                  });
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                className="saveBtn greenBtn"
                style={{ opacity: "0.5", pointerEvents: "none" }}
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Row>
        {isModalOpen ? (
          <ModalPlayer
            closeModal={handleCancel}
            mediaLink={videoUrl}
            mediaType={mediatype}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
