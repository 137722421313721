import {
  SET_TAB_DATA,
  SET_TAB_LOADER,
  SET_UPDATED_VALUES,
  SET_SKILLS,
  SET_SETTINGS,
  SET_MICROSITES,
  SET_MICROSITE_ACTIONS,
  SET_TEAMS_AND_ROLES,
  SET_COURSES_QUALIFICATIONS,
  SET_PRODUCT_GROUPS,
} from "./constants";

export const initialState = {
  tabData: {},
  tabLoader: {},
  updatedValues: {},
  skills: [],
  teams_and_roles: [],
  product_groups: [],
  courses_qualifications: [],
  settings: {},
  microSites: [],
  microSiteActions: {},
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAB_DATA:
      let constructData = state.tabData;

      if (
        action.tabData &&
        action.tabData.mainKey &&
        action.tabData.subKey &&
        action.tabData.data
      ) {
        if (constructData[action.tabData.mainKey]) {
          constructData[action.tabData.mainKey] = {
            ...constructData[action.tabData.mainKey],
            [action.tabData.subKey]: action.tabData.data,
          };
        } else {
          constructData[action.tabData.mainKey] = {
            [action.tabData.subKey]: action.tabData.data,
          };
        }
      } else if (
        action.tabData &&
        action.tabData.mainKey &&
        action.tabData.data
      ) {
        constructData[action.tabData.mainKey] = action.tabData.data;
      }

      let updatedValue = [];
      if (action.tabData.mainKey == "about-us") {
        let WhyDoCheck = action.tabData.data?.find(
          (res) => res.title == "WhyDo"
        );

        if (!WhyDoCheck) {
          updatedValue.push({
            title: "WhyDo",
          });
        }

        let HowDoCheck = action.tabData.data?.find(
          (res) => res.title == "howDo"
        );
        if (!HowDoCheck) {
          updatedValue.push({
            title: "howDo",
          });
        }

        let WhatDoCheck = action.tabData.data?.find(
          (res) => res.title == "whatDo"
        );
        if (!WhatDoCheck) {
          updatedValue.push({
            title: "whatDo",
          });
        }
      }
      // if (action.tabData.mainKey == "why-work") {
      //   let WhyDoCheck = action.tabData.data?.find((res) => res.title == "w1");

      //   if (!WhyDoCheck) {
      //     updatedValue.push({
      //       title: "w1",
      //       description: "",
      //     });
      //   }

      //   let WhatDoCheck = action.tabData.data?.find((res) => res.title == "w2");
      //   if (!WhatDoCheck) {
      //     updatedValue.push({
      //       title: "w2",
      //       description: "",
      //     });
      //   }

      //   let HowDoCheck = action.tabData.data?.find((res) => res.title == "w3");
      //   if (!HowDoCheck) {
      //     updatedValue.push({
      //       title: "w3",
      //       description: "",
      //     });
      //   }
      // }

      constructData[action.tabData.mainKey] = [
        ...action.tabData.data,
        ...updatedValue,
      ];

      return {
        ...state,
        tabData: constructData,
      };
    case SET_TAB_LOADER:
      let loader = state.tabLoader;

      if (
        action.tabLoader &&
        action.tabLoader.mainKey &&
        action.tabLoader.subKey &&
        action.tabLoader.loading
      ) {
        if (loader[action.tabLoader.mainKey]) {
          loader[action.tabLoader.mainKey] = {
            ...loader[action.tabLoader.mainKey],
            [action.tabLoader.subKey]: action.tabLoader.loading,
          };
        } else {
          loader[action.tabLoader.mainKey] = {
            [action.tabLoader.subKey]: action.tabLoader.loading,
          };
        }
      } else if (
        action.tabLoader &&
        action.tabLoader.mainKey &&
        action.tabLoader.loading
      ) {
        loader[action.tabLoader.mainKey] = action.tabLoader.loading;
      }

      return {
        ...state,
        tabLoader: loader,
      };
    case SET_UPDATED_VALUES:
      return {
        ...state,
        updatedValues: action.updatedValues
          ? action.updatedValues
          : state.updatedValues,
      };
    case SET_SKILLS:
      return {
        ...state,
        skills: action.skills ? action.skills : state.skills,
      };
    case SET_TEAMS_AND_ROLES:
      return {
        ...state,
        teams_and_roles: action.teams_and_roles ? action.teams_and_roles : state.teams_and_roles,
      };
    case SET_COURSES_QUALIFICATIONS:
      return {
        ...state,
        courses_qualifications: action.courses_qualifications ? action.courses_qualifications : state.courses_qualifications,
      };
    case SET_PRODUCT_GROUPS:
      return {
        ...state,
        product_groups: action.product_groups ? action.product_groups : state.product_groups,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings ? action.settings : state.settings,
      };
    case SET_MICROSITES:
      return {
        ...state,
        microSites: action.microSites ? action.microSites : state.microSites,
      };
    case SET_MICROSITE_ACTIONS:
      var prev = state.microSiteActions;

      const activeKey = action.microSiteActions.activeKey;
      const change = action.microSiteActions.change;
      const menuKey = action.microSiteActions.menuKey;

      if (prev[activeKey]) {
        var preMenuItems = prev[activeKey];

        preMenuItems[menuKey] = change;

        prev[activeKey] = preMenuItems;
      } else {
        prev[activeKey] = {
          [menuKey]: change,
        };
      }

      return {
        ...state,
        microSiteActions: { ...prev },
      };

    default:
      break;
  }
};
