import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import Line from "../../../line";
import ImageLabel from "../../../image-label";
import ImageSlider from "../../../slider/ImageSlider";

export default function CarouselComponent({ dataLoader, form, _, loader: formDataLoader }) {
  const params = useParams();
  const { state } = useLocation();
  const tabKey = state;
  const { tabData } = useStore();
  const whichTab = TAB_KEYS.corousel;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage } = useConnectsuForm();
  const [formChanged, setFormChanged] = useState(false);
  const [loader, setLoader] = useState(formDataLoader || true);

  const navigate = useNavigate();

  const { updatedValues } = useStore();

  useEffect(() => {
    localStorage.setItem("tab", "CAROUSEL");
  }, []);

  useEffect(() => {
    form.resetFields();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      tabData.corousel ? <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={15} style={{ paddingRight: "25px" }}>
                <Form.List
                  name={whichTab}
                  initialValue={
                    tabData &&
                      tabData[whichTab] &&
                      tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <Space
                          key={key}
                          style={{
                            display: "block",
                            marginBottom: 8,
                            paddingRight: '40px'
                          }}
                          align="baseline"
                          className="card-item"
                        >
                          {fields.length > 1 &&
                            i === 0 && <div style={{ textAlign: 'center', paddingBottom: '10px' }} >
                              <strong>Microsite carousel main card</strong>
                            </div>}

                          <div className="d-flex">
                            {/* <Tooltip placement="right" title="This image/video will be visible in the home screen card" color="red" key="red"> */}
                            <Form.Item
                              {...restField}
                              className="w-100"
                              label="Image/Video Title"
                              name={[name, "title"]}
                              labelCol={{ span: 5 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Image/Video Title is required",
                                },
                                {
                                  max: 25 - 1,
                                  message: `Title should be less than ${25} character`,
                                }
                              ]}
                            >
                              <Input
                                placeholder="Image/Video Title"
                                maxLength={25}
                                showCount
                              />
                            </Form.Item>
                            {/* </Tooltip> */}
                            <Form.Item>
                              {fields.length > 1 &&
                                i !== 0 &&
                                findValue(i, "id") && (
                                  <div className="remove-btn">

                                    <DeleteOutlined
                                      onClick={() =>
                                        deleteSingleItem(
                                          whichTab,
                                          form.getFieldValue([
                                            whichTab,
                                            i,
                                            "id",
                                          ]),
                                          form
                                        )
                                      }
                                    />
                                  </div>
                                )}
                            </Form.Item>
                            <Form.Item>
                              {fields.length > 1 &&
                                i === 0 &&
                                findValue(i, "id") && (
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "First slide users see. Showcase your most important media here."
                                      }
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                )}
                            </Form.Item>
                          </div>
                          <div className="d-flex">
                            <Form.Item
                              {...restField}
                              className="w-100"
                              name={[name, "mediaLink", "mediaType"]}
                              label="Video or Image"
                              labelCol={{ span: 5 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Select any option",
                                },
                              ]}
                            >
                              <Select
                                name={[name, "mediaLink", "mediaType"]}
                                onChange={(e) => SelectChangeHandler(e, i)}
                                placeholder="Select Option"
                              >
                                <Select.Option value="VIMEO_MEDIA">
                                  Add Vimeo Link
                                </Select.Option>
                                <Select.Option value="SOCIAL_MEDIA">
                                  Add Youtube Link
                                </Select.Option>
                                <Select.Option value="IMAGE">
                                  Upload Image
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item>
                              <div className="remove-btn">
                                <Tooltip
                                  title={
                                    "Upload Video link or image with size lesser than 2.5MB"
                                  }
                                >
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </div>
                            </Form.Item>
                          </div>
                          {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                            <Form.Item
                              label="Youtube Link"
                              {...restField}
                              labelCol={{ span: 5 }}
                              name={[name, "mediaLink", "url"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Youtube link is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                            <Form.Item
                              label="Vimeo Link"
                              labelCol={{ span: 5 }}
                              {...restField}
                              name={[name, "mediaLink", "url"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vimeo link is required",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          ) : findValue(i, "type") === "IMAGE" ? (
                            <Form.Item
                              {...restField}
                              label={<ImageLabel label={"Image"} />}
                              colon={false}
                              name="media"
                              className="experienceImg"
                              labelCol={{ span: 5 }}
                              rules={[
                                {
                                  validator: (_, value) => {
                                    return findValue(i, "blob")
                                      ? Promise.resolve()
                                      : findValue(i, "url")
                                        ? Promise.resolve()
                                        : Promise.reject(
                                          new Error("Image is required")
                                        );
                                  },
                                },
                              ]}
                            >
                              {findValue(i, "blob") ? (
                                <Avatar
                                  src={URL.createObjectURL(
                                    findValue(i, "blob")
                                  )}
                                  alt=""
                                />
                              ) : findValue(i, "url") ? (
                                <Avatar src={findValue(i, "url")} alt="" />
                              ) : (
                                <Avatar src={UserIcon} />
                              )}

                              <label
                                name="file"
                                className="fileUploadBtn greenBtn"
                              >
                                {" "}
                                Choose File
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  // required={true}
                                  onChange={(e) =>
                                    updateImage(e, i, form, whichTab)
                                  }
                                />
                              </label>
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                          <Form.Item
                            className="w-100"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {formChanged ? (
                              <Button
                                className="saveBtn greenBtn"
                                onClick={() => {
                                  form.submit().then(() => {
                                    setFormChanged(false);
                                  }).catch((errorInfo) => {
                                    console.error('Form submission failed:', errorInfo);
                                  });
                                }}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                className="saveBtn greenBtn"
                                style={{
                                  opacity: "0.5",
                                  pointerEvents: "none",
                                }}
                              >
                                Save
                              </Button>
                            )}
                          </Form.Item>
                        </Space>
                      ))}

                      <div className="inputBody">
                        <label></label>
                        <div style={{ textAlign: "right" }}>
                          {fields && fields.length <= 1 ? (
                            <></>
                          ) : (
                            <Button
                              className="cancelBtn"
                              type="button"
                              onClick={() => {
                                const itemsToRemove = [];
                                fields.forEach((f, i) => {
                                  if (!findValue(i, "id") && i !== 0) {
                                    itemsToRemove.push(f.name);
                                  }
                                });
                                if (itemsToRemove.length > 0) {
                                  remove(itemsToRemove);
                                }
                              }}
                            >
                              Clear
                            </Button>
                          )}
                          <Button className="greenBtn" onClick={() => add()}>
                            Add More
                            <PlusOutlined />
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Form.List>
              </Col>

              <Col className="gutter-row corousel-col" span={6}>
                {/* <CarouselContainer
                  data={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                /> */}
                <ImageSlider
                  data={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                />
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </div> : ''
    );
  }
}
