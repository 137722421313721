import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import jwt_decode from "jwt-decode";
import userServiceApi from "./userServiceApi";

const getCurrentTime = async () => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    const jwtExpTime = decoded?.exp;
    if (jwtExpTime) {
      const currentTime = moment().add(5, "minutes").unix();
      if (currentTime <= jwtExpTime) {
        resolve(true);
      } else {
        resolve(false);
      }
    } else {
      reject(false);
    }
  });
};

const getToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refresh");
    if (refreshToken) {
      const response = await fetch(userServiceApi.refreshToken(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
      });
      const dataR = await response.json();
      if (dataR) {
        localStorage.setItem("token", dataR.accessToken);
        return dataR.accessToken;
      }
    } else {
      console.log("refresh token not recived");
    }
  } catch (error) {
    console.error(error);
  }
};

axios.interceptors.request.use(
  async (req) => {
    if (localStorage.getItem("loginPage") === "true") {
      return req;
    } else {
      const tokenExist = await getCurrentTime();
      if (tokenExist) {
        const token = localStorage.getItem("token");
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
      } else {
        const token = await getToken();
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // debugger;
    if (error.response.status === 401) {
      const partnerID = localStorage.getItem("partnerID");
      window.location.href = `/login/${partnerID}`;
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("OrgEmail");
      localStorage.removeItem("OrgId");
      localStorage.removeItem("OrgImage");
      localStorage.removeItem("OrgName");
      localStorage.removeItem("page");
      localStorage.removeItem("tab");
      // window.location.reload();
    }
    if (error.response.status === 400) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
    }
    return error;
  }
);

export const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRequest = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const putRequest = async (url, data) => {
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export const patchRequest = async (url, data) => {
  try {
    const response = await axios.patch(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const response = await axios.delete(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const API_URL = process.env.REACT_APP_API_BASE_URL;
