import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoutes from "./routes/protected-routes";
import PublicRoutes from "./routes/public-route";
import { protectedRoutes, publicRoutes } from "./routes/routes";
import NotFound from "./components/not-found";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.scss";
import "antd/dist/antd.min.css";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/" element={<ProtectedRoutes />}>
          {protectedRoutes.map((route) => {
            return <><Route path={route.path} element={route.component} /></>
          })}
        </Route>

        {publicRoutes.map((route) => {
          return (
            <Route path={route.path} element={<PublicRoutes />}>
              <Route path={`/${route.path}`} element={route.component} />
            </Route>
          );
        })}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <ToastContainer /> */}
    </Router>
  );
}

export default App;
