import React, { useState, useEffect, useMemo } from "react";
import { Tabs, Modal, Spin, Switch, Button, Collapse, Row } from "antd";
import { getRequest } from "../../../../api/http";
import userServiceApi from "../../../../api/userServiceApi";
import { toast } from "react-toastify";
import "../index.scss";
import ReactPlayer from "react-player/youtube";
import ytPlayBtn from "../../../../assets/images/YouTube_play_button.svg";
import { TAB_KEYS } from "../../../../config";
import { useMicrosite } from "../../../../custom-hooks/useMicrosite";
import { useStore } from "../../../../store";
import leftArrow from "../../../../assets/images/Path3954.png";
import rightArrow from "../../../../assets/images/Path3900.png";
import VimeoPlayer from "react-player";
import Slider from "react-slick";
import "../../../microsite-accordian/style.scss";
import { motion } from "framer-motion";
import Reviews from "../tabs/contact-us/sub-tabs/reviews";
import watsapp from "../../../../assets/images/watsapp.svg";
import phoneIc from "../../../../assets/images/phone.svg";
import Website from "../../../../assets/images/website.svg";
import mail from "../../../../assets/images/mail.svg";
import twitter from "../../../../assets/images/twitter.svg";
import linkedin from "../../../../assets/images/linkedIn.svg";
import youtube from "../../../../assets/images/youtube.svg";
import tiktok from "../../../../assets/images/tiktok.svg";
import instagram from "../../../../assets/images/instagram.svg";
import facebook from "../../../../assets/images/facebook-icon.svg";

const tabList = [
  {
    tabName: "About Us",
    tabKey: TAB_KEYS.aboutUs,
    settingsKey: TAB_KEYS.aboutUs,
  },
  {
    tabName: "Products",
    tabKey: TAB_KEYS.product,
    settingsKey: "products",
  },
  {
    tabName: "Services",
    tabKey: TAB_KEYS.service,
    settingsKey: "services",
  },
  {
    tabName: "Contracts",
    tabKey: TAB_KEYS.contract,
    settingsKey: "contracts",
  },
  {
    tabName: "Contact Us",
    tabKey: TAB_KEYS.contactUs,
    settingsKey: TAB_KEYS.contactUs,
  },
  {
    tabName: "Apprenticeships",
    tabKey: "apprenticeship",
    settingsKey: "apprenticeships",
  },
  {
    tabName: "Vacancies",
    tabKey: "vacancy",
    settingsKey: "vacancies",
  },
  {
    tabName: "Courses",
    tabKey: "courses",
    settingsKey: "courses",
  },
  {
    tabName: "Why work for us",
    tabKey: "why-work",
    settingsKey: "whyWork",
  },
  {
    tabName: "Experiences of work",
    tabKey: TAB_KEYS.workExperience,
    settingsKey: "experienceOfWork",
  },
  {
    tabName: "Our Teams and Roles",
    tabKey: "teams-and-roles",
    settingsKey: "teams-and-roles",
  },
];

const CompLoader = () => {
  return (
    <div className="data-loader-alt">
      <Spin tip="Loading" size="small" />
    </div>
  );
};

const ContactUSComp = ({ data }) => {
  return (
    <div className="preview-congtact-item">
      <div className="preview--contact-item-container">
        <div className="gutter-row tabContent" span={15}>
          <img src={phoneIc} alt="Image" style={{ marginRight: "3px" }} />
          Phone
        </div>
        <div className="gutter-row tabContent-body" span={15}>
          {data.phone}
        </div>
      </div>
      <div className="preview--contact-item-container">
        <div className="gutter-row tabContent" span={15}>
          <img src={mail} alt="Image" style={{ marginRight: "3px" }} />
          Email
        </div>
        <div className="gutter-row tabContent-body" span={15}>
          {data.email}
        </div>
      </div>
      <div className="preview--contact-item-container">
        <div className="gutter-row tabContent" span={15}>
          <img src={watsapp} alt="Image" style={{ marginRight: "3px" }} />
          Whatsapp
        </div>
        <div className="gutter-row tabContent-body" span={15}>
          {data.chatNumber}
        </div>
      </div>
      <div className="preview--contact-item-container">
        <div className="gutter-row tabContent" span={15}>
          <img src={Website} alt="Image" style={{ marginRight: "3px" }} />
          Website
        </div>
        <div className="gutter-row tabContent-body" span={15}>
          <a href={data.websiteLink}> {data.websiteLink} </a>
        </div>
      </div>
    </div>
  );
};

const ContactUSSocialMedia = ({ data }) => {
  return (
    <div className="preview-congtact-item">
      {data.linkedInLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={linkedin} alt="Image" style={{ marginRight: "3px" }} />
            Linkedin
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.linkedInLink} target="_blank">
              {data.linkedInLink}
            </a>
          </div>
        </div>
      ) : null}
      {data.twitterLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={twitter} alt="Image" style={{ marginRight: "3px" }} />X
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.twitterLink} target="_blank">
              {data.twitterLink}
            </a>
          </div>
        </div>
      ) : null}
      {data.youtubeLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={youtube} alt="Image" style={{ marginRight: "3px" }} />
            Youtube
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.youtubeLink} target="_blank">
              {data.youtubeLink}
            </a>
          </div>
        </div>
      ) : null}
      {data.instagramLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={instagram} alt="Image" style={{ marginRight: "3px" }} />
            Instagram
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.instagramLink} target="_blank">
              {data.instagramLink}
            </a>
          </div>
        </div>
      ) : null}
      {data.tiktokLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={tiktok} alt="Image" style={{ marginRight: "3px" }} />
            Tik-Tok
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.tiktokLink} target="_blank">
              {data.tiktokLink}
            </a>
          </div>
        </div>
      ) : null}
      {data.facebookLink ? (
        <div className="preview--contact-item-container">
          <div className="gutter-row tabContent tab-heading-preview">
            <img src={facebook} alt="Image" style={{ marginRight: "3px" }} />
            Facebook
          </div>
          <div className="gutter-row tabContent-body">
            <a href={data.facebookLink} target="_blank">
              {data.facebookLink}
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function Preview({ closeSettings, siteSetting, micrositeId, data }) {
  // const switchState = localStorage.getItem("switch")
  const { publishMicroSite, microSitePublishLoading } = useMicrosite();

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setselectOption] = useState({});
  const [activeKey, setActiveKey] = useState("about-us");
  const [contactUsKey, setContactUsKey] = useState("REVIEWS");
  const [responseData, setReponseData] = useState([]);
  const [contactUsinnerTab, setcontactUsinnerTab] = useState("");
  const [isVideoPlaying, setVideoCompleted] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");

  const [switchState, setSwitchState] = useState(data.published);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [unPublishConfirmation, setUnPublishConfirmation] = useState(false);

  const [carouselData, setCData] = useState([]);
  const [cLoader, setCLoader] = useState(false);
  const [position, setPosition] = useState(["left", "right"]);
  const color = [
    "#E75E0D",
    "#EB9F11",
    "#ABC710",
    "#009340",
    "#CC0370",
    "#8366A9",
    "#149BD7",
  ];

  const { microSites } = useStore();

  const operationsSlot = {
    left: (
      <div onClick={() => onClickArrow("left")}>
        <img src={leftArrow} alt="main logo" width="auto" />
        <img src={leftArrow} alt="main logo" width="auto" />
      </div>
    ),
    right: (
      <div onClick={() => onClickArrow("right")}>
        <img src={rightArrow} alt="main logo" width="auto" />
        <img src={rightArrow} alt="main logo" width="auto" />
      </div>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: operationsSlot[direction],
      }),
      {}
    );
  }, [position, activeKey]);

  const onClickArrow = (direction) => {
    const modifiedTabLists = tabList.filter(
      (d) =>
        !(d.settingsKey in siteSetting) ||
        (d.settingsKey in siteSetting && siteSetting[d.settingsKey] !== false)
    );

    const getIndex = modifiedTabLists.findIndex((d) => d.tabKey == activeKey);

    if (getIndex !== -1) {
      if (direction === "right" && modifiedTabLists[getIndex + 1].tabKey) {
        setActiveKey(modifiedTabLists[getIndex + 1].tabKey);
      } else if (
        direction === "left" &&
        modifiedTabLists[getIndex - 1].tabKey
      ) {
        setActiveKey(modifiedTabLists[getIndex - 1].tabKey);
      }
    }
  };

  const getCoruselData = async (activeKey, tabKey) => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      let response = [];
      if (activeKey == "contact-us") {
        response = await getRequest(
          userServiceApi.getContactUsDetails(contactUsKey, micrositeId),
          { headers }
        );
      }
      if (
        activeKey == "why-work" ||
        activeKey == "product" ||
        activeKey == "service" ||
        activeKey == "about-us" ||
        activeKey == "courses" ||
        activeKey == "teams-and-roles"
      ) {
        response = await getRequest(
          userServiceApi.getMicrositeDetailsTabs(activeKey, micrositeId),
          { headers }
        );
      }
      if (activeKey == "apprenticeship") {
        response = await getRequest(
          userServiceApi.getMicrositeDetailsVacancyTab(
            "PART_TIME",
            micrositeId
          ),
          { headers }
        );
      }
      if (activeKey == "vacancy") {
        response = await getRequest(
          userServiceApi.getMicrositeDetailsVacancyTab(
            "FULL_TIME",
            micrositeId
          ),
          { headers }
        );
      }
      if (activeKey == "work-experience") {
        response = await getRequest(
          userServiceApi.getPreviewExperienceofWorkTab(micrositeId),
          { headers }
        );
      }
      if (activeKey == "contract") {
        response = await getRequest(
          userServiceApi.getMicrositeDetailsContractTab(micrositeId),
          { headers }
        );
      }
      // setVacanciesData(vacancyresponse.data);

      setReponseData(response.data);

      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const transformedData = responseData?.reduce((acc, item) => {
    // console.log("acc, item", acc, item)
    const teamEntry = acc.find((entry) => entry.assetTitle === item?.assetTitle);
    const itemCopy = {
      id: item?.id,
      title: item?.title,
      subTitle: item?.subTitle,
      available: item?.available,
      description: item?.description,
      type: item?.type,
      mediaLink: {
        id: item?.mediaLink?.id,
        mediaType: item?.mediaLink?.mediaType,
        url: item?.mediaLink?.url,
      },
    };

    if (teamEntry) {
      teamEntry.subTitles.push(itemCopy);
    } else {
      acc.push({
        assetTitle: item?.assetTitle,
        subTitles: [itemCopy],
      });
    }

    return acc;
  }, []);
  useEffect(() => {
    getCoruselData(activeKey);
  }, [activeKey, contactUsKey]);

  useEffect(() => {
    callCData();
  }, []);

  const callCData = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    try {
      setCLoader(true);
      const getCarouselData = await getRequest(
        userServiceApi.getMicrositeDetailsTabs("corousel", micrositeId),
        { headers }
      );

      if (getCarouselData && getCarouselData.data) {
        setCData(getCarouselData.data);
        setCLoader(false);
      } else {
        setCLoader(false);
      }
    } catch (error) {
      setCLoader(false);
    }
  };

  const handleConfirmationCancel = () => {
    // setSwitchState(data.published);
    setSwitchState(microSites[0]?.isPublished);
    setPublishConfirmation(false);
    setUnPublishConfirmation(false);
  };

  const handleConfirmationOk = () => {
    publishHandler(true);
  };

  const unPublishOk = () => {
    publishHandler(false);
  };

  const publishHandler = (value) => {
    if (value) {
      let isPublished = publishMicroSite(data.id, "published");
      setPublishConfirmation(false);
      console.log("isPublished", isPublished ? "true" : "false")
      if (localStorage.getItem("switchSuccess")) {
        console.log("local", localStorage.getItem("switchSuccess"), typeof (localStorage.getItem("switchSuccess")))
        if (localStorage.getItem("switchSuccess") === "true") {
          setSwitchState(value);
        } else {
          setSwitchState(!value);
        }
      }
      setTimeout(() => {
        localStorage.removeItem("switchSuccess");
      }, 2000);
      // setSwitchState(true);
    } else {
      let isUnpublished = publishMicroSite(data.id, "unpublished");
      console.log("isPublished", isUnpublished ? "true" : "false")
      setUnPublishConfirmation(false);
      if (localStorage.getItem("switchSuccess")) {
        console.log("local", localStorage.getItem("switchSuccess"), typeof (localStorage.getItem("switchSuccess")))
        if (localStorage.getItem("switchSuccess") === "false") {
          setSwitchState(false);
        } else {
          setSwitchState(true);
        }
      }
      setTimeout(() => {
        localStorage.removeItem("switchSuccess");
      }, 2000);
      // setSwitchState(false);
    }
  };

  const changeTab = (tabValue) => {
    setActiveKey(tabValue);
  };

  const changeContactUsTab = (tabValue) => {
    setContactUsKey(tabValue);
  };

  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const handleAfterChange = (index) => {
    // Update the current slide index when the slider changes
    setCurrentSlideIndex(index);
  };

  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    // variableWidth: true,
    adaptiveHeight: false,
    afterChange: handleAfterChange,
  };
  return (
    <>
      <Modal
        open={true}
        footer={null}
        onCancel={closeSettings}
        width="420px"
        className="preview-modal-root"
      >
        <div className="previewContainer">
          <h3>Preview</h3>
          <div className="previewBody">
            <div className="carousel-main">
              <div className="carousel-main-container">
                <div className="corousel-container">
                  <div className="carousel-items">
                    {/* <Slider {...settings}>
                    {cLoader ? (
                      <CompLoader />
                    ) : (
                      carouselData &&
                      carouselData.map((data) => {
                        // if (cLoader) {
                        //     return <CompLoader />
                        // }
                        return data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                          <div className="slide">
                            <ReactPlayer
                              controls={true}
                              url={data.mediaLink?.url}
                              // onEnded={() => setVideoCompleted(false)}
                              // style={{ width: 10, height: 10 }}
                              // height="100%"
                              width={"100%"}
                            // onPlay={() => setVideoCompleted(true)}
                            // onStart={() => setVideoCompleted(true)}
                            // onPause={() => setVideoCompleted(false)}
                            ></ReactPlayer>
                          </div>
                        ) : data.mediaLink?.mediaType == "VIMEO_MEDIA" ? (
                          <div className="slide">
                            <iframe
                              controls={true}
                              // video={VimeoLinkPlayHandler(data.mediaLink.url)}
                              src={data.mediaLink.url}
                              width="100%"
                              style={{ backgroundColor: "black" }}
                            // responsive={true}
                            // onEnd={() => setVideoCompleted(false)}
                            // onProgress={() => setVideoCompleted(true)}
                            // onPlaying={() => setVideoCompleted(true)}
                            // onPlay={() => setVideoCompleted(true)}
                            // onPause={() => setVideoCompleted(false)}
                            />
                          </div>
                        ) : (
                          <div className="slide">
                            <div className="img-slide">
                              <img
                                src={data.mediaLink?.url}
                                className="carousel-img"
                              />
                            </div>
                          </div>
                        );
                      })
                    )}
                  </Slider> */}
                    <Slider {...settings}>
                      {carouselData &&
                        carouselData?.map((data, i) => {
                          if (i < 4) {
                            if (
                              data?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                              data.mediaLink.url
                            ) {
                              return (
                                <div>
                                  <div className="slide">
                                    <ReactPlayer
                                      controls={true}
                                      url={data.mediaLink.url}
                                      // onEnded={() => setVideoCompleted(true)}
                                      // style={{ width: 10, height: "50%" }}
                                      height="150px"
                                      width="100%"
                                    // onEnded={() => setVideoCompleted(false)}
                                    // onPlay={() => setVideoCompleted(true)}
                                    // onStart={() => setVideoCompleted(true)}
                                    // onPause={() => setVideoCompleted(false)}
                                    ></ReactPlayer>
                                    {i === currentSlideIndex ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <label
                                          className="slider-label"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {data.title}
                                        </label>
                                      </div>
                                    ) : null}
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    ></div>
                                  </div>
                                  <div></div>
                                </div>
                              );
                            } else if (
                              data?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                              data.mediaLink.url
                            ) {
                              return (
                                <div className="slide video">
                                  <iframe
                                    controls={true}
                                    // video={VimeoLinkPlayHandler(data.mediaLink.url)}
                                    src={data.mediaLink.url}
                                    // responsive={true}
                                    // onEnd={() => setVideoCompleted(false)}
                                    // onProgress={() => setVideoCompleted(true)}
                                    // onPlaying={() => setVideoCompleted(true)}
                                    // onPlay={() => setVideoCompleted(true)}
                                    // onPause={() => setVideoCompleted(false)}
                                    // responsive={true}
                                    height="150px"
                                    width="100%"
                                    style={{ backgroundColor: "black" }}
                                  />
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {data.title}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="slide">
                                  <div className="img-slide">
                                    <img
                                      style={{
                                        height: "150px",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      src={data?.mediaLink?.url}
                                      alt=""
                                      className="c1 carousel-image"
                                    />
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label
                                        style={{
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {data.title}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        })}
                    </Slider>
                  </div>
                </div>
              </div>{" "}
            </div>

            <Tabs
              className="tabBodyContainer"
              onChange={changeTab}
              activeKey={activeKey}
              tabBarExtraContent={slot}
              type="card"
            >
              <Tabs.TabPane tab="About Us" key="about-us">
                <div className="rowBody preview-tab-container">
                  {loading ? (
                    <CompLoader />
                  ) : activeKey === "about-us" &&
                    responseData &&
                    responseData.length > 0 ? (
                    responseData.map((data, i) => {
                      return (
                        <div
                          className="preview-item-container"
                          style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                        >
                          <div className="gutter-row tabContent">
                            {data.title === "WhyDo"
                              ? "Why do we do what we do ?"
                              : data.title === "whatDo"
                                ? "What do we do ?"
                                : data.title === "howDo"
                                  ? "How do we do what we do ?"
                                  : data.title}
                          </div>
                          <div className="gutter-row">
                            {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                              <div
                                className="sliderImg videoOverlay"
                                onClick={PlayVideoHandler.bind(
                                  this,
                                  data.mediaLink?.url,
                                  data.mediaLink?.mediaType
                                )}
                              >
                                {(() => {
                                  const standardURL = convertToStandardURL(
                                    data.mediaLink?.url
                                  );
                                  const videoID = new URLSearchParams(
                                    new URL(standardURL).search
                                  ).get("v");
                                  return (
                                    <>
                                      <img
                                        src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                        style={{ objectFit: "cover" }}
                                        alt=""
                                        className="video-preview-image"
                                      />
                                      <img
                                        className="ytBtnOverlay"
                                        src={ytPlayBtn}
                                        onClick={() =>
                                          PlayVideoHandler(
                                            data.mediaLink?.url,
                                            data.mediaLink?.mediaType
                                          )
                                        }
                                      />
                                    </>
                                  );
                                })()}
                              </div>
                            ) : data?.mediaLink?.mediaType == "VIMEO_MEDIA" ? (
                              <div
                                className="sliderImg videoOverlay"
                                onClick={PlayVimeoVideoHandler.bind(
                                  this,
                                  data.mediaLink?.url,
                                  data.mediaLink?.mediaType
                                )}
                              >
                                <img
                                  // src={VideoImg}
                                  src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                    }.jpg`}
                                  alt=""
                                  className="video-preview-image"
                                />
                                <img
                                  className="ytBtnOverlay"
                                  src={ytPlayBtn}
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                />
                              </div>
                            ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                              <div className="sliderImg-alt">
                                <img
                                  // style={{ width: '50px', height: '30px' }}
                                  src={data.mediaLink?.url}
                                  className="preview-img"
                                />
                              </div>
                            ) : (
                              <div className="sliderImg-alt">
                                <div className="preview-img"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="background-container">
                      <span>Nothing here at the moment</span>
                    </div>
                  )}
                </div>{" "}
              </Tabs.TabPane>
              {siteSetting && siteSetting.products ? (
                <Tabs.TabPane tab="Products" key="product">
                  <div className="rowBody preview-container preview-tab-container">
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "product" &&
                      responseData &&
                      responseData.length > 0 ? (
                      <Collapse accordion>
                        {transformedData.map((team, index) => (
                          <Collapse.Panel
                            className="collapsePanelWidth"
                            key={`${team.id}-${team.assetTitle}`}
                            header={team.assetTitle}
                            style={{
                              width: "360px",
                              backgroundColor: "white",
                              marginBottom: "10px",
                              minHeight: "16%",
                              borderRadius: 10,
                              boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
                              fontSize: "1.25em",
                              fontWeight: "500",
                              paddingTop: 8,
                              paddingBottom: 8,
                              marginLeft: "15px",
                              borderLeft: `5px solid ${color[index % 7]}`,
                            }}
                          >
                            {team.subTitles.map((data, i) => (
                              <motion.div
                                key={i}
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                  duration: 0.8,
                                  delay: 0.5,
                                  ease: [0, 0.71, 0.2, 1.01],
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    borderLeft: "5px solid #ccc",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 24,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {data.subTitle}
                                  </div>
                                  <div className="gutter-row" span={9}>
                                    {data.mediaLink?.mediaType ==
                                      "SOCIAL_MEDIA" ? (
                                      <div
                                        className="sliderImg videoOverlay"
                                        onClick={PlayVideoHandler.bind(
                                          this,
                                          data.mediaLink?.url,
                                          data.mediaLink?.mediaType
                                        )}
                                      >
                                        {(() => {
                                          const standardURL =
                                            convertToStandardURL(
                                              data.mediaLink?.url
                                            );
                                          const videoID = new URLSearchParams(
                                            new URL(standardURL).search
                                          ).get("v");
                                          return (
                                            <>
                                              <img
                                                src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                                style={{
                                                  objectFit: "cover",
                                                }}
                                                alt=""
                                                className="video-preview-image"
                                              />
                                              <img
                                                className="ytBtnOverlay"
                                                src={ytPlayBtn}
                                                onClick={() =>
                                                  PlayVideoHandler(
                                                    data.mediaLink?.url,
                                                    data.mediaLink?.mediaType
                                                  )
                                                }
                                              />
                                            </>
                                          );
                                        })()}
                                      </div>
                                    ) : data?.mediaLink?.mediaType ==
                                      "VIMEO_MEDIA" ? (
                                      <div
                                        className="sliderImg videoOverlay"
                                        onClick={PlayVimeoVideoHandler.bind(
                                          this,
                                          data.mediaLink?.url,
                                          data.mediaLink?.mediaType
                                        )}
                                      >
                                        <img
                                          // src={VideoImg}
                                          src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                            }.jpg`}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={PlayVideoHandler.bind(
                                            this,
                                            data.mediaLink?.url,
                                            data.mediaLink?.mediaType
                                          )}
                                        />
                                      </div>
                                    ) : data?.mediaLink?.mediaType ==
                                      "IMAGE" ? (
                                      <div className="sliderImg-alt">
                                        <img
                                          src={data.mediaLink?.url}
                                          className="preview-img"
                                        />
                                      </div>
                                    ) : (
                                      <div className="sliderImg-alt">
                                        <div className="preview-img"></div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {i !== team.subTitles.length - 1 && (
                                  <hr
                                    style={{
                                      border: "1px solid lightgray",
                                    }}
                                  />
                                )}
                              </motion.div>
                            ))}
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.services ? (
                <Tabs.TabPane tab="Services" key="service">
                  <div className="rowBody preview-tab-container">
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "service" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent" span={15}>
                              {data.title}
                            </div>
                            <div className="gutter-row" span={9}>
                              {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.mediaLink?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data?.mediaLink?.mediaType ==
                                "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.mediaLink?.url,
                                      data.mediaLink?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={data.mediaLink?.url}
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div className="preview-img"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.contracts ? (
                <Tabs.TabPane tab="Contracts" key="contract">
                  <div className="rowBody preview-tab-container">
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "contract" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent" span={15}>
                              {data.procurementReference}
                            </div>
                            <div className="gutter-row" span={9}>
                              {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.mediaLink?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data?.mediaLink?.mediaType ==
                                "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.mediaLink?.url,
                                      data.mediaLink?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={data.mediaLink?.url}
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div className="preview-img"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              <Tabs.TabPane
                className="contactUsContent"
                tab="Contact Us"
                key="contact-us"
              >
                <Tabs
                  onChange={changeContactUsTab}
                  defaultActiveKey={contactUsKey}
                >

                  <Tabs.TabPane tab="Reviews" key="REVIEWS">
                    <div className="rowBody preview-tab-container">
                      <Reviews val={false} />;
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Sales" key="SALES">
                    <div className="rowBody preview-tab-container">
                      {loading ? (
                        <CompLoader />
                      ) : activeKey === "contact-us" &&
                        contactUsKey === "SALES" &&
                        responseData &&
                        responseData.length > 0 ? (
                        responseData.map((data) => {
                          return <ContactUSComp data={data} />;
                        })
                      ) : (
                        <div className="background-container">
                          <span>Nothing here at the moment</span>
                        </div>
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Human Resources" key="HUMAN_RESOURCE">
                    <div className="rowBody preview-tab-container">
                      {loading ? (
                        <CompLoader />
                      ) : activeKey === "contact-us" &&
                        contactUsKey === "HUMAN_RESOURCE" &&
                        responseData &&
                        responseData.length > 0 ? (
                        responseData.map((data) => {
                          return <ContactUSComp data={data} />;
                        })
                      ) : (
                        <div className="background-container">
                          <span>Nothing here at the moment</span>
                        </div>
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="Experiences of work"
                    key="EXPERIENCES_OF_WORK"
                  >
                    <div className="rowBody preview-tab-container">
                      {loading ? (
                        <CompLoader />
                      ) : activeKey === "contact-us" &&
                        contactUsKey === "EXPERIENCES_OF_WORK" &&
                        responseData &&
                        responseData.length > 0 ? (
                        responseData.map((data) => {
                          return <ContactUSComp data={data} />;
                        })
                      ) : (
                        <div className="background-container">
                          <span>Nothing here at the moment</span>
                        </div>
                      )}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Social media" key="SOCIAL_MEDIA">
                    <div className="rowBody preview-tab-container">
                      {loading ? (
                        <CompLoader />
                      ) : activeKey === "contact-us" &&
                        contactUsKey === "SOCIAL_MEDIA" &&
                        responseData &&
                        responseData.length > 0 ? (
                        responseData.map((data) => {
                          return <ContactUSSocialMedia data={data} />;
                        })
                      ) : (
                        <div className="background-container">
                          <span>Nothing here at the moment</span>
                        </div>
                      )}
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              {siteSetting && siteSetting.apprenticeships ? (
                <Tabs.TabPane tab="Apprenticeships" key="apprenticeship">
                  <div
                    className="rowBody preview-tab-container"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "apprenticeship" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent" span={15}>
                              {data.title}
                            </div>
                            <div className="gutter-row" span={9}>
                              {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.mediaLink?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data?.mediaLink?.mediaType ==
                                "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.mediaLink?.url,
                                      data.mediaLink?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={data.mediaLink?.url}
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div className="preview-img"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.vacancies ? (
                <Tabs.TabPane tab="Vacancies" key="vacancy">
                  <div
                    className="rowBody preview-tab-container"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "vacancy" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent" span={15}>
                              {data.title}
                            </div>
                            <div className="gutter-row" span={9}>
                              {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.mediaLink?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data?.mediaLink?.mediaType ==
                                "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.mediaLink?.url,
                                      data.mediaLink?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={data.mediaLink?.url}
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div className="preview-img"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.courses ? (
                <Tabs.TabPane tab="Courses" key="courses">
                  <div className="background-container">
                    <div className="card-container">
                      <Row
                        className="rowBody collapse-container preview-tab-container"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        {activeKey === "courses" &&
                          responseData &&
                          responseData.length > 0 ? (
                          <Collapse
                            accordion
                            bordered={false}
                            defaultActiveKey={`${transformedData[0].id}`}
                          >
                            {transformedData.map((team, index) => (
                              <Collapse.Panel
                                className="collapsePanelWidth"
                                key={`${team.id}-${team.assetTitle}`}
                                header={team.assetTitle}
                                style={{
                                  width: "360px",
                                  backgroundColor: "white",
                                  marginBottom: "10px",
                                  minHeight: "16%",
                                  borderRadius: 10,
                                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
                                  fontSize: "1.25em",
                                  fontWeight: "500",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginLeft: "15px",
                                  borderLeft: `5px solid ${color[index % 7]}`,
                                }}
                              >
                                {team.subTitles.map((data, i) => (
                                  <motion.div
                                    key={i}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      duration: 0.8,
                                      delay: 0.5,
                                      ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        borderLeft: `5px solid #ccc`,
                                      }}
                                    >
                                      <div>{data.subTitle}</div>
                                      <div className="gutter-row" span={9}>
                                        {data.mediaLink?.mediaType ==
                                          "SOCIAL_MEDIA" ? (
                                          <div
                                            className="sliderImg videoOverlay"
                                            onClick={PlayVideoHandler.bind(
                                              this,
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )}
                                          >
                                            <img
                                              // src={VideoImg}
                                              src={
                                                data.mediaLink.url.includes("=")
                                                  ? `https://img.youtube.com/vi/${data.mediaLink?.url
                                                    ?.split("/")[3]
                                                    ?.split("&")[0]
                                                    ?.split("=")[1]
                                                  }/hqdefault.jpg`
                                                  : `https://img.youtube.com/vi/${data.mediaLink?.url?.split(
                                                    "/"
                                                  )[3]
                                                  }/hqdefault.jpg`
                                              }
                                              alt=""
                                              className="video-preview-image"
                                            />
                                            <img
                                              className="ytBtnOverlay"
                                              src={ytPlayBtn}
                                              onClick={PlayVideoHandler.bind(
                                                this,
                                                data.mediaLink?.url,
                                                data.mediaLink?.mediaType
                                              )}
                                            />
                                          </div>
                                        ) : data?.mediaLink?.mediaType ==
                                          "VIMEO_MEDIA" ? (
                                          <div
                                            className="sliderImg videoOverlay"
                                            onClick={PlayVimeoVideoHandler.bind(
                                              this,
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )}
                                          >
                                            <img
                                              // src={VideoImg}
                                              src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                                }.jpg`}
                                              alt=""
                                              className="video-preview-image"
                                            />
                                            <img
                                              className="ytBtnOverlay"
                                              src={ytPlayBtn}
                                              onClick={PlayVideoHandler.bind(
                                                this,
                                                data.mediaLink?.url,
                                                data.mediaLink?.mediaType
                                              )}
                                            />
                                          </div>
                                        ) : data?.mediaLink?.mediaType ==
                                          "IMAGE" ? (
                                          <div className="sliderImg-alt">
                                            <img
                                              src={data.mediaLink?.url}
                                              className="preview-img"
                                            />
                                          </div>
                                        ) : (
                                          <div className="sliderImg-alt">
                                            <div className="preview-img"></div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {i !== team.subTitles.length - 1 && (
                                      <hr
                                        style={{
                                          border: "1px solid lightgray",
                                        }}
                                      />
                                    )}
                                  </motion.div>
                                ))}
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        ) : (
                          <div className="background-container">
                            <span>Nothing here at the moment</span>
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.whyWork ? (
                <Tabs.TabPane tab="Why work for us" key="why-work">
                  <div className="rowBody preview-container preview-tab-container">
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "why-work" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent">
                              {data.title === "w1"
                                ? "Our Culture and Values"
                                : data.title === "w2"
                                  ? "Our Application Process"
                                  : data.title === "w3"
                                    ? "Our diversity and inclusion vision"
                                    : data.title}
                            </div>
                            <div className="gutter-row">
                              {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.mediaLink?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data?.mediaLink?.mediaType ==
                                "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.mediaLink?.url,
                                      data.mediaLink?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={data.mediaLink?.url}
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div className="preview-img"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.experienceOfWork ? (
                <Tabs.TabPane tab="Experiences of work" key="work-experience">
                  <div
                    className="rowBody preview-tab-container"
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  >
                    {loading ? (
                      <CompLoader />
                    ) : activeKey === "work-experience" &&
                      responseData &&
                      responseData.length > 0 ? (
                      responseData.map((data, i) => {
                        return (
                          <div
                            className="preview-item-container"
                            style={{ borderLeft: `5px solid ${color[i % 7]}` }}
                          >
                            <div className="gutter-row tabContent" span={15}>
                              {data.experienceOfWorkTitle}
                            </div>
                            <div className="gutter-row" span={9}>
                              {data.experienceOfWorkOpportunityOutput?.media
                                ?.mediaType == "SOCIAL_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVideoHandler.bind(
                                    this,
                                    data.experienceOfWorkOpportunityOutput
                                      ?.media?.url,
                                    data.experienceOfWorkOpportunityOutput
                                      ?.media?.mediaType
                                  )}
                                >
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      data.experienceOfWorkOpportunityOutput
                                        ?.media?.url
                                    );
                                    const videoID = new URLSearchParams(
                                      new URL(standardURL).search
                                    ).get("v");
                                    return (
                                      <>
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                          onClick={PlayVideoHandler.bind(
                                            this,
                                            data
                                              .experienceOfWorkOpportunityOutput
                                              ?.media?.url,
                                            data
                                              .experienceOfWorkOpportunityOutput
                                              ?.media?.mediaType
                                          )}
                                        />
                                      </>
                                    );
                                  })()}
                                </div>
                              ) : data.experienceOfWorkOpportunityOutput?.media
                                ?.mediaType == "VIMEO_MEDIA" ? (
                                <div
                                  className="sliderImg videoOverlay"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.experienceOfWorkOpportunityOutput
                                      ?.media?.url,
                                    data.experienceOfWorkOpportunityOutput
                                      ?.media?.mediaType
                                  )}
                                >
                                  <img
                                    // src={VideoImg}
                                    src={`https://vumbnail.com/${data.experienceOfWorkOpportunityOutput?.media?.url?.split(
                                      "/"
                                    )[4]
                                      }.jpg`}
                                    alt=""
                                    className="video-preview-image"
                                  />
                                  <img
                                    className="ytBtnOverlay"
                                    src={ytPlayBtn}
                                    onClick={PlayVideoHandler.bind(
                                      this,
                                      data.experienceOfWorkOpportunityOutput
                                        ?.media?.url,
                                      data.experienceOfWorkOpportunityOutput
                                        ?.media?.mediaType
                                    )}
                                  />
                                </div>
                              ) : data.experienceOfWorkOpportunityOutput?.media
                                ?.mediaType == "IMAGE" ? (
                                <div className="sliderImg-alt">
                                  <img
                                    src={
                                      data.experienceOfWorkOpportunityOutput
                                        ?.media?.url
                                    }
                                    className="preview-img"
                                  />
                                </div>
                              ) : (
                                <div className="sliderImg-alt">
                                  <div
                                    // style={{ border: "0px" }}
                                    className="preview-img"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="background-container">
                        <span>Nothing here at the moment</span>
                      </div>
                    )}
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {siteSetting && siteSetting.teamsAndRoles ? (
                <Tabs.TabPane tab="Teams and Roles" key="teams-and-roles">
                  <div style={{ width: "100%" }}>
                    <div className="card-container">
                      <Row
                        className="rowBody collapse-container preview-tab-container"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        {activeKey === "teams-and-roles" &&
                          responseData &&
                          responseData.length > 0 ? (
                          <Collapse accordion>
                            {transformedData.map((team, index) => (
                              <Collapse.Panel
                                className="collapsePanelWidth"
                                key={`${team.id}-${team.assetTitle}`}
                                header={team.assetTitle}
                                style={{
                                  width: "360px",
                                  backgroundColor: "white",
                                  marginBottom: "10px",
                                  minHeight: "16%",
                                  borderRadius: 10,
                                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
                                  fontSize: "1.25em",
                                  fontWeight: "500",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  marginLeft: "15px",
                                  borderLeft: `5px solid ${color[index % 7]}`,
                                }}
                              >
                                {team.subTitles.map((data, i) => (
                                  <motion.div
                                    key={i}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      duration: 0.8,
                                      delay: 0.5,
                                      ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        borderLeft: `5px solid #ccc`,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: 24,
                                          fontWeight: 400,
                                        }}
                                      >
                                        {data.subTitle}
                                      </div>
                                      <div className="gutter-row" span={9}>
                                        {data.mediaLink?.mediaType ==
                                          "SOCIAL_MEDIA" ? (
                                          <div
                                            className="sliderImg videoOverlay"
                                            onClick={PlayVideoHandler.bind(
                                              this,
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )}
                                          >
                                            {(() => {
                                              const standardURL =
                                                convertToStandardURL(
                                                  data.mediaLink?.url
                                                );
                                              const videoID =
                                                new URLSearchParams(
                                                  new URL(standardURL).search
                                                ).get("v");
                                              return (
                                                <>
                                                  <img
                                                    src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                                    style={{
                                                      objectFit: "cover",
                                                    }}
                                                    alt=""
                                                    className="video-preview-image"
                                                  />
                                                  <img
                                                    className="ytBtnOverlay"
                                                    src={ytPlayBtn}
                                                    onClick={() =>
                                                      PlayVideoHandler(
                                                        data.mediaLink?.url,
                                                        data.mediaLink
                                                          ?.mediaType
                                                      )
                                                    }
                                                  />
                                                </>
                                              );
                                            })()}
                                          </div>
                                        ) : data?.mediaLink?.mediaType ==
                                          "VIMEO_MEDIA" ? (
                                          <div
                                            className="sliderImg videoOverlay"
                                            onClick={PlayVimeoVideoHandler.bind(
                                              this,
                                              data.mediaLink?.url,
                                              data.mediaLink?.mediaType
                                            )}
                                          >
                                            <img
                                              // src={VideoImg}
                                              src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                                }.jpg`}
                                              alt=""
                                              className="video-preview-image"
                                            />
                                            <img
                                              className="ytBtnOverlay"
                                              src={ytPlayBtn}
                                              onClick={PlayVideoHandler.bind(
                                                this,
                                                data.mediaLink?.url,
                                                data.mediaLink?.mediaType
                                              )}
                                            />
                                          </div>
                                        ) : data?.mediaLink?.mediaType ==
                                          "IMAGE" ? (
                                          <div className="sliderImg-alt">
                                            <img
                                              src={data.mediaLink?.url}
                                              className="preview-img"
                                            />
                                          </div>
                                        ) : (
                                          <div className="sliderImg-alt">
                                            <div className="preview-img"></div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {i !== team.subTitles.length - 1 && (
                                      <hr
                                        style={{
                                          border: "1px solid lightgray",
                                        }}
                                      />
                                    )}
                                  </motion.div>
                                ))}
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        ) : (
                          <div className="background-container">
                            <span>Nothing here at the moment</span>
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </Tabs.TabPane>
              ) : (
                <></>
              )}
            </Tabs>
          </div>
          <div className="preview-footer">
            {data && (
              <Switch
                checked={switchState}
                defaultChecked={data?.isPublished}
                checkedChildren="Online"
                unCheckedChildren="Offline"
                // onChange={(value) => publishHandler(value)}
                onChange={(e) => {
                  if (e) {
                    setPublishConfirmation(true);
                  } else {
                    setUnPublishConfirmation(true);
                  }
                }}
              />
            )}
          </div>
          <Modal
            open={isModalOpen}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
            width={350}
            destroyOnClose={true}
          >
            {/* <div className="preview-secondary-player">
            <ReactPlayer
              controls={true}
              url={videoUrl}
              onEnded={() => setVideoCompleted(true)}
              height="100%"
              width="100%"
            ></ReactPlayer>
          </div> */}
            {mediatype === "SOCIAL_MEDIA" ? (
              <div className="preview-secondary-player">
                <ReactPlayer
                  controls={true}
                  url={videoUrl}
                  onEnded={() => setVideoCompleted(true)}
                  height="100%"
                  width="100%"
                ></ReactPlayer>
              </div>
            ) : (
              <div className="preview-secondary-player">
                <VimeoPlayer
                  controls={true}
                  url={videoUrl}
                  // onEnded={() => setVideoCompleted(true)}
                  height="100%"
                  width="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nofullscreen",
                      },
                    },
                  }}
                ></VimeoPlayer>
              </div>
            )}
          </Modal>
        </div>
      </Modal>
      <Modal
        className="confirmation-modal"
        destroyOnClose={true}
        closable={false}
        footer={null}
        open={publishConfirmation}
        onOk={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      >
        <div className="message">Are you sure you want to go online?</div>
        <div className="modalBtns">
          <Button className="cancel-btn" type="primary" onClick={handleConfirmationCancel}>
            Cancel
          </Button>
          <Button className="confirm-btn" key="back" onClick={handleConfirmationOk}>
            Publish
          </Button>
        </div>
      </Modal>
      <Modal
        className="confirmation-modal"
        destroyOnClose={true}
        closable={false}
        footer={null}
        open={unPublishConfirmation}
        onOk={unPublishOk}
        onCancel={handleConfirmationCancel}
      >
        <div className="message">Are you sure you want to go offline?</div>
        <div className="modalBtns">
          <Button className="confirm-btn" key="back" onClick={unPublishOk}>
            Unpublish
          </Button>
          <Button className="cancel-btn" type="primary" onClick={handleConfirmationCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Preview;
