import React, { useEffect } from "react";
import { useMicrosite } from "../custom-hooks/useMicrosite";
import { useStore } from "../store";
import LoadingView from "../components/common/loadingView";
import { useParams, useNavigate } from "react-router-dom";
import "./style.scss";

export const withMicroSites = (Component) => {
  const MicroSites = (props) => {
    const { microSites } = useStore();
    const { microSiteLoading, getMicroSites } = useMicrosite();
    const { micrositeId } = useParams();

    useEffect(() => {
      localStorage.setItem("page","home");
    },[]);

    const navigate = useNavigate();
    // const { state } = useLocation();

    useEffect(() => {
      getMicroSites();
    }, []);


    // useEffect(() => {
    //   if (micrositeId === undefined && microSites && microSites.length > 0) {
    //     // localStorage.setItem("micrositeID", microSites[0].id)
    //     navigate(`/${microSites[0].id}`, {
    //       state: state ? state : TAB_KEYS.businessCard,
    //     });
    //   }
    // }, [micrositeId]);

    if (microSiteLoading) {
      return (
        <div>
          <LoadingView />
        </div>
      );
    } else {
      return (
        <div className="with-microsite">
          <div>
            {microSites && microSites.length > 0 ? (
              <Component micrositeData={microSites} />
            ) : (
              <></>
            )}
          </div>
          <div className="with-microsite-header">
            {/* style={{ marginTop: "100px" }}> */}

            <div className="footer-container">
              <div
                className="footer-content"
                onClick={() =>
                  window.open("https://www.connectsu.co.uk/", "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                {/* <span>Powered by </span> */}
                <div className="footer-logo">
                  <img src={require("../assets/images/PoweredNew.png")} />
                </div>
              </div>
            </div>
            {/* <div className="microsite-header-lastItem" > */}

            {/* <Button label="Add new Microsite" width="100%" /> */}
            {/* </div> */}
          </div>
         
          {/* <Modal open={modalVisible} footer={null} onCancel={closeModal}>
            <Form
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 10 }}
              form={form}
              onFinish={onFinish}
              //   onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Microsite name is required",
                  },
                ]}
                label="Microsite name"
                name="name"
              >
                <Input style={{ width: "300px" }} />
              </Form.Item>
              <Form.Item
                label={
                  <div className="ant-form-item-label ">
                    <label
                      htmlFor="media"
                      title="Organisation logo"
                    >
                      Microsite logo
                    </label>
                  </div>
                }
                colon={false}
                name="media"
                className="experienceImg centered-label"
                rules={[
                  {
                    required: true,
                    message: "Logo is required",
                  },
                ]}
              >
                <div className="choose-btn">
                  {selectedImage ? (
                    <img
                      className="org-admin-logo"
                      size={64}
                      src={selectedImage}
                    />
                  ) : (
                    <Avatar size={64} src={UserIcon} />
                  )}
                  <div className="d-flex">
                    <Tooltip
                      title={
                        "Upload Video link or image with size lesser than 2.5MB"
                      }
                    >
                      <label className="fileUploadBtn greenBtn">
                        {" "}
                        Choose File
                        <input type="file" onChange={handleFileChange} />
                      </label>
                    </Tooltip>
                  </div>
                </div>
              </Form.Item>
              <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button className="sendBtn" htmlType="submit" style={{
                  backgroundColor: "#5AA2FB",
                  borderRadius: "5px",
                  padding: "20px",
                  color: "white",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <span>Create</span>
                </Button>
              </Form.Item>
            </Form>
          </Modal> */}
        </div>
      );
    }
  };

  return MicroSites;
};
