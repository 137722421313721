import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Tooltip,
  Divider,
  Space,

} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ImageLabel from "../../../image-label";
import ReactPlayer from "react-player/youtube";
import products from "../../../organisations/json/products.json";
import { deleteRequest } from "../../../../api/http";
import userServiceApi from "../../../../api/userServiceApi";
import { toast } from "react-toastify";
import { truncateFilename } from "../../../../utils/helper"
const { TextArea } = Input;
const MAX_CHARACTERS = 700;

export default function ProductComponent({ dataLoader, form, getSingleTabData, loader: formDataLoader }) {
  const { micrositeId } = useParams();
  const { state } = useLocation();
  const inputRef = useRef(null);

  const tabKey = state;
  const { tabData, product_groups } = useStore();
  const whichTab = TAB_KEYS.product;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage, updateFile } = useConnectsuForm();
  const [loader, setLoader] = useState(formDataLoader || true);
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();
  const navigate = useNavigate();
  const [customName, setName] = useState("");
  const [items, setItems] = useState(products.map((item) => item.name));

  const onNameChange = (event) => {
    setName([event.target.value]);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, customName[0]]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (tabData[whichTab]) {
      tabData[whichTab].forEach((item) => {
        if (item?.title && !items.includes(item.title)) {
          items.push(item.title);
        }
      });
    }
  }, [tabData[whichTab]]);

  const MakeFieldDisableHandler = (i) => {
    let value = form.getFieldValue([whichTab, i, "title"]);
    // if (value == "w1" || value == "w2" || value == "w3") {
    //   return true;
    // }
  };

  useEffect(() => {
    localStorage.setItem("tab", "PRODUCT");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  const handleDeleteBrochure = async (id) => {
    try {
      const res = await deleteRequest(userServiceApi.deleteBrochure(id))
      if (res) {
        toast.success('Successfully Delete Brochure')
        getSingleTabData(tabKey, form)
      }
    } catch (error) {
      toast.error('Failed to Delete Brochure')
      console.log('failed to Delete Brochure', error)
    }
  }

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { add, remove }) => {
                      return (<>
                        {fields.map(({ key, name, ...restField }, i) => {
                          return (
                            <div className="card-item">
                              <Col className="gutter-row tabContent" span={16} style={{ paddingRight: "25px" }}>
                                <div className="d-flex">
                                  <Form.Item
                                    className="custom-work"
                                    {...restField}
                                    label="Product Group"
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                    // name={[name, "title"]}
                                    name={[name, "assetTitleId"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Product group needs to be selected",
                                      },
                                    ]}
                                  >
                                    {/* <Input placeholder="Enter team" /> */}
                                    {/* <Select
                                    name={[name, "title"]}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: "8px 0",
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: "0 8px 4px",
                                          }}
                                        >
                                          <Input
                                            placeholder="Please enter Product"
                                            ref={inputRef}
                                            value={customName}
                                            onChange={onNameChange}
                                            onPressEnter={addItem}
                                          />
                                          <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={addItem}
                                          >
                                            Add another Product
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                    options={items.map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                  >
                                  </Select> */}
                                    <Select
                                      // name={[name, "title"]}
                                      name={[name, "assetTitleId"]}
                                      options={product_groups.map((item) => ({
                                        label: item.title,
                                        value: item.id,
                                      }))}
                                      dropdownRender={(menu) => (
                                        <>
                                          {menu}
                                          <Divider
                                            style={{
                                              margin: "8px 0",
                                            }}
                                          />
                                          <Space
                                            style={{
                                              padding: "0 8px 4px",
                                            }}
                                          >
                                            <Button
                                              className="dropdow-configurations-btn greenBtn"
                                              type="text"
                                              icon={<PlusOutlined />}
                                              onClick={() => {
                                                localStorage.setItem("page", "configurations");
                                                navigate('/micrositeConfigurations', { state: { micrositeId: micrositeId, page: "product" } })
                                              }}
                                            >
                                              Add product group
                                            </Button>
                                          </Space>
                                        </>
                                      )}
                                    >
                                      {/* {product_groups.map((product) => (
                                      <Select.Option value={product.id}>
                                        {product.title}
                                      </Select.Option>
                                    ))} */}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item>
                                    {fields.length >= 1 &&
                                      // i !== 0 &&
                                      findValue(i, "id") &&
                                      !MakeFieldDisableHandler(i) ? (
                                      <div className="remove-btn">
                                        <DeleteOutlined
                                          onClick={() =>
                                            deleteSingleItem(
                                              whichTab,
                                              form.getFieldValue([
                                                whichTab,
                                                i,
                                                "id",
                                              ]),
                                              form
                                            )
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </Form.Item>
                                </div>
                                <Form.Item
                                  label="Product"
                                  {...restField}
                                  labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                  name={[name, "subTitle"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Product is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Sub product" />
                                </Form.Item>
                                <Form.Item
                                  label="Description"
                                  {...restField}
                                  labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                  name={[name, "description"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Description is required",
                                    },
                                    {
                                      max: MAX_CHARACTERS - 1,
                                      message: `Description should be less than ${MAX_CHARACTERS} character`,
                                    }
                                  ]}
                                >
                                  <TextArea
                                    placeholder="Enter Description"
                                    rows={5}
                                    maxLength={MAX_CHARACTERS}
                                    showCount
                                  />
                                </Form.Item>
                                <div className="d-flex">
                                  <Form.Item
                                    {...restField}
                                    className="w-100"
                                    name={[name, "mediaLink", "mediaType"]}
                                    label="Video or Image"
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Select any option",
                                  //   },
                                  // ]}
                                  >
                                    <Select
                                      name={[name, "mediaLink", "mediaType"]}
                                      onChange={(e) => SelectChangeHandler(e, i)}
                                      placeholder="Select Option"
                                    >
                                      <Select.Option value="VIMEO_MEDIA">
                                        Add Vimeo Link
                                      </Select.Option>
                                      <Select.Option value="SOCIAL_MEDIA">
                                        Add Youtube Link
                                      </Select.Option>
                                      <Select.Option value="IMAGE">
                                        Upload Image
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item>
                                    <div className="remove-btn"  >
                                      <Tooltip
                                        title={
                                          "Upload Video link or image with size lesser than 2.5MB"
                                        }
                                      >
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </div>
                                  </Form.Item>
                                </div>
                                {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                  <Form.Item
                                    label="Youtube Link"
                                    {...restField}
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                    name={[name, "mediaLink", "url"]}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Youtube link is required",
                                  //   },
                                  // ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                  <Form.Item
                                    label="Vimeo Link"
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                    {...restField}
                                    name={[name, "mediaLink", "url"]}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Vimeo link is required",
                                  //   },
                                  // ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                ) : findValue(i, "type") === "IMAGE" ? (
                                  <Form.Item
                                    {...restField}
                                    label="Image"
                                    colon={false}
                                    name="media"
                                    className="experienceImg"
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                  // rules={[
                                  //   {
                                  //     validator: (_, value) => {
                                  //       if (findValue(i, "type") === "IMAGE") {
                                  //         return findValue(i, "blob")
                                  //           ? Promise.resolve()
                                  //           : findValue(i, "url")
                                  //             ? Promise.resolve()
                                  //             : Promise.reject(
                                  //               new Error("Image is required")
                                  //             );
                                  //       } else {
                                  //         return Promise.resolve();
                                  //       }
                                  //     },
                                  //   },
                                  // ]}
                                  >
                                    {findValue(i, "blob") ? (
                                      <Avatar
                                        src={URL.createObjectURL(
                                          findValue(i, "blob")
                                        )}
                                        alt=""
                                      />
                                    ) : findValue(i, "url") ? (
                                      <Avatar src={findValue(i, "url")} alt="" />
                                    ) : (
                                      <Avatar src={UserIcon} />
                                    )}

                                    <label
                                      name="file"
                                      className="fileUploadBtn greenBtn"
                                    >
                                      {" "}
                                      Choose File
                                      <input
                                        accept="image/png, image/gif, image/jpeg"
                                        type="file"
                                        // required={true}
                                        onChange={(e) =>
                                          updateImage(e, i, form, whichTab)
                                        }
                                      />
                                    </label>
                                  </Form.Item>
                                ) : (
                                  <></>
                                )}
                                <Form.Item
                                  label="External Link"
                                  {...restField}
                                  className="w-100"
                                  name={[name, "urlLink"]}
                                  // rules={[
                                  //   {
                                  //     required: false,
                                  //     message: "Job Link is required",
                                  //   },
                                  // ]}
                                  labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                >
                                  <Input
                                    placeholder="External Link"
                                    name={[name, "urlLink"]}
                                  />
                                </Form.Item>

                                <div className="d-flex">
                                  <Form.Item
                                    {...restField}
                                    label="Brochure"
                                    colon={false}
                                    name={[name, "BrochureFile"]}
                                    className="experienceImg w-100"
                                    labelCol={{ span: window.width > 1024 ? 4 : 6 }}
                                  >
                                    {form.getFieldValue(tabKey)[i]?.BrochureFile ? (
                                      truncateFilename(form.getFieldValue(tabKey)[i]?.BrochureFile?.name)
                                    ) : (
                                      form.getFieldValue(tabKey)[i]?.brochureFileName ? <> <a href={form.getFieldValue(tabKey)[i].brochureFilePath || '#'}>
                                        <Button type="button" icon={<DownloadOutlined />} >{truncateFilename(form.getFieldValue(tabKey)[i]?.brochureFileName)}</Button>
                                      </a> <Button icon={<DeleteOutlined />} onClick={() => {
                                        form.getFieldValue(tabKey)[i].id && handleDeleteBrochure(form.getFieldValue(tabKey)[i].id)
                                      }} /> </> : 'No file chosen'
                                    )}
                                    <label
                                      className="fileUploadBtn greenBtn"
                                    >
                                      {form.getFieldValue(tabKey)[i]?.brochureFileName ? "Upload New Brochure" : 'Upload Brochure'}
                                      <input
                                        accept="application/pdf"
                                        type="file"
                                        name={[name, "BrochureFile"]}
                                        // required={true}
                                        onChange={(e) => {
                                          updateFile(e, i, form, whichTab)
                                        }}
                                      />
                                    </label>

                                  </Form.Item>
                                  <Form.Item>
                                    <div className="remove-btn"  >
                                      <Tooltip
                                        title={
                                          "Upload PDF files. Maximum file size: 5MB."
                                        }
                                      >
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </div>
                                  </Form.Item>
                                </div>

                                <Form.Item
                                  className="w-100"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  {formChanged ? (
                                    <Button
                                      className="saveBtn greenBtn"
                                      onClick={() => {
                                        form.submit().then(() => {
                                          setFormChanged(false);
                                        }).catch((errorInfo) => {
                                          console.error('Form submission failed:', errorInfo);
                                        });
                                      }}
                                    >
                                      Save
                                    </Button>
                                  ) : (
                                    <Button
                                      className="saveBtn greenBtn"
                                      style={{
                                        opacity: "0.5",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Save
                                    </Button>
                                  )}
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row tabContent" span={8}>
                                <div>{renderMedia(tabData[whichTab][i])}</div>
                              </Col>
                            </div>)
                        }

                        )}

                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (!findValue(i, "id") && i !== 0) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <Button className="greenBtn" onClick={() => add()}>
                              Add More
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </>)
                    }

                    }
                  </Form.List>
                </>
              </Col>

              {/* <Col className="gutter-row corousel-col" span={6}>
                {/* <CarouselContainer
                  data={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                /> */}
              {/*
                <ImageSlider data={tabData && tabData[whichTab] && tabData[whichTab].length > 0
                  ? tabData[whichTab]
                  : [""]} />
              </Col> */}
            </>
          ) : (
            <></>
          )
          }
        </Row >
      </div >
    );
  }
}
