import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Input,
    Form,
    Select,
    Row,
    Col,
    Spin,
    Tooltip,
    Tabs
} from "antd";
import {
    PlusOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { aboutUSkeys, TAB_KEYS } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../../../utils";
import ImageLabel from "../../../../../image-label";
import ReactPlayer from "react-player/youtube";

const { TextArea } = Input;
const MAX_CHARACTERS = 500;

export default function OurStoryComponent({
    dataLoader,
    form,
    handleTabs,
    activeKey,
}) {
    // const params = useParams();
    const { state } = useLocation();
    // const navigate = useNavigate();
    const tabKey = state;

    const { tabData } = useStore();
    const whichTab = aboutUSkeys["Our Story"];
    const { deleteSingleItem, postLoader } = useTab();
    const { updateImage } = useConnectsuForm();
    const [loader, setLoader] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const { updatedValues } = useStore();

    if (activeKey === "OUR_STORY") {
        localStorage.setItem("tab", activeKey);
    }

    useEffect(() => {
        setFormChanged(false);
    }, []);

    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;
        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    const renderMedia = (dataItem) => {
        const commonStyles = {
            width: "350px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
        };

        if (
            dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <ReactPlayer
                        controls={true}
                        url={dataItem.mediaLink.url}
                        width="100%"
                        height="100%"
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <iframe
                        src={dataItem.mediaLink.url}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{ backgroundColor: "black" }}
                    // width="350px"
                    // height="auto"
                    />
                </div>
            );
        } else if (
            dataItem?.mediaLink?.mediaType === "IMAGE" &&
            dataItem.mediaLink.url
        ) {
            return (
                <div className="iframe-comp" style={commonStyles}>
                    <img
                        src={dataItem?.mediaLink?.url}
                        alt=""
                        className="c1 carousel-image"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                </div>
            );
        }

        return null;
    };

    const findValue = (i, key) => {
        return getFormFieldValue(form, whichTab, i, key);
    };


    const SelectChangeHandler = (e, i) => {
        form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
    };

    useEffect(() => {
        form.resetFields();
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [tabData[whichTab]]);

    if (dataLoader || postLoader || loader) {
        return (
            <div className="data-loader">
                <Spin tip="Loading" size="large" />
            </div>
        );
    } else {
        return (
            <div>
                <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {tabKey.split('/')[1] === whichTab ? (
                        <>
                            <Col className="gutter-row tabContent" span={24}>
                                <Form.List
                                    name={whichTab}
                                    initialValue={
                                        tabData &&
                                            tabData[whichTab] &&
                                            tabData[whichTab].length > 0
                                            ? tabData[whichTab]
                                            : [""]
                                    }
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, i) => (
                                                <div className="card-item">
                                                    <Col
                                                        className="gutter-row tabContent"
                                                        span={16}
                                                        style={{ paddingRight: "25px" }}
                                                    >
                                                        <div className="d-flex">



                                                            <Form.Item
                                                                label="Description"
                                                                {...restField}
                                                                labelCol={{ span: 5 }}
                                                                name={[name, "description"]}
                                                                className="w-100"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Description is required",
                                                                    },
                                                                    {
                                                                        max: MAX_CHARACTERS - 1,
                                                                        message: `Description should be less than ${MAX_CHARACTERS} character`,
                                                                    }
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    placeholder="Enter Description"
                                                                    rows={5}
                                                                    maxLength={MAX_CHARACTERS}
                                                                    showCount
                                                                />
                                                            </Form.Item>


                                                            <Form.Item>


                                                                <div className="remove-btn">
                                                                    <DeleteOutlined
                                                                        onClick={() =>
                                                                            deleteSingleItem(
                                                                                whichTab,
                                                                                form.getFieldValue([
                                                                                    whichTab,
                                                                                    i,
                                                                                    "id",
                                                                                ]),
                                                                                form
                                                                            )
                                                                        }
                                                                    />
                                                                </div>

                                                            </Form.Item>
                                                        </div>


                                                        <div className="d-flex">
                                                            <Form.Item
                                                                {...restField}
                                                                className="custom-work"
                                                                name={[name, "mediaLink", "mediaType"]}
                                                                label="Video or Image"
                                                                labelCol={{ span: 5 }}

                                                            >
                                                                <Select
                                                                    name={[name, "mediaLink", "mediaType"]}
                                                                    onChange={(e) => SelectChangeHandler(e, i)}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Select.Option value="VIMEO_MEDIA">
                                                                        Add Vimeo Link
                                                                    </Select.Option>
                                                                    <Select.Option value="SOCIAL_MEDIA">
                                                                        Add Youtube Link
                                                                    </Select.Option>
                                                                    <Select.Option value="IMAGE">
                                                                        Upload Image
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <div className="remove-btn">
                                                                    <Tooltip
                                                                        title={
                                                                            "Upload Video link or image with size lesser than 2.5MB"
                                                                        }
                                                                    >
                                                                        <QuestionCircleOutlined />
                                                                    </Tooltip>
                                                                </div>
                                                            </Form.Item>
                                                        </div>
                                                        {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                                            <Form.Item
                                                                label="Youtube Link"
                                                                labelCol={{ span: 5 }}
                                                                {...restField}
                                                                name={[name, "mediaLink", "url"]}

                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                                            <Form.Item
                                                                label="Vimeo Link"
                                                                labelCol={{ span: 5 }}
                                                                {...restField}
                                                                name={[name, "mediaLink", "url"]}

                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        ) : findValue(i, "type") === "IMAGE" ? (
                                                            <Form.Item
                                                                {...restField}
                                                                label="Image"
                                                                colon={false}
                                                                // label={<p style={{ fontSize: '16px', margin: '0px' }}><span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>Image</p>}
                                                                name="media"
                                                                className="experienceImg"
                                                                labelCol={{ span: 5 }}

                                                            >
                                                                {findValue(i, "blob") ? (
                                                                    <Avatar
                                                                        src={URL.createObjectURL(
                                                                            findValue(i, "blob")
                                                                        )}
                                                                        alt=""
                                                                    />
                                                                ) : findValue(i, "url") ? (
                                                                    <Avatar src={findValue(i, "url")} alt="" />
                                                                ) : (
                                                                    <Avatar src={UserIcon} />
                                                                )}
                                                                <label
                                                                    name="file"
                                                                    className="fileUploadBtn greenBtn"
                                                                >
                                                                    {" "}
                                                                    Choose File
                                                                    <input
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        type="file"
                                                                        // required={true}
                                                                        onChange={(e) =>
                                                                            updateImage(e, i, form, whichTab)
                                                                        }
                                                                    />
                                                                </label>
                                                            </Form.Item>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <Form.Item
                                                            className="w-100"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "end",
                                                            }}
                                                        >
                                                            {formChanged ? (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    onClick={() => {
                                                                        form
                                                                            .submit()
                                                                            .then(() => {
                                                                                setFormChanged(false);
                                                                            })
                                                                            .catch((errorInfo) => {
                                                                                console.error(
                                                                                    "Form submission failed:",
                                                                                    errorInfo
                                                                                );
                                                                            });
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    style={{
                                                                        opacity: "0.5",
                                                                        pointerEvents: "none",
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row tabContent" span={8}>
                                                        <div>{tabData[whichTab]?.[i] ? renderMedia(tabData[whichTab][i]) : null}</div>

                                                    </Col>
                                                </div>
                                                // </Space>
                                            ))}

                                            <div className="inputBody">
                                                <div style={{ textAlign: "right" }}>
                                                    {fields && fields.length <= 1 ? (
                                                        <></>
                                                    ) : (
                                                        <Button
                                                            className="cancelBtn"
                                                            type="button"
                                                            onClick={() => {
                                                                const itemsToRemove = [];
                                                                fields.forEach((f, i) => {
                                                                    if (
                                                                        !findValue(i, "id") &&
                                                                        i !== 0
                                                                    ) {
                                                                        itemsToRemove.push(f.name);
                                                                    }
                                                                });
                                                                if (itemsToRemove.length > 0) {
                                                                    remove(itemsToRemove);
                                                                }
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                    <Button className="greenBtn" onClick={() => add()}>
                                                        Add More
                                                        <PlusOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </>
                    ) : (
                        <></>
                    )}
                </Row>
            </div>
        );
    }
}
