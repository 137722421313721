import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./Buttons.scss";

export default function Button(props) {
  return (
    <button
      className={`primary-btn ${props.className}`}
      style={{
        ...props.styles,
        width: props.width,
        backgroundColor: props.backgroundColor,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
    >
      <div className="pri-btn-loading">
        {props.loading ? <LoadingOutlined /> : null}
      </div>
      {props.icon ? <div className="pri-btn-icon">{props.icon}</div> : null}
      {props.label ? <div className="pri-btn-label">{props.label}</div> : null}
    </button>
  );
}

Button.defaultProps = {
  width: "100px",
  loading: false,
  icon: null,
  label: "Button",
  backgroundColor: "#004eda",
  styles: {},
};
