import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ImageLabel from "../../../image-label";
import ReactPlayer from "react-player/youtube";
import { toast } from "react-toastify";
import { truncateFilename } from "../../../../utils/helper"
import userServiceApi from "../../../../api/userServiceApi";
import { deleteRequest } from "../../../../api/http";

const { TextArea } = Input;
const MAX_CHARACTERS = 700;

export default function ServiceComponent({ dataLoader, form, getSingleTabData, loader: formDataLoader }) {
  const params = useParams();
  const { state } = useLocation();

  const tabKey = state;
  const { tabData } = useStore();
  const whichTab = TAB_KEYS.service;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage, updateFile } = useConnectsuForm();
  const [loader, setLoader] = useState(formDataLoader || true);
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("tab", "SERVICE");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  const handleDeleteBrochure = async (id) => {
    try {
      const res = await deleteRequest(userServiceApi.deleteBrochure(id))
      if (res) {
        toast.success('Successfully Delete Brochure')
        getSingleTabData(tabKey, form)
      }
    } catch (error) {
      toast.error('Failed to Delete Brochure')
      console.log('failed to Delete Brochure', error)
    }
  }

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <div className="card-item">
                            <Col className="gutter-row tabContent" span={16} style={{ paddingRight: "25px" }}>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  className="w-100"
                                  label="Service Name"
                                  name={[name, "title"]}
                                  labelCol={{ span: 5 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Service name is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Service Name" />
                                </Form.Item>
                                <Form.Item>
                                  {fields.length >= 1 && findValue(i, "id") && (
                                    <div className="remove-btn">
                                      {/* <MinusCircleOutlined
                                      onClick={() =>
                                        deleteSingleItem(
                                          whichTab,
                                          form.getFieldValue([
                                            whichTab,
                                            i,
                                            "id",
                                          ]),
                                          form
                                        )
                                      }
                                    /> */}
                                      <DeleteOutlined
                                        onClick={() =>
                                          deleteSingleItem(
                                            whichTab,
                                            form.getFieldValue([
                                              whichTab,
                                              i,
                                              "id",
                                            ]),
                                            form
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </Form.Item>
                              </div>
                              <Form.Item
                                label="Description"
                                {...restField}
                                name={[name, "description"]}
                                labelCol={{ span: 5 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Description is required",
                                  },
                                  {
                                    max: MAX_CHARACTERS - 1,
                                    message: `Description should be less than ${MAX_CHARACTERS} character`,
                                  }
                                ]}
                              >
                                <TextArea
                                  placeholder="Enter Description"
                                  rows={5}
                                  maxLength={MAX_CHARACTERS}
                                  showCount
                                />
                              </Form.Item>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  className="w-100"
                                  name={[name, "mediaLink", "mediaType"]}
                                  label="Video or Image"
                                  labelCol={{ span: 5 }}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Select any option",
                                //   },
                                // ]}
                                >
                                  <Select
                                    name={[name, "mediaLink", "mediaType"]}
                                    onChange={(e) => SelectChangeHandler(e, i)}
                                    placeholder="Select Option"
                                  >
                                    <Select.Option value="VIMEO_MEDIA">
                                      Add Vimeo Link
                                    </Select.Option>
                                    <Select.Option value="SOCIAL_MEDIA">
                                      Add Youtube Link
                                    </Select.Option>
                                    <Select.Option value="IMAGE">
                                      Upload Image
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Upload Video link or image with size lesser than 2.5MB"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                <Form.Item
                                  label="Youtube Link"
                                  {...restField}
                                  labelCol={{ span: 5 }}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Youtube link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                <Form.Item
                                  label="Vimeo Link"
                                  labelCol={{ span: 5 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Vimeo link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "IMAGE" ? (
                                <Form.Item
                                  {...restField}
                                  label="Image"
                                  colon={false}
                                  name="media"
                                  className="experienceImg"
                                  labelCol={{ span: 5 }}
                                // rules={[
                                //   {
                                //     validator: (_, value) => {
                                //       return findValue(i, "blob")
                                //         ? Promise.resolve()
                                //         : findValue(i, "url")
                                //           ? Promise.resolve()
                                //           : Promise.reject(
                                //             new Error("Image is required")
                                //           );
                                //     },
                                //   },
                                // ]}
                                >
                                  {findValue(i, "blob") ? (
                                    <Avatar
                                      src={URL.createObjectURL(
                                        findValue(i, "blob")
                                      )}
                                      alt=""
                                    />
                                  ) : findValue(i, "url") ? (
                                    <Avatar src={findValue(i, "url")} alt="" />
                                  ) : (
                                    <Avatar src={UserIcon} />
                                  )}

                                  <label
                                    name="file"
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {" "}
                                    Choose File
                                    <input
                                      accept="image/png, image/gif, image/jpeg"
                                      type="file"
                                      // required={true}
                                      onChange={(e) =>
                                        updateImage(e, i, form, whichTab)
                                      }
                                    />
                                  </label>
                                </Form.Item>
                              ) : (
                                <></>
                              )}

                              <Form.Item
                                label="External Link"
                                {...restField}
                                className="w-100"
                                name={[name, "urlLink"]}
                                // rules={[
                                //   {
                                //     required: false,
                                //     message: "Job Link is required",
                                //   },
                                // ]}
                                labelCol={{ span: 5 }}
                              >
                                <Input
                                  placeholder="External Link"
                                  name={[name, "urlLink"]}
                                />
                              </Form.Item>

                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  label="Brochure"
                                  colon={false}
                                  name={[name, "BrochureFile"]}
                                  className="experienceImg w-100"
                                  labelCol={{ span: 5 }}
                                >

                                  {/* "brochureFilePath": "https://connectsudevstorage.blob.core.windows.net/connectsu-dev-container/cebd4453-48aa-4e1c-8a97-394ba0c1b327.pdf",
                                  "brochureFileName": "test-brochure.pdf", */}

                                  {form.getFieldValue(tabKey)[i]?.BrochureFile ? (
                                    truncateFilename(form.getFieldValue(tabKey)[i]?.BrochureFile?.name)
                                  ) : (
                                    form.getFieldValue(tabKey)[i]?.brochureFileName ? <> <a href={form.getFieldValue(tabKey)[i].brochureFilePath || '#'}>
                                      <Button type="button" icon={<DownloadOutlined />} >{truncateFilename(form.getFieldValue(tabKey)[i]?.brochureFileName)}</Button>
                                    </a> <Button icon={<DeleteOutlined />} onClick={() => {

                                      form.getFieldValue(tabKey)[i].id && handleDeleteBrochure(form.getFieldValue(tabKey)[i].id)
                                    }} /> </> : 'No file chosen'
                                  )}
                                  <label
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {form.getFieldValue(tabKey)[i]?.brochureFileName ? "Upload New Brochure" : 'Upload Brochure'}
                                    <input
                                      accept="application/pdf"
                                      type="file"
                                      name={[name, "BrochureFile"]}
                                      // required={true}
                                      onChange={(e) => {
                                        updateFile(e, i, form, whichTab)
                                      }}
                                    />
                                  </label>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn"  >
                                    <Tooltip
                                      title={
                                        "Upload PDF files. Maximum file size: 5MB."
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              <Form.Item
                                className="w-100"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                {formChanged ? (
                                  <Button
                                    className="saveBtn greenBtn"
                                    onClick={() => {
                                      form.submit().then(() => {
                                        setFormChanged(false);
                                      }).catch((errorInfo) => {
                                        console.error('Form submission failed:', errorInfo);
                                      });
                                    }}
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    className="saveBtn greenBtn"
                                    style={{
                                      opacity: "0.5",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row tabContent" span={8}>
                              <div>{renderMedia(tabData[whichTab][i])}</div>
                            </Col>
                          </div>
                        ))}

                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (!findValue(i, "id") && i !== 0) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <Button className="greenBtn" onClick={() => add()}>
                              Add More
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form.List>
                </>
              </Col>

              {/*<Col className="gutter-row corousel-col" span={6}>
                {/* <CarouselContainer
                  data={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                /> */}
              {/* <ImageSlider data={
                  tabData && tabData[whichTab] && tabData[whichTab].length > 0
                    ? tabData[whichTab]
                    : [""]
                } /> */}
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    );
  }
}
