import React, { useState } from "react";
import { toast } from "react-toastify";
import userServiceApi from "../api/userServiceApi";
import { getRequest, patchRequest } from "../api/http";
import { putRequest } from "../api/http";
import { TAB_KEYS } from "../config";
import { setMicroSiteActions } from "../store/actions";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../store";
import { setMicroSites } from "../store/actions";

export function useMicrosite() {
  const { micrositeId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { dispatch } = useStore();

  const [microSiteLoading, setLoading] = useState(false);
  const [microSitePublishLoading, setPublishLoading] = useState(false);
  const [microSiteUpdateLoading, setUpdateLoading] = useState(false);
  const newMicrositeId = localStorage.getItem("newMicrositeId");

  // const [microSites, setMicroSites] = useState([])

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const getMicroSites = async () => {
    setLoading(true);
    try {
      const response = await getRequest(userServiceApi.getAllMicrosites(), {
        headers,
      });
      if (response && response.data) {
        const data = response.data;
        if (data.length > 0) {
          // if (newMicrositeId) {
          //   navigate(`/${newMicrositeId}`, {
          //     state: state ? state : TAB_KEYS.aboutUs,
          //   });
          //   localStorage.removeItem("newMicrositeId");
          // } else 
          if (micrositeId) {
            // navigate(`/${micrositeId}`, { state: state ? state : TAB_KEYS.aboutUs })
            navigate(`/${micrositeId}`, { state: state ? state : TAB_KEYS.aboutUs })
          } else {
            navigate(`/${data[0].id}`, { state: state ? state : TAB_KEYS.aboutUs })
          }
          // else {
          //   // navigate(`/${data[0].id}`, {
          //   //   state: state ? state : TAB_KEYS.aboutUs,
          //   // });

          //   navigate(`/`, {
          //     state: state ? state : TAB_KEYS.aboutUs,
          //   });
          // }
          dispatch(setMicroSites(data));
          // setMicroSites(data);
          setLoading(false);
        } else {
          navigate('/edit-organization', { state: { tab: "organizationDetailsTab" } })
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    finally {
      setLoading(false);
    }
  };

  const updateMicroSite = async (
    micrositeId,
    data,
    reloadFlagFunction,
    page
  ) => {
    try {
      setUpdateLoading(true);
      const response = await putRequest(
        userServiceApi.publishMicrosite(micrositeId),
        data,
        { headers: { "Content-Type": "multipart/form-data" }, headers }
      );
      if (response) {
        setUpdateLoading(false);
        if (page === "home") {
          getMicroSites();
        }
        if (page === "manageMicrosite") {
          reloadFlagFunction();
        }
        toast.success("Microsite updated successfully");
        dispatch(
          setMicroSiteActions({
            activeKey: micrositeId,
            menuKey: "edit",
            change: false,
          })
        );
      } else {
        setUpdateLoading(false);
        toast.error("Update failed");
      }
    } catch (error) {
      setUpdateLoading(false);

      toast.error(error);
    }
  };

  const publishMicroSite = async (micrositeId, status) => {
    try {
      setPublishLoading(true);
      const response = await patchRequest(
        userServiceApi.publishMicrosite(micrositeId),
        {
          headers,
        }
      );
      if (response) {
        setPublishLoading(false);
        toast.success(`Site ${status} successfully`);
        localStorage.setItem("switchSuccess", true);
        getMicroSites();
        return false;
      }
    } catch (error) {
      setPublishLoading(false);
      localStorage.setItem("switchSuccess", false);
      toast.error(error.error);
      return false;
    }
  };

  return {
    getMicroSites,
    microSiteLoading,
    publishMicroSite,
    microSitePublishLoading,
    updateMicroSite,
    microSiteUpdateLoading,
  };
}
