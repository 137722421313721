import React, { useState } from "react";
import { Input, Form, Row, Col, Button, Tabs } from "antd";
import { toast } from "react-toastify";
import { postRequest } from "../../api/http";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import "./ChangePassword.scss";

function ChangePassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (values.newPassword === values.confirmPwd) {
      delete values.confirmPwd;
      try {
        setLoading(true);
        const response = await postRequest(
          userServiceApi.changePassword(),
          values
        );
        if (response === "Success") {
          setLoading(false);
          toast.success("Password Updated Successfuly");
          navigate("/");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.error);
      }
    } else {
      setLoading(false);
      toast.error("Password and Confirm Password does not match");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  if (loading) return <LoadingView message="Loading..." />;

  return (
    <div className="tabContainer" style={{ minHeight: "fit-content" }}>
      <div className="page-header">
        <div className="backbtn-container">
          <img src={backArrow} alt="" onClick={() => navigate('/')} />
          <p>Change Password</p>
        </div>
      </div>
      {/* <Tabs>
        <Tabs.TabPane tab="Change Password" key="tab1"> */}
      <div className="change-pwd-form-container">
        <Row
          className="rowBody"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row tabContent" span={16}>
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 10 }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* <div className="changePwdHeading">Change Password</div> */}
              <div className="changePwdBody customBody">
                <Form.Item
                  label="Old Password"
                  name="currentPassword"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input placeholder="Old Password" />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input placeholder="New Password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPwd"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input placeholder="Confirm Password" />
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
        <div
          className="pwdUpdateBtn"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            className="cancelBtn"
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel
          </Button>
          <Button className="greenBtn" htmlType="submit" onClick={() => form.submit()}>
            Update
          </Button>
        </div>
      </div>
      {/* </Tabs.TabPane>
      </Tabs> */}
    </div >
  );
}

export default ChangePassword;
