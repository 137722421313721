import React, { useEffect, useState } from "react";
import { Form, Input, Tabs, Col, Button } from "antd";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../api/http";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.scss";

export default function ForgotPasswordForm({ setIsLoginMode }) {
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const partnerID = localStorage.getItem("partnerID");

  const getPartnerDetailsData = async (partnerID) => {
    // console.log("APICall entry")
    try {
      // setLoading(true);
      const response = await getRequest(userServiceApi.getPartnerDetailsLoginScreen(partnerID));
      // if (response) {
      //   console.log("res", response)
      // }
      if (response && response.data) {
        setPartnerData(response.data);
      }
    } catch (error) {
      // setLoading(false);
      toast.error(error.error);
    }
  };

  useEffect(() => {
    getPartnerDetailsData(partnerID);
  }, [])

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await postRequest(
        userServiceApi.forgotPassword(),
        values
      );
      if (response === "Success") {
        toast.success("Reset link has been sent to your mail");
        setLoading(false);
        navigate(`/login/${partnerID}`);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <React.Fragment>
      <div className="headerBannerRow reset" >
        <div>
          <img
            className="Reset-headerLogo"
            src={partnerData?.mediaLink}
            alt=""
          // onClick={() => {
          //   navigate("/");
          // }}
          />
          {/* <label>Organisation Admin</label> */}
        </div>
      </div>
      <div className="tabContainer">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}
          className="organizationForm"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Tabs>
            <Tabs.TabPane tab="Forgot Password" key="tab1">
              {
                loading ? <LoadingView message="Loading..." /> : <div className="forgotPwdBody">
                  <Col className="gutter-row" span={15}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <div className="forgotPwdButtons">
                    <Button
                      className="cancelBtn"
                      onClick={() => {
                        navigate(`/login/${partnerID}`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button className="greenBtn" htmlType="submit">
                      Request Reset Link
                    </Button>
                  </div>
                </div>
              }
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <div className="footer d-flex" style={{ paddingTop: "2vh" }}>
          <div className="footer-content" onClick={() => window.open("https://www.connectsu.co.uk/", '_blank')} style={{ cursor: "pointer" }}>
            {/* <span>Powered by</span> */}
            <div className="footer-logo">
              <img
                src={require("../../assets/images/PoweredNew.png")}
                alt="company logo"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
