import React, { useState } from "react";
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  Tabs,
  Space,
  Upload,
  Typography,
  Select,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import "./Feedback.scss";
import { InboxOutlined } from "@ant-design/icons";
import { deleteRequest, postRequest } from "../../api/http";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";

function FeedBack() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [fileLoader, setFileLoader] = useState(true);
  const { micrositeId } = useParams();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [fileSizeOk, setFileSizeOk] = useState(false);

  const checkFile = (file) => {
    const maxSize = 2.5 * 1024 * 1024;
    const isSizeValid = file.size <= maxSize;

    // console.log("size", isSizeValid);
    if (!isSizeValid) {
      setFileSizeOk(false);
      toast.error("File size exceeds 2.5MB!");
    } else {
      setFileSizeOk(true);
    }

    return isSizeValid;
  };

  const onFinish = async (values) => {
    try {
      if (file) {
        if (fileSizeOk) {
          setLoading(true);
          if (values && values.message && values.feedbackAboutEnum) {
            var formData = new FormData();
            if (file) {
              formData.append("file", file, fileName);
            }
            const formatedData = {
              feedbackAboutEnum: values.feedbackAboutEnum,
              message: values.message,
              organizationId: localStorage.getItem("OrgId"),
            };
            const json = JSON.stringify(formatedData);
            const blob = new Blob([json], {
              type: "application/json",
            });
            formData.append("organizationFeedbackDto", blob);
            const response = await postRequest(
              userServiceApi.feedbackForm(),
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response) {
              setFileName(null);
              toast.success("Feedback sent successfully");
              setFileSizeOk(false);
              setLoading(false);
              // window.location.reload();
              // getFiles();
              // setLoader(false);
            }
          }
        } else {
          toast.error("File size exceeds 2.5MB!");
        }
      } else {
        setLoading(true);
        if (values && values.message && values.feedbackAboutEnum) {
          var formData = new FormData();
          const formatedData = {
            feedbackAboutEnum: values.feedbackAboutEnum,
            message: values.message,
            organizationId: localStorage.getItem("OrgId"),
          };
          const json = JSON.stringify(formatedData);
          const blob = new Blob([json], {
            type: "application/json",
          });
          formData.append("organizationFeedbackDto", blob);
          const response = await postRequest(
            userServiceApi.feedbackForm(),
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response) {
            toast.success("Feedback sent successfully");
            setLoading(false);
            // window.location.reload();
            // getFiles();
            // setLoader(false);
          }
        }
      }
    } catch (error) {
      toast.error(error.error);
      // setLoading(false);
    }
  };

  const handleUploadAPI = ({ file }) => {
    const fileName = file.name;
    setFileName(fileName);
    // var formData = new FormData();
    const blob = new Blob([file], {
      type: "multipart/form-data",
    });
    setFile(blob);

    // formData.append("file", blob);

    if (Object.values(fileList).length > 0) {
      // toast.error("You can upload only one file")
    } else {
      setFileList((pre) => {
        return { ...pre, [file.uid]: file };
      });
    }
  };

  // const handleDeleteFile = async (documentId) => {
  //   try {
  //     // setLoader(true);
  //     const response = await deleteRequest(
  //       userServiceApi.deleteInsuranceDoc(documentId)
  //     );
  //     if (response) {
  //       toast.success("Document deleted successfully");
  //       // window.location.reload();
  //       // getFiles();
  //       // setLoader(false)
  //     }
  //   } catch (error) {
  //     // toast.error("Error in deleting the document")
  //   }
  // };

  const uploadProps = {
    accept: ".pdf, .jpeg, .png, .jpg",
    beforeUpload: checkFile,
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    customRequest: handleUploadAPI,
  };

  if (loading) return <LoadingView message="Loading..." />;

  return (
    <>
      <div className="tabContainer">
        <div className="page-header">
          <div className="backbtn-container">
            <img src={backArrow} alt="" onClick={() => navigate('/')} />
            <p>Support & Feedback</p>
          </div>
        </div>
        {/* <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Support & Feedback" key={"1"}> */}
        <div style={{ padding: "10px" }}>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={16}>
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 10 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  label="I want to"
                  name="feedbackAboutEnum"
                  rules={[
                    {
                      required: true,
                      message: "Feedback type is required",
                    },
                  ]}
                >
                  <Select
                    name="feedbackAboutEnum"
                    placeholder="I want to.."
                  >
                    <Select.Option value="COMMENT">
                      Leave a comment
                    </Select.Option>
                    <Select.Option value="REPORT">
                      Report a bug
                    </Select.Option>
                    <Select.Option value="SUGGESTION">
                      Suggest an improvement
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Feedback message is required",
                    },
                  ]}
                  label="Message"
                  name="message"
                >
                  <Input.TextArea
                    rows={3}
                    maxLength={250}
                    showCount
                    placeholder="Message"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  className="doc"
                  label="Document"
                  name="feedbackDocument"
                >
                  <div className="files-feedback">
                    <div className="file-upload-feedback">
                      {/* <label>Add Employer's insurance liability document</label> */}
                      <Tooltip
                        title={
                          "Upload image or pdf with size less than 2.5MB"
                        }
                      >
                        <div className="upload-component">
                          {/* <Space direction="horizontal"> */}
                          <Dragger {...uploadProps} className="dropbox">
                            <div className="box-content">
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                            </div>
                          </Dragger>
                        </div>
                      </Tooltip>
                    </div>
                    <div className="existing">
                      {/* <label>Existing document</label> */}
                      {fileName ? (
                        <Space className="fileList-Item">
                          <Typography className="nodoc-text">
                            {fileName}
                          </Typography>
                        </Space>
                      ) : (
                        <Space className="fileList-Item">
                          <Typography>No document uploaded</Typography>
                        </Space>
                      )}
                    </div>
                  </div>
                </Form.Item>
                {/* <Form.Item className="form-save">
                  <Button className="sendBtn greenBtn" htmlType="submit">
                    <span>Submit</span>
                  </Button>
                </Form.Item> */}
              </Form>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button className="sendBtn greenBtn" htmlType="submit" onClick={() => form.submit()}>
              Submit
            </Button>
          </div>
        </div>
        {/* </Tabs.TabPane>
        </Tabs> */}
      </div>

      {/* <div className="footer">
        <div
          className="footer-content"
          onClick={() => window.open("https://www.connectsu.co.uk/", "_blank")}
          style={{ cursor: "pointer" }}
        >
          <div className="footer-logo">
            <img
              src={require("../../assets/images/PoweredNew.png")}
              alt="company logo"
            />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default FeedBack;
