import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import userServiceApi from "../../api/userServiceApi";
import { LoadingView } from "../common";
import ytPlayBtn from "../../../src/assets/images/ytPlayBtn.svg";
import "./Help.scss";
import ModalPlayer from "../microSites/ModalMediaPlayer";
import backArrow from "../../assets/images/back-arrow.svg";
import { SearchOutlined } from "@ant-design/icons";


function Help() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tab = localStorage.getItem("tab");
  const [helpTabName, setHelpTabName] = useState([]);
  const [renderKey, setRenderKey] = useState(0);
  const [renderFlag, setRenderFlag] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    // Update the key to trigger a re-render when organisation Type list changes
    setRenderKey((prevKey) => prevKey + 1);
  }, [renderFlag]);


  const tabName = (tab) => {
    if (tab === "businessCard") {
      tab = "Business card";
    } else if (tab === "BUSINESS_CARD") {
      tab = "Business card";
    } else if (tab === "CAROUSEL") {
      tab = "Carousel";
    } else if (tab === "about-us") {
      tab = "About us";
    } else if (tab === "ABOUT_US") {
      tab = "About us";
    } else if (tab === "PRODUCT") {
      tab = "Product";
    } else if (tab === "SERVICE") {
      tab = "Service";
    } else if (tab === "APPRENTICESHIP") {
      tab = "Apprenticeship";
    } else if (tab === "VACANCY") {
      tab = "Vacancy";
    } else if (tab === "COURSES") {
      tab = "Courses";
    } else if (tab === "CONTRACTS") {
      return "Contract";
    } else if (tab === "WHY_WORK") {
      tab = "Why work for us";
    } else if (tab === "TEAMS_AND_ROLES") {
      tab = "Teams and roles";
    } else if (tab === "WORK_EXPERIENCE") {
      tab = "Experience of work";
    }
    return tab;
  };

  const getAllHelp = () => {
    setSearchTerm('')
    localStorage.removeItem("tab");
    setRenderFlag(!renderFlag);
    // window.location.reload();
    // getHelpData();
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const getHelpData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      if (tab) {
        const response = await getRequest(searchTerm === '' ? userServiceApi.getHelpByTab(
          tab === "businessCard" ? "BUSINESS_CARD"
            : tab === "about-us" ? "ABOUT_US"
              : tab
        ) : userServiceApi.getFilteredHelpInfo('ORGANIZATION_ADMIN', searchTerm, tab), {
          headers,
        });
        if (response) {
          setLoading(false);
          setData(response.data);
        } else {
          setLoading(true);
        }
      } else {
        const response = await getRequest(searchTerm === '' ? userServiceApi.help() : userServiceApi.getFilteredHelpInfo('ORGANIZATION_ADMIN', searchTerm, tab), { headers });
        if (response) {
          setLoading(false);
          setData(response.data);
        } else {
          setLoading(true);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let HelpTabNames = {}
    data.forEach((ele) => {
      // prevent duplicate
      if (!HelpTabNames[ele.assetTypeEnum]) {
        HelpTabNames[ele.assetTypeEnum] = 1
      }
    }
    )
    setHelpTabName(Object.keys(HelpTabNames).map((ele) => ele))
  }, [data]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getHelpData();
    }, 500);
    return () => {
      clearTimeout(handler)
    }
  }, [tab, searchTerm]);

  return (
    <>
      <div className="tabContainer">
        <div className="header page-header">
          <div className="backbtn-container">
            <img src={backArrow} alt="" onClick={() => navigate('/')} />
            <p>Help</p>
          </div>
        </div>
        <div className="microsite-header-lastItem" style={{ display: 'flex', justifyContent: 'space-between' }} >
          <Button
            className="saveBtn greenBtn"
            htmlType="submit"
            onClick={getAllHelp}
          >
            Get all help information
          </Button>
          <div className="searchbar-containers"  >

            <Input
              className="searchbar"
              placeholder={`Search help`}
              value={searchTerm}
              suffix={
                <SearchOutlined
                  style={{ color: "#CC0E2D", verticalAlign: "middle" }}
                />
              }
              onChange={(e) => { setSearchTerm(e.target.value) }}
              style={{ width: '328px' }}
            />
          </div>
        </div>
        <hr />

        {
          loading ?
            <div className="data-loader">
              <Spin tip="Loading" size="large" />
            </div>
            : <>

              {helpTabName.length > 0 ? helpTabName.map((tabNames, i) => (
                <div className="help-org-card-container" key={`${tabNames}${i}`}>
                  <div className="title-help">{tabName(tabNames)}</div>
                  <Row gutter={[16, 16]}>
                    {" "}
                    {/* This will give space between the cards */}
                    {data && data.length > 0
                      ? data.map((ele) =>
                        ele?.assetTypeEnum === tabNames ? (
                          <Col key={ele.id}
                            xs={24} sm={12} md={8} lg={8}>
                            <div
                              className="help-card-item help-page-card-item help-card-hover"
                              key={ele.id}
                              bordered
                            >
                              <div className="infoClass">
                                <div className="card-title">
                                  {ele.title}
                                </div>
                                <div className="card-desc">
                                  {ele.description}
                                </div>
                                {ele?.webLink ? (
                                  <Button
                                    type="primary"
                                    className="greenBtn btn-km"
                                    onClick={() =>
                                      window.open(ele?.webLink, "_blank")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    Know More
                                  </Button>
                                ) : null}
                              </div>
                              <div className="help-cardContent">
                                {ele.mediaLink?.mediaType ===
                                  "SOCIAL_MEDIA" && ele.mediaLink?.url ? (
                                  <>
                                    {(() => {
                                      const standardURL = convertToStandardURL(
                                        ele.mediaLink.url
                                      );
                                      const videoID = standardURL
                                        .split("v=")[1]
                                        ?.split("&")[0];
                                      return (
                                        <div
                                          className="media youtube"
                                          style={{ objectFit: "contain" }}
                                          onClick={() =>
                                            PlayVideoHandler(
                                              ele.mediaLink?.url,
                                              ele.mediaLink?.mediaType
                                            )
                                          }
                                        >
                                          <img
                                            src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                            alt=""
                                            className="video-preview-image"
                                            style={{
                                              height: "100%",
                                              borderRadius: "8px",
                                            }}
                                          />
                                          <img
                                            className="ytBtnOverlay"
                                            src={ytPlayBtn}
                                          />
                                        </div>
                                      );
                                    })()}
                                  </>
                                ) : ele.mediaLink?.mediaType ===
                                  "VIMEO_MEDIA" && ele.mediaLink?.url ? (
                                  <div
                                    className="media vimeo youtube"
                                    onClick={() => {
                                      PlayVideoHandler(
                                        ele.mediaLink?.url,
                                        ele.mediaLink?.mediaType
                                      );
                                    }}
                                  >
                                    <img
                                      src={`https://vumbnail.com/${ele.mediaLink?.url?.split("/")[4]
                                        }.jpg`}
                                      className="video-preview-image"
                                      style={{
                                        height: "100%",
                                        borderRadius: "8px",
                                      }}
                                    />

                                    <img
                                      className="ytBtnOverlay"
                                      src={ytPlayBtn}
                                    />
                                  </div>
                                ) : (
                                  <div className="media">
                                    <div className="slider-Img">
                                      <img
                                        src={ele.mediaLink?.url}
                                        alt=""
                                        className="home-preview-img"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        ) : null
                      )
                      : ""}
                  </Row>
                  <div className='connectsu-line-help'></div>
                  {loading && (
                    <div className="no-data-warning">
                      <Spin tip="Loading" size="large" />
                    </div>
                  )}
                </div>
              )) : null}

              {helpTabName.length === 0 ? <p className="sub-header">No data available</p>
                : null}
            </>
        }

      </div>
      {isModalOpen ? (
        <ModalPlayer
          closeModal={handleCancel}
          mediaLink={videoUrl}
          mediaType={mediatype}
        />
      ) : (
        null
      )}
      <div className="footer">
        <div
          className="footer-content"
          onClick={() => window.open("https://www.connectsu.co.uk/", "_blank")}
          style={{ cursor: "pointer" }}
        >
          {/* <span>Powered by</span> */}
          <div className="footer-logo">
            <img
              src={require("../../assets/images/PoweredNew.png")}
              alt="company logo"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Help;
