import React from "react";
import Sales from "./sub-tabs/sales";
import HR from "./sub-tabs/hr";
import EOW from "./sub-tabs/eow";
import { contactUSkeys } from "../../../../../config";
import { Tabs, Button, Form, Modal } from "antd";
import Reviews from "./sub-tabs/reviews";
import SocialMedia from "./sub-tabs/social-media";
import Address from "./sub-tabs/Address";

const tabList = [
  {
    tabName: "Sales",
    component: (params) => <Sales {...params} />,
    tabKey: contactUSkeys.sales,
  },
  {
    tabName: "Careers",
    component: (params) => <HR {...params} />,
    tabKey: contactUSkeys.hr,
  },
  {
    tabName: "Experiences of work",
    component: (params) => <EOW {...params} />,
    tabKey: contactUSkeys.eow,
  },
  {
    tabName: "Social media",
    component: (params) => <SocialMedia {...params} />,
    tabKey: contactUSkeys.socialMedia,
  },
  {
    tabName: "Reviews",
    component: () => <Reviews />,
    tabKey: contactUSkeys.reviews,
  },
  {
    tabName: "Address",
    component: (params) => <Address {...params} />,
    tabKey: contactUSkeys.address,
  },
];

export default function ContactUsComponent({
  dataLoader,
  form,
  handleTabs,
  splitParams,
}) {
  return (
    <div className="contact-tab-container">
      {splitParams[1] && <Tabs
        className="tabBodyContainer"
        onChange={handleTabs}
        activeKey={splitParams[1]}
      >
        {tabList.map((tab, i) => {
          return (
            <Tabs.TabPane
              className="contactUsContent contact-tab-content-container"
              tab={tab.tabName}
              key={tab.tabKey}
            >
              {tab.component({
                dataLoader,
                form,
                tabKey: splitParams[1],
              })}
            </Tabs.TabPane>
          );
        })}
      </Tabs>}
    </div>
  );
}
