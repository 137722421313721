import React, { useState, useEffect } from "react";
import { Switch, Modal, Button, Form, Input, Tooltip } from "antd";
import settingIcon from "../../assets/images/settings-icon.svg";
import previewIcon from "../../assets/images/preview-icon.svg";
import userServiceApi from "../../api/userServiceApi";
import { getRequest } from "../../api/http";
import helpIcon from "../../assets/images/helpIcon.svg";
import upArrow from "../../assets/images/right-arrow.svg";
import downArrow from "../../assets/images/new-down-arrow.svg";
import analyticsIcon from "../../assets/images/analytics.svg";
import { useMicrosite } from "../../custom-hooks/useMicrosite";
import MicrositeSettings from "./microsite-settings";
import MicrositeSettingsCopy from "./microsite-settings-copy";
import Preview from "../microSites/edit/preview";
import { useStore } from "../../store";
import MicrositeEdit from "./microsite-edit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TAB_KEYS } from "../../config";
import { setMicroSiteActions } from "../../store/actions";
import "./style.scss";
import { useTab } from "../../custom-hooks/useTab";
import MicrositeAnalytics from "../../components/analytics/microsites-analytics";

export default function MicroSiteAccordian({
  data,
  children,
  activeKey,
  micrositeData,
}) {
  const { publishMicroSite, microSitePublishLoading } = useMicrosite();
  const [switchState, setSwitchState] = useState(data.published);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [unPublishConfirmation, setUnPublishConfirmation] = useState(false);
  const { settings, dispatch, microSiteActions, microSites } = useStore();
  const [micrositeModal, setMicrositeModal] = useState(true);
  const [selectedMicrosite, setSelectedMicrosite] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { micrositeId } = useParams();

  useEffect(() => {
    setSwitchState(data.isPublished);
  }, [data]);

  const handleMenus = (activeKey, menuKey, change) => {
    dispatch(setMicroSiteActions({ activeKey, menuKey, change }));
  };

  const redirectMicroSite = (key) => {
    if (key === activeKey) {
      navigate(`/`, { state: state ? state : TAB_KEYS.aboutUs });
    } else {
      navigate(`/${key}`, { state: state ? state : TAB_KEYS.aboutUs });
    }
    // if (key) {
    //   navigate(`/${key}`, { state: state ? state : TAB_KEYS.aboutUs });
    // }
  };

  const handleHelpClick = () => {
    if (localStorage.getItem("tab")) {
      localStorage.setItem("page", "help");
      navigate("/help");
    }
  };

  const handleCancel = () => {
    // setSwitchState(data.published)
    setSwitchState(microSites[0].isPublished);
    setPublishConfirmation(false);
    setUnPublishConfirmation(false);
  };

  const handleOk = () => {
    publishHandler(true);
  };

  const unPublishOk = () => {
    publishHandler(false);
  };

  const publishHandler = (value) => {
    if (value === true) {
      publishMicroSite(data.id, "published");
      setPublishConfirmation(false);
      if (localStorage.getItem("switchSuccess")) {
        setSwitchState(true);
      } else {
        setSwitchState(false);
      }
      setTimeout(() => {
        localStorage.removeItem("switchSuccess");
      }, 2000);
      // localStorage.setItem("switch", true)
    } else {
      publishMicroSite(data.id, "unpublished");
      setUnPublishConfirmation(false);
      if (localStorage.getItem("switchSuccess")) {
        setSwitchState(false);
      } else {
        setSwitchState(true);
      }
      setTimeout(() => {
        localStorage.removeItem("switchSuccess");
      }, 2000);
      // setSwitchState(false);
      // localStorage.setItem("switch", false)
    }
  };

  const closeMicrositeModal = () => {
    setMicrositeModal(false);
  };

  const [settingsModal, setSettingsModal] = useState(false);
  const handleSettings = () => {
    setSettingsModal(false);
  }

  return (
    <div className="ms-acc-main">
      {/* <div>
               
            </div> */}
      <div className="ms-accordian">
        {microSiteActions &&
          microSiteActions[data.id] &&
          microSiteActions[data.id]["edit"] &&
          microSiteActions[data.id]["edit"] ? (
          <div className="microsite-edit-header">
            <MicrositeEdit
              page={"home"}
              data={data}
              closeSettings={() => handleMenus(data.id, "edit", false)}
            />
          </div>
        ) : (
          <div className="microsite-header">
            <div className="microsite-header-first">
              {micrositeData && micrositeData.length >= 1 ? (
                <div className="header-handler">
                  {data.id === activeKey ? (
                    <img
                      className="arrows"
                      src={downArrow}
                      onClick={() => redirectMicroSite(data.id)}
                    />
                  ) : (
                    <img
                      className="arrows"
                      src={upArrow}
                      onClick={() => redirectMicroSite(data.id)}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}

              <div>
                {/* <Avatar src={data.mediaLink} /> */}
                <img
                  className="org-admin-logo"
                  style={{ backgroundColor: "#fff" }}
                  src={data.mediaLink}
                  size={10}
                />
              </div>
              <div className="microsite-header-first-title">
                {data.name}
                <span className="title-edit">
                  <a
                    style={{ fontSize: "12px", textDecoration: "underline" }}
                    onClick={() => handleMenus(data.id, "edit", true)}
                  >
                    Edit name & logo
                  </a>
                  {/* <img src={pencilIcon} onClick={() => handleMenus(data.id, 'edit', true)} /> */}
                </span>
              </div>
            </div>
            <div className="microsite-header-last">
              <div className="microsite-header-last-sub">
                {micrositeId === data.id ? (
                  <div
                    className="microsite-header-last-sub-sec"
                    onClick={handleHelpClick}
                  //  onClick={() => handleMenus(data.id, "preview", true)}
                  >
                    <img src={helpIcon} style={{ marginRight: "10px" }} />
                    <div className="microsite-header-last-sub-sec-label" style={{ marginRight: "10px" }}>
                      Help
                    </div>
                  </div>
                ) : null}
                {micrositeId === data.id ? (
                  data.isPublished ? (
                    <div
                      className="microsite-header-last-sub-sec"
                      onClick={() => {
                        // getMicrositeAnalytics(data.id, data.name);
                        // setMicrositeModal(true);
                        navigate(`/microsite-analytics/${data.id}`, { state: { micrositeName: data.name, page: "home", orgId: "" } })
                      }}
                    >
                      <img src={analyticsIcon} />
                      <div className="microsite-header-last-sub-sec-label">
                        Engagement
                      </div>
                    </div>
                  ) : (
                    <Tooltip
                      title={
                        "Microsite should be published atleast once to view engagement data"
                      }
                    >
                      <div
                        className="microsite-header-last-sub-sec"
                        style={{ opacity: "0.5", cursor: "not-allowed" }}
                      >
                        <img src={analyticsIcon} />
                        <div className="microsite-header-last-sub-sec-label">
                          Engagement
                        </div>
                      </div>
                    </Tooltip>
                  )
                ) : null}
                <div
                  className="microsite-header-last-sub-sec"
                  // onClick={() => handleMenus(data.id, "settings", true)}
                  onClick={() => setSettingsModal(true)}
                // style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={settingIcon} />
                  <div
                    className="microsite-header-last-sub-sec-label"
                    style={{
                      width: 60,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Pro Settings
                  </div>
                </div>
                <div
                  className="microsite-header-last-sub-sec"
                  onClick={() => handleMenus(data.id, "preview", true)}
                >
                  <img src={previewIcon} />
                  <div className="microsite-header-last-sub-sec-label">
                    Preview
                  </div>
                </div>
                <div className="microsite-header-last-sub-sec switch-publish">
                  <Switch
                    checked={switchState}
                    defaultChecked={microSites[0].isPublished}
                    checkedChildren="Online"
                    unCheckedChildren="Offline"
                    // onChange={(value) => publishHandler(value)}
                    onChange={(e) => {
                      if (e) {
                        setPublishConfirmation(true);
                      } else {
                        setUnPublishConfirmation(true);
                      }
                    }}
                  />
                </div>
                {/* <div className="microsite-header-last-sub-sec" onClick={() => handleMenus(data.id, 'edit', true)}>
                                        <img src={editIcon} />
                                        <div className="microsite-header-last-sub-sec-label">Edit</div>

                                    </div> */}
              </div>
            </div>
          </div>
        )}
        {activeKey === data.id ? (
          <div className="ms-accordian-content">{children}</div>
        ) : (
          <></>
        )}
        {/* {microSiteActions &&
          microSiteActions[data.id] &&
          microSiteActions[data.id]["settings"] &&
          microSiteActions[data.id]["settings"] ? (
          <MicrositeSettings
            activeKey={data.id}
            // closeSettings={() => handleMenus(data.id, "settings", false)}
            closeSettings={() => handleSettings()}
          />
        ) : (
          <></>
        )} */}
        {settingsModal ? (
          <>
            {/* <MicrositeSettings
              activeKey={data.id}
              open={settingsModal}
              closeSettings={() => handleSettings()}
            /> */}
            <MicrositeSettingsCopy
              activeKey={data.id}
              open={settingsModal}
              closeSettings={() => handleSettings()}
            />
          </>
        ) : (
          <></>
        )}
        {microSiteActions &&
          microSiteActions[data.id] &&
          microSiteActions[data.id]["preview"] &&
          microSiteActions[data.id]["preview"] ? (
          <Preview
            micrositeId={data.id}
            siteSetting={settings}
            closeSettings={() => handleMenus(data.id, "preview", false)}
            data={data}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal
        className="confirmation-modal"
        open={publishConfirmation}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        closable={false}
        footer={null}
      // footer={[
      //   <Button key="back" onClick={handleOk}>
      //     Publish
      //   </Button>,
      //   <Button type="primary" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      // ]}
      >
        <div className="message">Are you sure you want to go online?</div>
        <div className="modalBtns">
          <Button className="cancel-btn" type="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="confirm-btn" key="back" onClick={handleOk}>
            Publish
          </Button>
        </div>
      </Modal>
      <Modal
        className="confirmation-modal"
        open={unPublishConfirmation}
        onOk={unPublishOk}
        destroyOnClose={true}
        closable={false}
        onCancel={handleCancel}
        footer={null}
      // footer={[
      //   <Button key="back" onClick={unPublishOk}>
      //     Unpublish
      //   </Button>,
      //   <Button type="primary" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      // ]}
      >
        <div className="message">Are you sure you want to go offline?</div>
        <div className="modalBtns">
          <Button className="cancel-btn" key="back" onClick={unPublishOk}>
            Unpublish
          </Button>
          <Button className="confirm-btn" type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}
