import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Popover, Modal, Form, Input, Button, Divider } from "antd";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../../api/http";
import userServiceApi from "../../../api/userServiceApi";
import "./index.scss";
import UserIcon from "../../../assets/images/icons/userLogo.svg";
import Leftarrow from "../../../assets/images/LeftArrow.svg";
import logoutIcon from "../../../assets/images/logout-icon.svg";
import analyticsIcon from "../../../assets/images/analytics.svg";
import helpIcon from "../../../assets/images/helpIcon.svg";
import feedbackIcon from "../../../assets/images/feedback-icon.svg";
import changePwdIcon from "../../../assets/images/change-pwd-icon.svg";
import profileIcon from "../../../assets/images/profile-icon.svg";
import orgDetailsIcon from "../../../assets/images/details-icon.svg";
import manageMicrositeIcon from "../../../assets/images/ManageMicrosite.svg";
import referIcon from "../../../assets/images/Refer.svg";

function MicroSites() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isOrgPending, setIsOrgPending] = useState();
  const [partnerData, setPartnerData] = useState({});
  const partnerID = localStorage.getItem("partnerID");
  const [referModal, setReferModal] = useState(false);
  const micrositeId = localStorage.getItem("micrositeId");
  const tab = localStorage.getItem("tab");
  const token = localStorage.getItem("token");

  const tabName = (tab) => {
    if (tab === "CAROUSEL") {
      return "corousel";
    } else if (tab === "PRODUCT") {
      return "product";
    } else if (tab === "SERVICE") {
      return "service";
    } else if (tab === "CONTRACT") {
      return "contract";
    } else if (tab === "APPRENTICESHIP") {
      return "PART_TIME";
    } else if (tab === "VACANCY") {
      return "FULL_TIME";
    } else if (tab === "COURSES") {
      return "courses";
    } else if (tab === "WHY_WORK") {
      return "why-work";
    } else if (tab === "TEAMS_AND_ROLES") {
      return "teams-and-roles";
    } else if (tab === "WORK_EXPERIENCE") {
      return "work-experience";
    } else {
      return tab;
    }
  };

  const orgImg = localStorage.getItem("OrgImage");

  // const orgName = localStorage.getItem("OrgNames");
  const [orgNames, setOrgNames] = useState("");

  const referOrganisation = async (value) => {
    try {
      const response = await postRequest(
        userServiceApi.referOrganisation(partnerID, value.email)
      );
      if (response) {
        form.resetFields();
        toast.success("Email sent");
        setReferModal(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // useEffect(() => {
  //   setOrgNames(orgName)
  //   console.log("orglocal", orgName);
  // },[orgName]);

  const fetchOrganization = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await getRequest(
        userServiceApi.getSingleOrganization(),
        { headers }
      );

      if (response && response.data) {
        setOrgNames(response.data.name);
        setIsOrgPending(response.data.isOrganizationProfilePending);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrganization();
    }
  }, []);

  const showProfile = () => {
    setIsShowProfile(!isShowProfile);
  };

  const LogoutHandler = () => {
    // const partner = localStorage.getItem("partner");
    // navigate(`/login/${partner.replace(/ /g, "_")}`);
    navigate(`/login/${partnerID}`);

    localStorage.clear();
    // window.location.reload();
  };

  const getPartnerDetailsData = async (partnerID) => {
    // console.log("APICall entry")
    try {
      // setLoading(true);
      const response = await getRequest(
        userServiceApi.partnerDetails(partnerID)
      );
      if (response && response.data) {
        setPartnerData(response.data);
      }
    } catch (error) {
      // setLoading(false);
      toast.error(error.error);
    }
  };
  useEffect(() => {
    if (partnerID) {
      getPartnerDetailsData(partnerID);
    }
  }, []);

  return (
    <div className="headerBannerRow fixedRow">
      <div className="headerBannerCol">
        <div className="header-logo">
          {localStorage.getItem("page") === "personalDetails" ||
            localStorage.getItem("page") === "organisationDetails" ||
            localStorage.getItem("page") === "configurations" ? (
            <div className="back-btn-header">
              <img
                style={{ cursor: "pointer" }}
                src={Leftarrow}
                onClick={() => {
                  // navigate(`/${micrositeId}`, { state: `${tabName(tab)}` });
                  navigate(`/`);
                  localStorage.removeItem("getAllHelpState");
                }}
              />
            </div>
          ) : null}

          {localStorage.getItem("page") === "configurations" ||
            localStorage.getItem("page") === "help" ? (
            <img
              className="partner-logo"
              style={{ cursor: "pointer" }}
              src={partnerData.mediaLink}
              alt=""
              onClick={() => {
                navigate(`/`);
                localStorage.removeItem("getAllHelpState");
              }}
            />
          ) : (
            <img
              className="partner-logo"
              style={{ cursor: "pointer" }}
              src={partnerData.mediaLink}
              alt=""
              onClick={() => {
                if (isOrgPending) {
                  // console.log("here if")
                  toast.error("Please complete updating your details");
                } else {
                  localStorage.removeItem("tab");
                  localStorage.removeItem("getAllHelpState");
                  navigate("/");
                  localStorage.setItem("page", "home");
                }
              }}
            />
          )}

          <div className="siteName-container">
            <label>{partnerData.siteName}</label>
          </div>
        </div>

        <div className="org-container">
          <div className="org-text">
            <label className="org-name">{orgNames}</label>
            <label className="admin-label">Organisation Admin</label>
          </div>
          <Popover
            content={
              <div className="popover-content">
                {/* <div className="profile-email">
                  <p>{localStorage.getItem("OrgEmail")}</p>
                </div> */}
                <div className="dropdown-details">
                  {/* <Link
                    to={`/edit-organization`}
                    state={{ tab: "personalDetailsTab" }}
                    onClick={() => {
                      setIsShowProfile(false);
                      localStorage.setItem("page", "personalDetails");
                    }}
                  >
                    <h3>Personal Details</h3>
                  </Link> */}
                  <div className="popover-single-content" onClick={() => {
                    setIsShowProfile(false);
                    navigate('/edit-organization', { state: { tab: "personalDetailsTab" } });
                    localStorage.setItem("page", "personalDetails");
                  }}>
                    <img src={profileIcon} alt="" />
                    <h3>Personal Details</h3>
                  </div>
                  <Divider />
                  {/* <Link
                    to={`/edit-organization`}
                    state={{ tab: "organizationDetailsTab" }}
                    onClick={() => {
                      setIsShowProfile(false);
                      localStorage.setItem("page", "organisationDetails");
                    }}
                  >
                    <h3>Organisation Details</h3>
                  </Link> */}
                  <div className="popover-single-content" onClick={() => {
                    setIsShowProfile(false);
                    // sessionStorage.setItem("page", "feedback");
                    navigate('/edit-organization', { state: { tab: "organizationDetailsTab" } });
                    localStorage.setItem("page", "organisationDetails");
                  }}>
                    <img src={orgDetailsIcon} alt="" />
                    <h3>Organisation Details</h3>
                  </div>
                  <Divider />
                  {!isOrgPending && (
                    // <Link
                    //   to="/manageMicrosites"
                    //   onClick={() => {
                    //     setIsShowProfile(false);
                    //     localStorage.setItem("page", "manageMicrosite");
                    //   }}
                    // >
                    //   <h3>Manage Microsites</h3>{" "}
                    // </Link>
                    <>
                      <div className="popover-single-content" onClick={() => {
                        setIsShowProfile(false);
                        localStorage.setItem("page", "manageMicrosite");
                        navigate('/manageMicrosites');
                      }}>
                        <img src={manageMicrositeIcon} alt="" />
                        <h3>Manage Microsites</h3>
                      </div>
                      <Divider />
                    </>
                  )}
                  {!isOrgPending && (
                    // <div
                    //   onClick={() => {
                    //     setIsShowProfile(false);
                    //     setReferModal(true);
                    //     form.resetFields();
                    //   }}
                    // >
                    //   <h3>Refer a friend</h3>
                    // </div>
                    <>
                      <div className="popover-single-content" onClick={() => {
                        setIsShowProfile(false);
                        setReferModal(true);
                        form.resetFields();
                      }}>
                        <img src={referIcon} alt="" />
                        <h3>Refer a Friend</h3>
                      </div>
                      <Divider />
                    </>
                  )}
                  {!isOrgPending && (
                    // <Link
                    //   to="/change-password"
                    //   onClick={() => {
                    //     setIsShowProfile(false);
                    //     localStorage.setItem("page", "changePassword");
                    //   }}
                    // >
                    //   <h3>Change Password</h3>{" "}
                    // </Link>
                    <>
                      <div className="popover-single-content" onClick={() => {
                        setIsShowProfile(false);
                        localStorage.setItem("page", "changePassword");
                        navigate('/change-password')
                      }}>
                        <img src={changePwdIcon} alt="" />
                        <h3>Change Password</h3>
                      </div>
                      <Divider />
                    </>

                  )}
                  {/* <Link
                    to="/org-analytics"
                    onClick={() => {
                      setIsShowProfile(false);
                      localStorage.setItem("page", "analytics");
                    }}
                  >
                    <h3>Organisation analytics</h3>{" "}
                  </Link> */}
                  <div className="popover-single-content" onClick={() => {
                    setIsShowProfile(false);
                    navigate('/org-analytics');
                    localStorage.setItem("page", "analytics");
                  }}>
                    <img src={analyticsIcon} alt="" />
                    <h3>Engagement</h3>
                  </div>
                  <Divider />
                  {/* <Link
                    to="/help"
                    onClick={() => {
                      setIsShowProfile(false);
                      localStorage.removeItem("tab");
                      localStorage.setItem("getAllHelpState", true);
                      localStorage.setItem("page", "help");
                    }}
                  >
                    <h3>Help</h3>
                  </Link> */}
                  <div className="popover-single-content" onClick={() => {
                    setIsShowProfile(false);
                    localStorage.removeItem("tab");
                    localStorage.setItem("getAllHelpState", true);
                    localStorage.setItem("page", "help");
                    navigate('/help');
                  }}>
                    <img src={helpIcon} alt="" />
                    <h3>Help</h3>
                  </div>
                  <Divider />
                  {/* <Link
                    to="/feedback"
                    onClick={() => {
                      setIsShowProfile(false);
                      localStorage.setItem("page", "feedback");
                    }}
                  >
                    <h3>Support & Feedback</h3>{" "}
                  </Link> */}
                  <div className="popover-single-content" onClick={() => {
                    setIsShowProfile(false);
                    localStorage.setItem("page", "feedback");
                    navigate('/feedback')
                  }}>
                    <img src={feedbackIcon} alt="" />
                    <h3>Support & Feedback</h3>
                  </div>
                  <Divider />
                  <div className="popover-single-content" onClick={LogoutHandler}>
                    <img src={logoutIcon} alt="" />
                    <h3>Logout</h3>{" "}
                  </div>
                </div>
              </div>
            }
            // title="Click title"
            trigger="click"
            open={isShowProfile}
            onOpenChange={showProfile}
            placement="bottomRight"
          >
            {orgImg ? (
              <img
                className="org-admin-logo"
                style={{ cursor: "pointer", backgroundColor: "#fff" }}
                onClick={() => showProfile()}
                src={localStorage.getItem("OrgImage")}
                size={50}
              />
            ) : (
              <Avatar
                className="headerAv"
                src={UserIcon}
                style={{ cursor: "pointer", backgroundColor: "#fff" }}
                onClick={() => showProfile()}
                size={50}
              />
            )}
          </Popover>
        </div>

        {/* {isShowProfile ?  : null} */}
      </div >
      <Modal
        className="refer-modal"
        open={referModal}
        width={600}
        footer={null}
        onCancel={() => setReferModal(false)}
      >
        <div style={{ fontSize: "17px", fontWeight: "bold" }}>
          Refer organisation to a friend
        </div>
        <Form form={form} onFinish={referOrganisation} autoComplete="off">
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Enter valid email" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                label="Cancel"
                onClick={() => setReferModal(false)}
                style={{ marginRight: "5px", marginLeft: "-10px" }}
                type="button"
                className="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                label="Submit"
                className="greenBtn"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div >
  );
}
export default MicroSites;
