import moment from "moment";
import { contactUSkeys, TAB_KEYS, vacancyKeys, contract, aboutUSkeys } from "../config";

export const formatData = (tabKey, values, updatedValues) => {
  values =
    values?.length > 0
      ? values?.filter(function (entry) {
        return entry === "" ? entry.trim() != "" : entry;
      })
      : [];

  if (vacancyKeys.includes(tabKey) && values.length > 0) {
    if (Object.keys(values[0]).length >= 2) {
      values = values;
    } else {
      values = [];
    }
  }

  if (values && values.length > 0) {
    values = values.filter(function (element) {
      return element !== undefined;
    });

    switch (tabKey) {
      case TAB_KEYS.corousel:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.product:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.service:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.contract:
        return getContractTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.workExperience:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.apprenticeship:
        return getVacancyTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.vacancy:
        return getVacancyTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.whyWorkForUs:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.teamsAndRoles:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.courses:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.businessCard:
        return getTempValues(tabKey, values, updatedValues);
      case TAB_KEYS.news:
        return getNewsTempValues(tabKey, values, updatedValues);
      case contactUSkeys.sales:
        return getContactUsTempValues(tabKey, values, updatedValues);
      case contactUSkeys.hr:
        return getContactUsTempValues(tabKey, values, updatedValues);
      case contactUSkeys.eow:
        return getContactUsTempValues(tabKey, values, updatedValues);
      case contactUSkeys.socialMedia:
        return getContactUsSocialMediaTempValues(tabKey, values, updatedValues);
      case contactUSkeys.address:
        return getContactUsAddressTempValues(tabKey, values, updatedValues);
      case aboutUSkeys.About:
        return getTempValues(tabKey, values, updatedValues, aboutUSkeys.About);
      case aboutUSkeys["Our Story"]:
        return getTempValues(tabKey, values, updatedValues, aboutUSkeys["Our Story"]);
      case aboutUSkeys["Our Team"]:
        return getOurTeamTempValues(tabKey, values, updatedValues, aboutUSkeys["Our Team"]);
      case aboutUSkeys["Our Client"]:
        return getOurTeamTempValues(tabKey, values, updatedValues, aboutUSkeys["Our Client"]);
      case aboutUSkeys.Accreditation:
        return getAccreditationTempValues(tabKey, values, updatedValues);
      default:
        return {};
    }
  } else {
    return {};
  }
};

const getSortedData = (modifiedValues, updatedDataIds, tabKey) => {
  var items = {};

  if (modifiedValues && modifiedValues.length > 0) {
    const freshItems =
      modifiedValues.filter((d) =>
        vacancyKeys.includes(tabKey) || contract === tabKey ? d.id : d.sectionBlockId
      ).length > 0
        ? []
        : modifiedValues;
    if (freshItems.length > 0) {
      items["newData"] = vacancyKeys.includes(tabKey)
        ? getVacancyFormData(freshItems)
        : contract === tabKey ? getContractFormData(freshItems) : getFormData(freshItems);
    } else {
      const modify = modifiedValues.filter(
        (d) => !(vacancyKeys.includes(tabKey) || contract === tabKey ? d.id : d.sectionBlockId)
      );
      if (modify && modify.length > 0) {
        items["extraData"] = vacancyKeys.includes(tabKey)
          ? getVacancyFormData(modify)
          : contract === tabKey ? getContractFormData(modify) : getFormData(modify);
      }
      const tempD = [];
      modifiedValues.forEach((d, i) => {
        if (
          updatedDataIds &&
          updatedDataIds.includes(i) &&
          (vacancyKeys.includes(tabKey) || contract === tabKey ? d.id : d.sectionBlockId)
        ) {
          tempD.push(d);
        }
      });
      if (tempD.length > 0) {
        items["editedData"] = vacancyKeys.includes(tabKey)
          ? getVacancyFormData(tempD)
          : contract === tabKey
            ? getContractFormData(tempD)
            : getFormData(tempD);
      }
    }
  }
  return items;
};

const getFormData = (data) => {
  var formData = new FormData();
  const modifiedData = data.map((d) => {
    if (d.file) {
      formData.append("medias", d.file);
      delete d.file;
    }
    if (d.BrochureFile) {
      formData.append("file", d.BrochureFile);
      delete d.BrochureFile;
    }
    return d;
  });
  const json = JSON.stringify(modifiedData);
  const blob = new Blob([json], {
    type: "application/json",
  });

  formData.append("micrositeDetailsInputs", blob);

  return formData;
};


const formatAboutUs = (data) => {
  var formData = new FormData();
  const mediaMapping = { OUR_TEAM: 'images', OUR_CLIENT: 'images', ACCREDITATIONS_AWARDS: 'awardLogos' }
  const modifiedData = data.map((d) => {
    if (d.blob) {
      formData.append(mediaMapping[d.aboutUsEnum], d.blob);
      delete d.blob;
    }
    return d;
  });
  const json = JSON.stringify(modifiedData);
  const blob = new Blob([json], {
    type: "application/json",
  });
  formData.append("micrositeDetailsInputs", blob);
  return formData;
};

const getVacancyFormData = (data) => {
  var formData = new FormData();
  const modifiedData = data.map((d) => {
    if (d.file) {
      formData.append("files", d.file);
      delete d.file;
    }
    return d;
  });
  const json = JSON.stringify(modifiedData);
  const blob = new Blob([json], {
    type: "application/json",
  });

  formData.append("vacancyDtoList", blob);

  return formData;
};

const getContractFormData = (data) => {
  var formData = new FormData();
  const modifiedData = data.map((d) => {
    if (d.file) {
      formData.append("files", d.file);
      delete d.file;
    }
    return d;
  });
  const json = JSON.stringify(modifiedData);
  const blob = new Blob([json], {
    type: "application/json",
  });

  formData.append("contractsTabInputs", blob);

  return formData;
};

const getTempValues = (tabKey, values, updatedValues, aboutUsEnum) => {
  const updatedDataIds = updatedValues[tabKey];

  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = {};
        if (d && d.id) {
          temp.sectionBlockId = d.id;
        }
        temp.aboutUsEnum = aboutUsEnum
        temp.title = d.title ? d.title : "";
        if (d && d.assetTitleId) {
          temp.assetTitleId = d.assetTitleId ? d.assetTitleId : "titleID";
        }
        if (d && d.subTitle) {
          temp.subTitle = d.subTitle;
        }
        if (d && d.urlLink) {
          temp.urlLink = d.urlLink;
        }
        temp.description = d.description ? d.description : "";
        if (tabKey !== "businessCard") {
          temp.type = tabKey;
        }
        if (d && d.BrochureFile) {
          temp["BrochureFile"] = d.BrochureFile;
        }
        if (d && d.blob) {
          temp["file"] = d.blob;
        }
        if (d && d.mediaLink) {
          const mediaEx = d.mediaLink;
          if (mediaEx.mediaType === "IMAGE" && mediaEx.mediaProperty) {
            temp.media = {
              mediaProperty: mediaEx.mediaProperty,
              mediaType: "IMAGE",
            };
          } else if (mediaEx.mediaType === "SOCIAL_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "SOCIAL_MEDIA",
              url: mediaEx.url,
            };
          } else if (mediaEx.mediaType === "VIMEO_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "VIMEO_MEDIA",
              url: mediaEx.url,
            };
          }
        }
        temp.levelId = d.levelId;

        return temp;
      }
    });

    return getSortedData(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
};

const getOurTeamTempValues = (tabKey, values, updatedValues, aboutUsEnum) => {
  const updatedDataIds = updatedValues[tabKey];

  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = {
          "aboutUsEnum": "OUR_TEAM",
          "aboutUsTeamDataInput": {
            "image": {
              "mediaProperty": {
                "id": "",
                "name": "",
                "extension": ""
              },
              "mediaType": ""
            },
            "bio": ""
          }
        }
        if (d && d.id) {
          temp.sectionBlockId = d.id;
        }
        temp["aboutUsEnum"] = aboutUsEnum
        if (d.blob) {
          temp["blob"] = d.blob
        }
        temp.aboutUsTeamDataInput.bio = d.bio
        temp.aboutUsTeamDataInput.image = d.awardsLogos
        temp.aboutUsTeamDataInput.linkedin = d.linkedin
        temp.aboutUsTeamDataInput.email = d.email

        return temp;
      }
    });
    return getSortedDataForAboutUs(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
}

const getAccreditationTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];

  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = { ...d };
        if (d && d.id) {
          temp.sectionBlockId = d.id;
        }
        temp.aboutUsEnum = "ACCREDITATIONS_AWARDS"
        if (d.blob) {
          temp["blob"] = d.blob
        }
        if (temp.awardsLogos && !temp.awardsLogos.mediaProperty?.name) {
          delete temp.awardsLogos;
        }
        return temp;
      }
    });
    return getSortedDataForAboutUs(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
};

const getNewsTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];


  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = {};
        if (d && d.id) {
          temp.sectionBlockId = d.id;
        }
        temp.title = d.title ? d.title : "";
        if (d && d.assetTitleId) {
          temp.assetTitleId = d.assetTitleId ? d.assetTitleId : "titleID";
        }
        if (d && d.urlLink) {
          temp.urlLink = d.urlLink;
        }
        temp.description = d.description ? d.description : "";
        if (tabKey !== "businessCard") {
          temp.type = tabKey;
        }
        if (d && d.blob) {
          temp["file"] = d.blob;
        }
        if (d && d.mediaLink) {
          const mediaEx = d.mediaLink;
          if (mediaEx.mediaType === "IMAGE" && mediaEx.mediaProperty) {
            temp.media = {
              mediaProperty: mediaEx.mediaProperty,
              mediaType: "IMAGE",
            };
          } else if (mediaEx.mediaType === "SOCIAL_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "SOCIAL_MEDIA",
              url: mediaEx.url,
            };
          } else if (mediaEx.mediaType === "VIMEO_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "VIMEO_MEDIA",
              url: mediaEx.url,
            };
          }
        }
        temp.levelId = d.levelId;
        return temp;
      }
    });

    return getSortedData(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
};

const getVacancyTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];
  const descriptionItems = [TAB_KEYS.apprenticeship, TAB_KEYS.vacancy];

  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = {};
        if (d && d.id) {
          temp.id = d.id;
        }
        temp.apprenticeshipType = tabKey;
        temp.title = d.title;
        temp.jobTypeEnum = d.jobTypeEnum;
        temp.compensationType = "ANNUALLY";
        temp.compensation = d.compensation;
        temp.closingDate = moment(new Date(d.closingDate)).format("YYYY-MM-DD");
        temp.description = descriptionItems.includes(tabKey)
          ? d.description
          : "description";
        // temp.type = tabKey
        temp.skillIds = d.skillIds;
        temp.urlLink = d.urlLink;

        if (d && d.blob) {
          temp["file"] = d.blob;
        }
        if (d && d.mediaLink) {
          const mediaEx = d.mediaLink;
          if (mediaEx.mediaType === "IMAGE" && mediaEx.mediaProperty) {
            temp.media = {
              mediaProperty: mediaEx.mediaProperty,
              mediaType: "IMAGE",
            };
          } else if (mediaEx.mediaType === "SOCIAL_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "SOCIAL_MEDIA",
              url: mediaEx.url,
            };
          } else if (mediaEx.mediaType === "VIMEO_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "VIMEO_MEDIA",
              url: mediaEx.url,
            };
          }
        }
        temp.levelId = d.levelId;

        return temp;
      }
    });

    return getSortedData(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
};

const getContractTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];

  if (values && values.length > 0) {
    const modifiedValues = values.map((d, i) => {
      if (d) {
        var temp = {};
        if (d && d.id) {
          temp.id = d.id;
        }
        temp.title = d.title;
        temp.procurementReference = d.procurementReference;
        temp.postalCode = d.postalCode;
        temp.valueOfContract = d.valueOfContract;
        temp.description = d.description;
        if (d.closeTime) {
          temp.closeDate = moment(new Date(d.closeDate)).format("YYYY-MM-DD");
        }
        if (d.startDate) {
          temp.startDate = moment(new Date(d.startDate)).format("YYYY-MM-DD");
        }
        if (d.endDate) {
          temp.endDate = moment(new Date(d.endDate)).format("YYYY-MM-DD");
        }
        if (d.closeTime) {
          temp.closeTime = moment(new Date(d.closeTime)).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          );
        }
        temp.contractType = d.contractType;
        temp.webLink = d.webLink;
        temp.email = temp.email;

        if (d && d.blob) {
          temp["file"] = d.blob;
        }
        if (d && d.mediaLink) {
          const mediaEx = d.mediaLink;
          if (mediaEx.mediaType === "IMAGE" && mediaEx.mediaProperty) {
            temp.media = {
              mediaProperty: mediaEx.mediaProperty,
              mediaType: "IMAGE",
            };
          } else if (mediaEx.mediaType === "SOCIAL_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "SOCIAL_MEDIA",
              url: mediaEx.url,
            };
          } else if (mediaEx.mediaType === "VIMEO_MEDIA" && mediaEx.url) {
            temp.media = {
              mediaType: "VIMEO_MEDIA",
              url: mediaEx.url,
            };
          }
        }

        return temp;
      }
    });

    return getSortedData(modifiedValues, updatedDataIds, tabKey);
  } else {
    return {};
  }
};

const getContactUsTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];
  const modifiedValues = values.map((d, i) => {
    if (d) {
      var temp = {};
      if (d && d.id) {
        temp.sectionBlockId = d.id;
      }
      // values.address.replace(/(\r\n|\n|\r)/g, ' ');
      temp.contactUsId = d.id
      temp.contactUsTypeEnum = tabKey;
      temp.phone = d.phone;
      temp.chatNumber = d.chatNumber;
      temp.websiteLink = d.websiteLink;
      // temp.postalAddress = d?.postalAddress?.replace(/(\r\n|\n|\r)/g, " ") || '';
      temp.email = d.email;
      return temp;
    }
  });

  return getSortedDataForContactUs(modifiedValues, updatedDataIds, tabKey);
};

const getContactUsSocialMediaTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];

  const modifiedValues = values.map((d, i) => {
    if (d) {
      var temp = {};
      if (d && d.id) {
        temp.sectionBlockId = d.id;
      }
      temp.contactUsId = d.id
      temp.contactUsTypeEnum = tabKey;
      temp.linkedInLink = d.linkedInLink;
      temp.youtubeLink = d.youtubeLink;
      temp.instagramLink = d.instagramLink;
      temp.facebookLink = d.facebookLink;
      temp.tiktokLink = d.tiktokLink;
      temp.twitterLink = d.twitterLink;
      return temp;
    }
  });

  return getSortedDataForContactUs(modifiedValues, updatedDataIds, tabKey);
};
const getContactUsAddressTempValues = (tabKey, values, updatedValues) => {
  const updatedDataIds = updatedValues[tabKey];

  const modifiedValues = values.map((d, i) => {
    if (d) {
      var temp = {};
      if (d && d.id) {
        temp.sectionBlockId = d.id;
      }
      temp.contactUsTypeEnum = tabKey;
      temp.addressInputs = [
        {
          "street": d.street,
          "postalCode": d.postalCode
        }
      ]
      return temp;
    }
  });
  return getSortedDataForContactUsAddress(modifiedValues, updatedDataIds, tabKey);
};

const getSortedDataForContactUs = (modifiedValues, updatedDataIds, tabKey) => {
  var items = {};

  if (modifiedValues && modifiedValues.length > 0) {
    const freshItems =
      modifiedValues.filter((d) => d.sectionBlockId).length > 0
        ? []
        : modifiedValues;
    if (freshItems.length > 0) {
      items["newData"] = freshItems;
    } else {
      const modify = modifiedValues.filter((d) => !d.sectionBlockId);
      if (modify && modify.length > 0) {
        items["extraData"] = modify;
      }
      const tempD = [];
      modifiedValues.forEach((d, i) => {
        if (updatedDataIds && updatedDataIds.includes(i) && d.sectionBlockId) {
          tempD.push(d);
        }
      });
      if (tempD.length > 0) {
        items["editedData"] = tempD;
      }
    }
  }
  return items;
};

const getSortedDataForContactUsAddress = (modifiedValues, updatedDataIds, tabKey) => {
  var items = { newData: [], editedData: [] };

  modifiedValues.forEach((ele, i) => {
    if (!ele.sectionBlockId) {
      items['newData'].push({
        contactUsTypeEnum: ele.contactUsTypeEnum,
        addressInputs: ele.addressInputs
      })
    }
    if (ele.sectionBlockId && updatedDataIds && updatedDataIds.length > 0 && updatedDataIds.includes(i)) {
      items['editedData'].push({
        contactUsId: ele.sectionBlockId,
        contactUsTypeEnum: ele.contactUsTypeEnum,
        addressInputs: ele.addressInputs
      })
    }
  })

  if (items.newData.length === 0) {
    delete items.newData
  }
  if (items.editedData.length === 0) {
    delete items.editedData
  }
  return items;
};

const getSortedDataForAboutUs = (modifiedValues, updatedDataIds, tabKey) => {
  var items = { newData: [], editedData: [] };

  modifiedValues.forEach((ele, i) => {
    if (!ele.sectionBlockId) {
      items['newData'].push(ele)
    }
    if (ele.sectionBlockId && updatedDataIds && updatedDataIds.length > 0 && updatedDataIds.includes(i)) {
      items['editedData'].push(ele)
    }
  })
  if (items.newData.length === 0) {
    delete items.newData
  }
  if (items.editedData.length === 0) {
    delete items.editedData
  }

  if (items['newData'])
    items['newData'] = formatAboutUs(items['newData'])

  if (items['editedData'])
    items['editedData'] = formatAboutUs(items['editedData'])


  return items;
};