import React, { useState, useRef } from "react";
import { Button, Form, Input, Divider, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useStore } from "../../../store";
import { useTab } from "../../../custom-hooks/useTab";
import { deleteRequest, putRequest } from "../../../api/http";
import userServiceApi from "../../../api/userServiceApi";
import { setProductGroups } from "../../../store/actions";
import { toast } from "react-toastify";

export default function ProductsConfigurations({ micrositeId }) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const { product_groups, dispatch } = useStore();
  const { getOptions } = useTab();

  const [newProducts, setNewProducts] = useState([]);
  const [editID, setEditID] = useState();
  // const [itemVal, setItemVal] = useState('');

  const handleAdd = ({ itemField }) => {
    if (itemField.trim().length) {
      setNewProducts([...newProducts, itemField]);
      inputRef.current.value = "";
      inputRef.current.focus();
      form.resetFields();
    }
  };

  const handleRemove = (tagToRemove) => {
    setNewProducts(newProducts.filter((tag) => tag !== tagToRemove));
  };

  const handleSave = async () => {
    const moddedArray = newProducts.map((item) => {
      return {
        title: item,
      };
    });
    try {
      const res = await putRequest(
        userServiceApi.saveTeamsCoursesProducts(micrositeId, "PRODUCT"),
        moddedArray
      );
      if (res) {
        toast.success("New products added");
        setNewProducts([]);
        dispatch(setProductGroups(res));
      }
      // console.log("save", res);
    } catch (error) { }
  };

  const handleDelete = async (product) => {
    try {
      const res = await deleteRequest(
        userServiceApi.deleteTeamsCoursesProduct(product.id)
      );
      if (res) {
        toast.success("Product deleted successfully");
        getOptions(micrositeId, "PRODUCT");
      }
    } catch (error) { }
  };

  const handleEdit = async (value) => {
    // console.log("edited", value.productname, editID)
    let editArray = [
      {
        title: value.productname,
        id: editID,
      },
    ];

    try {
      const res = await putRequest(
        userServiceApi.saveTeamsCoursesProducts(micrositeId, "PRODUCT"),
        editArray
      );
      // console.log("res", res);
      if (res) {
        setEditID();
        toast.success("Product edited successfully");
        dispatch(setProductGroups(res));
      }
    } catch (error) {
      // toast.error("Something went wrong, try again later.")
    }
  };

  // console.log("newProducts array", newProducts)
  return (
    <div className="tabContent">
      <div className="add-field">
        <Form form={form} className="add-field-form" onFinish={handleAdd}>
          <Form.Item name="itemField">
            <Input
              ref={inputRef}
              className="input-field"
              placeholder="Enter new product group"
            />
          </Form.Item>
          <Form.Item>
            <Button className="greenBtn add-btn" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
      {newProducts.length > 0 && (
        <>
          <div className="new-items">
            {newProducts.map((newItem, index) => (
              <div className="tag" key={newItem}>
                <Tag
                  className="tags-component"
                  closable={true}
                  onClose={() => handleRemove(newItem)}
                >
                  {newItem}
                </Tag>
              </div>
            ))}
          </div>
          <div className="btn-container">
            <Button className="greenBtn SaveBtn" onClick={handleSave}>
              Save
            </Button>
          </div>
        </>
      )}
      <Divider />
      <div className="display">
        {product_groups.map((product) => (
          <div className="product-item">
            {product.id === editID ? (
              <>
                <Form
                  onFinish={handleEdit}
                  className="item-edit-form"
                  initialValues={{
                    productname: product.title,
                  }}
                >
                  <Form.Item name="productname">
                    <Input className="edit-input" />
                  </Form.Item>
                  <>
                    <Form.Item>
                      <Button
                        style={{ marginRight: "5px" }}
                        className="greenBtn SaveBtn"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button className="SaveBtn" onClick={() => setEditID()}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </>
                </Form>
              </>
            ) : (
              <>
                <h3>{product.title}</h3>
                <div className="op-btns">
                  <div className="remove-btns">
                    <EditOutlined onClick={() => setEditID(product.id)} />
                  </div>
                  <div className="remove-btns">
                    <DeleteOutlined onClick={() => handleDelete(product)} />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
