
import * as React from "react";

const Store = React.createContext(null);

Store.displayName = 'Store';

export const StoreProvider = ({ children, initialState, reducer }) => {


  const [globalState, dispatch] = React.useReducer(reducer, initialState);


  return (<Store.Provider value={[globalState, dispatch]}>{children}</Store.Provider>);
}


export const useStore = () => {

  const [state, dispatch] = React.useContext(Store);

  const tabData = state && state.tabData ? state.tabData : {};
  const updatedValues = state && state.updatedValues ? state.updatedValues : {};
  const skills = state && state.skills ? state.skills : []
  const teams_and_roles = state && state.teams_and_roles ? state.teams_and_roles : []
  const product_groups = state && state.product_groups ? state.product_groups : []
  const courses_qualifications = state && state.courses_qualifications ? state.courses_qualifications : []
  const settings = state && state.settings ? state.settings : {}
  const microSites = state && state.microSites ? state.microSites : {}
  const microSiteActions = state && state.microSiteActions ? state.microSiteActions : {}




  return {
    dispatch,
    tabData,
    updatedValues,
    skills,
    teams_and_roles,
    product_groups,
    courses_qualifications,
    settings,
    microSites,
    microSiteActions
  }

}
