import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
import "./configurations.scss"
import { Tabs } from 'antd'
import ProductsConfigurations from './subtabs/products-configurations';
import TeamsConfigurations from './subtabs/teams-configurations';
import CoursesConfigurations from './subtabs/courses-configurations';
import { useLocation } from 'react-router-dom';
// import backArrow from "../../assets/images/back-arrow.svg";

export default function MicrositeConfigurations() {
    const location = useLocation();
    const { state } = location;
    const micrositeId = state.micrositeId;
    const [activeKey, setActiveKey] = useState(state.page ? state.page : "product");
    // const navigate = useNavigate();

    const changeTab = (activeKey) => {
        setActiveKey(activeKey);
    }
    return (
        <div className="tabContainer">
            {/* <div className="header page-header">
                <div className="backbtn-container">
                    <img src={backArrow} alt="" onClick={() => navigate('/')} />
                    <p>Configurations</p>
                </div>
            </div> */}
            <Tabs onChange={changeTab} activeKey={activeKey}>
                <Tabs.TabPane tab="Products" key="product">
                    <ProductsConfigurations micrositeId={micrositeId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Courses" key="courses">
                    <CoursesConfigurations micrositeId={micrositeId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Teams" key="teams">
                    <TeamsConfigurations micrositeId={micrositeId} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}
