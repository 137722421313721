import React from "react";
import Slider from "react-slick";
import "./imageSlider.scss";
import ReactPlayer from "react-player/youtube";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ImageSlider({ data }) {

  // const [isVideoPlaying, setVideoCompleted] = useState(false);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    // speed: 500,
    // autoplay: !isVideoPlaying,
    autoplay: false,
    // autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    // variableWidth: true,
    adaptiveHeight: false,
  };
  // const VimeoLinkPlayHandler = (url) => {
  //   if (url) {
  //     let urlArray = url.split("/");
  //     return urlArray[urlArray.length - 1];
  //   }
  // };
  if (data && data.length > 0 && data[0].id) {
    return (
      // <div className="corousel-container">
      <div className="custom-slider-container">
        <Slider {...settings}>
          {data &&
            data?.map((data, i) => {
              // if (i < 4) {
              if (
                data?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                data.mediaLink.url
              ) {
                return (
                  <div key={i} className="slide">
                    <ReactPlayer
                      controls={true}
                      url={data.mediaLink.url}
                      // onEnded={() => setVideoCompleted(true)}
                      // style={{ width: 10, height: 10 }}
                      // height="150px"
                      width={"100%"}
                    // onEnded={() => setVideoCompleted(false)}
                    // onPlay={() => setVideoCompleted(true)}
                    // onStart={() => setVideoCompleted(true)}
                    // onPause={() => setVideoCompleted(false)}
                    ></ReactPlayer>
                  </div>
                );
              } else if (
                data?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                data.mediaLink.url
              ) {
                return (
                  <div key={i} className="slide">
                    <iframe
                      controls={true}
                      // video={VimeoLinkPlayHandler(data.mediaLink.url)}
                      src={data.mediaLink.url}
                      // height="150px"
                      width="100%"
                      style={{ backgroundColor: "black" }}
                    // responsive={true}
                    // onEnd={() => setVideoCompleted(false)}
                    // onProgress={() => setVideoCompleted(true)}
                    // onPlaying={() => setVideoCompleted(true)}
                    // onPlay={() => setVideoCompleted(true)}
                    // onPause={() => setVideoCompleted(false)}
                    />
                  </div>
                );
              } else if (data?.mediaLink?.mediaType === "IMAGE" &&
                data.mediaLink.url) {
                return (
                  <div key={i} className="slide">
                    <div className="img-slide" style={{ backgroundColor: 'white' }} >
                      <img
                        src={data?.mediaLink?.url}
                        alt=""
                        className="c1 carousel-image"
                      />
                    </div>
                  </div>
                );
              }
              // else {
              //   <div className="slide">
              //     <div className="img-slide">
              //       <img
              //         src={defImg}
              //         alt=""
              //         className="c1 carousel-image"
              //       />
              //     </div>
              //   </div>
              // }
              // /}
            })}
        </Slider>
      </div>
      // </div>
    );
  } else {
    return <></>;
  }
}
