import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Space,
  Spin,
  Tooltip,
  Divider,
  Menu,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import "../../../slider/imageSlider.scss";
import ReactPlayer from "react-player/youtube";
import teamsAndRoles from "../../../organisations/json/teamsAndRoles.json";
import { getRequest } from "../../../../api/http";
import { Option } from "antd/lib/mentions";
import userServiceApi from "../../../../api/userServiceApi";

const { TextArea } = Input;
const MAX_CHARACTERS = 500;
const levelIndexMap = {
  "Level 1": 1,
  "Level 2": 2,
  "Level 3": 3,
  "Level 4": 4,
  "Level 5": 5,
  "Level 6": 6,
  "Level 7": 7,
  "Level 8": 8,
  "Entry Level": 0,
};

export default function TeamsAndRoles({ dataLoader, form }) {
  const { micrositeId } = useParams();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const tabKey = state;
  const { tabData, updatedValues, teams_and_roles } = useStore();
  const whichTab = TAB_KEYS.teamsAndRoles;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage } = useConnectsuForm();
  const [loader, setLoader] = useState(true);
  const [customName, setName] = useState("");
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [items, setItems] = useState(teamsAndRoles.map((item) => item.name));
  const [formChanged, setFormChanged] = useState(false);
  const levelColor = [
    "#ff0000",
    "#ff9900",
    "#332206",
    "#b6d7a8",
    "#00ffff",
    "#0000ff",
    "#9900ff",
    "#6aa84f",
    "#ff00ff",
  ];
  // const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("tab", "TEAMS_AND_ROLES");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  let index = 0;

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };

  const MakeFieldDisableHandler = (i) => {
    let value = form.getFieldValue([whichTab, i, "title"]);
    // if (value == "w1" || value == "w2" || value == "w3") {
    //   return true;
    // }
  };

  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  const onNameChange = (event) => {
    setName([event.target.value]);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addItem();
    }
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, customName[0] || `New item ${index++}`]);
    setName("");
    // setFormChanged(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (tabData[whichTab]) {
      tabData[whichTab].forEach((item) => {
        if (item?.title && !items.includes(item.title)) {
          items.push(item.title);
        }
      });
    }
  }, [tabData[whichTab]]);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await getRequest(userServiceApi.getLevels(), "");
        console.log(response, "res");
        const data = response.data;
        // console.log(data);
        setLevels(data);

        if (data.length > 0) {
          setSelectedLevel(data[0].id.toString());
        }
      } catch (error) {
        // console.error("Error fetching levels:", error);
      }
    };

    fetchLevels();
  }, []);

  console.log(levels);

  const levelsData = [
    {
      id: 0,
      value: "Entry Level",
      additionalInfo:
        "Qualifications for Entry Level are:\n Entry Level Award\n Entry Level Certificate (ELC)\n Entry Level diploma\n Entry Level English for speakers of other languages(ESOL)\n Entry Level essential skills\n  Entry Level functional skills\n Skills for Life",
    },
    {
      id: 1,
      value: "Level 1",
      additionalInfo:
        "Level 1 Qualifications are:\n first certificate\n GCSE- grades 3,2,1 or grades D,E,F,G\n Level 1 award\n Level 1 certificate\n Level 1 diploma\n Level 1 ESOL\n Level 1 essential skills\n level 1 functional skills\n level 1 national vocational qualification(NVQ)\n Music grades 1,2 and 3",
    },
    {
      id: 2,
      value: "Level 2",
      additionalInfo:
        "Level 2 Qualifications are:\n CSE- grade 1\nGCSE-grades 9,8,7,6,5,4 or grades A*,A,B,C\n intermediate apprenticeship\n Level 2 award\n Level 2 certificate\n Level 2 diploma\n Level 2 ESOL\n Level 2 essential skills\n Level 2 Functional skills\n Level 2 national certificate\n Level 2 national diploma\n Level 2 NVQ\n Music grades 4 and 5\n O-Level- grade A,B or C ",
    },
    {
      id: 3,
      value: "Level 3",
      additionalInfo:
        "Level 3 Qualifications are:\n A Level\n Access to higher education diploma\n Advanced apprenticeship\n applied general\n AS level\n international Baccalaureate diploma\n Level 3 award\n Level 3 certificate\n Level 3 diploma\n Level 3 ESOL\n Level 3 national certificate\n Level 3 national diploma\n Level 3 NVQ\n music grades 6,7 and 8\n T Level\n Tech level",
    },
    {
      id: 4,
      value: "Level 4",
      additionalInfo:
        "Level 4 Qualifications are:\n Certificate of higher education(CertHE)\n Higher apprenticeship\n Higher national Certificate\nLevel 4 award\n Level 4 certificate\n Level 4 diploma\n n Level 4 NVQ",
    },
    {
      id: 5,
      value: "Level 5",
      additionalInfo:
        "Level 5 qualifications are:\n diploma of higher education (DipHE)\n foundation degree\n higher national diploma (HND)\n level 5 award\n level 5 certificate\n level 5 diploma\n level 5 NVQ",
    },
    {
      id: 6,
      value: "Level 6",
      additionalInfo:
        "Level 6 qualifications are:\n degree apprenticeship\n degree with honours for example bachelor of the arts (BA) hons, bachelor of science (BSc) hons\n graduate certificate\n graduate diploma\n level 6 award\n level 6 certificate\n level 6 diploma\n level 6 NVQ\n ordinary degree without honours",
    },
    {
      id: 7,
      value: "Level 7",
      additionalInfo:
        "Level 7 qualifications are:\n integrated master's degree, for example master of engineering (MEng)\n level 7 award\n level 7 certificate\n level 7 diploma\n level 7 NVQ\n master's degree, for example master of arts (MA), master of science (MSc)\n postgraduate certificate\n postgraduate certificate in education (PGCE)\n postgraduate diploma",
    },
    {
      id: 8,
      value: "Level 8",
      additionalInfo:
        "Level 8 qualifications are:\n doctorate, for example doctor of philosophy (PhD or DPhil)\n level 8 award\n level 8 certificate\n level 8 diploma",
    },
  ];

  const menu = (
    <Menu>
      {levelsData.map((level) => (
        <Menu.Item key={level.id}>
          <Tooltip
            overlay={
              <div>
                {level?.additionalInfo?.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            }
            placement="right"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {level.value}
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: levelColor[level.id],
                  marginRight: "8px",
                  marginLeft: "8px",
                }}
              ></div>
            </div>
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    // if (items?.length > 0) {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <div className="card-item">
                            <Col className="gutter-row tabContent" span={16} style={{ paddingRight: "25px" }}>
                              <div className="d-flex">
                                <Form.Item
                                  className="custom-work"
                                  {...restField}
                                  label="Team"
                                  labelCol={{ span: 8 }}
                                  // name={[name, "title"]}
                                  name={[name, "assetTitleId"]}
                                >
                                  {/* <Input placeholder="Enter team" /> */}
                                  {/* <Select
                                    name={[name, "title"]}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: "8px 0",
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: "0 8px 4px",
                                          }}
                                        >
                                          <Input
                                            placeholder="Please enter Team"
                                            ref={inputRef}
                                            value={customName}
                                            onChange={onNameChange}
                                            onPressEnter={addItem}
                                          />
                                          <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={addItem}
                                          >
                                            Add another Team
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                    options={items.map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                  >
                                  </Select> */}
                                  <Select
                                    name={[name, "assetTitleId"]}
                                    options={teams_and_roles.map((item) => ({
                                      label: item.title,
                                      value: item.id,
                                    }))}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: "8px 0",
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: "0 8px 4px",
                                          }}
                                        >
                                          <Button
                                            className="dropdow-configurations-btn greenBtn"
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                              localStorage.setItem("page", "configurations");
                                              navigate('/micrositeConfigurations', { state: { micrositeId: micrositeId, page: "teams" } })
                                            }}
                                          >
                                            Add team
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                  >
                                    {/* {teams_and_roles.map((teams) => (
                                      <Select.Option value={teams.id}>
                                        {teams.title}
                                      </Select.Option>
                                    ))} */}
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  {fields.length >= 1 &&
                                    // i !== 0 &&
                                    findValue(i, "id") &&
                                    !MakeFieldDisableHandler(i) ? (
                                    <div className="remove-btn">
                                      <DeleteOutlined
                                        onClick={() =>
                                          deleteSingleItem(
                                            whichTab,
                                            form.getFieldValue([
                                              whichTab,
                                              i,
                                              "id",
                                            ]),
                                            form
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </Form.Item>
                              </div>
                              <Form.Item
                                label="Role"
                                {...restField}
                                labelCol={{ span: 8 }}
                                name={[name, "subTitle"]}
                              >
                                <Input placeholder="Enter Role" />
                              </Form.Item>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  name={[name, "levelId"]}
                                  className="w-100"
                                  label="Select qualification level required"
                                  labelCol={{ span: 8 }}
                                >
                                  <Select
                                    value={selectedLevel}
                                    onChange={(value) =>
                                      setSelectedLevel(value)
                                    }
                                    allowClear
                                    placeholder="Select Level"
                                  >
                                    {levels.map((level, index) => (
                                      <Option
                                        key={level.id}
                                        value={level.id.toString()}
                                      >
                                        <Tooltip
                                          overlay={
                                            <div>
                                              {levelsData[
                                                levelsData.length - 1 - index
                                              ]?.additionalInfo
                                                ?.split("\n")
                                                ?.map((line, index) => (
                                                  <div key={index}>{line}</div>
                                                ))}
                                            </div>
                                          }
                                          placement="right"
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  levelColor[
                                                  levelIndexMap[level.value]
                                                  ],
                                                marginRight: "8px",
                                                marginLeft: "8px",
                                              }}
                                            ></div>
                                            {level.value}
                                          </div>
                                        </Tooltip>
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                {/* <Form.Item>
                                  <div className="remove-btn">
                                    <div style={{ textAlign: "center" }}>
                                      <Dropdown
                                        overlay={menu}
                                        placement="bottomLeft"
                                        arrow
                                        trigger={["click", "hover"]}
                                      >
                                        <InfoCircleOutlined />
                                      </Dropdown>
                                    </div>
                                  </div>
                                </Form.Item> */}
                              </div>
                              <Form.Item
                                label="Description"
                                {...restField}
                                labelCol={{ span: 8 }}
                                name={[name, "description"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Description is required",
                                  },
                                  {
                                    max: MAX_CHARACTERS - 1,
                                    message: `Description should be less than ${MAX_CHARACTERS} character`,
                                  }
                                ]}
                              >
                                <TextArea
                                  placeholder="Enter Description"
                                  rows={5}
                                  maxLength={MAX_CHARACTERS}
                                  showCount
                                />
                              </Form.Item>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  name={[name, "mediaLink", "mediaType"]}
                                  className="w-100"
                                  label="Video or Image"
                                  labelCol={{ span: 8 }}
                                >
                                  <Select
                                    name={[name, "mediaLink", "mediaType"]}
                                    onChange={(e) => SelectChangeHandler(e, i)}
                                    placeholder="Select Option"
                                    allowClear
                                  >
                                    <Select.Option value="VIMEO_MEDIA">
                                      Add Vimeo Link
                                    </Select.Option>
                                    <Select.Option value="SOCIAL_MEDIA">
                                      Add Youtube Link
                                    </Select.Option>
                                    <Select.Option value="IMAGE">
                                      Upload Image
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Upload Video link or image with size lesser than 2.5MB"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                <Form.Item
                                  label="Youtube Link"
                                  labelCol={{ span: 8 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Youtube link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                <Form.Item
                                  label="Vimeo Link"
                                  labelCol={{ span: 8 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Vimeo link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "IMAGE" ? (
                                <Form.Item
                                  {...restField}
                                  // label={<ImageLabel label={"Image"} />}
                                  label="Image"
                                  colon={false}
                                  // label={<p style={{ fontSize: '16px', margin: '0px' }}><span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>Image</p>}
                                  name="media"
                                  className="experienceImg"
                                  labelCol={{ span: 8 }}
                                // rules={[
                                //   {
                                //     validator: (_, value) => {
                                //       return findValue(i, "blob")
                                //         ? Promise.resolve()
                                //         : findValue(i, "url")
                                //         ? Promise.resolve()
                                //         : findValue(i, "type") === "IMAGE"
                                //         ? Promise.reject(
                                //             new Error("Image is required")
                                //           )
                                //         : Promise.resolve();
                                //     },
                                //   },
                                // ]}
                                >
                                  {findValue(i, "blob") ? (
                                    <Avatar
                                      src={URL.createObjectURL(
                                        findValue(i, "blob")
                                      )}
                                      alt=""
                                    />
                                  ) : findValue(i, "url") ? (
                                    <Avatar src={findValue(i, "url")} alt="" />
                                  ) : (
                                    <Avatar src={UserIcon} />
                                  )}
                                  <label
                                    name="file"
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {" "}
                                    Choose File
                                    <input
                                      accept="image/png, image/gif, image/jpeg"
                                      type="file"
                                      // required={true}
                                      onChange={(e) =>
                                        updateImage(e, i, form, whichTab)
                                      }
                                    />
                                  </label>
                                </Form.Item>
                              ) : (
                                <></>
                              )}

                              <Form.Item
                                className="w-100"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                {formChanged ? (
                                  <Button
                                    className="saveBtn greenBtn"
                                    onClick={() => {
                                      form.submit().then(() => {
                                        setFormChanged(false);
                                      }).catch((errorInfo) => {
                                        console.error('Form submission failed:', errorInfo);
                                      });
                                    }}
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    className="saveBtn greenBtn"
                                    style={{
                                      opacity: "0.5",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row tabContent" span={8}>
                              <div>{renderMedia(tabData[whichTab][i])}</div>
                            </Col>
                          </div>
                        ))}
                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w1" &&
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w2" &&
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w3" &&
                                      !findValue(i, "id") &&
                                      i !== 0
                                    ) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <Button className="greenBtn" onClick={() => add()}>
                              Add More
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form.List>
                </>
              </Col>
              {/* <Col className="gutter-row corousel-col" span={6}>
                                  <ImageSlider data={tabData && tabData[whichTab] && tabData[whichTab].length > 0
                                      ? tabData[whichTab]
                                      : [""]} />
                              </Col> */}
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    );
    // } else {
    //   return (
    //     <div className="data-loader">
    //       <Spin tip="Loading" size="large" />
    //     </div>
    //   );
    // }
  }
}
