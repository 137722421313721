import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Input,
    Form, Spin, Col
} from "antd";
import {
    PlusOutlined, DeleteOutlined
} from "@ant-design/icons";
import UserIcon from "../../../../../../assets/images/icons/userLogo.svg";
import { useLocation } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { aboutUSkeys } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../../../utils";

const { TextArea } = Input;
const MAX_CHARACTERS = 500;

export default function AccreditationComponent({
    dataLoader,
    form,
    activeKey,
}) {
    // const params = useParams();
    const { state } = useLocation();
    // const navigate = useNavigate();
    const tabKey = state;

    const { tabData } = useStore();
    const whichTab = aboutUSkeys.Accreditation;
    const { updateAccreditationImage } = useConnectsuForm();
    const [loader, setLoader] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const { updatedValues } = useStore();
    const { deleteSingleItem, postLoader, getSingleTabData, dataLoader: tabDataLoader } = useTab();

    if (activeKey === "ABOUT_US") {
        localStorage.setItem("tab", activeKey);
    }

    useEffect(() => {
        setFormChanged(false);
    }, []);

    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;
        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    const renderMedia = (dataItem) => {
        const commonStyles = {
            width: "350px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
        };
        return (
            <div className="iframe-comp" style={commonStyles}>
                <img
                    src={dataItem?.awardsLogos?.url}
                    alt=""
                    className="c1 carousel-image"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
            </div>
        );
    };
    const findValue = (i, key) => {
        return getFormFieldValue(form, whichTab, i, key);
    };


    const handleDelete = (whichTab, form, i, tabKey) => {
        deleteSingleItem(
            whichTab,
            form.getFieldValue([
                whichTab,
                i,
                "id",
            ]),
            form
        )
    }

    useEffect(() => {
        form.resetFields();
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [tabData[whichTab]]);

    if (dataLoader || postLoader || loader) {
        return (
            <div className="data-loader">
                <Spin tip="Loading" size="large" />
            </div>
        );
    } else {
        return (
            <div>
                <div style={{ display: 'flex' }} >
                    {tabKey.split('/')[1] === whichTab ? (
                        <>
                            <div className="w-100">
                                <Form.List
                                    name={whichTab}
                                    initialValue={
                                        tabData &&
                                            tabData[whichTab] &&
                                            tabData[whichTab].length > 0
                                            ? tabData[whichTab]
                                            : [{}]}
                                >
                                    {(fields, { add, remove }) => (
                                        <div className="w-100" style={{ display: 'flex', flexWrap: 'wrap' }} >
                                            {fields.map(({ key, name, ...restField }, i) => (
                                                <div key={i} className="card-item">
                                                    <Col
                                                        className="gutter-row tabContent"
                                                        span={16}
                                                        style={{ paddingRight: "25px" }}
                                                    >
                                                        <div style={{ display: 'flex' }} >

                                                            <Form.Item
                                                                className="w-100"
                                                                label="Description"
                                                                {...restField}
                                                                labelCol={{ span: 5 }}
                                                                name={[name, "description"]}
                                                                rules={[
                                                                    {
                                                                        max: MAX_CHARACTERS - 1,
                                                                        message: `Description should be less than ${MAX_CHARACTERS} character`,
                                                                    }
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    placeholder="Enter Description"
                                                                    rows={5}
                                                                    maxLength={MAX_CHARACTERS}
                                                                    showCount
                                                                />
                                                            </Form.Item>
                                                            <div className="remove-btn" style={{ position: 'relative' }} >
                                                                {<DeleteOutlined
                                                                    style={{ cursor: 'pointer' }}
                                                                    title="Delete Team"


                                                                    onClick={() => {
                                                                        handleDelete(whichTab, form, i, tabKey)
                                                                    }}



                                                                />}
                                                            </div>
                                                        </div>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Accreditation"
                                                            colon={false}
                                                            name="awardsLogos.name"
                                                            className="experienceImg"
                                                            labelCol={{ span: 5 }}

                                                        >
                                                            {findValue(i, "blob") ? (
                                                                <Avatar
                                                                    src={URL.createObjectURL(
                                                                        findValue(i, "blob")
                                                                    )}
                                                                    alt=""
                                                                />
                                                            ) : form.getFieldValue(whichTab)[i]?.awardsLogos?.url ? (
                                                                <Avatar src={form.getFieldValue(whichTab)[i].awardsLogos.url} alt="" />

                                                            ) : (
                                                                <Avatar src={UserIcon} />
                                                            )}
                                                            <label
                                                                name="file"
                                                                className="fileUploadBtn greenBtn"
                                                            >
                                                                {" "}
                                                                Choose File
                                                                <input
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    type="file"
                                                                    // required={true}
                                                                    onChange={(e) =>
                                                                        updateAccreditationImage(e, i, form, whichTab)
                                                                    }
                                                                />
                                                            </label>
                                                        </Form.Item>

                                                        <Form.Item
                                                            className="w-100"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "end",
                                                            }}
                                                        >
                                                            {formChanged ? (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    onClick={() => {
                                                                        form.submit()
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="saveBtn greenBtn"
                                                                    style={{
                                                                        opacity: "0.5",
                                                                        pointerEvents: "none",
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row tabContent" span={8}>
                                                        <div>{tabData[whichTab]?.[i] ? renderMedia(tabData[whichTab][i]) : null}</div>

                                                    </Col>
                                                </div>
                                            ))}

                                            <div className="inputBody w-100"  >
                                                <div style={{ textAlign: "right" }}>
                                                    {fields && fields.length <= 1 ? (
                                                        <></>
                                                    ) : (
                                                        <Button
                                                            className="cancelBtn"
                                                            type="button"
                                                            onClick={() => {
                                                                const itemsToRemove = [];
                                                                fields.forEach((f, i) => {
                                                                    if (
                                                                        !findValue(i, "id") &&
                                                                        i !== 0
                                                                    ) {
                                                                        itemsToRemove.push(f.name);
                                                                    }
                                                                });
                                                                if (itemsToRemove.length > 0) {
                                                                    remove(itemsToRemove);
                                                                }
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                    <Button className="greenBtn" onClick={() => add({})}>
                                                        Add More
                                                        <PlusOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Form.List>
                            </div>

                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
}
