import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Tabs, Col, Button } from "antd";
// import Button from "../buttons/Button";
import userServiceApi from "../../api/userServiceApi";
import { postRequest } from "../../api/http";
import Logo from "../../../src/assets/images/PoweredNew.png";
import { toast } from "react-toastify";

export default function ResetPasswordForm() {
  const param = useParams();
  const partnerID = localStorage.getItem("partnerID");
  const token = param.token;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const textInput = useRef(null);
  const [form] = Form.useForm();

  const handleReset = async (values) => {
    if (values && values.newPassword && values.confirmPassword) {
      if (values.newPassword !== values.confirmPassword) {
        toast.error("Passwords does not match");
      } else {
        setLoading(true);
        // navigate("/createOrganization", { replace: true });
        try {
          const response = await postRequest(
            `${userServiceApi.resetPassword(token)}`,
            { newPassword: values.newPassword }
          );
          if (response) {
            navigate(`/login/${response}`);
            toast.success("Password successfully created");
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };
  return (
    <React.Fragment>
      {/* <Banner /> */}
      <div className="headerBannerRow">
        <div className="headerBannerCol">
          <div>
            <img
              className="Reset-headerLogo"
              src={Logo}
              alt=""
            // onClick={() => {
            //   navigate("/");
            // }}
            />
            {/* <label>Organisation Admin</label> */}
          </div>
        </div>
      </div>
      <div className="tabContainer">
        <Form
          name="control-hooks"
          className="organizationForm"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 10,
          }}
          form={form}
          autoComplete="off"
          onFinish={handleReset}
        >
          <Tabs>
            <Tabs.TabPane tab="Create Password" key="tab1">
              <div className="">
                <Col className="gutter-row" span={15}>
                  <Form.Item
                    name="newPassword"
                    label="New Password"
                  >
                    <Input required={true} type="password" placeholder="New Password" />
                  </Form.Item>
                </Col>

                {/* <label>New Password:</label> */}
              </div>

              <div className="">
                {/* <label>Confirm Password:</label> */}
                <Col className="gutter-row" span={15}>
                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                  >
                    <Input required={true} type="password" placeholder="Confirm Password" />
                  </Form.Item>
                </Col>
              </div>
              <div className="forgotPwdButtons">
                <Button
                  type="submit"
                  className="greenBtn"
                  loading={loading}
                  onClick={() => form.submit()}
                >Submit</Button>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <div className="footer d-flex" style={{ paddingTop: "2vh" }}>
          <div className="footer-content" onClick={() => window.open("https://www.connectsu.co.uk/", '_blank')} style={{ cursor: "pointer" }}>
            {/* <span>Powered by</span> */}
            <div className="footer-logo">
              <img
                src={Logo}
                alt="company logo"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
