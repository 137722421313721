import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  Spin,
} from "antd";
import { useStore } from "../../../../../../store";
import { contactUSkeys } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import twitter from "../../../../../../assets/images/twitter.svg";
import linkedin from "../../../../../../assets/images/linkedIn.svg";
import youtube from "../../../../../../assets/images/youtube.svg";
import tiktok from "../../../../../../assets/images/tiktok.svg";
import instagram from "../../../../../../assets/images/instagram.svg";
import facebook from "../../../../../../assets/images/facebook-icon.svg";

const { TextArea } = Input;

export default function SocialMedia({ dataLoader, form, tabKey }) {
  const { tabData, skills } = useStore();
  const whichTab = contactUSkeys.socialMedia;
  const { postLoader } = useTab();
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const onChange = () => {
    setFormChanged(true);
  };

  if (dataLoader || postLoader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        {/* <Form name={tabKey}> */}
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === contactUSkeys.socialMedia ? (
            <>
              <Col className="gutter-row tabContent" span={15}>
                <Form.List
                  name={contactUSkeys.socialMedia}
                  initialValue={
                    tabData && tabData[tabKey] && tabData[tabKey].length > 0
                      ? tabData[tabKey]
                      : [""]
                  }
                >
                  {(fields) => (
                    <div>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={linkedin} alt="Image"style={{ marginRight: '3px' }} />
                              Linkedin
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "linkedInLink"]}
                          >
                            <Input placeholder="Linkedin" />
                          </Form.Item>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={twitter} alt="Image"style={{ marginRight: '3px' }} />
                              X
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "twitterLink"]}
                          >
                            <Input placeholder="X" />
                          </Form.Item>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={youtube} alt="Image"style={{ marginRight: '3px' }} />
                              Youtube
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "youtubeLink"]}
                          >
                            <Input placeholder="Youtube" />
                          </Form.Item>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={instagram} alt="Image"style={{ marginRight: '3px' }} />
                              Instagram
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "instagramLink"]}
                          >
                            <Input placeholder="instagram" />
                          </Form.Item>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={tiktok} alt="Image"style={{ marginRight: '3px' }} />
                              Tik-Tok
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "tiktokLink"]}
                          >
                            <Input placeholder="Tik-Tok" />
                          </Form.Item>
                          <Form.Item
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={facebook} alt="Image"style={{ marginRight: '3px' }} />
                              Facebook
                            </div>
                          }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "facebookLink"]}
                          >
                            <Input placeholder="facebook" />
                          </Form.Item>
                          <Form.Item
                            className="w-100"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            {formChanged ? (
                              <Button
                                className="saveBtn greenBtn"
                                onClick={() => {
                                  form.submit().then(() => {
                                    setFormChanged(false);
                                  }).catch((errorInfo) => {
                                    console.error('Form submission failed:', errorInfo);
                                  });
                                }}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                className="saveBtn greenBtn"
                                style={{
                                  opacity: "0.5",
                                  pointerEvents: "none",
                                }}
                              >
                                Save
                              </Button>
                            )}
                          </Form.Item>
                        </>
                      ))}
                    </div>
                  )}
                </Form.List>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {/* </Form> */}
      </div>
    );
  }
}
