import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox, Button, Modal, Spin, Row, Col } from "antd";
import { toast } from "react-toastify";
import userServiceApi from "../../api/userServiceApi";
import { postRequest, getRequest } from "../../api/http";
import "./Login.scss";

export default function TermsForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [partnerData, setPartnerData] = useState({});
  const partnerName = localStorage.getItem("partner");
  const partnerID = localStorage.getItem("partnerID");
  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  const [submitBtnStatus, SetSubmitBtnStatus] = useState(true);

  const handleCancel = () => {
    setReviewModalVisible(false);
  };

  const handleChange = (e) => {
    SetSubmitBtnStatus(!e.target.checked);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const HideModal = () => {
    setIsModalOpen(false);
  };

  const submitHandler = async () => {
    // let submit = { ...state?.formData?.values };
    let submit = {
      // firstName: state?.formData?.values.firstName,
      // lastName: state?.formData?.values.lastName,
      userName: state?.formData?.userName,
      email: state?.formData?.email,
      phone: state?.formData?.phone,
      partnerName: partnerName,
      isActiveMember: state?.formData?.isActiveMember,
      name: state?.formData?.name,
      websiteLink: state?.formData?.websiteLink,
      typeId: state?.formData?.typeId,
      size: state?.formData?.size,
      addressInputs: [
        {
          street: state?.formData?.addressStreet,
          postalCode: state?.formData?.postcode,
          // countryId: state?.formData?.values.countryId
        },
      ],
    };

    // console.log("subkitclicked",submit)
    try {
      setLoading(true);
      const response = await postRequest(
        userServiceApi.createOrganizationUser(),
        submit
      );
      if (response) {
        setReviewModalVisible(false);
        // setLoading(false);
        showModal();
        sessionStorage.clear();
        setTimeout(() => {
          redirectHandler();
        }, 10000);
      }
    } catch (error) {
      // console.log("error", error);
      // setDeniedModal(true);
      // setTimeout(() => {
      //   navigate("/signup");
      // }, 10000);
    }
  };

  const getPartnerDetailsData = async (partnerID) => {
    try {
      // setLoading(true);
      const response = await getRequest(
        userServiceApi.getPartnerDetailsLoginScreen(partnerID)
      );
      if (response && response.data) {
        setPartnerData(response.data);
      }
      setLoading(true);
    } catch (error) {
      setLoading(false);
      toast.error(error.error);
    }
  };

  const redirectHandler = () => {
    sessionStorage.clear();
    if (isMobile) {
      setAlertModal(true);
    } else {
      navigate(`/login/${partnerID}`);
    }
  };

  const partner = "Somerset";
  useEffect(() => {
    getPartnerDetailsData(partnerID);
  }, []);
  return (
    <>
      <div className="headerBannerRow">
        <div className="headerBannerCol">
          <div className="container">
            <img
              className="signup-logo"
              style={{ cursor: "pointer" }}
              src={partnerData.mediaLink}
              alt=""
              onClick={redirectHandler}
            />
            {/* <label>{partnerData.siteName}</label> */}
            <h3 className="signUpHeading">
              Add your organisation to {partnerData.siteName}
            </h3>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="tabContainer">
          <div className="terms-container">
            <div className="termsHeading">
              <h2>Terms and conditions</h2>
            </div>
            <div className="terms-text">
              <div className="terms-content">
                <p>
                  Please read these Terms and Conditions carefully before using
                  our services.
                </p>
                <p>
                  By accessing or using our services, you agree to be bound by
                  these Terms and Conditions. If you do not agree with any part
                  of these terms, do not access or use our services.
                </p>
                <br></br>
                <p>
                  1. **Service Description:** Our services provide [brief
                  description of services] to users [define users]. The services
                  are subject to change or termination without prior notice.
                </p>
                <br></br>
                <p>
                  2. **User Obligations:** Users must provide accurate
                  information during registration. You are responsible for
                  maintaining the confidentiality of your account and password.
                  You agree to accept responsibility for all activities that
                  occur under your account.
                </p>
                <br></br>
                <p>
                  3. **Intellectual Property:** All content provided through our
                  services, including text, graphics, logos, images, and
                  software, is the property of [your company name] and protected
                  by applicable intellectual property laws.
                </p>
                <br></br>
                <p>
                  4. **Limitation of Liability:** We are not liable for any
                  direct, indirect, incidental, special, or consequential
                  damages arising from the use of our services.
                </p>
                <br></br>
                <p>
                  5. **Privacy Policy:** Our Privacy Policy explains how we
                  collect, use, and protect your personal information. By using
                  our services, you consent to our Privacy Policy.
                </p>
                <br></br>
                <p>
                  6. **Governing Law:** These Terms and Conditions shall be
                  governed by and construed in accordance with the laws of [your
                  jurisdiction].
                </p>
                <br></br>
                <p>
                  By using our services, you acknowledge that you have read,
                  understood, and agreed to these Terms and Conditions. If you
                  do not agree with any part of these terms, you must refrain
                  from using our services.
                </p>
                <br></br>
                <br></br>
                <br></br>
                <h4>
                  <b>[Your company name]</b>
                </h4>
                <h4>
                  <b>[Contact information]</b>
                </h4>
                <h4>
                  <b>[Date]</b>
                </h4>
                <br></br>
                (Note: This is a generic sample and should be tailored to your
                specific needs. Consult with a legal professional to ensure
                accuracy and compliance with relevant laws.)
              </div>
            </div>
            <div className="check-submit">
              <div className="checkbox">
                <Checkbox onChange={handleChange}>
                  Accept the terms and conditions
                </Checkbox>
              </div>
            </div>
            <div className="submitbtn">
              <Button
                disabled={submitBtnStatus}
                className={!submitBtnStatus ? `greenBtn` : "disbledBtn"}
                onClick={() => setReviewModalVisible(true)}
                type="primary"
                htmlType="submit"
              >
                Review & Submit
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="data-loader">
          <Spin tip="Loading" size="large" />
        </div>
      )}
      <Modal
        width={450}
        style={{ top: "30vh" }}
        open={alertModal}
        onCancel={() => setAlertModal(false)}
        footer={null}
        closable={false}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Please use a laptop or a desktop to login</h3>
        </div>
      </Modal>
      <Modal open={isModalOpen} footer={null} width={320} onCancel={HideModal}>
        <p className="signup-text">
          Thank you for signing up and once approved please check your email for
          your login details &#40; also check your spam folder &#41;
        </p>
      </Modal>
      <Modal open={deniedModal} footer={null} width={320} onCancel={HideModal}>
        <p className="signup-text">
          Sorry, Organisation name already exists
        </p>
      </Modal>
      <Modal
        open={reviewModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className="viewMicrositeModal"
        style={{ marginTop: "45px" }}
      >
        <div className="approve-container">
          <Row
            className="rowBody review-header"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <h2>Review your Information</h2>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Organisation type
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.nameType}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Are you an existing member
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.isActiveMember === true ? "Yes" : "No"}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Organisation name
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.name}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Address
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.addressStreet}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Postal code
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.postcode}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Organisation size
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.size === "MICRO" ? "Micro 0-9"
                : state?.formData?.size === "SMALL" ? "Small 10-49"
                  : state?.formData?.size === "MEDIUM" ? "Medium 50-249"
                    : state?.formData?.size === "LARGE" ? "Large 249+"
                      : ""}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Full name
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.userName}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Email
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.email}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Phone number
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.phone}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={9}>
              Organisation website
            </Col>
            <Col className="gutter-row tabContent-body" span={10}>
              {state?.formData?.websiteLink}
            </Col>
          </Row>
          <div className="submitbtnend" style={{ gap: "10px" }}>
            <Button
              onClick={() => navigate("/signup")}
              // type="primary"
              // htmlType="submit"
              className="editbtn"
            >
              Edit
            </Button>
            <Button
              onClick={() => submitHandler()}
              type="primary"
              htmlType="submit"
              className="greenBtn"
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
