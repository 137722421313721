import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";
import { isUserLoggedIn } from "../utils/helper";
import Header from "../components/header/banner/index";

const ProtectedRoutes = (props) => {
  const auth = isUserLoggedIn();

  const navigate = useNavigate();
  const locations = useLocation();

  const partnerID = localStorage.getItem("partnerID")

  useEffect(() => {
    if (auth) {
      const isOrganizationProfilePending = localStorage.getItem(
        "isOrganizationProfilePending"
      );

      if (isOrganizationProfilePending === "true") {
        navigate("/edit-organization", {
          state: {
            tab:
              locations && locations.state && locations.state.tab
                ? locations.state.tab
                : "organizationDetailsTab",
          },
        });
      }
    }
  }, [locations.pathname]);

  return auth ? <>
    <Header />
    <Outlet />
    {/* </> : <Navigate to="/login/somerset" />; */}
    {/* </> : <Navigate to={`/login/${localStorage.getItem("partnerID")}`} />; */}
  </> : <Navigate to={`/login/${partnerID}`} />;
};

export default ProtectedRoutes;
