
export const SET_TAB_DATA = 'SET_TAB_DATA';
export const SET_TAB_LOADER = 'SET_TAB_LOADER';
export const SET_UPDATED_VALUES = 'SET_UPDATED_VALUES';
export const SET_SKILLS = 'SET_SKILLS';
export const SET_TEAMS_AND_ROLES = 'SET_TEAMS_AND_ROLES';
export const SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS';
export const SET_COURSES_QUALIFICATIONS = 'SET_COURSES_QUALIFICATIONS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_MICROSITES = 'SET_MICROSITES';
export const SET_MICROSITE_ACTIONS = 'SET_MICROSITE_ACTIONS';