import React, { useState, useEffect } from "react";
import {
    Button,
    Input,
    Form,
    Row,
    Col,
    Spin,
    Tooltip
} from "antd";
import { useParams } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { contactUSkeys } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import {
    PlusOutlined,
    DeleteOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";


const { TextArea } = Input;
const MAX_CHARACTERS = 150;

const Address = ({ dataLoader, form, tabKey }) => {
    const params = useParams();
    const { tabData, updatedValues } = useStore();
    const whichTab = contactUSkeys.address;
    const { deleteSingleItem, postLoader, getSingleTabData, dataLoader: tabDataLoader } = useTab();
    const [formChanged, setFormChanged] = useState(false);
    const [loading, SetLoading] = useState(postLoader || true)
    const [loader, setLoader] = useState(dataLoader || true);

    const [initialValues, setInitialValues] = useState([])
    useEffect(() => {
        const isNotEmpty = Object.keys(updatedValues).length > 0;
        setFormChanged(isNotEmpty);
    }, [Object.keys(updatedValues).length]);

    useEffect(() => {
        SetLoading(tabDataLoader || postLoader)
    }, [tabDataLoader, postLoader])

    const handleDelete = (whichTab, form, i, tabKey) => {
        SetLoading(true)
        deleteSingleItem(
            whichTab,
            form.getFieldValue([
                whichTab,
                i,
                "id",
            ]),
            form
        )

        getSingleTabData(tabKey, form);
        SetLoading(false)
    }

    useEffect(() => {
        setLoader(true)
        SetLoading(true)

        form.resetFields();
        setTimeout(() => {
            SetLoading(false)
            setLoader(false);
        }, 1000);

        let tempInitialValues = []
        if (tabData.ADDRESS) {
            tempInitialValues = tabData.ADDRESS.map((item) => {
                if (item.addressOutputs[0]) {
                    return {
                        id: item.id,
                        street: item.addressOutputs[0].street,
                        postalCode: item.addressOutputs[0].postalCode
                    }
                }
            }).filter((item) => item)
        }
        else {
            tempInitialValues = [{
                id: '',
                street: '',
                postalCode: ''
            }]
        }
        setInitialValues(tempInitialValues)
    }, [tabData[whichTab]]);

    const handleSave = async () => {
        try {
            await form.submit();
            setFormChanged(false);
            setLoader(true);
            setInitialValues([]);
            // form.resetFields();
        } catch (errorInfo) {
            console.error('Form submission failed:', errorInfo);
        }
    };

    if (dataLoader || postLoader || loader) {
        return <div className="data-loader">
            <Spin tip="Loading" size="large" />
        </div>
    } else {
        return (
            <div>
                {tabKey === whichTab ? (
                    <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {(!loading && initialValues) ?
                            <Col className="gutter-row tabContent" span={15}>
                                <Form.List
                                    name={contactUSkeys.address}
                                    initialValue={initialValues}
                                >
                                    {(fields, { add, remove }) => {
                                        return <div key={fields} >
                                            <div style={{ marginBottom: '10px' }} className="inputBody">
                                                <label></label>
                                                <div style={{ textAlign: "right" }}>
                                                    {fields && fields.length <= 1 ? (
                                                        <></>
                                                    ) : (
                                                        <Button
                                                            className="cancelBtn"
                                                            type="button"
                                                            onClick={() => {
                                                                const itemsToRemove = fields
                                                                    .filter((f, i) => {
                                                                        return !form.getFieldValue([contactUSkeys.address, i]) && i === 0;
                                                                    })
                                                                    .map(f => f.name);

                                                                if (itemsToRemove.length > 0) {
                                                                    itemsToRemove.forEach(name => remove(name));
                                                                }
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                    <Button className="greenBtn" onClick={() => add(undefined, 0)}>
                                                        Add More
                                                        <PlusOutlined />
                                                    </Button>
                                                </div>
                                            </div>

                                            {fields.map(({ key, name, ...restField }, i) => {
                                                return <React.Fragment key={key}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }} >
                                                        {/* <Form.Item
                                                            className="w-100"
                                                            label={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    Postal code
                                                                </div>
                                                            }
                                                            labelCol={{ span: 4 }}
                                                            {...restField}
                                                            name={[name, "postalCode"]}
                                                            rules={[{ required: true, message: "Postal Code is required" }]}
                                                        >
                                                            <Input placeholder="Enter postal Code" />

                                                        </Form.Item> */}

                                                        <Form.Item
                                                            className="w-100"
                                                            label={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    Full address including
                                                                    postal code
                                                                </div>
                                                            }
                                                            labelCol={{ span: 8 }}
                                                            {...restField}
                                                            name={[name, "street"]}
                                                            rules={[
                                                                { required: true, message: "Address is required" },
                                                                {
                                                                    max: MAX_CHARACTERS - 1,
                                                                    message: `Description should be less than ${MAX_CHARACTERS} character`,
                                                                }]}
                                                        >
                                                            <TextArea placeholder="Enter address with Street Name and postal-code"
                                                                rows={2}
                                                                maxLength={MAX_CHARACTERS}
                                                                showCount />
                                                        </Form.Item>
                                                        <div className="remove-btn" style={{ position: 'relative', display: 'flex' }} >
                                                            <DeleteOutlined
                                                                style={{ cursor: 'pointer', paddingRight: '10px' }}
                                                                title="Delete News"
                                                                onClick={() => {

                                                                    handleDelete(whichTab, form, i, tabKey)

                                                                }
                                                                }
                                                            />
                                                            <Tooltip title={"For locations on map"}>
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                    <Form.Item
                                                        className="w-100"
                                                        style={{ display: "flex", justifyContent: "end" }}
                                                    >
                                                        <Button
                                                            className="saveBtn greenBtn"
                                                            onClick={() => {
                                                                handleSave()
                                                            }}
                                                            disabled={!formChanged}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Form.Item>
                                                </React.Fragment>
                                            })}
                                        </div>
                                    }}
                                </Form.List>
                            </Col>
                            : <div className="data-loader">
                                <Spin tip="Loading" size="large" />
                            </div>}
                    </Row>) :
                    (
                        <></>
                    )

                }
            </div>
        )
    }
}
export default Address