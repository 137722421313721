import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Space,
  Spin,
  Tooltip,
  Divider,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import whyWork from "../../../organisations/json/whyWork.json";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import "../../../slider/imageSlider.scss";
import ReactPlayer from "react-player/youtube";

const { TextArea } = Input;
const MAX_CHARACTERS = 700;

export default function WhyWorkForUs({ dataLoader, form }) {
  const params = useParams();
  const { state } = useLocation();
  const inputRef = useRef(null);

  const tabKey = state;
  const { tabData } = useStore();
  const whichTab = TAB_KEYS.whyWorkForUs;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage } = useConnectsuForm();
  const [loader, setLoader] = useState(true);
  const [customName, setName] = useState("");
  // const [items, setItems] = useState(['item 1', 'item 2'])
  const [items, setItems] = useState(whyWork.map((item) => item.name));
  let index = 0;
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("tab", "WHY_WORK");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };

  const MakeFieldDisableHandler = (i) => {
    let value = form.getFieldValue([whichTab, i, "title"]);
    // if (value == "w1" || value == "w2" || value == "w3") {
    //   return true;
    // }
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  const onNameChange = (event) => {
    setName([event.target.value]);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, customName[0] || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <div className="card-item">
                            <Col className="gutter-row tabContent" span={16} style={{ paddingRight: "25px" }}>
                              <div className="d-flex">
                                <Form.Item
                                  className="custom-work"
                                  {...restField}
                                  label="Why work For us"
                                  labelCol={{ span: 5 }}
                                  name={[name, "title"]}
                                >
                                  <Select
                                    name={[name, "title"]}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: "8px 0",
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: "0 8px 4px",
                                          }}
                                        >
                                          <Input
                                            placeholder="Please enter item"
                                            ref={inputRef}
                                            value={customName}
                                            onChange={onNameChange}
                                          />
                                          <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={addItem}
                                          >
                                            Add another reason
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                    options={items.map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                  >
                                    {/* {whyWork.map((whyWork, i) => (
                                      <Select.Option key={i} value={whyWork.id}>
                                        {whyWork.name}
                                      </Select.Option>
                                    ))} */}
                                    {/* <Input placeholder="Enter title" /> */}
                                  </Select>
                                </Form.Item>

                                <Form.Item>
                                  {fields.length >= 1 &&
                                    // i !== 0 &&
                                    findValue(i, "id") &&
                                    !MakeFieldDisableHandler(i) ? (
                                    <div className="remove-btn">
                                      {/* <MinusCircleOutlined
                                      onClick={() =>
                                        deleteSingleItem(
                                          whichTab,
                                          form.getFieldValue([
                                            whichTab,
                                            i,
                                            "id",
                                          ]),
                                          form
                                        )
                                      }
                                    /> */}
                                      <DeleteOutlined
                                        onClick={() =>
                                          deleteSingleItem(
                                            whichTab,
                                            form.getFieldValue([
                                              whichTab,
                                              i,
                                              "id",
                                            ]),
                                            form
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </Form.Item>
                              </div>

                              <Form.Item
                                label="Description"
                                {...restField}
                                labelCol={{ span: 5 }}
                                name={[name, "description"]}
                                rules={[
                                  // {
                                  //   required: true,
                                  //   message: "Description is required",
                                  // },
                                  {
                                    max: MAX_CHARACTERS - 1,
                                    message: `Description should be less than ${MAX_CHARACTERS} character`,
                                  }
                                ]}
                              >
                                <TextArea
                                  placeholder="Enter Description"
                                  rows={5}
                                  maxLength={MAX_CHARACTERS}
                                  showCount
                                />
                              </Form.Item>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  name={[name, "mediaLink", "mediaType"]}
                                  className="w-100"
                                  label="Video or Image"
                                  labelCol={{ span: 5 }}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Select any option",
                                //   },
                                // ]}
                                // validateTrigger="onChange"
                                // shouldUpdate={(prev, cur) => {
                                // }}
                                >
                                  <Select
                                    name={[name, "mediaLink", "mediaType"]}
                                    onChange={(e) => SelectChangeHandler(e, i)}
                                    placeholder="Select Option"
                                    allowClear
                                  >
                                    <Select.Option value="VIMEO_MEDIA">
                                      Add Vimeo Link
                                    </Select.Option>
                                    <Select.Option value="SOCIAL_MEDIA">
                                      Add Youtube Link
                                    </Select.Option>
                                    <Select.Option value="IMAGE">
                                      Upload Image
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Upload Video link or image with size lesser than 2.5MB"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                <Form.Item
                                  label="Youtube Link"
                                  labelCol={{ span: 5 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Youtube link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                <Form.Item
                                  label="Vimeo Link"
                                  labelCol={{ span: 5 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Vimeo link is required",
                                //   },
                                // ]}
                                >
                                  <Input />
                                </Form.Item>
                              ) : findValue(i, "type") === "IMAGE" ? (
                                <Form.Item
                                  {...restField}
                                  // label={<ImageLabel label={"Image"} />}
                                  label="Image"
                                  colon={false}
                                  // label={<p style={{ fontSize: '16px', margin: '0px' }}><span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>Image</p>}
                                  name="media"
                                  className="experienceImg"
                                  labelCol={{ span: 5 }}
                                // rules={[
                                //   {
                                //     validator: (_, value) => {
                                //       return findValue(i, "blob")
                                //         ? Promise.resolve()
                                //         : findValue(i, "url")
                                //         ? Promise.resolve()
                                //         : findValue(i, "type") === "IMAGE"
                                //         ? Promise.reject(
                                //             new Error("Image is required")
                                //           )
                                //         : Promise.resolve();
                                //     },
                                //   },
                                // ]}
                                >
                                  {findValue(i, "blob") ? (
                                    <Avatar
                                      src={URL.createObjectURL(
                                        findValue(i, "blob")
                                      )}
                                      alt=""
                                    />
                                  ) : findValue(i, "url") ? (
                                    <Avatar src={findValue(i, "url")} alt="" />
                                  ) : (
                                    <Avatar src={UserIcon} />
                                  )}
                                  <label
                                    name="file"
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {" "}
                                    Choose File
                                    <input
                                      accept="image/png, image/gif, image/jpeg"
                                      type="file"
                                      // required={true}
                                      onChange={(e) =>
                                        updateImage(e, i, form, whichTab)
                                      }
                                    />
                                  </label>
                                </Form.Item>
                              ) : (
                                <></>
                              )}
                              <Form.Item
                                className="w-100"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                {formChanged ? (
                                  <Button
                                    className="saveBtn greenBtn"
                                    onClick={() => {
                                      form.submit().then(() => {
                                        setFormChanged(false);
                                      }).catch((errorInfo) => {
                                        console.error('Form submission failed:', errorInfo);
                                      });
                                    }}
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    className="saveBtn greenBtn"
                                    style={{
                                      opacity: "0.5",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row tabContent" span={8}>
                              <div>{renderMedia(tabData[whichTab][i])}</div>
                            </Col>
                          </div>
                        ))}

                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w1" &&
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w2" &&
                                      form.getFieldValue([
                                        whichTab,
                                        i,
                                        "title",
                                      ]) !== "w3" &&
                                      !findValue(i, "id") &&
                                      i !== 0
                                    ) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <Button className="greenBtn" onClick={() => add()}>
                              Add More
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form.List>
                </>
              </Col>

              {/*<Col className="gutter-row corousel-col" span={6}>
                {/* <CarouselContainer
                  data={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                /> */}
              {/* <ImageSlider data={tabData && tabData[whichTab] && tabData[whichTab].length > 0
                  ? tabData[whichTab]
                  : [""]} />
              </Col> */}
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    );
  }
}
