export const isUserLoggedIn = () => {
  const token = localStorage.getItem("token");
  if (token) {
    // const decryptedData = decryptData(token);
    if (token) {
      return true;
    } else {
      return false;
    }
  }
};

export function truncateFilename(filename, maxLength = 25) {
  if (filename.length <= maxLength) {
    return filename;
  } else {
    let basename = filename.substring(0, filename.lastIndexOf('.'));
    let extension = filename.substring(filename.lastIndexOf('.'));

    let truncatedName = basename.substring(0, maxLength - extension.length) + '...' + extension;
    return truncatedName;
  }
}