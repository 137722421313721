import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Input,
  InputNumber,
  Form,
  Select,
  DatePicker,
  Row,
  Col,
  Spin,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import moment from "moment";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS, dateFormat, dateTimeFormat } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ImageLabel from "../../../image-label";
import { formatData } from "../../../../utils/formatData";
import { setUpdatedValues } from "../../../../store/actions";
import ReactPlayer from "react-player/youtube";

const { TextArea } = Input;
const MAX_CHARACTERS = 250;

export default function ContractComponent({ dataLoader, form }) {
  const params = useParams();
  const { state } = useLocation();

  const tabKey = state;
  const { tabData } = useStore();
  const whichTab = TAB_KEYS.contract;
  const { deleteSingleItem, postLoader, callMicroServices } = useTab();
  const { updateImage } = useConnectsuForm();
  const [loader, setLoader] = useState(true);
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues, dispatch } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("tab", "CONTRACT");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const validatePostalCode = (value) => {
    const ukPostalCodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/;
    if (ukPostalCodeRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };
  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };
  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  const submit = (info) => {
    const formattedData = formatData(
      "contract",
      info["contract"],
      updatedValues
    );
    console.log("calling microservice  formatteddata", formattedData);
    // form.resetFields()
    if (Object.keys(formattedData).length > 0) {
      dispatch(setUpdatedValues({}));
      console.log("calling microservice  formatteddata", formattedData);
      callMicroServices("contract", formattedData, form);
      console.log("called microservice");
    } else {
      toast.error("Nothing to update");
    }
  };

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <>
                  <Form.List
                    name={whichTab}
                    initialValue={
                      tabData &&
                        tabData[whichTab] &&
                        tabData[whichTab].length > 0
                        ? tabData[whichTab]
                        : [""]
                    }
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <div className="card-item">
                            <Col
                              className="gutter-row tabContent"
                              span={16}
                              style={{ paddingRight: "25px" }}
                            >
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  label="Contract Title"
                                  className="w-100"
                                  name={[name, "title"]}
                                  labelCol={{ span: 6 }}
                                >
                                  <Input
                                    placeholder="Contract Title"
                                  />
                                </Form.Item>
                                <Form.Item>
                                  {fields.length >= 1 && findValue(i, "id") && (
                                    <div className="remove-btn">
                                      <DeleteOutlined
                                        onClick={() =>
                                          deleteSingleItem(
                                            whichTab,
                                            form.getFieldValue([
                                              whichTab,
                                              i,
                                              "id",
                                            ]),
                                            form
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </Form.Item>
                              </div>
                              <Form.Item
                                {...restField}
                                className="w-100"
                                label="Procurement Reference"
                                name={[name, "procurementReference"]}
                                labelCol={{ span: 6 }}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Procurement Reference is required",
                              //   },
                              // ]}
                              >
                                <Input placeholder="Procurement Reference" />
                              </Form.Item>
                              <Form.Item
                                label="Description"
                                {...restField}
                                name={[name, "description"]}
                                labelCol={{ span: 6 }}
                                rules={[
                                  // {
                                  //   required: true,
                                  //   message: "Description is required",
                                  // },
                                  {
                                    max: MAX_CHARACTERS - 1,
                                    message: `Description should be less than ${MAX_CHARACTERS} character`,
                                  }
                                ]}
                              >
                                <TextArea
                                  placeholder="Enter Description"
                                  showCount
                                  rows={5}
                                  maxLength={MAX_CHARACTERS}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Postal code"
                                name={[name, "postalCode"]}
                                labelCol={{ span: 6 }}
                                rules={[
                                  {
                                    // required: true,
                                    validator: (_, value) =>
                                      validatePostalCode(value),
                                    // message: "Please enter a valid UK postal code",
                                  },
                                ]}
                              >
                                <TextArea rows={1} placeholder="postal code" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Value Of Contract"
                                name={[name, "valueOfContract"]}
                                labelCol={{ span: 6 }}
                                rules={[
                                  {
                                    // required: true,
                                    // message: "Annual Salary is required",
                                    type: "regexp",
                                    pattern: new RegExp(/\d+/g),
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  placeholder="Value Of Contract"
                                  min={0}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Start date"
                                name={[name, "startDate"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "End Date is required",
                                //   },
                                // ]}
                                labelCol={{ span: 6 }}
                              >
                                <DatePicker
                                  format={dateFormat}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                {...restField}
                                label="End date"
                                name={[name, "endDate"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "End Date is required",
                                //   },
                                // ]}
                                labelCol={{ span: 6 }}
                              >
                                <DatePicker
                                  format={dateFormat}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Closing date"
                                name={[name, "closeDate"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Closing Date is required",
                                //   },
                                // ]}
                                labelCol={{ span: 6 }}
                              >
                                <DatePicker
                                  format={dateFormat}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                label="Closing time"
                                name={[name, "closeTime"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Closing Time is required",
                                //   },
                                // ]}
                                labelCol={{ span: 6 }}
                              >
                                <DatePicker
                                  showTime
                                  format={dateTimeFormat}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-100"
                                label="Contract Type"
                                name={[name, "contractType"]}
                                labelCol={{ span: 6 }}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Contract Type is required",
                              //   },
                              // ]}
                              >
                                <Input placeholder="Contract Type" />
                              </Form.Item>
                              <Form.Item
                                label="Web Link"
                                {...restField}
                                className="w-100"
                                name={[name, "webLink"]}
                                // rules={[
                                //   {
                                //     required: false,
                                //     message: "Web Link is required",
                                //   },
                                // ]}
                                labelCol={{ span: 6 }}
                              >
                                <Input
                                  placeholder="Web Link"
                                  name={[name, "webLink"]}
                                />
                              </Form.Item>
                              <div className="d-flex">
                                <Form.Item
                                  {...restField}
                                  className="w-100"
                                  name={[name, "mediaLink", "mediaType"]}
                                  label="Video or Image"
                                  labelCol={{ span: 6 }}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Select any option",
                                //   },
                                // ]}
                                >
                                  <Select
                                    name={[name, "mediaLink", "mediaType"]}
                                    onChange={(e) => SelectChangeHandler(e, i)}
                                    placeholder="Select Option"
                                  >
                                    <Select.Option value="VIMEO_MEDIA">
                                      Add Vimeo Link
                                    </Select.Option>
                                    <Select.Option value="SOCIAL_MEDIA">
                                      Add Youtube Link
                                    </Select.Option>
                                    <Select.Option value="IMAGE">
                                      Upload Image
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <div className="remove-btn">
                                    <Tooltip
                                      title={
                                        "Upload Video link or image with size lesser than 2.5MB"
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                </Form.Item>
                              </div>
                              {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                                <Form.Item
                                  label="Youtube Link"
                                  {...restField}
                                  labelCol={{ span: 6 }}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Youtube link is required",
                                //   },
                                // ]}
                                >
                                  <Input placeholder="Youtube Link" />
                                </Form.Item>
                              ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                                <Form.Item
                                  label="Vimeo Link"
                                  labelCol={{ span: 6 }}
                                  {...restField}
                                  name={[name, "mediaLink", "url"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Vimeo link is required",
                                //   },
                                // ]}
                                >
                                  <Input placeholder="Vimeo Link" />
                                </Form.Item>
                              ) : findValue(i, "type") === "IMAGE" ? (
                                <Form.Item
                                  {...restField}
                                  label="Image"
                                  colon={false}
                                  name="media"
                                  className="experienceImg"
                                  labelCol={{ span: 6 }}
                                // rules={[
                                //   {
                                //     validator: (_, value) => {
                                //       return findValue(i, "blob")
                                //         ? Promise.resolve()
                                //         : findValue(i, "url")
                                //           ? Promise.resolve()
                                //           : Promise.reject(
                                //             new Error("Image is required")
                                //           );
                                //     },
                                //   },
                                // ]}
                                >
                                  {findValue(i, "blob") ? (
                                    <Avatar
                                      src={URL.createObjectURL(
                                        findValue(i, "blob")
                                      )}
                                      alt=""
                                    />
                                  ) : findValue(i, "url") ? (
                                    <Avatar src={findValue(i, "url")} alt="" />
                                  ) : (
                                    <Avatar src={UserIcon} />
                                  )}

                                  <label
                                    name="file"
                                    className="fileUploadBtn greenBtn"
                                  >
                                    {" "}
                                    Choose File
                                    <input
                                      accept="image/png, image/gif, image/jpeg"
                                      type="file"
                                      // required={true}
                                      onChange={(e) =>
                                        updateImage(e, i, form, whichTab)
                                      }
                                    />
                                  </label>
                                </Form.Item>
                              ) : (
                                <></>
                              )}

                              <Form.Item
                                {...restField}
                                className="w-100"
                                label="Email"
                                name={[name, "email"]}
                                labelCol={{ span: 6 }}
                                rules={[
                                  // { required: true, message: "Email is required" },
                                  {
                                    type: "email",
                                    message: "Enter valid email",
                                  },
                                ]}
                              >
                                <Input placeholder="Email" />
                              </Form.Item>
                              <Form.Item
                                className="w-100"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                {formChanged ? (
                                  <Button
                                    className="saveBtn greenBtn"
                                    onClick={() => {
                                      console.log("form", form.getFieldValue());
                                      submit(form.getFieldValue());
                                    }}
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    className="saveBtn greenBtn"
                                    style={{
                                      opacity: "0.5",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row tabContent" span={8}>
                              <div>{renderMedia(tabData[whichTab][i])}</div>
                            </Col>
                          </div>
                        ))}

                        <div className="inputBody">
                          <label></label>
                          <div style={{ textAlign: "right" }}>
                            {fields && fields.length <= 1 ? (
                              <></>
                            ) : (
                              <Button
                                className="cancelBtn"
                                type="button"
                                onClick={() => {
                                  const itemsToRemove = [];
                                  fields.forEach((f, i) => {
                                    if (!findValue(i, "id") && i !== 0) {
                                      itemsToRemove.push(f.name);
                                    }
                                  });
                                  if (itemsToRemove.length > 0) {
                                    remove(itemsToRemove);
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            )}
                            <Button className="greenBtn" onClick={() => add()}>
                              Add More
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Form.List>
                </>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    );
  }
}
