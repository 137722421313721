import React, { useEffect, useState, useRef } from "react";
import { Spin, Form, Select, Dropdown, Button as AntDButton } from "antd";
import Button from "../buttons/Button";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import { Chart as GChart } from "react-google-charts";
import geoJsonData from "./populationData.json";
import { MapContainer, GeoJSON, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Duration from "../../components/duration-filter/duration.jsx"
import { Chart, Line } from "react-chartjs-2";
import "./org-analytics.scss";
import { getRequest } from "../../api/http";
import { useNavigate } from "react-router-dom";
import userServiceApi from "../../api/userServiceApi";
import backArrow from "../../assets/images/back-arrow.svg";
import ReactToPrint from "react-to-print";
import { FileExcelOutlined, FilePdfOutlined, FundViewOutlined, DownloadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Organalytics() {
  const navigate = useNavigate();
  const orgId = localStorage.getItem("OrgId");
  const currentYear = new Date().getFullYear();

  // const [loader, setLoader] = useState(true);
  const [overallViewCount, setOverallViewCount] = useState();
  const [updatedJSON, setUpdatedJSON] = useState();
  const [localLoader, setLocalLoader] = useState(true);

  const [selectedAgeID, setSelectedAgeID] = useState();
  const [selectedAge, setSelectedAge] = useState();
  const [selectedProfID, setSelectedProfID] = useState();
  const [professions, setProfessions] = useState();
  const [selectedProf, setSelectedProf] = useState();

  const [activeCounty, setActiveCounty] = useState(null);


  const [loader, setLoader] = useState(true);

  const [selected, setSelected] = useState("National");

  const [totalInternational, setTotalInternational] = useState();
  const [totalNational, setTotalNational] = useState();
  const [totalLocal, setTotalLocal] = useState();

  const [internationalUsers, setInternationalUsers] = useState();
  const [nationalUsers, setNationalUsers] = useState();

  const [filterType, setFilterType] = useState();
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [yearDropdownOptions, setYearDropdownOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();

  const [showYearLocationDropdown, setShowYearLocationDropdown] = useState(false);
  const [locationFilterType, setLocationFilterType] = useState();
  const [showLocationQuarterly, setShowLocationQuarterly] = useState(false);
  const [showLocationMonthly, setShowLocationMonthly] = useState(false);

  const [selectedLocationMonth, setSelectedLocationMonth] = useState();
  const [selectedLocationYear, setSelectedLocationYear] = useState();
  const [selectedLocationQuarter, setSelectedLocationQuarter] = useState();

  const [showQuarterly, setShowQuarterly] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState();
  const [showMonthly, setShowMonthly] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();

  const [lineGraphData, setLineGraphData] = useState(null);
  const [micrositeData, setMicrositeData] = useState(null);
  const [micrositesList, setMicrositesList] = useState();

  const [shareDropdown, setShareDropdown] = useState(false)
  const componentRef = useRef();


  const getOrgAnalyticsData = async (orgId, selectedMonth, selectedYear, selectedQuarter) => {
    try {
      const res = await getRequest(userServiceApi.orgAnalytics(orgId, selectedMonth, selectedYear, selectedQuarter));
      // console.log("res", res);
      let labels = [];
      let lineData = [];
      if (res && res.data) {
        res.data.map((month) => {
          // console.log(month.monthYear, month.count)
          labels.push(month.monthYear);
          lineData.push(month.count);
        });

        setLineGraphData({
          labels,
          datasets: [
            {
              label: "Views",
              // data: [24, 59, 76, 45, 14, 98, 87, 34, 56, 18, 54, 78],
              data: lineData,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        });
      }
    } catch (error) { }
  };

  const getOverallMicrositeData = async (orgId, selectedMonth, selectedYear, selectedQuarter) => {
    try {
      const res = await getRequest(
        userServiceApi.orgOverallMicrositeAnalytics(orgId, selectedMonth, selectedYear, selectedQuarter)
      );
      // console.log("res", res);
      let labels = [];
      let barGraphData = [];
      if (res && res.data) {
        setMicrositesList(res.data);
        res.data.map((microsite) => {
          labels.push(microsite.micrositeName);
          barGraphData.push(microsite.count);
        });

        setMicrositeData({
          labels,
          datasets: [
            {
              type: "bar",
              label: "Views",
              backgroundColor: "rgb(2,110,204)",
              data: barGraphData,
              borderColor: "white",
              borderWidth: 2,
              fill: true,
            },
          ],
        });
      }
    } catch (error) { }
  };

  const getOverallViewCount = async (orgId) => {
    try {
      const res = await getRequest(userServiceApi.overallViewCount(orgId));
      // console.log("res", res)
      if (res && res.data) {
        setOverallViewCount(res.data);
      }
    } catch (error) { }
  };

  const handleCSVDownload = async () => {
    try {
      const res = await getRequest(
        userServiceApi.downloadOrgEngagementCSV(
          localStorage.getItem('OrgId')
        )
      );
      // console.log("res", res.data)
      if (res) {
        const csvData = res.data;
        // console.log("csvData", csvData)
        downloadCSV(csvData);
        // setCSVData(csvData);
      }
    } catch (error) {
      toast.error("CSV couldn't be downloaded");
    }
  };

  const downloadCSV = (csvData) => {
    if (!csvData) return;

    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const filename = `User_Engagement_data_${day}-${month}-${year}.csv`;

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    // setTimeout(() => {
    //     toast.success("CSV downloaded");
    // }, 1000)
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (orgId) {
      getOverallViewCount(orgId);
      getOrgAnalyticsData(orgId);
      getOverallMicrositeData(orgId);
    }
  }, []);

  const getYearsDropdown = () => {
    const currentYear = new Date().getFullYear();
    let yearsarray = [];
    for (let year = 2023; year <= currentYear + 20; year++) {
      // console.log(year)
      // yearsarray.push({ label: year, value: year });
      yearsarray.push(year);
    }
    setYearDropdownOptions(yearsarray);
  };

  // console.log("years", yearDropdownOptions)

  useEffect(() => {
    getYearsDropdown();
  }, []);


  const getLocationAnalytics = async (orgId, selectedAgeID, selectedProfID, filterType, year, month, quarter) => {
    try {
      const res = await getRequest(userServiceApi.locationAnalytics(orgId, selectedAgeID, selectedProfID, filterType, year, month, quarter))
      if (res) {
        setTotalInternational(res?.data?.internationalUsers?.totalCount);
        setTotalNational(res?.data?.nationalUsers?.totalCount);
        setTotalLocal(res?.data?.localUsers?.totalCount);

        const tempinternationalUsers = [["Country", "No. of Users"]];
        const tempnationalUsers = [["State", "No. of Users"]];
        const tempupdatedJSON = {
          type: "FeatureCollection",
          features: [],
        };

        res?.data?.internationalUsers?.countryCount?.map((country) => {
          let temp = [];
          temp.push(country.country);
          temp.push(country.count);
          // console.log("temp", temp)
          // internationalUsers.push(temp);
          tempinternationalUsers.push(temp);
        });

        res?.data?.nationalUsers?.nationalCount?.map((country) => {
          let temp = [];
          temp.push(country.state);
          temp.push(country.count);
          tempnationalUsers.push(temp);
        });

        const populationData = res?.data?.localUsers?.countyCount;

        const updatedGeoJSON = geoJsonData.features.map((feature) => {
          // Get the county name from GeoJSON
          const countyName = feature.properties.ctyua_name[0];
          // console.log("countyname ---> ", countyName)

          // Find the corresponding population data
          const populationInfo = populationData.find(
            (population) => population.county === countyName
          );

          // If population data is found, update the GeoJSON properties
          if (populationInfo) {
            feature.properties.population = populationInfo.count;
          } else {
            // If county is not present in population data, set population to 0
            feature.properties.population = 0;
          }
          // updatedJSON?.features.push(feature);
          tempupdatedJSON?.features.push(feature);
          return feature;
        });

        setInternationalUsers(tempinternationalUsers);
        setNationalUsers(tempnationalUsers);
        setUpdatedJSON(tempupdatedJSON);
        setLoader(false);
        setLocalLoader(false);
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (orgId) {
      getLocationAnalytics(orgId);
    }
  }, [])

  const handleAgeCard = (age) => {
    setSelectedProf();
    setSelectedProfID();
    setSelectedAge(age);
    if (age === undefined) {
      setSelectedAgeID();
      setSelectedProfID();
    }
  };

  useEffect(() => {
    getOrgAnalyticsData(
      orgId,
      filterType === "monthly" && selectedMonth,
      (filterType === "yearly" ||
        filterType === "monthly" ||
        filterType === "quarterly") &&
      selectedYear,
      filterType === "quarterly" && selectedQuarter
    );
    getOverallMicrositeData(
      orgId,
      filterType === "monthly" && selectedMonth,
      (filterType === "yearly" ||
        filterType === "monthly" ||
        filterType === "quarterly") &&
      selectedYear,
      filterType === "quarterly" && selectedQuarter
    );
  }, [
    selectedYear,
    selectedQuarter,
    selectedMonth,
  ]);

  const getProfessions = async () => {
    let id;
    if (selectedAge === "18") {
      id = "3923bb48-79f8-4a9e-98f8-b604137197cc";
    } else if (selectedAge === "22") {
      id = "0ae3762c-d05f-4762-8a02-1ba071fcbcdc";
    } else if (selectedAge === "37") {
      id = "7ab21967-37bc-41ed-8fef-d52247d30acd";
    } else if (selectedAge === "53") {
      id = "7683d02e-8311-4051-aa14-7671fe5bab2f";
    } else {
      id = "2b47e4f5-c72b-4429-973f-8408719c1a53";
    }

    setSelectedAgeID(id);
    try {
      const response = await getRequest(userServiceApi.getProfessions(id));
      if (response) {
        // console.log('ageres', response)
        setProfessions(response.data);
      }
    } catch (error) {
      // console.log("error is professions API")
    }
  };

  useEffect(() => {
    if (
      selectedAge === "18" ||
      selectedAge === "22" ||
      selectedAge === "37" ||
      selectedAge === "53" ||
      selectedAge === "54"
    ) {
      getProfessions();
    }
  }, [selectedAge]);

  useEffect(() => {
    getLocationAnalytics(
      orgId,
      selectedAgeID,
      selectedProfID,
      ((locationFilterType === "yearly" && selectedLocationYear) ||
        (locationFilterType === "monthly" && selectedLocationMonth) ||
        (locationFilterType === "quarterly" && selectedLocationQuarter)) &&
      locationFilterType,
      (locationFilterType === "yearly" ||
        locationFilterType === "monthly" ||
        locationFilterType === "quarterly") &&
      selectedLocationYear,
      locationFilterType === "monthly" && selectedLocationMonth,
      locationFilterType === "quarterly" && selectedLocationQuarter
    );
  }, [selectedAgeID,
    selectedProfID,
    selectedLocationYear,
    selectedLocationQuarter,
    selectedLocationMonth])

  const getMicrositeAnalytics = async (index) => {
    let micrositeData = micrositesList[index];
    navigate(`/microsite-analytics/${micrositeData.micrositeId}`, { state: { micrositeName: micrositeData.micrositeName, page: "organalytics", orgId: orgId } })
  };

  const handleSelected = (value) => {
    setSelected(value);
  };

  const getColor = (population) => {
    // Implement your own logic to set colors based on population ranges
    return population > 5000
      ? "#032034"
      : population > 1000
        ? "#053656"
        : population > 500
          ? "#074b79"
          : population > 200
            ? "#08619b"
            : population > 100
              ? "#0a76be"
              : population > 50
                ? "#0c8be1"
                : // population > 50 ? '#021523' :
                "#1e9ef3";
    // '#e8300c';
  };

  const options = {
    // region: "002", // Africa
    colorAxis: { colors: ["#0f97f2", "#065e99", "#04253b"] },
    // colorAxis: { colors: ["#0cf041", "078f27", "#033b10"] },
    backgroundColor: "#FFFFF",
    // backgroundColor: "#81d4fa",
    // datalessRegionColor: "#f8bbd0",

    // datalessRegionColor: '#81D4FA',
    defaultColor: "#f5f5f5",
    defaultColor: "#FFFFF",
    region: "GB", // ISO 3166-1 alpha-2 code for the United Kingdom
    displayMode: "regions",
    resolution: "provinces",
  };

  const options2 = {
    // region: "002", // Africa
    colorAxis: { colors: ["#0f97f2", "#065e99", "#04253b"] },
    // colorAxis: { colors: ["#0cf041", "078f27", "#033b10"] },
    backgroundColor: "#FFFFF",
    // backgroundColor: "#81d4fa",
    // datalessRegionColor: "#f8bbd0",

    // datalessRegionColor: '#81D4FA',
    defaultColor: "#f5f5f5",
    defaultColor: "#FFFFF",
    // region: 'GB', // ISO 3166-1 alpha-2 code for the United Kingdom
    // displayMode: 'regions',
    // resolution: 'provinces',
  };

  const BarChartOptions = {
    title: "No. of users based on province",
    // chartArea: { width: "50%" },
    hAxis: {
      title: "Total Population",
      minValue: 0,
    },
    vAxis: {
      title: "Province",
    },
  };

  const BarChartOptions2 = {
    title: "No. of users based on countries",
    // chartArea: { width: "50%" },
    hAxis: {
      title: "Total Population",
      minValue: 0,
    },
    vAxis: {
      title: "Country",
    },
  };

  const OptionsHandler = (value) => {
    return {
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: "black",
          },
          title: {
            display: true,
            text: "Microsites",
            color: "black",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            color: "black",
          },
          title: {
            display: true,
            text: "Number of views",
            color: "black",
          },
        },
      },
      plugins: {
        legend: {
          position: "top",
        },
        title: value,
        datalabels: {
          formatter: Math.round,
          anchor: "end",
          offset: -20,
          align: "start",
        },
      },
      onClick: function (evt, element) {
        // setMicrositeModal(true)
        getMicrositeAnalytics(element[0].index);
        // console.log("clicked ", element, value)
      },
    };
  };

  const Lineoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
      datalabels: {
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start",
      },
    },
  };

  const renderChoroplethMap = () => {
    // Assume 'geoJsonData' is the GeoJSON data for UK counties
    // 'populationData' is the merged population data with GeoJSON data

    const onEachCounty = (county, layer) => {
      layer.on({
        mouseover: (e) => {
          const layer = e.target;
          layer.setStyle({ fillOpacity: 1 });

          const population = layer.feature.properties.population || "0";
          const popupContent = `
                    <b>${layer.feature.properties.ctyua_name}</b><br>
                    No. of people: ${population}
                  `;
          layer.bindPopup(popupContent).openPopup();

          setActiveCounty(layer);
        },
        mouseout: () => {
          if (activeCounty) {
            activeCounty.setStyle({ fillOpacity: 0.9 });
          }
          // activeCounty.closePopup();
        },
      });

      // Use the population data to set style or add popups for each county
      const population = county.properties.population; // Assuming 'name' contains the county name
      // console.log("population", population)
      if (population >= 1) {
        // Customize styles based on population, for example:
        layer.setStyle({
          fillColor: getColor(population),
          weight: 2,
          color: getColor(population),
          fillOpacity: 0.7,
        });
      } else {
        layer.setStyle({
          fillColor: "#8c8a89",
          weight: 1,
          color: "#8c8a89",
          fillOpacity: 0.4,
        });
      }
    };
    // console.log("updatedJSON in render func ---> ", updatedJSON);

    return (
      <MapContainer
        className="map-component"
        center={[54.8, -4.6]}
        zoom={6}
        minZoom={5}
      // style={{ height: '450px', width: '70vw' }}
      >
        <TileLayer
          attribution=""
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Dark mode */}
        {/* <TileLayer
                    // attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
                    attribution=""
                    url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
                /> */}
        <GeoJSON
          // data={geoJsonData.features}
          data={updatedJSON.features}
          onEachFeature={onEachCounty}
        />
      </MapContainer>
    );
  };

  if (loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    )
  } else {
    return (
      <div ref={componentRef} className="tabContainer analytics-tab">
        <div className="header page-header" style={{ width: '100%' }} >
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
              <div className="backbtn-container">
                <img src={backArrow} alt="" onClick={() => navigate('/')} />
                <p>Engagement</p>
              </div>
              <div>
                <Dropdown
                  open={shareDropdown}
                  trigger={["click"]}
                  placement="bottomRight"
                  arrow={false}
                  onOpenChange={(open, e) => setShareDropdown(open)}
                  overlay={
                    <div
                      className="dropdown"
                      style={{
                        border: "1px solid #3067AE",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        width: "190px",
                      }}
                    >
                      <>
                        {/* <div
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #f0f0f0",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="dropdown-items"
                              style={{
                                display: "flex",
                                alignItems: 'center'
                              }}
                              onClick={handleViewCsv}
                            >
                              <FundViewOutlined className="pdf-icon" />
                              <label
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                              >
                                View  CSV
                              </label>
                            </div>
                          </div> */}
                        <div
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #f0f0f0",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="dropdown-items"
                            style={{
                              display: "flex",
                              alignItems: 'center'
                            }}
                            onClick={handleCSVDownload}
                          >
                            <FileExcelOutlined className="pdf-icon" />
                            <label
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                            >
                              Download as CSV
                            </label>
                          </div>
                        </div>
                        <ReactToPrint
                          copyStyles={true}
                          content={() => componentRef.current}
                          // onAfterPrint={() => {
                          //     toast.success("PDF downloaded successfully")
                          // }}
                          trigger={() => {
                            return (
                              <div
                                style={{
                                  padding: "13px",
                                  borderBottom: "1px solid #f0f0f0",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  className="dropdown-items"
                                  style={{
                                    display: "flex",
                                    alignItems: 'center'
                                  }}
                                >
                                  <FilePdfOutlined className="pdf-icon" />
                                  <label
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Download as PDF
                                  </label>
                                </div>
                              </div>
                            );
                          }}
                        ></ReactToPrint>
                      </>
                    </div>
                  }
                >
                  <AntDButton type="primary" icon={<DownloadOutlined />} style={{ backgroundColor: '#3067ae' }} > Download viewership data</AntDButton>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-box">
          <div className="filters-container-analytics">
            <div className="ages">
              <div className="age-cards">
                <div
                  className={`card ${selectedAge === "18" ? "selected-age" : ""
                    }`}
                  onClick={() =>
                    selectedAge === "18" ? handleAgeCard() : handleAgeCard("18")
                  }
                >
                  <p className="age-card-content">{`<18`}</p>
                </div>
                <div
                  className={`card ${selectedAge === "22" ? "selected-age" : ""
                    }`}
                  onClick={() =>
                    selectedAge === "22" ? handleAgeCard() : handleAgeCard("22")
                  }
                >
                  <p className="age-card-content">19-22</p>
                </div>
                <div
                  className={`card ${selectedAge === "37" ? "selected-age" : ""
                    }`}
                  onClick={() =>
                    selectedAge === "37" ? handleAgeCard() : handleAgeCard("37")
                  }
                >
                  <p className="age-card-content">23-37</p>
                </div>
                <div
                  className={`card ${selectedAge === "53" ? "selected-age" : ""
                    }`}
                  onClick={() =>
                    selectedAge === "53" ? handleAgeCard() : handleAgeCard("53")
                  }
                >
                  <p className="age-card-content">38-53</p>
                </div>
                <div
                  className={`card ${selectedAge === "54" ? "selected-age" : ""
                    }`}
                  onClick={() =>
                    selectedAge === "54" ? handleAgeCard() : handleAgeCard("54")
                  }
                >
                  <p className="age-card-content">54+</p>
                </div>
              </div>
            </div>
            <div className="profs">
              <div className="prof-cards">
                <Form.Item
                  label="Profession"
                  colon={false}
                  className="select-formitem"
                >
                  <Select
                    allowClear
                    className="customSelect"
                    // placeholder={firstValue}
                    disabled={selectedAge ? false : true}
                    placeholder="Select Profession"
                    value={selectedProf}
                    onChange={(e) => {
                      const profID = professions?.find(
                        (element) => element.tag.userTag === e
                      );
                      setSelectedProfID(profID?.tag?.id);
                      // console.log(profID?.tag?.id);
                      setSelectedProf(e);
                    }}
                    onClear={() => {
                      setSelectedProf();
                      setSelectedProfID();
                    }}
                  >
                    {professions &&
                      professions?.map((ageGroupData, i) => (
                        <Select.Option
                          key={ageGroupData.tag.id}
                          value={ageGroupData.tag.userTag}
                        >
                          {ageGroupData.tag.userTag}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="add-filter-btns">
            <Form.Item className="term-select" label="Term" colon={false}>
              <Select
                allowClear
                className="customSelect"
                // placeholder={firstValue}
                placeholder="Select Term"
                value={locationFilterType}
                onChange={(e) => {
                  setLocationFilterType(e);
                  if (e == "quarterly") {
                    setShowLocationMonthly(false);
                    setSelectedLocationMonth();
                    setSelectedLocationYear();
                    setSelectedLocationQuarter();
                    setShowYearLocationDropdown(true);
                    setShowLocationQuarterly(true);
                  } else if (e == "monthly") {
                    setSelectedLocationMonth();
                    setSelectedLocationYear();
                    setSelectedLocationQuarter();
                    setShowYearLocationDropdown(true);
                    setShowLocationQuarterly(false);
                    setShowLocationMonthly(true);
                  } else if (e == "yearly") {
                    setSelectedLocationMonth();
                    setSelectedLocationYear();
                    setSelectedLocationQuarter();
                    setShowLocationMonthly(false);
                    setShowLocationQuarterly(false);
                    setShowYearLocationDropdown(true);
                  }
                }}
                onClear={() => {
                  setLocationFilterType();
                  setSelectedLocationYear();
                  setSelectedLocationMonth();
                  setSelectedLocationQuarter();
                  setShowYearLocationDropdown(false);
                  setShowLocationMonthly(false);
                  setShowLocationQuarterly(false);
                }}
                options={[
                  {
                    value: "yearly",
                    label: "Yearly",
                  },
                  {
                    value: "monthly",
                    label: "Monthly",
                  },
                  {
                    value: "quarterly",
                    label: "Quarterly",
                  },
                ]}
              ></Select>
            </Form.Item>
            {showYearLocationDropdown && (
              <Form.Item className="term-select align-right-class" label="Year" colon={false}>
                <Select
                  allowClear
                  className="customSelect"
                  // placeholder={firstValue}
                  placeholder="Select Year"
                  value={selectedLocationYear}
                  onChange={(e) => {
                    // console.log("e", e)
                    setSelectedLocationYear(e);
                  }}
                  onClear={() => {
                    setSelectedLocationYear();
                    setSelectedLocationMonth();
                    setSelectedLocationQuarter();
                    setShowLocationQuarterly(false);
                    setShowLocationMonthly(false);
                    setShowYearLocationDropdown(false);
                    setLocationFilterType();
                  }}
                >
                  {yearDropdownOptions.length > 0 &&
                    yearDropdownOptions.map((year, i) => (
                      // console.log('year', year)
                      <Select.Option
                        disabled={year > currentYear ? true : false}
                        key={year}
                        value={year}
                      >
                        {year}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {showLocationMonthly && (
              <Form.Item className="term-select align-right-class" label="Month" colon={false}>
                <Select
                  allowClear
                  className="customSelect"
                  // placeholder={firstValue}
                  disabled={selectedLocationYear ? false : true}
                  placeholder="Select Month"
                  value={selectedLocationMonth}
                  onChange={(e) => {
                    // console.log("e", e)
                    setSelectedLocationMonth(e);
                  }}
                  onClear={() => {
                    setSelectedLocationMonth();
                    setSelectedLocationYear();
                  }}
                  options={[
                    { value: "1", label: "January" },
                    { value: "2", label: "February" },
                    { value: "3", label: "March" },
                    { value: "4", label: "April" },
                    { value: "5", label: "May" },
                    { value: "6", label: "June" },
                    { value: "7", label: "July" },
                    { value: "8", label: "August" },
                    { value: "9", label: "September" },
                    { value: "10", label: "October" },
                    { value: "11", label: "November" },
                    { value: "12", label: "December" },
                  ]}
                ></Select>
              </Form.Item>
            )}
            {showLocationQuarterly && (
              <Form.Item className="term-select align-right-class" label="Quarter" colon={false}>
                <Select
                  allowClear
                  className="customSelect"
                  // placeholder={firstValue}
                  placeholder="Select Quarter"
                  value={selectedLocationQuarter}
                  disabled={selectedLocationYear ? false : true}
                  onChange={(e) => {
                    console.log("e", e)
                    setSelectedLocationQuarter(e);
                  }}
                  onClear={() => {
                    setSelectedLocationQuarter();
                    // setFilterType();
                    setSelectedLocationYear();
                    // setShowQuarterly(false);
                    // setShowYearDropdown(false);
                  }}
                  options={[
                    {
                      value: "1",
                      label: "Q1",
                    },
                    {
                      value: "2",
                      label: "Q2",
                    },
                    {
                      value: "3",
                      label: "Q3",
                    },
                    {
                      value: "4",
                      label: "Q4",
                    },
                  ]}
                ></Select>
              </Form.Item>
            )}
          </div>
        </div>
        <div className="overall-views-graph">
          <div className="microsite-data">
            {/* <h2 className="all-time-count">
                    <b>Based on location</b>
                  </h2> */}
            <div className="selection-container" style={{ display: "flex", alignItems: "center" }}>
              <Duration
                options={["Local", "National", "International"]}
                selected={handleSelected}
              />
              <div className="user-count">
                <p className="total-count">
                  {selected === "Local"
                    ? totalLocal
                    : selected === "National"
                      ? totalNational
                      : totalInternational}
                </p>
                <div className="count-label">
                  <p>{`${selected === "Local"
                    ? `Local`
                    : selected === "National"
                      ? `National`
                      : `International`
                    }`}</p>
                  <p>No. of users</p>
                </div>
              </div>
            </div>
            <div className="users-by-map">
              {selected === "Local" ? (
                <div className="charts">
                  {
                    !localLoader && updatedJSON ? (
                      <div className="local-chart">
                        {Object.keys(updatedJSON).length === 0 && (
                          <div>Loading...</div>
                        )}
                        {Object.keys(updatedJSON).length > 0 &&
                          renderChoroplethMap()}
                        <div>
                          <div className="gradient-legend"></div>
                          <div className="text-legend">
                            <div className="start">
                              <p style={{ marginLeft: "0px" }}>less than 50</p>
                            </div>
                            <div className="end">
                              <p style={{ marginRight: "0px" }}>
                                greater than 5000
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="data-loader">
                        <Spin
                          className="loading-svg"
                          tip="Loading"
                          size="large"
                        />
                      </div>
                    )}
                </div>
              ) : selected === "National" ? (
                nationalUsers?.length > 1 ? (
                  <div className="charts">
                    <div className="geoChart">
                      <GChart
                        // className="geoChart"
                        className="gchart"
                        chartType="GeoChart"
                        width="100%"
                        height="400px"
                        loader={true}
                        // data={data}
                        data={nationalUsers}
                        options={options}
                      />
                    </div>
                    <div className="barChart">
                      <GChart
                        // className="barChart"
                        className="gchart"
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        loader={true}
                        // data={data}
                        data={nationalUsers}
                        options={BarChartOptions}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="sub-header">No data available</p>
                  </>
                )
              ) : internationalUsers?.length > 1 ? (
                <div className="charts">
                  <div className="geoChart" style={{ height: "500px" }}>
                    <GChart
                      // className="geoChart"
                      className="gchart"
                      chartType="GeoChart"
                      // width="650px"
                      height="400px"
                      width="100%"
                      // height="100%"
                      data={internationalUsers}
                      options={options2}
                    />
                  </div>
                  <div className="barChart" style={{ height: "500px" }}>
                    <GChart
                      className="gchart"
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={internationalUsers}
                      options={BarChartOptions2}
                    />
                  </div>
                </div>
              ) : (
                <p className="sub-header">No data available</p>
              )}
            </div>
          </div>
        </div>
        <h2 className="all-time-count">
          <b>Organisation Statistics</b>
        </h2>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div className="filter-box">
            <div className="add-filter-btns">
              <Form.Item className="term-select" label="Term" colon={false}>
                <Select
                  allowClear
                  className="customSelect"
                  // placeholder={firstValue}
                  placeholder="Select Term"
                  value={filterType}
                  onChange={(e) => {
                    setFilterType(e);
                    if (e == "quarterly") {
                      setShowMonthly(false);
                      setSelectedMonth();
                      setSelectedYear();
                      setSelectedQuarter();
                      setShowYearDropdown(true);
                      setShowQuarterly(true);
                    } else if (e == "monthly") {
                      setSelectedMonth();
                      setSelectedYear();
                      setSelectedQuarter();
                      setShowYearDropdown(true);
                      setShowQuarterly(false);
                      setShowMonthly(true);
                    } else if (e == "yearly") {
                      setSelectedMonth();
                      setSelectedYear();
                      setSelectedQuarter();
                      setShowMonthly(false);
                      setShowQuarterly(false);
                      setShowYearDropdown(true);
                    }
                  }}
                  onClear={() => {
                    setFilterType();
                    setSelectedYear();
                    setSelectedMonth();
                    setSelectedQuarter();
                    setShowYearDropdown(false);
                    setShowMonthly(false);
                    setShowQuarterly(false);
                  }}
                  options={[
                    {
                      value: "yearly",
                      label: "Yearly",
                    },
                    {
                      value: "monthly",
                      label: "Monthly",
                    },
                    {
                      value: "quarterly",
                      label: "Quarterly",
                    },
                  ]}
                ></Select>
              </Form.Item>
              {showYearDropdown && (
                <Form.Item className="term-select" label="Year" colon={false}>
                  <Select
                    allowClear
                    className="customSelect"
                    // placeholder={firstValue}
                    placeholder="Select Year"
                    value={selectedYear}
                    onChange={(e) => {
                      // console.log("e", e)
                      setSelectedYear(e);
                    }}
                    onClear={() => {
                      setSelectedYear();
                      setSelectedMonth();
                      setSelectedQuarter();
                      setShowQuarterly(false);
                      setShowMonthly(false);
                      setShowYearDropdown(false);
                      setFilterType();
                    }}
                  >
                    {yearDropdownOptions.length > 0 &&
                      yearDropdownOptions.map((year, i) => (
                        // console.log('year', year)
                        <Select.Option
                          disabled={year > currentYear ? true : false}
                          key={year}
                          value={year}
                        >
                          {year}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {showMonthly && (
                <Form.Item className="term-select" label="Month" colon={false}>
                  <Select
                    allowClear
                    className="customSelect"
                    // placeholder={firstValue}
                    disabled={selectedYear ? false : true}
                    placeholder="Select Month"
                    value={selectedMonth}
                    onChange={(e) => {
                      // console.log("e", e)
                      setSelectedMonth(e);
                    }}
                    onClear={() => {
                      setSelectedMonth();
                      setSelectedYear();
                      // setFilterType();
                      // setShowMonthly(false);
                      // setShowYearDropdown(false);
                    }}
                    options={[
                      { value: "January", label: "January" },
                      { value: "February", label: "February" },
                      { value: "March", label: "March" },
                      { value: "April", label: "April" },
                      { value: "May", label: "May" },
                      { value: "June", label: "June" },
                      { value: "July", label: "July" },
                      { value: "August", label: "August" },
                      { value: "September", label: "September" },
                      { value: "October", label: "October" },
                      { value: "November", label: "November" },
                      { value: "December", label: "December" },
                    ]}
                  ></Select>
                </Form.Item>
              )}
              {showQuarterly && (
                <Form.Item className="term-select" label="Quarter" colon={false}>
                  <Select
                    allowClear
                    className="customSelect"
                    // placeholder={firstValue}
                    placeholder="Select Quarter"
                    value={selectedQuarter}
                    disabled={selectedYear ? false : true}
                    onChange={(e) => {
                      // console.log("e", e)
                      setSelectedQuarter(e);
                    }}
                    onClear={() => {
                      setSelectedQuarter();
                      // setFilterType();
                      setSelectedYear();
                      // setShowQuarterly(false);
                      // setShowYearDropdown(false);
                    }}
                    options={[
                      {
                        value: "1",
                        label: "Q1",
                      },
                      {
                        value: "2",
                        label: "Q2",
                      },
                      {
                        value: "3",
                        label: "Q3",
                      },
                      {
                        value: "4",
                        label: "Q4",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              )}
            </div>
          </div>
          <div className="overall-views-graph">
            {lineGraphData ? (
              <div className="microsite-data">
                <h2 className="all-time-count">
                  <b>All time Organisation viewership</b> <span> {`${overallViewCount}
                     views`}</span>
                </h2>
                <div className="graph-wrapper">
                  <Line
                    options={Lineoptions}
                    height="80%"
                    data={lineGraphData}
                  />
                </div>
              </div>
            ) : (
              <div className="data-loader">
                <Spin tip="Loading" size="large" />
              </div>
            )}
          </div>
          <div className="overall-views-graph">
            {micrositesList && micrositesList?.length > 1 ? (
              <>
                {micrositeData ? (
                  <div className="microsite-data">
                    <h2 className="all-time-count">
                      <b>Top performing microsites</b>
                    </h2>
                    <div className="graph-wrapper">
                      <Chart
                        type="bar"
                        height="80%"
                        data={micrositeData}
                        options={OptionsHandler("complaintNumber")}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="data-loader">
                    <Spin tip="Loading" size="large" />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="microsite-data">
                  <h2 className="all-time-count">
                    <b>Top performing microsites</b>
                  </h2>
                  {micrositesList.length > 0 ? (
                    <div className="microsite-info">
                      <div className="microsite-name">
                        {micrositesList && micrositesList[0]?.micrositeName}
                      </div>
                      <div className="microsite-count">
                        {micrositesList && micrositesList[0]?.count} views
                      </div>
                      <Button
                        label="View microsite analytics"
                        className="greenBtn view-microsite-anaytics-btn"
                        width="200px"
                        onClick={() => {
                          getMicrositeAnalytics(0);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <h3>No microsites available</h3>
                    </div>
                  )}

                </div>
              </>
            )}
          </div>
        </div>
        {/* </Tabs.TabPane>
        </Tabs> */}
      </div >
    );
  }
}
