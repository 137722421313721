import React, { useEffect } from 'react';
import { Collapse } from 'antd';
import { useMicrosite } from '../../custom-hooks/useMicrosite';
import { withMicroSites } from '../../hoc/withMicroSites';
import { useParams } from 'react-router-dom';
import MicroSiteAccordian from '../microsite-accordian';
import { useSettings } from '../../custom-hooks/useSettings';
import MicroSiteTabs from '../microSites/edit/index'


const { Panel } = Collapse;

function MicroSites({ micrositeData }) {

    const { getMicroSites } = useMicrosite();
    const { getSettings } = useSettings()
    const {micrositeId} = useParams();



    useEffect(() => {
        getMicroSites();
        // if(micrositeId){
        //     getSettings(micrositeId)
        // }
    }, [])

    return (
        <div direction="vertical" style={{width : '100%'}}>
            {
                micrositeData.map(microSite => {
                    return <MicroSiteAccordian data={microSite} activeKey = {micrositeId} micrositeData={micrositeData}>
                        <MicroSiteTabs micrositeId = {micrositeId}/>
                    </MicroSiteAccordian>
                })
            }

        </div>
    )
}


export default withMicroSites(React.memo(MicroSites))