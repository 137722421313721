import MicrositeLists from "../components/microSites";
import EditOrganization from "../components/organisations/editOrganization";
import Login from "../components/login-form/Login";
import Signup from "../components/login-form/Signup";
import ResetPassword from "../components/login-form/ResetPassword";
import ChangePassword from "../components/login-form/ChangePassword";
import Help from "../components/login-form/Help";
import ManageMicrosite from "../components/login-form/manage-microsites";
import ForgotPassword from "../components/login-form/ForgotPasswordForm";
import Edit from "../components/microSites/edit";
import TermsForm from "../components/login-form/Terms";
import FeedBack from "../components/login-form/Feedback";
import Organalytics from "../components/analytics/org-analytics";
import MicrositeAnalytics from "../components/analytics/microsites-analytics";
import MicrositeConfigurations from "../components/configurations";

const protectedRoutes = [
  {
    path: "",
    component: <MicrositeLists />,
    exact: true,
  },
  {
    path: ":micrositeId/*",
    component: <MicrositeLists />,
    exact: true,
  },
  {
    path: "edit-microsite/*",
    component: <Edit />,
    exact: true,
  },
  {
    path: "edit-organization",
    component: <EditOrganization />,
    exact: true,
  },
  {
    path: "manageMicrosites",
    component: <ManageMicrosite />,
    exact: true,
  },
  {
    path: "micrositeConfigurations",
    component: <MicrositeConfigurations />,
    exact: true,
  },
  {
    path: "change-password",
    component: <ChangePassword />,
    exact: true,
  },
  {
    path: "org-analytics",
    component: <Organalytics />,
    exact: true,
  },
  {
    path: "microsite-analytics/:micrositeId",
    component: <MicrositeAnalytics />,
    exact: true,
  },
  {
    path: "help",
    component: <Help />,
    exact: true,
  },
  {
    path: "feedback",
    component: <FeedBack />,
    exact: true,
  }
];



const publicRoutes = [
  {
    // When route is changed, change it in the navigate tag in protected-routes.jsx
    path: "login/:partner",
    component: <Login />,
    exact: true,
  },
  {
    path: "forgot-password",
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: "signup",
    component: <Signup />,
    exact: true,
  },
  {
    path: "terms",
    component: <TermsForm />,
    exact: true,
  },
  {
    path: "reset-password/:token",
    component: <ResetPassword />,
    exact: true,
  },
];

export { protectedRoutes, publicRoutes };
