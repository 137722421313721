import uuid from "react-uuid";

export const getMediaProperty = (event) => {
    let unique_ID = uuid();
    var file = event.target.files[0].name;
    var FT = event.target.files[0].type;
    //Get file name
    var fileArray = file.split(".");
    var fileExt = fileArray[fileArray.length - 1];
    //Get file type
    var fileName = file.split(".").slice(0, -1).join(".");
    var fileType = FT.substr(0, FT.lastIndexOf("/"));

    let data = {
        mediaType: "IMAGE",
        mediaProperty: {
            id: unique_ID,
            name: fileName,
            extension: fileExt,
        },
    };

    var image = event.target.files[0]; //The image is in binary form
    var blob = image.slice(0, image.size); //slice method returns an array
    const newFile = new File([blob], `${unique_ID}_${fileName}.${fileExt}`, {
        type: `${image.type}`,
    }); //The image is still in type "file", not blob or binary 

    return { mediaProperty: data, blob: newFile }
}

export const getMediaValueByName = (key, form, i) => {
    return form.getFieldValue([key.corousel, i, 'mediaLink', 'mediaType'])
}

export const getblobValue = (key, form, i) => {
    return form.getFieldValue([key, i, 'blob'])
}

export const getFormFieldValue = (form, tabKey, i, type) => {
    switch (type) {
        case 'url':
            return form.getFieldValue([tabKey, i, 'mediaLink', 'url'])
        case 'type':
            return form.getFieldValue([tabKey, i, 'mediaLink', 'mediaType'])
        case 'blob':
            return form.getFieldValue([tabKey, i, 'blob'])
        case 'id':
            return form.getFieldValue([tabKey, i, 'id'])
        default:
            return null
    }
}
