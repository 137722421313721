import React, { useEffect, useState } from "react";
import { Button, Input, Form, Row, Col, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useStore } from "../../../../../../store";
import { contactUSkeys } from "../../../../../../config";
import { useTab } from "../../../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../../../custom-hooks/useConnectsuForm";
import twitter from "../../../../../../assets/images/twitter.svg";
import linkedin from "../../../../../../assets/images/linkedIn.svg";
import watsapp from "../../../../../../assets/images/watsapp.svg";
import phoneIc from "../../../../../../assets/images/phone.svg";
import youtube from "../../../../../../assets/images/youtube.svg";
import Website from "../../../../../../assets/images/website.svg";
import tiktok from "../../../../../../assets/images/tiktok.svg";
import mail from "../../../../../../assets/images/mail.svg";
import instagram from "../../../../../../assets/images/instagram.svg";
import address from "../../../../../../assets/images/address.svg";

const { TextArea } = Input;

export default function EOW({ dataLoader, form, tabKey }) {
  const params = useParams();
  const { tabData, skills } = useStore();
  const whichTab = contactUSkeys.eow;
  const { deleteSingleItem, postLoader, getSkills } = useTab();
  const { updateImage } = useConnectsuForm();
  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const onChange = () => {
    setFormChanged(true);
  };

  if (dataLoader || postLoader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        {/* <Form name={tabKey}> */}
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === contactUSkeys.eow ? (
            <>
              <Col className="gutter-row tabContent" span={15}>
                <Form.List
                  name={contactUSkeys.eow}
                  initialValue={
                    tabData && tabData[tabKey] && tabData[tabKey].length > 0
                      ? tabData[tabKey]
                      : [""]
                  }
                >
                  {(fields) => (
                    <div>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <>
                          <Form.Item
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={phoneIc}
                                  alt="Image"
                                  style={{ marginRight: "3px" }}
                                />
                                Phone
                              </div>
                            }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "phone"]}
                            rules={[
                              {
                                required: true,
                                message: "Phone number is required",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Phone Number" />
                          </Form.Item>
                          <Form.Item
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={mail}
                                  alt="Image"
                                  style={{ marginRight: "3px" }}
                                />
                                Email
                              </div>
                            }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            rules={[
                              {
                                required: true,
                                message: "Email is required",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Email" />
                          </Form.Item>
                          <Form.Item
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={watsapp}
                                  alt="Image"
                                  style={{ marginRight: "3px" }}
                                />
                                Whatsapp
                              </div>
                            }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "chatNumber"]}
                          // rules={[{ required: true, message: 'Chat number is required' }]}
                          >
                            <Input placeholder="Enter Whatsapp Number" />
                          </Form.Item>
                          <Form.Item
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Website}
                                  alt="Image"
                                  style={{ marginRight: "3px" }}
                                />
                                Website
                              </div>
                            }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "websiteLink"]}
                            rules={[
                              {
                                required: true,
                                message: "Link is required",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Website Link" />
                          </Form.Item>
                          {/* <Form.Item
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={address}
                                  alt="Image"
                                  style={{ marginRight: "3px" }}
                                />
                                Address
                              </div>
                            }
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "postalAddress"]}
                            rules={[
                              {
                                required: true,
                                message: "Address is required",
                              },
                            ]}
                          >
                            <TextArea placeholder="Enter Address" rows={5} />
                          </Form.Item> */}
                          <Form.Item
                            className="w-100"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            {formChanged ? (
                              <Button
                                className="saveBtn greenBtn"
                                onClick={() => {
                                  form
                                    .submit()
                                    .then(() => {
                                      setFormChanged(false);
                                    })
                                    .catch((errorInfo) => {
                                      console.error(
                                        "Form submission failed:",
                                        errorInfo
                                      );
                                    });
                                }}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                className="saveBtn greenBtn"
                                style={{
                                  opacity: "0.5",
                                  pointerEvents: "none",
                                }}
                              >
                                Save
                              </Button>
                            )}
                          </Form.Item>
                        </>
                      ))}
                    </div>
                  )}
                </Form.List>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {/* </Form> */}
      </div>
    );
  }
}
