import React, { useState } from 'react'
import "./duration.scss";

export default function Duration({ options, selected }) {
    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(true);
    const [option3, setOption3] = useState(false);

    const selection = (selection) => {
        selected(selection);
    }
    return (
        <div className="duration-box">
            <div className={`selection year-btn ${option1 ? `selected` : ``}`} onClick={() => {
                setOption1(true);
                setOption2(false);
                setOption3(false);
                selection(options[0])
            }}>
                <p>{options[0]}</p>
            </div>
            <div className="seperation-line"></div>
            <div className={`selection half-year-btn ${option2 ? `selected` : ``}`} onClick={() => {
                setOption2(true);
                setOption1(false);
                setOption3(false);
                selection(options[1])
            }}>
                <p>{options[1]}</p>
            </div>
            <div className="seperation-line"></div>
            <div className={`selection month-btn ${option3 ? `selected` : ``}`} onClick={() => {
                setOption3(true);
                setOption1(false);
                setOption2(false);
                selection(options[2]);
            }}>
                <p>{options[2]}</p>
            </div>
        </div>
    )
}
