import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Space,
  Spin,
  Tooltip,
  Divider,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../../assets/images/icons/userLogo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../../store";
import { TAB_KEYS } from "../../../../config";
import { useTab } from "../../../../custom-hooks/useTab";
import { useConnectsuForm } from "../../../../custom-hooks/useConnectsuForm";
import { getFormFieldValue } from "../../../../utils";
import ImageLabel from "../../../image-label";
import ReactPlayer from "react-player/youtube";

const { TextArea } = Input;
const MAX_CHARACTERS = 700;

export default function Courses({ dataLoader, form }) {
  const { micrositeId } = useParams();
  const { state } = useLocation();
  const inputRef = useRef(null);
  const tabKey = state;
  const { tabData, courses_qualifications } = useStore();
  const whichTab = TAB_KEYS.courses;
  const { deleteSingleItem, postLoader } = useTab();
  const { updateImage } = useConnectsuForm();
  const [loader, setLoader] = useState(true);
  const [customName, setName] = useState("");
  const [items, setItems] = useState([]);
  let index = 0;
  const navigate = useNavigate();

  const [formChanged, setFormChanged] = useState(false);
  const { updatedValues } = useStore();

  useEffect(() => {
    localStorage.setItem("tab", "COURSES");
  }, []);

  useEffect(() => {
    const isNotEmpty = Object.keys(updatedValues).length > 0;

    setFormChanged(isNotEmpty);
  }, [Object.keys(updatedValues).length]);

  const renderMedia = (dataItem) => {
    const commonStyles = {
      width: "350px",
      height: "250px",
      // margin: "10px",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "black"
    };

    if (
      dataItem?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <ReactPlayer
            controls={true}
            url={dataItem.mediaLink.url}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <iframe
            src={dataItem.mediaLink.url}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            style={{ backgroundColor: "black" }}
          />
        </div>
      );
    } else if (
      dataItem?.mediaLink?.mediaType === "IMAGE" &&
      dataItem.mediaLink.url
    ) {
      return (
        <div className="iframe-comp" style={commonStyles}>
          <img
            src={dataItem?.mediaLink?.url}
            alt=""
            className="c1 carousel-image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      );
    }

    return null;
  };

  const findValue = (i, key) => {
    return getFormFieldValue(form, whichTab, i, key);
  };

  const MakeFieldDisableHandler = (i) => {
    let value = form.getFieldValue([whichTab, i, "title"]);
    // if (value == "w1" || value == "w2" || value == "w3") {
    //   return true;
    // }
  };

  const SelectChangeHandler = (e, i) => {
    form.setFieldValue([whichTab, i, "mediaLink", "url"], "");
  };

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [tabData[whichTab]]);

  const onNameChange = (event) => {
    // console.log(event.target.value)
    setName([event.target.value]);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, customName[0] || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (items.length === 0) {
      tabData[whichTab] &&
        tabData[whichTab].map((item) => {
          // item?.title in items ?
          //   console.log("its there") :
          if (item?.title && !items.includes(item.title)) {
            items.push(item?.title);
          }
        });
    }
  }, [tabData[whichTab]]);

  if (dataLoader || postLoader || loader) {
    return (
      <div className="data-loader">
        <Spin tip="Loading" size="large" />
      </div>
    );
  } else {
    return (
      <div>
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {tabKey === whichTab ? (
            <>
              <Col className="gutter-row tabContent" span={24}>
                <Form.List
                  name={whichTab}
                  initialValue={
                    tabData && tabData[whichTab] && tabData[whichTab].length > 0
                      ? tabData[whichTab]
                      : [""]
                  }
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <div className="card-item">
                          <Col
                            className="gutter-row tabContent"
                            span={16}
                            style={{ paddingRight: "25px" }}
                          >
                            <div className="d-flex">
                              <Form.Item
                                {...restField}
                                className="w-100"
                                label={<>Type of Qualification</>}
                                labelCol={{ span: 6 }}
                                // name={[name, "title"]}
                                name={[name, "assetTitleId"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Type of Qualification name is required",
                                  },
                                ]}
                              >
                                {/* <Input placeholder="Type of Qualification" /> */}
                                {/* <Select
                                  name={[name, "title"]}
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <Divider
                                        style={{
                                          margin: "8px 0",
                                        }}
                                      />
                                      <Space
                                        style={{
                                          padding: "0 8px 4px",
                                        }}
                                      >
                                        <Input
                                          placeholder="Please enter qualification"
                                          ref={inputRef}
                                          value={customName}
                                          onChange={onNameChange}
                                        />
                                        <Button
                                          type="text"
                                          icon={<PlusOutlined />}
                                          onClick={addItem}
                                        >
                                          Add another Type of Qualification
                                        </Button>
                                      </Space>
                                    </>
                                  )}
                                  options={items.map((item) => ({
                                    label: item,
                                    value: item,
                                  }))}
                                >
                                </Select> */}
                                <Select
                                  name={[name, "assetTitleId"]}
                                  options={courses_qualifications.map((item) => ({
                                    label: item.title,
                                    value: item.id,
                                  }))}
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <Divider
                                        style={{
                                          margin: "8px 0",
                                        }}
                                      />
                                      <Space
                                        style={{
                                          padding: "0 8px 4px",
                                        }}
                                      >
                                        <Button
                                          className="dropdow-configurations-btn greenBtn"
                                          type="text"
                                          icon={<PlusOutlined />}
                                          onClick={() => {
                                            localStorage.setItem("page", "configurations");
                                            navigate('/micrositeConfigurations', { state: { micrositeId: micrositeId, page: "courses" } })
                                          }}
                                        >
                                          Add course type
                                        </Button>
                                      </Space>
                                    </>
                                  )}
                                >
                                  {/* {courses_qualifications.map((courses) => (
                                    <Select.Option value={courses.id}>
                                      {courses.title}
                                    </Select.Option>
                                  ))} */}
                                </Select>
                              </Form.Item>
                              <Form.Item>
                                {fields.length >= 1 &&
                                  // i !== 0 &&
                                  findValue(i, "id") &&
                                  !MakeFieldDisableHandler(i) ? (
                                  <div className="remove-btn">
                                    <DeleteOutlined
                                      onClick={() =>
                                        deleteSingleItem(
                                          whichTab,
                                          form.getFieldValue([
                                            whichTab,
                                            i,
                                            "id",
                                          ]),
                                          form
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Form.Item>
                            </div>
                            <Form.Item
                              {...restField}
                              className="w-100"
                              label="Course"
                              labelCol={{ span: 6 }}
                              name={[name, "subTitle"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Course is required",
                                },
                              ]}
                            >
                              <Input placeholder="Course" />
                            </Form.Item>
                            <Form.Item
                              label="Description"
                              {...restField}
                              labelCol={{ span: 6 }}
                              name={[name, "description"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Description is required",
                                },
                                {
                                  max: MAX_CHARACTERS - 1,
                                  message: `Description should be less than ${MAX_CHARACTERS} character`,
                                }
                              ]}
                            >
                              <TextArea
                                placeholder="Enter Description"
                                rows={5}
                                maxLength={MAX_CHARACTERS}
                                showCount />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              className="w-100"
                              label="Course Link"
                              labelCol={{ span: 6 }}
                              name={[name, "urlLink"]}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Course link is required",
                            //     },
                            // ]}
                            >
                              <Input placeholder="Course Link" />
                            </Form.Item>
                            <div className="d-flex">
                              <Form.Item
                                {...restField}
                                className="w-100"
                                name={[name, "mediaLink", "mediaType"]}
                                label="Video or Image"
                                labelCol={{ span: 6 }}
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Select any option",
                              //     },
                              // ]}
                              >
                                <Select
                                  name={[name, "mediaLink", "mediaType"]}
                                  onChange={(e) => SelectChangeHandler(e, i)}
                                  placeholder="Select Option"
                                  allowClear
                                >
                                  <Select.Option value="VIMEO_MEDIA">
                                    Add Vimeo Link
                                  </Select.Option>
                                  <Select.Option value="SOCIAL_MEDIA">
                                    Add Youtube Link
                                  </Select.Option>
                                  <Select.Option value="IMAGE">
                                    Upload Image
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <Form.Item>
                                <div className="remove-btn">
                                  <Tooltip
                                    title={
                                      "Upload Video link or image with size lesser than 2.5MB"
                                    }
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </div>
                              </Form.Item>
                            </div>
                            {findValue(i, "type") === "SOCIAL_MEDIA" ? (
                              <Form.Item
                                label="Youtube Link"
                                {...restField}
                                labelCol={{ span: 6 }}
                                name={[name, "mediaLink", "url"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Youtube link is required",
                              //   },
                              // ]}
                              >
                                <Input />
                              </Form.Item>
                            ) : findValue(i, "type") === "VIMEO_MEDIA" ? (
                              <Form.Item
                                label="Vimeo Link"
                                labelCol={{ span: 6 }}
                                {...restField}
                                name={[name, "mediaLink", "url"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Vimeo link is required",
                              //   },
                              // ]}
                              >
                                <Input />
                              </Form.Item>
                            ) : findValue(i, "type") === "IMAGE" ? (
                              <Form.Item
                                {...restField}
                                label="Image"
                                colon={false}
                                name="media"
                                className="experienceImg"
                                labelCol={{ span: 6 }}
                              // rules={[
                              //   {
                              //     validator: (_, value) => {
                              //       if (findValue(i, "type") === "IMAGE") {
                              //         return findValue(i, "blob")
                              //           ? Promise.resolve()
                              //           : findValue(i, "url")
                              //             ? Promise.resolve()
                              //             : Promise.reject(
                              //               new Error("Image is required")
                              //             );
                              //       } else {
                              //         return Promise.resolve();
                              //       }
                              //     },
                              //   },
                              // ]}
                              >
                                {findValue(i, "blob") ? (
                                  <Avatar
                                    src={URL.createObjectURL(
                                      findValue(i, "blob")
                                    )}
                                    alt=""
                                  />
                                ) : findValue(i, "url") ? (
                                  <Avatar src={findValue(i, "url")} alt="" />
                                ) : (
                                  <Avatar src={UserIcon} />
                                )}

                                <label
                                  name="file"
                                  className="fileUploadBtn greenBtn"
                                >
                                  {" "}
                                  Choose File
                                  <input
                                    accept="image/png, image/gif, image/jpeg"
                                    type="file"
                                    // required={true}
                                    onChange={(e) =>
                                      updateImage(e, i, form, whichTab)
                                    }
                                  />
                                </label>
                              </Form.Item>
                            ) : (
                              <></>
                            )}
                            <Form.Item
                              className="w-100"
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              {formChanged ? (
                                <Button
                                  className="saveBtn greenBtn"
                                  onClick={() => {
                                    form
                                      .submit()
                                      .then(() => {
                                        setFormChanged(false);
                                      })
                                      .catch((errorInfo) => {
                                        console.error(
                                          "Form submission failed:",
                                          errorInfo
                                        );
                                      });
                                  }}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  className="saveBtn greenBtn"
                                  style={{
                                    opacity: "0.5",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Save
                                </Button>
                              )}
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row tabContent" span={8}>
                            <div>{renderMedia(tabData[whichTab][i])}</div>
                          </Col>
                        </div>
                      ))}

                      <div className="inputBody">
                        <label></label>
                        <div style={{ textAlign: "right" }}>
                          {fields && fields.length <= 1 ? (
                            <></>
                          ) : (
                            <Button
                              className="cancelBtn"
                              type="button"
                              onClick={() => {
                                const itemsToRemove = [];
                                fields.forEach((f, i) => {
                                  if (!findValue(i, "id") && i !== 0) {
                                    itemsToRemove.push(f.name);
                                  }
                                });
                                if (itemsToRemove.length > 0) {
                                  remove(itemsToRemove);
                                }
                              }}
                            >
                              Clear
                            </Button>
                          )}
                          <Button className="greenBtn" onClick={() => add()}>
                            Add More
                            <PlusOutlined />
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Form.List>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    );
  }
}
