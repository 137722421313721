import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Button,
  Input,
  Select,
  Form,
  Space,
  Modal,
  Avatar,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import UserIcon from "../../../assets/images/icons/userLogo.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./index.scss";
import uuid from "react-uuid";
import OrganiationSizes from "../json/organizationSize.json";
import userServiceApi from "../../../api/userServiceApi";
import { getRequest, putRequest } from "../../../api/http";
import { toast } from "react-toastify";
import { LoadingView } from "../../common";
import powered from "../../../assets/images/PoweredNew.png";

function EditOrganisation() {
  const navigate = useNavigate();
  const [organizationData, setOrganizationData] = useState({});
  const [selectedOption, setselectOption] = useState({});
  const [getFile, setFile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState({});
  const [subSectorVal, setSubSectorVal] = useState();
  const [media, setmedia] = useState({});
  const [countries, setCountries] = useState([]);
  const [activeKey, setActiveKey] = useState("personalDetailsTab");
  const [profile, setProfile] = useState();
  const [form] = Form.useForm();
  const { state } = useLocation() || {};
  const [startDate, setStartDate] = useState(new Date());
  const isInitialRender = useRef(true);
  const [partnerDatas, setPartnerDatas] = useState({});

  const partner = localStorage.getItem("partner");
  const partnerID = localStorage.getItem("partnerID");

  localStorage.setItem("page", "edit");

  // useEffect(() => {
  //   if (organizationData?.name) {
  //     localStorage.setItem("OrgNames", organizationData.name);
  //   }
  // }, [organizationData, localStorage.getItem("OrgNames")]);

  const getPartnerDetailsData = async (partner) => {
    try {
      // setLoading(true);
      const response = await getRequest(
        userServiceApi.getPartnerDetailsLoginScreen(partnerID)
      );
      if (response && response.data) {
        setPartnerDatas(response.data);
      }
    } catch (error) {
      // setLoading(false);
      // toast.error(error.error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getPartnerDetailsData(partner);
    }, 1000);
  }, []);

  useEffect(() => {
    if (state && state?.tab) {
      setActiveKey(state.tab);
    }
  }, [state]);

  const fetchSectorList = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(
        userServiceApi.getAllSectors(partnerID),
        {
          headers,
        }
      );
      setSectors(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  const fetchCountryList = async () => {
    try {
      setLoading(true);
      const response = await getRequest(userServiceApi.getAllCountries());
      setCountries(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const fetchOrganization = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(
        userServiceApi.getSingleOrganization(),
        { headers }
      );

      if (response && response.data) {
        form.resetFields();
        // form.setFieldsValue()
        setOrganizationData(response.data);
        const resData = response.data;

        if (
          resData &&
          resData.sectorsOutputs &&
          resData.sectorsOutputs.length > 0
        ) {
          resData.sectorsOutputs.map((d, i) => {
            if (d.sectorOutput && d.sectorOutput.id) {
              sectorChange(d.sectorOutput.id, i);
            }
          });
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  const fetchAdminProfile = async () => {
    try {
      setLoader(true);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(userServiceApi.getProfile(), {
        headers,
      });
      if (response.data) {
        setProfile(response.data);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSectorList();
    fetchCountryList();
    fetchOrganization();
    fetchAdminProfile();
  }, []);

  const changeTab = (tabValue) => {
    if (tabValue === "organizationDetailsTab") {
      navigate("/edit-organization", {
        state: { tab: "organizationDetailsTab" },
      });
      // setActiveKey("organizationDetailsTab");
    } else {
      navigate("/edit-organization", { state: { tab: "personalDetailsTab" } });
      // setActiveKey("personalDetailsTab");
    }
  };

  const sectorChange = async (sectorId, i) => {
    setSubSectorVal(null);
    setselectOption((prev) => {
      prev[i] = sectorId;
      return { ...prev };
    });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(
        userServiceApi.getSubSectors(sectorId),
        { headers }
      );

      setSubSectors((prev) => {
        prev[sectorId] = response.data;
        return { ...prev };
      });

      if (!isInitialRender.current) {
        form.setFieldsValue({
          sectorsOutputs: form
            .getFieldsValue()
            .sectorsOutputs.map((output, index) =>
              index === i
                ? { ...output, subSectorOutput: { id: null } }
                : output
            ),
        });
      } else {
        isInitialRender.current = false;
      }

      if (response) {
        form.setFieldValue(["sectorsOutputs", "subSectorOutput", "id"], "");
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const changeHandler = (event) => {
    if (event.target.files && event.target.files[0].size >= 2e6) {
      toast.error("File should not exceed 2MB");
    } else {
      setImage(event.target.files[0]);
      let unique_ID = uuid();
      var file = event.target.files[0].name;
      var FT = event.target.files[0].type;
      var fSize = event.target.files[0].size;
      //Get file name
      var fileArray = file.split(".");
      var fileExt = fileArray[fileArray.length - 1];
      //Get file type
      var fileName = file.split(".").slice(0, -1).join(".");
      var fileType = FT.substr(0, FT.lastIndexOf("/"));
      const items = {
        mediaType: fileType === "image" ? "IMAGE" : null,
        mediaProperty: {
          id: unique_ID,
          name: fileName,
          extension: fileExt,
        },
      };
      setmedia({ ...items });
      var image = event.target.files[0];
      var blob = image.slice(0, image.size);
      const newFile = new File([blob], `${unique_ID}_${fileName}.${fileExt}`, {
        type: `${image.type}`,
      });
      setFile(newFile);

      const fields = form.getFieldsValue(true);
      form.resetFields();
      form.setFieldsValue({ ...fields });
    }
  };
  const onFinish = async (values) => {
    if (activeKey === "personalDetailsTab") {
      // let dateOfBirth = moment(values.dateOfBirth).format("DD-MM-YYYY");
      // let submit = { ...values, dateOfBirth };
      let submit = { ...values };

      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        const response = await putRequest(
          userServiceApi.updateProfile(),
          submit,
          {
            headers,
          }
        );
        if (response) {
          toast.success("Organisation Updated Successfully");
          navigate(`/`);
        }
      } catch (error) {
        toast.error(error.error);
      }
    } else {
      let addressArray = [];
      let sectorArray = [];
      values.sectorsOutputs.map((ddd) => {
        sectorArray.push({
          sectorId: ddd.sectorOutput.id,
          subSectorId: ddd.subSectorOutput.id,
        });
      });

      values.addressOutputs.map((address) => {
        addressArray.push({
          street: address.street?.replace(/(\r\n|\n|\r)/g, " "),
          countryId: address.countryOutput.id,
          postalCode: address.postalCode,
        });
      });

      if (getFile) {
        let submit = {
          ...values,
          addressInputs: addressArray,
          sectorsInputs: sectorArray,
          media: media, //changed
        };
        delete submit.addressOutputs;
        delete submit.sectorsOutputs;

        const json = JSON.stringify(submit);
        const blob = new Blob([json], {
          type: "application/json",
        });

        var formData = new FormData();
        formData.append("organizationInput", blob);
        formData.append("media", getFile);
        try {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };
          const response = await putRequest(
            userServiceApi.updateOrganization(),
            formData,
            { headers: { "Content-Type": "multipart/form-data" }, headers }
          );

          if (response) {
            if (response.name) {
              localStorage.setItem("OrgName", response.name);
            }
            if (response.mediaLink) {
              localStorage.setItem("OrgImage", response.mediaLink);
            }
            if ("isOrganizationProfilePending" in response) {
              localStorage.setItem(
                "isOrganizationProfilePending",
                response.isOrganizationProfilePending
              );
            }
            toast.success("Organization Updated Successfully");
            navigate("/");
          }
        } catch (error) {
          toast.error(error.error);
        }
      } else {
        let submit = {
          ...values,
          addressInputs: addressArray,
          sectorsInputs: sectorArray,
        };
        delete submit.addressOutputs;
        delete submit.sectorsOutputs;

        const json = JSON.stringify(submit);
        const blob = new Blob([json], {
          type: "application/json",
        });

        var formData = new FormData();
        formData.append("organizationInput", blob);
        try {
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };
          const response = await putRequest(
            userServiceApi.updateOrganization(),
            formData,
            { headers: { "Content-Type": "multipart/form-data" }, headers }
          );
          if (response) {
            if (response.name) {
              localStorage.setItem("OrgName", response.name);
            }
            if ("isOrganizationProfilePending" in response) {
              localStorage.setItem(
                "isOrganizationProfilePending",
                response.isOrganizationProfilePending
              );
            }
            if (response.mediaLink) {
              localStorage.setItem("OrgImage", response.mediaLink);
            }
            toast.success("Organization Updated Successfully");
            navigate("/");
          }
        } catch (error) {
          toast.error(error.error);
        }
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const createMicreosite = () => {
    navigate("/");
  };

  // useEffect(() => form.resetFields(), [organizationData]);;

  if (
    loading ||
    !(organizationData && organizationData.id && organizationData.name) ||
    !(profile && profile.userName)
  ) {
    return <LoadingView message="Loading..." />;
  }

  return (
    <>
      <React.Fragment>
        {/* <Banner /> */}
        <div className="tabContainer" style={{ minHeight: "50vh" }}>
          <Form
            name="control-hooks"
            className="organizationForm"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 10,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={
              organizationData && organizationData.id && organizationData.name
                ? {
                  type: organizationData && organizationData.type,
                  name:
                    organizationData && organizationData.name
                      ? organizationData.name
                      : "",
                  size: organizationData && organizationData.size,
                  media: organizationData && organizationData.media,
                  country:
                    organizationData &&
                      organizationData.addressOutputs.length > 0
                      ? organizationData &&
                      organizationData.addressOutputs[0].countryOutput
                      : "",
                  mediaLink: organizationData && organizationData.mediaLink,
                  addressOutputs:
                    organizationData &&
                      organizationData.addressOutputs.length > 0
                      ? organizationData && organizationData.addressOutputs
                      : [""],

                  sectorsOutputs:
                    organizationData &&
                      organizationData.sectorsOutputs.length > 0
                      ? organizationData && organizationData.sectorsOutputs
                      : [""],

                  // firstName:
                  //   profile && profile.firstName ? profile.firstName : "",
                  // lastName:
                  //   profile && profile.lastName ? profile.lastName : "",
                  userName:
                    profile && profile.userName ? profile.userName : "",
                  email: profile && profile.email ? profile.email : "",
                  phoneNumber:
                    profile && profile.phoneNumber ? profile.phoneNumber : "",
                  // dateOfBirth:
                  //   profile && profile.dateOfBirth !== null
                  //     ? moment(profile && profile.dateOfBirth, 'DD-MM-YYYY')
                  //     : null,
                }
                : {
                  addressOutputs: [""],
                  sectorsOutputs: [""],
                  firstName:
                    profile && profile.firstName ? profile.firstName : "",
                  lastName:
                    profile && profile.lastName ? profile.lastName : "",
                }
            }
          >
            <div className="buttons">
              <Button
                className="cancelBtn"
                onClick={() => {
                  navigate("/");
                }}
                disabled={
                  organizationData &&
                    organizationData.isOrganizationProfilePending === true
                    ? true
                    : false
                }
              >
                Cancel
              </Button>
              <Button className="greenBtn" htmlType="submit" dis>
                Save
              </Button>
            </div>

            <Tabs onChange={changeTab} activeKey={activeKey}>
              <Tabs.TabPane tab="Personal Details" key="personalDetailsTab">
                {activeKey === "personalDetailsTab" ? (
                  <>
                    <Row
                      className="rowBody"
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    >
                      <Col className="gutter-row tabContent" span={15}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Full Name is required",
                            },
                          ]}
                          label="Full Name"
                          name="userName"
                        >
                          <Input />
                        </Form.Item>
                        {/* <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "First Name is required",
                            },
                          ]}
                          label="First Name"
                          name="firstName"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "First Name is required",
                            },
                          ]}
                          label="Last Name"
                          name="lastName"
                        >
                          <Input />
                        </Form.Item> */}
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Email is required",
                            },
                          ]}
                          label="Email"
                          name="email"
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Phone number is required",
                            },
                          ]}
                          label="Phone Number"
                          name="phoneNumber"
                        >
                          <Input disabled />
                        </Form.Item>
                        {/* <Form.Item
                      label="Date of Birth"
                      name="dateOfBirth"
                      rules={[{ required: true, message: "DOB is required" }]}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        disabledDate={(current) => {
                          return (
                            current && current > moment().endOf("day")
                            // moment().add(-1, "days") >= current ||
                            // moment().add(1, "month") >= current
                          );
                        }}
                      />
                    </Form.Item> */}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane
                tab="Organisation Details"
                key="organizationDetailsTab"
              >
                {activeKey === "organizationDetailsTab" ? (
                  <>
                    <Row
                      className="rowBody"
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    >
                      <Col className="gutter-row tabContent" span={15}>
                        <Form.Item
                          name="type"
                          label="Organisation type"
                          rules={[
                            {
                              required: true,
                              message: "Organisation type is required",
                            },
                          ]}
                        >
                          <Select placeholder="Select Option" disabled>
                            {partnerDatas?.organizationTypes?.length > 0
                              ? partnerDatas?.organizationTypes.map(
                                (OrganiationType, i) => (
                                  <Select.Option
                                    key={i}
                                    value={OrganiationType}
                                  >
                                    {OrganiationType}
                                  </Select.Option>
                                )
                              )
                              : null}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row helpOrg" span={1}>
                        <Form.Item>
                          <div className="remove-btn">
                            <Tooltip
                              title={
                                "To edit name and type of organization please send a request mail to partner.connectsu@gmail.com"
                              }
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row tabContent" span={15}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Organisation Name is required",
                            },
                          ]}
                          label="Organisation name"
                          name="name"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row tabContent" span={15}>
                        <Form.Item
                          label={
                            <div className="ant-form-item-label">
                              <label
                                htmlFor="media"
                                className="ant-form-item-required"
                                title="Organisation logo"
                              >
                                Organisation logo
                              </label>
                            </div>
                          }
                          colon={false}
                          name="media"
                          className="experienceImg"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (getFile) {
                                  return Promise.resolve();
                                } else if (organizationData.mediaLink) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    new Error("Image is required")
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {organizationData.mediaLink ? (
                            getFile ? (
                              <img
                                className="org-admin-logo"
                                size={64}
                                src={
                                  getFile ? URL.createObjectURL(getFile) : null
                                }
                                alt={getFile ? getFile.name : null}
                              />
                            ) : (
                              <img
                                className="org-admin-logo"
                                size={64}
                                src={organizationData.mediaLink}
                              />
                            )
                          ) : image === undefined ? (
                            <Avatar size={64} src={UserIcon} />
                          ) : (
                            <img
                              className="org-admin-logo"
                              size={64}
                              src={
                                getFile ? URL.createObjectURL(getFile) : null
                              }
                              alt={getFile ? getFile.name : null}
                            />
                          )}
                          {/* {image === undefined ? (
                        <Avatar size={64} src={UserIcon} />
                      ) : image ? (
                        <Avatar
                          size={64}
                          src={getFile ? URL.createObjectURL(getFile) : null}
                          alt={getFile ? getFile.name : null}
                        />
                      ) : (
                        <Avatar size={64} src={organizationData.mediaLink} />
                      )} */}
                          <div className="d-flex">
                            <Tooltip
                              title={
                                "Upload image with size lesser than 2.5MB"
                              }
                            >
                              <label className="fileUploadBtn greenBtn">
                                {" "}
                                Choose File
                                <input onChange={changeHandler} type="file" />
                              </label>
                            </Tooltip>

                            {/* <div className="remove-btn">
                              <Tooltip title={"Upload image or video link"}>
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </div> */}
                          </div>
                        </Form.Item>
                        {/* <Form.Item>
                          <div className="remove-btn">
                            <Tooltip title={"Upload image or video link"}>
                              <QuestionCircleOutlined />
                          </div>
                        </Form.Item> */}
                        <Form.Item
                          name="size"
                          label="Size by employees"
                          rules={[
                            {
                              required: true,
                              message: "Organisation size is required",
                            },
                          ]}
                        >
                          <Select placeholder="Select Option">
                            {OrganiationSizes.map((OrganiationSize, i) => (
                              <Select.Option key={i} value={OrganiationSize.id}>
                                {OrganiationSize.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.List name="addressOutputs">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }, i) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "block",
                                    marginBottom: 8,
                                  }}
                                  align="baseline"
                                >
                                  <div className="remove-btn" style={{ position: 'relative', display: 'flex', alignItems: 'center' }} >

                                    <Form.Item
                                      // label="Address"
                                      className="address-form w-100"
                                      colon={false}
                                      label={
                                        <div className="ant-form-item-label">
                                          <label
                                            className="ant-form-item-required"
                                            title="Address"
                                            colon={false}
                                          >
                                            {/* Address: */}
                                            Full address including <br /> postal
                                            code:
                                          </label>
                                        </div>
                                      }
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, "street"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Address is required",
                                          },
                                        ]}
                                        style={{ width: "100%" }}
                                      >
                                        <Input.TextArea rows={4} />
                                      </Form.Item>

                                      {fields.length > 1 && i !== 0 ? (
                                        <Button
                                          style={{ marginLeft: "10px" }}
                                          onClick={() => remove(i)}
                                          icon={<MinusCircleOutlined />}
                                        ></Button>
                                      ) : null}
                                    </Form.Item>
                                    <Tooltip title={"Not for map"}>
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </div>
                                  <Form.Item
                                    label="Country"
                                    {...restField}
                                    name={[name, "countryOutput", "id"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Country is required",
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Select Option">
                                      {countries.map((country, i) => (
                                        <Select.Option
                                          key={i}
                                          value={country.id}
                                        >
                                          {country.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>

                                  {/* <Form.Item
                                    label="Postal Code"
                                    {...restField}
                                    name={[name, "postalCode"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Postal Code is required",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item> */}
                                </Space>
                              ))}

                              <div className="inputBody">
                                <label></label>
                                <div className="addAddressBtn">
                                  <Button className="greenBtn" onClick={() => add()}>
                                    Add additional address
                                    <PlusOutlined />
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </Form.List>

                        <Form.List name="sectorsOutputs">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }, i) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "block",
                                    marginBottom: 8,
                                  }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    // label="Sector"
                                    label={
                                      <div className="ant-form-item-label">
                                        <label
                                          className="ant-form-item-required"
                                          title="Address"
                                        >
                                          Categories
                                        </label>
                                      </div>
                                    }
                                    colon={false}
                                  >
                                    <Form.Item
                                      noStyle
                                      {...restField}
                                      name={[name, "sectorOutput", "id"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Categories is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        onChange={(e) => sectorChange(e, i)}
                                        placeholder="Select Option"

                                      // dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
                                      >
                                        {sectors.map((Sector, i) => (
                                          <Select.Option
                                            key={i}
                                            value={Sector.id}
                                          >
                                            {Sector.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>

                                    {fields.length > 1 && i !== 0 ? (
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => remove(i)}
                                        icon={<MinusCircleOutlined />}
                                      ></Button>
                                    ) : null}
                                  </Form.Item>

                                  <Form.Item
                                    label="Sub categories"
                                    {...restField}
                                    name={[name, "subSectorOutput", "id"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Sub categories is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      value={subSectorVal}
                                      // disabled={
                                      //   i in selectedOption ||
                                      //   form.getFieldValue([
                                      //     "sectorsOutputs",
                                      //     i,
                                      //     "subSectorOutput",
                                      //     "id",
                                      //   ])
                                      //     ? false
                                      //     : true
                                      // }
                                      disabled={
                                        i in selectedOption
                                          ? false
                                          : form.getFieldValue([
                                            "sectorsOutputs",
                                            i,
                                            "sectorOutput",
                                            "id",
                                          ])
                                            ? false
                                            : true
                                      }
                                      placeholder="Select Option"
                                    >
                                      {subSectors &&
                                        subSectors[
                                        form.getFieldValue([
                                          "sectorsOutputs",
                                          i,
                                          "sectorOutput",
                                          "id",
                                        ])
                                        ] &&
                                        subSectors[
                                          form.getFieldValue([
                                            "sectorsOutputs",
                                            i,
                                            "sectorOutput",
                                            "id",
                                          ])
                                        ]?.map((subSector, i) => (
                                          <Select.Option
                                            key={i}
                                            value={subSector.id}
                                          >
                                            {subSector.name}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>

                                  {/* {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        style={{ margin: "0 8px" }}
                                                        onClick={() => {
                                                            remove(fields.name);
                                                        }}
                                                    />
                                                ) : null} */}

                                  {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                </Space>
                              ))}
                              {/* <div className="inputBody">
                                <label></label>
                                <div className="addAddressBtn">
                                  <Button onClick={() => add()}>
                                    Add More Sectors/Sub Sectors
                                    <PlusOutlined />
                                  </Button>
                                </div>
                              </div> */}
                            </>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </div>
        <div className="footerlogo">
          <img
            style={{ cursor: "pointer", width: "100px", marginBottom: "5px" }}
            src={powered}
            alt=""
          />
        </div>
        <Modal
          open={isModalOpen}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modalBody">
            <h3>Organization created successfully</h3>
            <h3>You are ready to create the Microsite</h3>
            <Button onClick={() => createMicreosite()} className="greenBtn">
              Create Microsite
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
}

export default EditOrganisation;
