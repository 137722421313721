import React, { useState, useEffect } from "react";
import { aboutUSkeys } from "../../../../../config";
import { Tabs } from "antd";
import AboutUsDetailsComponent from "./sub-tabs/AboutUs";
import OurStoryComponent from "./sub-tabs/OurStory";
import OurTeamComponent from "./sub-tabs/OurTeam";
import AccreditationComponent from "./sub-tabs/Accreditation";
const tabList = [
    {
        tabName: "Our purpose",
        component: (params) => <AboutUsDetailsComponent {...params} />,
        tabKey: aboutUSkeys.About,
    },
    {
        tabName: "Our Story",
        component: (params) => <OurStoryComponent {...params} />,
        tabKey: aboutUSkeys["Our Story"],
    },
    {
        tabName: "Our Team",
        component: (params) => <OurTeamComponent {...params} whichTab={aboutUSkeys["Our Team"]} />,
        tabKey: aboutUSkeys["Our Team"],
    },
    {
        tabName: "Our Client",
        component: (params) => <OurTeamComponent {...params} whichTab={aboutUSkeys["Our Client"]} />,
        tabKey: aboutUSkeys["Our Client"],
    },
    {
        tabName: "Accreditations",
        component: (params) => <AccreditationComponent {...params} />,
        tabKey: aboutUSkeys.Accreditation,
    },

];

export default function AboutUsComponent({
    dataLoader,
    form,
    handleTabs,
    splitParams,
}) {
    const [activeKey, setActiveKey] = useState()

    useEffect(() => {
        setActiveKey(splitParams.length > 1 ? splitParams[1] : aboutUSkeys.About)
    }, [splitParams])


    return (
        <div className="contact-tab-container">
            <Tabs
                className="tabBodyContainer"
                onChange={handleTabs}
                activeKey={activeKey}
            >
                {tabList.map((tab, i) => {
                    return (
                        <Tabs.TabPane
                            className="contactUsContent contact-tab-content-container"
                            tab={tab.tabName}
                            key={tab.tabKey}
                        >
                            {tab.component({
                                dataLoader,
                                form,
                                handleTabs,
                                activeKey
                            })}
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
}
